import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import aliquotaApi from "../../../../services/aliquotaApi";
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import AssessmentIcon from '@material-ui/icons/Assessment';

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        code: "",
        description: "",
        percentTax: "",
        taxType: {
            id: "",
            description: ""
        }
    }
};

export default function AliquotaPreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function getAliquota() {
            const aliquotaResponse = await aliquotaApi.findById(id);
            if (aliquotaResponse) {
                const aliquotaData = aliquotaResponse.data.response[0];
                setData({
                    code: aliquotaData.code,
                    description: aliquotaData.description,
                    percentTax: aliquotaData.percentTax,
                    taxType: aliquotaData.taxType.description
                })
            }
        }
        getAliquota();
    }, [])

    return (
        <Box>
            <PageTitle title="Aliquota" icon={<AssessmentIcon style={{ color: 'gray' }} />} />
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="code"
                        label="Código"
                        value={data.code}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="percentTax"
                        label="Percentual de Imposto"
                        value={data.percentTax}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="taxType"
                        label="Tipo de Imposto"
                        value={data.taxType}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    );
}