export const serialize = (data) => {
    const serializedData = {
        id: data.id,
        active: data.active,
        name: data.name,
        document: data.document,
        type: data.type,
        category: data.category,
        createdAt: data.createdAt,
    };

    if (data.addresses && data.addresses.length > 0) {
        const serializedAddresses = data.addresses.map(address => {
            const cleanedAddress = {};

            Object.keys(address).forEach(key => {
                if (!isEmpty(address[key])) {
                    cleanedAddress[key] = address[key];
                }
            });

            return cleanedAddress;
        }).filter(address => Object.keys(address).length > 0);

        if (serializedAddresses.length > 0) {
            serializedData.addresses = serializedAddresses;
        }
    }

    return serializedData;
}

const isEmpty = (value) => {
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
    }
    return value === '' || value === null || value === undefined;
};

export const serializeInactive = (data) => {
    return {
        id: data.id,
        active: data.active
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        name: data.code,
        createdAt: data.createdAt,
        type: data.name
    }
}


export default{
    serialize
}