import { Box, Button } from "@material-ui/core"
import BuildIcon from '@material-ui/icons/Build';
import React from "react";

export default function ButtonConfig(props) {
    const { onClick, className, title } = props;

    return (
        <Box className="c-inline-btn-float-right">
             <Button
                variant="outlined"
                color="primary"
                className={className}
                onClick={onClick}
                startIcon={<BuildIcon />}>
                {title}
            </Button>
        </Box>
    )
}