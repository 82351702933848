import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, TextField } from "@material-ui/core";
import toast from "../../../utils/toast";
import siteApi from "../../../services/siteApi";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";
import ButtonRemove from "../../../components/ButtonRemove";
import { useParams, useHistory } from 'react-router-dom';
import { useLoader } from "../../../hooks/LoaderContext";
import AlertDialog from "../../../components/AlertDialog";
import PageTitle from "../../../components/PageTitle";
import BasicReturn from "../../../components/BasicReturn";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';
import ButtonSubmit from "../../../components/ButtonSubmit";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        name: "",
        description: "",
    },
    sites: []
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().required(REQUIRED_FIELD)
});

export default function Sitio() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [siteList, setSiteList] = useState(DEFAULT_STATE.sites);
    const history = useHistory();
    const params = useParams();
    const { showLoader, hideLoader } = useLoader();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        async function findById() {
            showLoader();
            if (params.ident) {
                const siteResponse = await siteApi.findById(params.ident);
                if (siteResponse) {
                    const site = siteResponse.data.response[0];
                    const data = {
                        id: site.id,
                        name: site.name,
                        description: site.description
                    };
                    setData(data);
                    setSiteList(siteResponse.data.response);
                }
            }
            hideLoader();
        }
        findById();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let site;
            if (values.id.length == 0) {
                site = await siteApi.post(values);
            } else {
                site = await siteApi.put(values);
            }
            if (site) {
                handleSuccess(values.id.length == 0);
            } else {
                handleError();
            }
            redirectToSite();
        } catch (error) {
            console.error('Erro ao enviar formulário: ', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSuccess = (isNew) => {
        const sucessMessage = isNew ? "Sítio cadastrado com sucesso."
            : "Sítio atualizado com sucesso.";
        toast.success(sucessMessage);
    }

    const handleError = () => {
        toast.error(`Falha ao criar Sítio, favor verifique!.`);
    }

    const redirectToSite = () => {
        history.goBack();
    };

    const handleDelete = async () => {
        const deleted = await siteApi.delete(params.ident);
        if (deleted) {
            toast.success(`Site deletado com sucesso.`);
        } else {
            toast.error(`Falha ao excluir site, favor verifique!.`);
        }
        redirectToSite();
    };

    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseModalDelete = () => {
        setOpenDeleteModal(false);
    };

    return (
        <>
            <div className="container-fluid">
                <PageTitle title={!params.ident ? 'Cadastrar Sítio' : 'Editar Sítio'} icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
                <BasicReturn />
                <Divider />
                <div className="artical-1 out-shadow">
                    <Formik
                        initialValues={data}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ isSubmitting, touched, errors }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            label="Nome do Sítio"
                                            helperText={(touched.name && errors.name) && <ErrorMessage name="name" />}
                                            error={touched.name && errors.name}>
                                        </Field>
                                    </Grid>
                                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="description"
                                            label="Descrição do sítio"
                                            helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                                            error={touched.description && errors.description}>
                                        </Field>
                                    </Grid>
                                    <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                                            <Box className="mt-1 c-inline-btn">
                                                <Grid container spacing={2}>
                                                    {params.ident && (
                                                        <Grid item md={6} sm={6} xs={12}>
                                                            <ButtonRemove
                                                                title="excluir"
                                                                onClick={handleOpenDeleteModal}
                                                                variant="contained"
                                                                color="primary"
                                                                className="btn btn-primary btn-user btn-block"
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                                        <ButtonSubmit
                                                            label="salvar"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className="btn btn-primary btn-user btn-block"
                                                            disable={isSubmitting}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <AlertDialog title="Realmente deseja excluir?"
                description="O dado será excluído de forma permanente"
                confirmTitle="Confirmar"
                cancelTitle="Cancelar"
                openDialog={openDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleCloseModalDelete}
            />
        </>
    );

}