import { pathRoutes } from "../../config/constants";
import Conta from "./index";

export default [
  {
    path: pathRoutes.conta,
    component: Conta,
    exact: true,
  },
];
