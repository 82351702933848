import { del, get, patch, post, put } from "../utils/request"
import { serialize } from "./mapper/LineMapper";

export const create = async (data) => {
    return post("v1/config/line", serialize(data), `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const update = async (data) => {
    return put("v1/config/line", serialize(data), `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const status = async (data) => {
    return patch("v1/config/line", serialize(data), `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const findAll = async () => {
    return get("v1/config/line/all", `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const findAllPageable = async (page, size, filters) => {
    if (filters && filters != '') {
        return get(`v1/config/line/all?page=${page}&size=${size}&${filters}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
    }
    return get(`v1/config/line/all?page=${page}&size=${size}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const findById = async (id) => {
    return get(`v1/config/line/${id}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const remove = async (id) => {
    return del(`v1/config/line/${id}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export default {
    post: create,
    update,
    status,
    findAll,
    findAllPageable,
    findById,
    delete: remove,
    put: update
}