import { pathRoutes } from "../../../../config/constants";
import ItemUnidadeClienteCadastro from "./index";

export default [
  {
    path: pathRoutes.itemConfigUnidadeClienteCadastro,
    component: ItemUnidadeClienteCadastro,
    exact: true,
  },

  {
    path: pathRoutes.itemConfigUnidadeClienteEdit,
    component: ItemUnidadeClienteCadastro,
    exact: true,
  },
];
