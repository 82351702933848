export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        description: data.description,
        type: data.type,
        status: data.status,
        value: data.value,
        occurrenceDate: data.occurrenceDate,
        paymentMethod: data.paymentMethod,
        paymentCondition: data.paymentCondition,
        numberInstallment: data.numberInstallment,
        chartAccountItem: {
            id: data.chartAccountItem?.id
        },
        costCenter: {
            id: data.costCenter?.id
        },
        supplier: {
            id: data.supplier?.id
        },
        createdAt: data.createdAt
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        name: data.code,
        createdAt: data.createdAt,
        type: data.name
    }
}


export default{
    serialize
}