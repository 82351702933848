import { Divider,Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../components/PageTitle";
import Genero from "../../components/pages/Genero";
import Ncm from "../../components/pages/Ncm";
import { useQueryState } from "../../hooks/QueryState";

export default function NcmGenero() {

  const [currentTab, setCurrentTab] = useQueryState("tab", "genero");

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue.toString());
  }; 

  return (
      <div className="container-fluid">
        <PageTitle title="Genero/Ncm" icon={<AssignmentIcon style={{ color: 'gray' }}/>} />
        <Divider />
        <div className="artical-1 out-shadow">
          <TabContext value={currentTab}>
            <Tabs 
              value={currentTab} 
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered>
              <Tab label="Genero" value={"genero"} />
              <Tab label="Ncm" value={"ncm"} />
            </Tabs>
            <TabPanel value={"genero"}>
              <Genero />
            </TabPanel>
            <TabPanel value={"ncm"}>
              <Ncm />
            </TabPanel>
          </TabContext>
        </div>
      </div>
  );

}
