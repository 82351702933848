export const serialize = (data) => {
    return {
        id: data.id,
        category: data.category,
        days: data.days,
        type: data.type,
        description: data.description,
        active: data.active
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        category: data.category,
        description: data.description,
        type: data.type,
        active: data.active
    }

}

export default{
    serialize
}