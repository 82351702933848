import { pathRoutes } from "../../config/constants";
import Categoria from "./index";

export default [
  {
    path: pathRoutes.categoria,
    component: Categoria,
    exact: true,
  },
];
