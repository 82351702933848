import CentroCustosCadastro from ".";
import { pathRoutes } from "../../../config/constants";

export default [
  {
    path: pathRoutes.centroCustosCadastro,
    component: CentroCustosCadastro,
    exact: true,
  },
  {
    path: pathRoutes.centroCustosEdit,
    component: CentroCustosCadastro,
    exact: true,
  },
];
