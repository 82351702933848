import { Box, Divider,Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import { REQUIRED_FIELD, pathRoutes } from "../../../config/constants";
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from "formik";
import unitApi from "../../../services/unitApi";
import PieChartIcon from '@material-ui/icons/PieChart';
import BasicReturn from "../../../components/BasicReturn";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        code: "",
        description: "",
        createdAt: "",
        active: false
    }
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().required(REQUIRED_FIELD),
  });

export default function UnidadeCadastro() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const history = useHistory();
    const params = useParams();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        async function findById() {
            if (!isNaN(params.ident)) {
                const unitResponse = await unitApi.findById(params.ident);
                const unitData = unitResponse.data.response[0];
                if (unitData) {
                    const data = {
                        id : unitData.id,
                        code : unitData.code,
                        description : unitData.description,
                    };
                    setData(data);
                }
            }
        }
        findById();
    },[]);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let unit;
            if (values.id.length == 0) {
                unit = await unitApi.post(values);
            } else {
              unit = await unitApi.put(values);
            }
            if (unit) {
              handleSuccess(values.id.length == 0);
            } else {
              handleError();
            }
            redirectTo();
        } catch (error) {
          console.error('Erro ao enviar formulário: ', error);
        } finally {
          setSubmitting(false);
        }
    };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Unidade cadastrado com sucesso." 
                                : "Unidade atualizado com sucesso."; 
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar Unidade, favor verifique!.`);
  }

  const redirectTo = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    const deleted = await unitApi.delete(params.ident);
    if (deleted) {
        toast.success(`Unidade deletado com sucesso.`);
        redirectTo();
    } else {
      toast.error(`Falha ao excluir unidade, favor verifique!.`);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  
  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Cadastrar Unidade' : 'Editar Unidade'} icon={<PieChartIcon style={{ color: 'gray' }}/>} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
        <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting, touched, errors }) => (
            <Form className="mt-3">
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="code"
                    label="Sigla"
                    helperText={(touched.code && errors.code) && <ErrorMessage name="code" />}
                    error={touched.code && errors.code}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="description"
                    label="Descrição"
                    helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                    error={touched.description && errors.description}
                  />
                </Grid>
                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Box className="mt-1 c-inline-btn">
                            <Grid container spacing={2}>
                                {params.ident &&(
                                    <Grid  item md={6} sm={6} xs={12}>
                                        <ButtonRemove
                                            title="excluir"
                                            onClick={handleOpenDeleteModal}
                                            variant="contained"
                                            color="primary"
                                            className="btn btn-primary btn-user btn-block"
                                        />
                                    </Grid>
                                )}
                                <Grid item md={params.ident ? 6: 12} sm={params.ident ? 6: 12} xs={12}>
                                    <ButtonSubmit
                                        label="salvar"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn btn-primary btn-user btn-block"
                                        disable={isSubmitting}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
            </Form>
          )}
        </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?" 
            description="O dado será excluído de forma permanente" 
            confirmTitle="Confirmar"
            cancelTitle="Cancelar"
            openDialog={openDeleteModal} 
            onConfirm={handleDelete}
            onCancel={handleCloseModalDelete}
        />
    </>
  );
}