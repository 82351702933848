import { Box, Button, Divider, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "../../../DataTable";
import controlInvoiceNumberApi from "../../../../services/controlInvoiceNumberApi";
import toast from "../../../../utils/toast";
import SwitchStatus from "../../../SwitchStatus";
import DataTableDefaultAction from "../../../DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    number: "",
    series: "",
    model: "",
    active: true,
    createdAt: "",
    company: {
      id: ""
    }
  },
  controlInvoiceNumber: [],
  validateApi: []
};

export default function ControleNumerico(props) {

  const { identCompany } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  const [validate, setValidate] = useState(DEFAULT_STATE.validateApi);
  const [controlInvoiceNumberList, setControlInvoiceNumberList] = useState(DEFAULT_STATE.controlInvoiceNumber);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function getAllControle() {
      const controlResponse = await controlInvoiceNumberApi.findAllPageable(`&idCompany=${identCompany}`, 0, rowsPerPage);
      if (controlResponse) {
        setControlInvoiceNumberList(controlResponse.data.response);
        setTotalPages(controlResponse.data.totalPages);
      }
    }
    getAllControle();
  }, [forceRefreshData])

  const handleInputChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const handleSalvar = async e => {
    e.preventDefault();

    if (data.id.length == 0) {
      data.company.id = identCompany;
      setValidate(await controlInvoiceNumberApi.post(data));
    } else {
      setValidate(await controlInvoiceNumberApi.put(data));
    }
    if (validate) {
      handleSuccess(data.id.length == 0);
      cleanForm();
      refreshDatatable();
    } else {
      handleError();
    }
  };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Controle numerico cadastrado com sucesso."
      : "Controle numerico atualizado com sucesso.";
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar Controle numerico, favor verifique!.`);
  }

  const handleDelete = async (id) => {
    const deleted = await controlInvoiceNumberApi.delete(id);
    if (deleted) {
      cleanForm();
      refreshDatatable();
      toast.success(`Controle numerico deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Controle numerico, favor verifique!.`);
    }
  };

  const handleEdit = async (id) => {
    const controlResponse = await controlInvoiceNumberApi.findById(id);
    if (controlResponse) {
      const data = {
        id: controlResponse.data.response[0].id,
        number: controlResponse.data.response[0].number,
        series: controlResponse.data.response[0].series,
        model: controlResponse.data.response[0].model,
        company: {
          id: controlResponse.data.response[0].company.id
        }
      };
      setData(data);
    }
  };

  const cleanForm = () => {
    setData(DEFAULT_STATE.data);
  }

  const refreshDatatable = async () => {
    const controlResponse = await controlInvoiceNumberApi.findAllPageable(`&idCompany=${identCompany}`, page - 1, rowsPerPage);
    if (controlResponse) {
      setControlInvoiceNumberList(controlResponse.data.response);
    } else {
      setControlInvoiceNumberList(DEFAULT_STATE.controlInvoiceNumber);
    }
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    const controlResponse = await controlInvoiceNumberApi.findAllPageable(value - 1, rowsPerPage);
    if (controlResponse) {
      setControlInvoiceNumberList(controlResponse.data.response);
      setTotalPages(controlResponse.data.totalPages);
    }
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const controlResponse = await controlInvoiceNumberApi.findAllPageable(0, rows);
    if (controlResponse) {
      setControlInvoiceNumberList(controlResponse.data.response);
      setTotalPages(controlResponse.data.totalPages);
    }
  };

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const controlInvoiceNumber = await controlInvoiceNumberApi.status(data);
    if (controlInvoiceNumber) {
      if (controlInvoiceNumber.data.response[0].active) {
        toast.success(`Controle numerico ativado com sucesso.`);
      } else {
        toast.success(`Controle numerico desativado com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const header = ['Numero', 'Serie', 'Modelo Fiscal', 'Data Inclusão', 'Situação', 'Ações'];

  const rows = controlInvoiceNumberList.map(control => [
    control.number,
    control.series,
    control.model,
    control.createdAt,
    <SwitchStatus
      id={control.id}
      active={control.active}
      actions={{
        onSwitch: (id, status) => handleChangeActive(id, status)
      }}
    />,
    <DataTableDefaultAction
      id={control.id}
      actions={{
        onEdit: () => handleEdit(control.id),
        onDelete: () => handleDelete(control.id),
        onPreview: () => handlePreview(control.id)
      }}
    />
  ]);

  const model = [
    {
      value: 'NFE',
      label: 'Nota Fiscal Eletrônica',
    }
  ];

  return (
    <div className="artical-1 out-shadow">
      <form>
        <Box>
          <Grid container spacing={2}>
            <Grid className="mt-4 mb-3" item md={3} sm={3} xs={12}>
              <TextField
                variant="outlined"
                className="form-control"
                name="model"
                select
                label="Modelo Documento"
                value={data.model}
                onChange={handleInputChange}
                helperText="Selecione o Modelo do documento">
                {model.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
                )}
              </TextField>
            </Grid>
            <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
              <TextField
                variant="outlined"
                className="form-control"
                name="number"
                label="Número"
                value={data.number}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
              <TextField
                variant="outlined"
                className="form-control"
                name="series"
                label="Serie"
                value={data.series}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box className="mt-2 c-inline-btn">
                <Button
                  color="primary"
                  variant="contained"
                  className="btn btn-primary btn-user btn-block"
                  onClick={handleSalvar}>
                  Salvar
                </Button>
              </Box>
              <Box className="mt-2 c-inline-btn">
                {data.id && (
                  <Button
                    color="primary"
                    onClick={cleanForm}>
                    Cancelar Edição
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
      <Box>
        <DataTable header={header}
          rows={rows}
          totalPages={totalPages}
          page={page}
          rowsPerPage={rowsPerPage}
          onPage={handleChangePage}
          onRowsPerPage={handleChangeRowsPerPage} />
      </Box>
    </div>
  );
}