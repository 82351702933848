import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '6px', // Ajuste conforme necessário para alinhar o ícone visualmente
    minWidth: 'auto', // Remove o mínimo de largura padrão do Material-UI para botões
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.light,
    },
    '&:focus': {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
    },
  },
  icon: {
    color: 'inherit',
  },
}));

export default function ButtonRemoveIcon(props) {
  const { onClick, className, size, title } = props;
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <Button
        variant="outlined"
        size={size}
        className={`${classes.button} ${className} btn-primary`}
        onClick={onClick}
      >
        <DeleteOutlineIcon className={classes.icon} />
      </Button>
    </Tooltip>
  );
}
