import { pathRoutes } from "../../../../config/constants";
import ItemPrecificacaoPadraoCadastro from "./index";

export default [
  {
    path: pathRoutes.itemConfigPrecificacaoPadraoCadastro,
    component: ItemPrecificacaoPadraoCadastro,
    exact: true,
  },

  {
    path: pathRoutes.itemConfigPrecificacaoPadraoEdit,
    component: ItemPrecificacaoPadraoCadastro,
    exact: true,
  },
];
