import { del, get, patch, post, put } from "../utils/request"
import { serialize } from './mapper/OrganizationMapper'

export const create = async (data) => {
    return post("v1/config/organization", serialize(data), undefined, true);
}

export const update = async (data) => {
    return put("v1/config/organization", serialize(data), undefined, true);
}

export const status = async (data) => {
    return patch("v1/config/organization", serialize(data), undefined, true);
}

export const findAll = async () => {
    return get("v1/config/organization/all", undefined, true);
}

export const findAllPageable = async (page, size) => {
    return get(`v1/config/organization/all?page=${page}&size=${size}`, undefined, true);
}

export const findById = async (id) => {
    return get(`v1/config/organization/${id}`, undefined, true);
}

export const remove = async (id) => {
    return del(`v1/config/organization/${id}`, undefined, true);
}

export default {
    post : create,
    update,
    status,
    findAll,
    findAllPageable,
    findById,
    delete : remove,
    put : update
}