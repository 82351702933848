export const serialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        description: data.description
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        description: data.description
    }
}

export default{
    serialize
}