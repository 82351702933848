import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import toast from "../../utils/toast";
import DataTable from "../../components/DataTable";
import PreviewModal from "../../components/PreviewModal";
import ButtonAdd from "../../components/ButtonAdd";
import PageTitle from "../../components/PageTitle";
import categoryApi from "../../services/categoryApi";
import CategoriaFilter from "../../components/pages/categoria/CategoriaFilter";
import { pathRoutes } from "../../config/constants";
import CategoriaPreview from "../../components/pages/categoria/CategoriaPreview";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import ItemActions from "../../components/ItemActions";
import CategoryIcon from '@material-ui/icons/Category';

const DEFAULT_STATE = {
  isLoading: false,
  items: [],
  categories: [],
  categoryInput: {
    title: "",
    id: ""
  }
};

export default function Categoria() {

  const [categoryList, setCategoryList] = useState(DEFAULT_STATE.categories);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [categoryIdPreview, setCategoryIdPreview] = useState(0);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [filters, setFilters] = useState('');
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
    async function getAllCategories() {
      showLoader();
      const categoryResponse = await categoryApi.findAllPageable(0, rowsPerPage, filters);
      if (categoryResponse) {
        setCategoryList(categoryResponse.data.response);
        setTotalPages(categoryResponse.data.totalPages);
      } else {
        setCategoryList(DEFAULT_STATE.categories);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllCategories();
  }, [filters, forceRefreshData]);

  const handlePreview = (id) => {
    setCategoryIdPreview(id);
    setShowPreview(true);
  };


  const handleDelete = async (id) => {
    const deleted = await categoryApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Categoria deletada com sucesso.`);
    } else {
      toast.error(`Falha ao excluir categoria, favor verifique!.`);
    }
  };

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const category = await categoryApi.status(data);
    if (category) {
      if (category.data.response[0].active) {
        toast.success(`Categoria ativada com sucesso.`);
      } else {
        toast.success(`Categoria desativada com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleFilter = async (filters) => {
    setFilters(filters);
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    const categoryResponse = await categoryApi.findAllPageable(value - 1, rowsPerPage, filters);
    if (categoryResponse) {
      setCategoryList(categoryResponse.data.response);
      setTotalPages(categoryResponse.data.totalPages);
    }
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const categoryResponse = await categoryApi.findAllPageable(0, rows, filters);
    if (categoryResponse) {
      setCategoryList(categoryResponse.data.response);
      setTotalPages(categoryResponse.data.totalPages);
    }
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.categoria}/edicao/${id}`)
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.categoria}/cadastro`)
  };

  const handleConfig = async (id) => {
    history.push(`${pathRoutes.categoryConfig}`.replace(":ident?", id))
  };


  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const header = ['Nome', 'Data Inclusão', 'Situação', 'Ações'];

  const rows = categoryList.map(category => [
    category.name,
    category.createdAt,
    <SwitchStatus
      id={category.id}
      active={category.active}
      actions={{
        onSwitch: (id, status) => handleChangeActive(id, status)
      }}
    />,
    <ItemActions
      id={category.id}
      actions={{
        onEdit: () => handleEdit(category.id),
        onDelete: () => handleDelete(category.id),
        onPreview: () => handlePreview(category.id),
        onConfig: () => handleConfig(category.id)
      }}
    />
  ]);

  return (
    <div className="container-fluid">
      <PageTitle title="Categoria" icon={<CategoryIcon style={{ color: 'gray' }} />} />
      <div className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <CategoriaFilter onFilter={handleFilter} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Categoria" onClick={handleCreate} />
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable header={header}
                rows={rows}
                totalPages={totalPages}
                page={page}
                onPage={handleChangePage}
                onRowsPerPage={handleChangeRowsPerPage} />
            </Box>
            <PreviewModal openDialog={showPreview}
              onCloseDialog={handleClosePreview}
              width="70%" height="75%">
              <CategoriaPreview id={categoryIdPreview} />
            </PreviewModal>
          </Grid>
        </Grid>
      </div>
    </div>
  );

}