import { pathRoutes } from "../../../../config/constants";
import OperacaoFiscalCategoriaCadastro from "./index";

export default [
  {
    path: pathRoutes.operacaoFiscalConfigCategoryCadastro,
    component: OperacaoFiscalCategoriaCadastro,
    exact: true,
  },

  {
    path: pathRoutes.operacaoFiscalConfigCategoryEdit,
    component: OperacaoFiscalCategoriaCadastro,
    exact: true,
  },
];
