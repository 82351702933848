import { Box, Divider, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import ncmApi from "../../../services/ncmApi";
import { Autocomplete } from "@material-ui/lab";
import generoApi from "../../../services/generoApi";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    code: "",
    description: "",
    genero: {
      id: "",
      code: "",
      description: ""
    },
  },
  generos: [],
  generoInput: {
    title: "",
    id: ""
  }
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD),
});

export default function NcmCadastro() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [generoList, setGeneroList] = useState(DEFAULT_STATE.generos);
  const [genero, setGenero] = useState(DEFAULT_STATE.generoInput);

  useEffect(() => {
    async function getAllGenero() {
      const generosResponse = await generoApi.findAll();
      if (generosResponse) {
        setGeneroList(generosResponse.data.response);
      }
    }
    getAllGenero();
  }, []);

  useEffect(() => {
    handleEdit(params.ident);
  }, []);

  const handleEdit = async (id) => {
    if (!isNaN(id)) {
      const ncmReponse = await ncmApi.findById(id);
      if (ncmReponse) {
        const data = {
          id: ncmReponse.data.response[0].id,
          code: ncmReponse.data.response[0].code,
          description: ncmReponse.data.response[0].description,
          genero: {
            id: ncmReponse.data.response[0].genero.id,
            code: ncmReponse.data.response[0].genero.code,
            description: ncmReponse.data.response[0].genero.description
          }
        };
        setData(data);
        setGenero({
          title: `${ncmReponse.data.response[0].genero.code} - ${ncmReponse.data.response[0].genero.description}`,
          id: ncmReponse.data.response[0].genero.id
        });
      }
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let ncm;
      if (values.id.length == 0) {
        ncm = await ncmApi.post(values);
      } else {
        ncm = await ncmApi.put(values);
      }
      if (ncm) {
        handleSuccess(values.id.length);
      } else {
        handleError();
      }
      redirectToNcmGenero();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "NCM cadastrada com sucesso."
      : "NCM atualizado com sucesso.";
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar NCM, favor verifique!.`);
  }

  const redirectToNcmGenero = () => {
    history.goBack();
  };

  const generos = generoList.map(genero => ({
    title: `${genero.code} - ${genero.description}`,
    id: genero.id
  }));

  const handleDelete = async () => {
    const deleted = await ncmApi.delete(params.ident);
    if (deleted) {
      redirectToNcmGenero();
      toast.success(`NCM deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir NCM, favor verifique!.`);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const handleChangeGenero = (value, setFieldValue) => {
    setGenero(value)
    setFieldValue("genero", value ? value : '');
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Cadastrar NCM' : 'Editar NCM'} icon={<AssignmentIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors, setFieldValue, values }) => (
              <Form className='mt-3'>
                <Grid container spacing={2}>
                  <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="code"
                      label="Código"
                      helperText={touched.code && errors.code ? <ErrorMessage name="code" /> : ""}
                      error={touched.code && Boolean(errors.code)}
                    />
                  </Grid>
                  <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="description"
                      label="Descrição"
                      helperText={touched.description && errors.description ? <ErrorMessage name="description" /> : ""}
                      error={touched.description && Boolean(errors.description)}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-3" item md={8} sm={8} xs={14}>
                    <Autocomplete
                      id="autocomplete-genero"
                      options={generos}
                      clearText="Limpar"
                      value={genero}
                      onChange={(event, value) => handleChangeGenero(value, setFieldValue)}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField {...params} label="Genero" variant="outlined" />
                      )
                      }
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <Grid item md={6} sm={6} xs={12}>
                              <ButtonRemove
                                title="excluir"
                                onClick={handleOpenDeleteModal}
                                variant="contained"
                                color="primary"
                                className="btn btn-primary btn-user btn-block"
                              />
                            </Grid>
                          )}
                          <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disable={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}