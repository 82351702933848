import { Box, Divider,Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EcoIcon from '@material-ui/icons/Eco';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import { REQUIRED_FIELD, pathRoutes } from "../../../config/constants";
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import chartAccountApi from "../../../services/chartAccountApi";
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from "formik";
import chartAccountItemApi from "../../../services/chartAccountItemApi";
import { Autocomplete } from "@material-ui/lab";
import BasicReturn from "../../../components/BasicReturn";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        name: "",
        description: "",
        createdAt: "",
        charAccount: {
          id : ""
        },
        active: false
    },
    chartAccounts: [],
    chartAccountInput : {
      title: "",
      id: ""
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().required(REQUIRED_FIELD),
    chartAccount: Yup.object().shape({
      id: Yup.string().required('Plano de Conta é obrigatório'),
    }),
  });

export default function PlanoContaItemCadastro() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const history = useHistory();
    const params = useParams();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [chartAccount, setChartAccount] = useState(DEFAULT_STATE.chartAccountInput);
    const [chartAccountList, setChartAccountList] = useState(DEFAULT_STATE.chartAccounts);


    useEffect(() => {
      async function getAllChartAccounts() {
          const chartAccountResponse = await chartAccountApi.findAll();
          if (chartAccountResponse) {
            setChartAccountList(chartAccountResponse.data.response);
          }
      }
      getAllChartAccounts();
    },[]);


    useEffect(() => {
        async function findById() {
            if (!isNaN(params.ident)) {
                const chartAccountItemResponse = await chartAccountItemApi.findById(params.ident);
                const chartAccountItemData = chartAccountItemResponse.data.response[0];
                if (chartAccountItemData) {
                    const data = {
                        id : chartAccountItemData.id,
                        name : chartAccountItemData.name,
                        description : chartAccountItemData.description,
                        chartAccount: {
                          id: chartAccountItemData.chartAccount.id
                        }
                    };
                    setData(data);
                    setChartAccount({title: chartAccountItemData.chartAccount.name, 
                                     id: chartAccountItemData.chartAccount.id
                                    });
                }
            }
        }
        findById();
    },[]);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let chartAccountItem;
            if (values.id.length == 0) {
                chartAccountItem = await chartAccountItemApi.post(values);
            } else {
              chartAccountItem = await chartAccountItemApi.put(values);
            }
            if (chartAccountItem) {
              handleSuccess(values.id.length == 0);
            } else {
              handleError();
            }
            redirectTo();
        } catch (error) {
          console.error('Erro ao enviar formulário: ', error);
        } finally {
          setSubmitting(false);
        }
    };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Item do Plano de Conta cadastrado com sucesso." 
                                : "Item do Plano de Conta atualizado com sucesso."; 
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar Item do Plano de Contas, favor verifique!.`);
  }

  const redirectTo = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    const deleted = await chartAccountItemApi.delete(params.ident);
    if (deleted) {
        toast.success(`Item do Plano de Contas deletado com sucesso.`);
        redirectTo();
    } else {
      toast.error(`Falha ao excluir item do plano de contas, favor verifique!.`);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  
  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const chartAccounts = chartAccountList.map(chartAccount => ({
    title: chartAccount.name,
    id: chartAccount.id
  }));

  const handleChangeAccount = (value) => {
    setChartAccount(value);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Cadastrar Item' : 'Editar Item'} icon={<EcoIcon style={{ color: 'gray' }}/>} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
        <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting, touched, errors }) => (
            <Form className="mt-3">
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="name"
                    label="Nome"
                    helperText={(touched.name && errors.name) && <ErrorMessage name="name" />}
                    error={touched.name && errors.name}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="description"
                    label="Descrição"
                    helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                    error={touched.description && errors.description}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    name="chartAccount.id"
                    render={({ form }) => (
                      <Autocomplete
                        id="autocomplete-chartAccount"
                        options={chartAccounts}
                        getOptionLabel={(option) => option.title}
                        value={chartAccount}
                        onChange={(event, value) => {
                          form.setFieldValue("chartAccount.id", value ? value.id : '');
                          handleChangeAccount(value ? value : '');
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Plano de Conta" variant="outlined" 
                            error={form.touched.chartAccount && !!form.errors.chartAccount}
                            helperText={form.touched.chartAccount && form.errors.chartAccount}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Box className="mt-1 c-inline-btn">
                            <Grid container spacing={2}>
                                {params.ident &&(
                                    <Grid  item md={6} sm={6} xs={12}>
                                        <ButtonRemove
                                            title="excluir"
                                            onClick={handleOpenDeleteModal}
                                            variant="contained"
                                            color="primary"
                                            className="btn btn-primary btn-user btn-block"
                                        />
                                    </Grid>
                                )}
                                <Grid item md={params.ident ? 6: 12} sm={params.ident ? 6: 12} xs={12}>
                                    <ButtonSubmit
                                        label="salvar"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn btn-primary btn-user btn-block"
                                        disable={isSubmitting}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
            </Form>
          )}
        </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?" 
            description="O dado será excluído de forma permanente" 
            confirmTitle="Confirmar"
            cancelTitle="Cancelar"
            openDialog={openDeleteModal} 
            onConfirm={handleDelete}
            onCancel={handleCloseModalDelete}
        />
    </>
  );
}