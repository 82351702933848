import React, { useState } from 'react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AlertDialog from '../AlertDialog';
import DeleteIcon from '@material-ui/icons/Delete';

function DataTableDefaultAction(props) {
  const { id, actions } = props;
  const { onEdit, onDelete, onPreview } = actions;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const openDeleteDialog = () => {
    setOpenDeleteModal(true);
  }

  const handleConfirm = () => {
    onDelete(id);
    setOpenDeleteModal(false);
  }

  const handleCancel = () => {
    setOpenDeleteModal(false);
  }

  return (
    <React.Fragment>
      <PopupState variant="popover">
        {(popupState) => (
          <React.Fragment>
            <Button {...bindTrigger(popupState)} >
              <MenuIcon />
            </Button>
            <Menu {...bindMenu(popupState)}>
              {onEdit && (
                <Tooltip title="Editar">
                  <MenuItem onClick={() => { popupState.close(); onEdit(id); }}>
                  <EditIcon style={{ color: 'gray' }}/>
                </MenuItem>
                </Tooltip>
              )}
              {onPreview && (
                <Tooltip title="Visualizar">
                  <MenuItem onClick={() => {popupState.close(); onPreview(id); }}>
                    <VisibilityIcon style={{ color: 'gray' }}/>
                  </MenuItem>
                </Tooltip>
              )}
              {onDelete && (
                <Tooltip title="Remover">
                  <MenuItem onClick={() => {popupState.close(); openDeleteDialog(); }}>
                    <DeleteIcon style={{ color: 'gray' }}/>
                  </MenuItem>
                </Tooltip>
              )}
            </Menu>
          </React.Fragment>
      )}
    </PopupState>
      <AlertDialog title="Realmente deseja excluir?" 
          description="O dado será excluído de forma permanente" 
          confirmTitle="Confirmar"
          cancelTitle="Cancelar"
          openDialog={openDeleteModal} 
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
    </React.Fragment>
  );
}

export default DataTableDefaultAction;

