import { Box, Divider, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScheduleIcon from '@material-ui/icons/Schedule';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import scheduleApi from "../../../services/scheduleApi";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    category: "",
    type: "",
    description: "",
    days: "",
    active: false
  }
};

const validationSchema = Yup.object().shape({
  category: Yup.string().required(REQUIRED_FIELD),
  type: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD),
  days: Yup.number().required(REQUIRED_FIELD),
});

export default function AgendamentoCadastro() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    handleEdit(params.ident);
  }, []);

  const handleEdit = async (id) => {
    if (id) {
      const scheduleReponse = await scheduleApi.findById(id);
      if (scheduleReponse) {
        const data = {
          id: scheduleReponse.data.response[0].id,
          category: scheduleReponse.data.response[0].category,
          type: scheduleReponse.data.response[0].type,
          description: scheduleReponse.data.response[0].description,
          days: scheduleReponse.data.response[0].days
        };
        setData(data);
      }
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let schedule;
      if (values.id.length == 0) {
        schedule = await scheduleApi.post(values);
      } else {
        schedule = await scheduleApi.put(values);
      }
      if (schedule) {
        handleSuccess(data.id.length == 0);
      } else {
        handleError();
      }
      redirectToAgendamento();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Agendamento cadastrado com sucesso."
      : "Agendamento atualizado com sucesso.";
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar Agendamento, favor verifique!.`);
  }

  const redirectToAgendamento = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    const deleted = await scheduleApi.delete(params.ident);
    if (deleted) {
      redirectToAgendamento();
      toast.success(`Agendamento deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Agendamento, favor verifique!.`);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const categories = [
    {
      value: 'INPUT',
      label: 'Aplicação de Insumo',
    },
    {
      value: 'PLANTING',
      label: 'Replantio',
    }
  ];

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={!params.ident? 'Cadastrar Agendamento' : 'Editar Agendamento'} icon={<ScheduleIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      select
                      name="category"
                      label="Categoria"
                      helperText={
                        (touched.category && errors.category)
                          ? <ErrorMessage name="category" />
                          : "Selecione a categoria do agendamento"
                      }
                      error={touched.category && errors.category}>
                      {categories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="type"
                      label="Tipo"
                      helperText={(touched.type && errors.type) && <ErrorMessage name="type" />}
                      error={touched.type && errors.type}>
                    </Field>
                  </Grid>
                  <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="description"
                      label="Descrição"
                      helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                      error={touched.description && errors.description}>
                    </Field>
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="days"
                      label="Prazo após o plantio"
                      inputProps={{
                        type: 'number'
                      }}
                      helperText={(touched.days && errors.days) && <ErrorMessage name="days" />}
                      error={touched.days && errors.days}>
                    </Field>
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <Grid item md={6} sm={6} xs={12}>
                              <ButtonRemove
                                title="excluir"
                                onClick={handleOpenDeleteModal}
                                variant="contained"
                                color="primary"
                                className="btn btn-primary btn-user btn-block"
                              />
                            </Grid>
                          )}
                          <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disabled={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}