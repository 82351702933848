import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import ButtonSearch from "../../../../../ButtonSearch";
import BoxFilter from "../../../../../BoxFilter";
import aliquotaApi from "../../../../../../services/aliquotaApi";
import { useQueryState, useSetQueryStateValues } from "../../../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    aliquotPercents: [],
    aliquotPercentInput: {
        title: "",
        id: ""
    }
};

export default function OperacaoFiscalCategoriaFilter(props) {

    const { onFilter}  =  props;

    const [setValues] = useSetQueryStateValues();

    const [aliquotPercentId, setAliquotPercentId] = useQueryState("aliquot", "");
    const [cstId, setCstId] = useQueryState("cst", "");

    const [aliquotPercent, setAliquotPercent] = useState(DEFAULT_STATE.aliquotPercentInput);
    const [aliquotPercentList, setAliquotPercentList] = useState(DEFAULT_STATE.aliquotPercents);

    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
      }, [])

    useEffect(() => {
        async function getAllAliquots() {
            const aliquotResponse = await aliquotaApi.findAll();
            if (aliquotResponse) {
                setAliquotPercentList(aliquotResponse.data.response);
            }
        }
        getAllAliquots();
    },[]);

    const aliquotPercents = aliquotPercentList.map(aliquotPercent => ({
        title: `${aliquotPercent.code} - ${aliquotPercent.description}`,
        id: aliquotPercent.id
    }));

    const handleFilter = async e => {
        let param = '';
        if (aliquotPercentId){
            param = param.concat(`&aliquot_percent_id=${aliquotPercentId}`);
        }
        if (cstId){
            param = param.concat(`&cst_id=${cstId}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (aliquotPercentList.length > 0 && aliquotPercentId) {
                const foundItem = aliquotPercents.find(aliquot => aliquot.id == aliquotPercentId);
                if (foundItem) {
                    setAliquotPercent({title: `${foundItem.code} - ${foundItem.description}`, id: foundItem.id});
                }
            }
        }
        findAutocompleteQueryFilters();
    }, [aliquotPercentList]);

    const cleanFilter = async e => {
        onFilter();
        setValues({aliquot: "", cst: ""});
        setAliquotPercent(DEFAULT_STATE.aliquotPercentInput);
        setIsFiltering(false);
    };

    const handleChangeAliquot = (value) => {
        setAliquotPercent(value);
        setAliquotPercentId(value.id);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mt-2 mb-1" item md={12} sm={12} xs={12}>
                    <Autocomplete
                        id="autocomplete-aliquot-filter"
                        options={aliquotPercents}
                        clearText="Limpar"
                        value={aliquotPercent}
                        onChange={(event, value) => handleChangeAliquot(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Alíquota" variant="outlined" />
                        }
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}