import React, { useEffect, useState } from "react";
import lineApi from "../../../services/lineApi";
import { Box, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import toast from "../../../utils/toast";
import sectorApi from "../../../services/sectorApi";
import DataTable from "../../DataTable";
import ButtonAdd from "../../ButtonAdd";
import { pathRoutes } from "../../../config/constants";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../../hooks/LoaderContext";
import LineFilter from "./LineFilter";
import SwitchStatus from "../../SwitchStatus";
import DataTableDefaultAction from "../../DataTableDefaultActions";
import LinePreview from "./LinePreview";
import PreviewModal from "../../PreviewModal";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        sector: {
            id: ""
        }
    },
    lines: [],
    sectors: [],
    sectorInput: {
        title: "",
        id: ""
    }
};

export default function Linha() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [sector, setSector] = useState(DEFAULT_STATE.sectorInput);
    const [sectorList, setSectorList] = useState(DEFAULT_STATE.sectors);
    const [lineList, setLineList] = useState(DEFAULT_STATE.lines);
    const [lineIdPreview, setLineIdPreview] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();
    const [filters, setFilters] = useState();
    const [forceRefreshData, setForceRefreshData] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        showLoader();
        async function getAllLines() {
            const lineResponse = await lineApi.findAllPageable(0, rowsPerPage, filters);
            if (lineResponse) {
                setLineList(lineResponse.data.response);
                setTotalPages(lineResponse.data.totalPages);
            } else {
                setLineList(DEFAULT_STATE.lines);
                setTotalPages(0);
            }
            hideLoader();
        }
        getAllLines();
    }, [filters, forceRefreshData, rowsPerPage]);

    const handleFilter = async (filters) => {
        setFilters(filters);
        setForceRefreshData(!forceRefreshData);
    };

    const handleDelete = async (id) => {
        const deleted = await lineApi.delete(id);
        if (deleted) {
            cleanForm();
            setForceRefreshData(!forceRefreshData);
            toast.success(`Linha deletada com sucesso.`);
        } else {
            toast.error(`Falha ao excluir linha, favor verifique!.`);
        }
    };

    const handlePreview = (id) => {
        setLineIdPreview(id);
        setShowPreview(true);
    };

    const handleClosePreview = (id) => {
        setShowPreview(false);
    };

    const handleEdit = async (id) => {
        history.push(`${pathRoutes.line}/edicao/${id}`)
    };

    const handleCreate = async () => {
        history.push(`${pathRoutes.lineCadastro}`)
    };

    const handleChangeActive = async (id, status) => {
        const data = {
            id: id,
            active: status
        };
        const line = await lineApi.status(data);
        if (line) {
            if (line.data.response[0].active) {
                toast.success(`Linha ativada com sucesso.`);
            } else {
                toast.success(`Linha desativada com sucesso.`);
            }
            setForceRefreshData(!forceRefreshData);
        }
    };

    const handleChangePage = async (event, value) => {
        setPage(value);
        const lineResponse = await lineApi.findAllPageable(value - 1, rowsPerPage);
        if (lineResponse) {
            setLineList(lineResponse.data.response);
            setTotalPages(lineResponse.data.totalPages);
        }
    };

    const handleChangeRowsPerPage = async (rows) => {
        setRowsPerPage(rows);
        const lineResponse = await lineApi.findAllPageable(0, rows);
        if (lineResponse) {
            setLineList(lineResponse.data.response);
            setTotalPages(lineResponse.data.totalPages);
        }
    };

    const cleanForm = () => {
        setData(DEFAULT_STATE.data);
        setSector(DEFAULT_STATE.sectorInput);
    }

    const header = ['Código', 'Data Inclusão', 'Setor', 'Situação', 'Ações'];

    const rows = lineList.map(line => [
        line.code,
        line.createdAt,
        line.sector.code,
        <SwitchStatus
            id={line.id}
            active={line.active}
            actions={{
                onSwitch: (id, status) => handleChangeActive(id, status)
            }}
        />,
        <DataTableDefaultAction
            id={line.id}
            actions={{
                onEdit: () => handleEdit(line.id),
                onDelete: () => handleDelete(line.id),
                onPreview: () => handlePreview(line.id)
            }}
        />
    ]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <LineFilter onFilter={handleFilter} />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Box>
                        <ButtonAdd title="Cadastrar Linha" onClick={handleCreate} />
                    </Box>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Box position="relative">
                        <DataTable header={header}
                            rows={rows}
                            totalPages={totalPages}
                            page={page}
                            onPage={handleChangePage}
                            onRowsPerPage={handleChangeRowsPerPage} />
                    </Box>
                    <PreviewModal openDialog={showPreview}
                        onCloseDialog={handleClosePreview}
                        width="70%" height="35%">
                        <LinePreview id={lineIdPreview} />
                    </PreviewModal>
                </Grid>
            </Grid>
        </>
    );

}