export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        description: data.description,
        genero: {
            id: data.genero.id,
            code: data.genero.code,
            description: data.genero.description
        }
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        description: data.description,
        genero: {
            id: data.genero.id,
            code: data.genero.code,
            description: data.genero.description
        }
    }

}


export default{
    serialize
}