import PedidoCadastro from ".";
import { pathRoutes } from "../../../config/constants";

export default [
  {
    path: pathRoutes.pedidoCadastro,
    component: PedidoCadastro,
    exact: true,
  },
  {
    path: pathRoutes.pedidoEdit,
    component: PedidoCadastro,
    exact: true,
  },
];
