import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import serviceApi from "../../../../services/serviceApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: false,
        code: "",
        description: ""
    },
  };
 
export default function ServicoPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const serviceReponse = await serviceApi.findById(id);
        if (serviceReponse) {
          const data = {
              id : serviceReponse.data.response[0].id,
              code : serviceReponse.data.response[0].code,
              description : serviceReponse.data.response[0].description
          };
          setData(data);
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Serviço" icon={<BuildRoundedIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="code"
                        label="Código"
                        value={data.code}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}