import { Box, Divider, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import { REQUIRED_FIELD } from "../../../config/constants";
import AssignmentIcon from '@material-ui/icons/Assignment';
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import costCenterApi from "../../../services/costCenterApi";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import BasicReturn from "../../../components/BasicReturn";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD)
});

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    active: true,
    name: "",
    description: ""
  },
  costCenters: []
};

export default function CentroCustosCadastro() {
  const [data, setData] = useState(DEFAULT_STATE.data);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    async function findById() {
      if (!isNaN(params.ident)) {
        const costCenterResponse = await costCenterApi.findById(params.ident);
        if (costCenterResponse) {
          const data = {
            id : costCenterResponse.data.response[0].id,
            name : costCenterResponse.data.response[0].name,
            description: costCenterResponse.data.response[0].description
          };
          setData(data);
        }
      }
    }
    findById();
  },[]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let costCenter;
      if (values.id.length === 0) {
        costCenter = await costCenterApi.post(values);
      } else {
        costCenter = await costCenterApi.put(values);
      }
      if (costCenter) {
        handleSuccess(values.id.length === 0);
      } else {
        handleError();
      }
      redirectTo();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuccess = (isNew) => {
    const successMessage = isNew ? "Centro de custos cadastrado com sucesso." 
                                 : "Centro de custos atualizado com sucesso."; 
    toast.success(successMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar Centro de Custos, favor verifique!.`);
  }

  const redirectTo = () => {
    history.goBack();
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  
  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = async () => {
    const deleted = await costCenterApi.delete(params.ident);
    if (deleted) {
      toast.success(`Centro de custos deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir centro de custos, favor verifique!.`);
    }
    redirectTo();
  };

  return (
    <>
    <div className="container-fluid">
      <PageTitle title={isNaN(params.ident) ? 'Cadastrar Centro de Custos' : 'Editar Centro de Custos'} icon={<AssignmentIcon style={{ color: 'gray' }}/>} />
      <BasicReturn />
      <Divider />
      <div className="artical-1 out-shadow">
        <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting, touched, errors }) => (
            <Form className="mt-3">
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="name"
                    label="Nome"
                    helperText={(touched.name && errors.name) && <ErrorMessage name="name" />}
                    error={touched.name && errors.name}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="description"
                    label="Descrição"
                    helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                    error={touched.description && errors.description}
                  />
                </Grid>
                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Box className="mt-1 c-inline-btn">
                            <Grid container spacing={2}>
                                {params.ident &&(
                                    <Grid  item md={6} sm={6} xs={12}>
                                        <ButtonRemove
                                            title="excluir"
                                            onClick={handleOpenDeleteModal}
                                            variant="contained"
                                            color="primary"
                                            className="btn btn-primary btn-user btn-block"
                                        />
                                    </Grid>
                                )}
                                <Grid item md={params.ident ? 6: 12} sm={params.ident ? 6: 12} xs={12}>
                                    <ButtonSubmit
                                        label="salvar"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn btn-primary btn-user btn-block"
                                        disable={isSubmitting}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
    <AlertDialog title="Realmente deseja excluir?" 
            description="O dado será excluído de forma permanente" 
            confirmTitle="Confirmar"
            cancelTitle="Cancelar"
            openDialog={openDeleteModal} 
            onConfirm={handleDelete}
            onCancel={handleCloseModalDelete}
        />
    </>
  );
}
