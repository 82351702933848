import React from "react";
import { PropTypes } from "prop-types";

function Image(props) {
  const { source, alt, className, width, minWith } = props;
  return (
    <img
      // eslint-disable-next-line global-require
      src={Object.keys(source).length > 100 ? source : require(`../../assets/img/${source}`)}
      alt={alt}
      className={className}
      width={width}
      min-width={minWith}
    />
  );
}

Image.propTypes = {
  source: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  minWith: PropTypes.string,
};

Image.defaultProps = {
  className: "d-block mx-auto",
  width: "120",
  minWith: null,
};

export default Image;
