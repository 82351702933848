import { pathRoutes } from "../../../../config/constants";
import ItemPrecificacaoClienteCadastro from "./index";

export default [
  {
    path: pathRoutes.itemConfigPrecificacaoClienteCadastro,
    component: ItemPrecificacaoClienteCadastro,
    exact: true,
  },

  {
    path: pathRoutes.itemConfigPrecificacaoClienteEdit,
    component: ItemPrecificacaoClienteCadastro,
    exact: true,
  },
];
