import { Switch } from "@material-ui/core";

function SwitchStatus(props) {
    const { id, active, actions } = props;
    const { onSwitch } = actions;
    return (
      <Switch
          checked={active}
          onChange={(event) => onSwitch(id, event.target.checked)}
          color="primary"
          name="switch-stats-datatable"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    )
  }

export default SwitchStatus;