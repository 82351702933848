import { Box, Grid, TextField, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { REQUIRED_FIELD } from "../../../../../config/constants";
import ButtonSubmit from "../../../../ButtonSubmit";
import toast from "../../../../../utils/toast";
import { useLoader } from "../../../../../hooks/LoaderContext";
import generoApi from "../../../../../services/generoApi";
import ncmApi from "../../../../../services/ncmApi";
import { Autocomplete } from "@material-ui/lab";
import categoryConfigInvoiceApi from "../../../../../services/categoryConfigInvoiceApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        categoryId: "",
        genero: "",
        ncm: "",
        cest: "",
        name: ""
    }, 
    generoList: [],
    genero: {
        description: "",
        code: ""
    },
    ncmList: [],
    ncm: {
        description: "",
        code: ""
    }
};

const validationSchema = Yup.object().shape({ 
    genero: Yup.string().required(REQUIRED_FIELD),
    ncm: Yup.string().required(REQUIRED_FIELD),
    cest: Yup.string().required(REQUIRED_FIELD)
});

export default function CategoryConfigInvoice() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [genero, setGenero] = useState(DEFAULT_STATE.genero);
    const [generoList, setGeneroList] = useState(DEFAULT_STATE.generoList);
    const [ncm, setNcm] = useState(DEFAULT_STATE.ncm);
    const [ncmList, setNcmList] = useState(DEFAULT_STATE.ncmList);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { showLoader, hideLoader } = useLoader();
    const params = useParams();

    useEffect(() => {
        async function getAllGenero() {
            showLoader();
            const generoResponse = await generoApi.findAll();
            if (generoResponse) {
                setGeneroList(generoResponse.data.response);
            }
            hideLoader();
        }
        getAllGenero();
    }, []);

    useEffect(() => {
        handleEdit(params.ident);
    }, []);

    const handleEdit = async (categoryId) => {
        if (!isNaN(categoryId)) {
            showLoader();
            const categoryResponse = await categoryConfigInvoiceApi.findAllPageable(page - 1, rowsPerPage, `&categoryId=${categoryId}`);
            if (categoryResponse) {
                const data = {
                    categoryId: categoryResponse.data.response[0].categoryId,
                    id: categoryResponse.data.response[0].id,
                    genero: categoryResponse.data.response[0].genero,
                    ncm: categoryResponse.data.response[0].ncm,
                    cest: categoryResponse.data.response[0].cest
                };
                setData(data);
                handlerGenero(data);
                handlerNcm(data);
                setGenero({
                    title: `${data.genero} - ${data.genero}`,
                    code: data.genero
                });
            }
            hideLoader();
        }
    }

    const handlerGenero = async (data) => {
        if (data.genero) {
            const generoResponse = await generoApi.findAllPageable(0, 10, `code=${data.genero}`);
            if (generoResponse) {
                setGenero({
                    title: `${generoResponse.data.response[0].code} - ${generoResponse.data.response[0].description}`,
                    code: generoResponse.data.response[0].code
                });
                handlerNcmList(generoResponse.data.response[0]);
            }
        } else {
            setNcmList(DEFAULT_STATE.ncmList);
            setNcm(DEFAULT_STATE.ncm);
        }
    };

    const handlerNcm = async (data) => {
        if (data.ncm) {
            const ncmResponse = await ncmApi.findAllPageable(0, 10, `codeEq=${data.ncm}`);
            if (ncmResponse) {
                setNcm({
                    title: `${ncmResponse.data.response[0].code} - ${ncmResponse.data.response[0].description}`,
                    code: ncmResponse.data.response[0].code
                });
            }
        }
    };

    const handlerNcmList = async (genero) => {
        if (genero) {
            const ncmResponse = await ncmApi.findAllPageable(0, 9999, `generoCode=${genero.code}`);
            if (ncmResponse) {
                setNcmList(ncmResponse.data.response);
            }
        } else {
            setNcmList(DEFAULT_STATE.ncmList);
            setNcm(DEFAULT_STATE.ncm);
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let category;
            values = {
                ...values,
                categoryId: params.ident
            }
            if (values.id.length === 0) {
                category = await categoryConfigInvoiceApi.post(values);
            } else {
                category = await categoryConfigInvoiceApi.put(values);
            }
            if (category) {
                handleSuccess(values.id.length === 0);
            } else {
                handleError();
            }
        } catch (error) {
            console.error('Erro ao enviar formulário: ', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSuccess = (isNew) => {
        const successMessage = isNew ? "Informação fiscal da categoria cadastrada com sucesso." : "Informação fiscal da categoria atualizada com sucesso.";
        toast.success(successMessage);
    };

    const handleError = () => {
        toast.error(`Falha ao criar Informação fiscal da categoria, favor verifique!`);
    };

    const handleChangeGenero = (value, setFieldValue) => {
        setGenero(value);
        setFieldValue("genero", value ? value.code : "");
        handlerNcmList(value);
        handleChangeNcm(null, setFieldValue);
    };

    const handleChangeNcm = (value, setFieldValue) => {
        setNcm(value);
        setFieldValue("ncm", value ? value.code : "");
    };

    const ncms = ncmList.map(ncm => ({
        title: `${ncm.code} - ${ncm.description}`,
        code: ncm.code
    }));

    const generos = generoList.map(genero => ({
        title: `${genero.code} - ${genero.description}`,
        code: genero.code
    }));

    return (
        <div className="container-fluid">
            <div className="artical-1 out-shadow">
                <Formik
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ isSubmitting, touched, errors, setFieldValue }) => (
                        <Form className='mt-3'>
                            <Grid container spacing={2}>
                                <Grid className="mt-2 mb-2" item md={4} sm={4} xs={10}>
                                    <Autocomplete
                                        id="autocomplete-invoice-genero"
                                        options={generos}
                                        clearText="Limpar"
                                        value={genero}
                                        onChange={(event, value) => handleChangeGenero(value, setFieldValue)}
                                        getOptionLabel={(option) => option.title}
                                        getOptionSelected={(option, value) => option.code === value.code}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Genero" variant="outlined"
                                                helperText={touched.genero && errors.genero ? <ErrorMessage name="genero" /> : ""}
                                                error={touched.genero && Boolean(errors.genero)} />
                                        )}
                                    />
                                </Grid>
                                <Grid className="mt-2 mb-2" item md={8} sm={8} xs={12}>
                                    <Autocomplete
                                        id="autocomplete-invoice-ncm"
                                        options={ncms}
                                        clearText="Limpar"
                                        value={ncm}
                                        onChange={(event, value) => handleChangeNcm(value, setFieldValue)}
                                        getOptionLabel={(option) => option.title}
                                        getOptionSelected={(option, value) => option.code === value.code}
                                        renderInput={(params) => (
                                            <TextField {...params} label="NCM" variant="outlined"
                                                helperText={touched.ncm && errors.ncm ? <ErrorMessage name="ncm" /> : ""}
                                                error={touched.ncm && Boolean(errors.ncm)} />
                                        )}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={8}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="cest"
                                        label="Cest"
                                        helperText={touched.cest && errors.cest ? <ErrorMessage name="cest" /> : ""}
                                        error={touched.cest && Boolean(errors.cest)}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                                        <Box className="mt-1 c-inline-btn">
                                            <Grid container spacing={2}>
                                                <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                                    <ButtonSubmit
                                                        label="salvar"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className="btn btn-primary btn-user btn-block"
                                                        disable={isSubmitting}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}