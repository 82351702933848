import { del, get, patch, post, put } from "../utils/request"
import { serialize, serializeInactive } from "./mapper/SupplierMapper";

export const create = async (data) => {
    return post("v1/config/supplier", serialize(data), undefined, true);
}

export const update = async (data) => {
    return put("v1/config/supplier", serialize(data), undefined, true);
}

export const status = async (data) => {
    return patch("v1/config/supplier", serialize(data), undefined, true);
}

export const inactivate = async (data) => {
    return put("v1/config/supplier", serializeInactive(data), undefined, true);
}

export const findAll = async (filters) => {
    if (filters) {
        return get(`v1/config/supplier/all${filters}`, undefined, true);
    }
    return get("v1/config/supplier/all", undefined, true);
}

export const findAllPageable = async (page, size, filters) => {
    if (filters) {
        return get(`v1/config/supplier/all?page=${page}&size=${size}&${filters}`, undefined, true);
    }
    return get(`v1/config/supplier/all?page=${page}&size=${size}`, undefined, true);
}

export const findById = async (id) => {
    return get(`v1/config/supplier/${id}`, undefined, true);
}

export const remove = async (id) => {
    return del(`v1/config/supplier/${id}`, undefined, true);
}

export default {
    post : create,
    update,
    status,
    inactivate,
    findAll,
    findAllPageable,
    findById,
    delete : remove,
    put : update
}