import { pathRoutes } from "../../../config/constants";
import SiteCadastro from "./index";

export default [
    {
        path: pathRoutes.siteCadastro,
        component: SiteCadastro,
        exact: true,
    },
    {
        path: pathRoutes.siteEdit,
        component: SiteCadastro,
        exact: true,
    },
];