import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import { set } from "date-fns";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        category: "",
        type: "",
        description: "",
        days: "",
        active: false
    },
    service: []
};

export default function AgendamentoFilter(props) {

    const { onFilter } = props;

    const [filter, setFilter] = useState(DEFAULT_STATE.filter);
    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();
    const [description, setDescription] = useQueryState("description", '');
    const [category, setCategory] = useQueryState("category", '');
    const [type, setType] = useQueryState("type", '');
    const [days, setDays] = useQueryState("days", '');

    useEffect(() => {
        handleFilter();
    }, [])

    const handleFilter = async e => {
        if (e) {
            e.preventDefault();
        }
        let param = '';
        if (description) {
            param = param.concat('&description=').concat(description);
        }
        if (category) {
            param = param.concat('&category=').concat(category);
        }
        if (type) {
            param = param.concat('&type=').concat(type);
        }
        if (days) {
            param = param.concat('&days=').concat(days);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const cleanFilter = async e => {
        onFilter();
        setFilter(DEFAULT_STATE.filter);
        setIsFiltering(false);
        setValues({ description: "", category: "", type: "", days: "" })
    };

    const categories = [
        {
            value: 'INPUT',
            label: 'Aplicação de Insumo',
        },
        {
            value: 'PLANTING',
            label: 'Replantio',
        }
    ];

    const handleChangeDescription = ({ target: { value } }) => {
        setDescription(value);
    };

    const handleChangeType = ({ target: { value } }) => {
        setType(value);
    };

    const handleInputChange = ({ target: { value } }) => {
        setDays(value);
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    return (
        <Box>
            <form onSubmit={handleFilter}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="category"
                                select
                                label="Categoria"
                                value={category}
                                onChange={handleChangeCategory}
                                helperText="Selecione a categoria do agendamento">
                                {categories.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="type"
                                label="Tipo"
                                value={type}
                                onChange={handleChangeType}
                            />
                        </Grid>
                        <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="description"
                                label="Descrição"
                                value={description}
                                onChange={handleChangeDescription}
                            />
                        </Grid>
                        <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="days"
                                label="Prazo após o plantio"
                                value={days}
                                onChange={handleInputChange}
                                helperText="Somente números (em dias)"
                                inputProps={{
                                    type: 'number'
                                }}
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className="mt-1 c-inline-btn">
                                <ButtonSearch
                                    label="Filtrar"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                />
                            </Box>
                            <Box className="mt-1 c-inline-btn">
                                {isFiltering && (
                                    <Button
                                        color="primary"
                                        onClick={cleanFilter}>
                                        Limpar Filtros
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box>
    );
}