import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScheduleIcon from '@material-ui/icons/Schedule';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import scheduleApi from "../../../../services/scheduleApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        category: "",
        type: "",
        description: "",
        days: "",
        active: false
      },
  };
 
export default function AgendamentoPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const scheduleReponse = await scheduleApi.findById(id);
        if (scheduleReponse) {
          const data = {
              id : scheduleReponse.data.response[0].id,
              category : scheduleReponse.data.response[0].category,
              type: scheduleReponse.data.response[0].type,
              description : scheduleReponse.data.response[0].description,
              days: scheduleReponse.data.response[0].days
          };
          setData(data);
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Agendamentos" icon={<ScheduleIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}> 
                    <TextFieldPreview
                        name="category"
                        label="Categoria"
                        value={data.category}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="type"
                        label="Tipo"
                        value={data.type}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="days"
                        label="Prazo após o plantio"
                        value={data.days}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}