import LoginPage from "../../../components/LoginPage";
import React from "react";
import { Link } from "react-router-dom"
import { pathRoutes } from "../../../config/constants";

export default function EsperaConfirmacao(){

    return(
        <LoginPage 
            body={
                <div className="justfy-confirmation">
                    <h1 className="h4 text-gray-900 mb-2">Aguardando confirmação...</h1>
                    <p className="mb-4">Enviamos um email de confirmação.
                        Verifique sua caixa de email, pode ser que esteja no spam :).       
                        A confirmação deve ser feita em até 24 horas.</p>
                    <hr/>
                    <Link className="small" to={pathRoutes.login}>
                        Voltar para login</Link> 
                </div>
            }/>
    )
}