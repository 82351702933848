import { Box, Divider, FilledInput, FormControl, Grid, InputAdornment, InputLabel, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import orderApi from "../../../services/orderApi";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import BasicReturn from "../../../components/BasicReturn";
import supplierApi from "../../../services/supplierApi";
import { Autocomplete } from "@material-ui/lab";
import BoxNamed from "../../../components/BoxNamed";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import taxOperationApi from "../../../services/taxOperationApi";
import OrderItem from "../../../components/OrderItem";
import NumberFormatText from "../../../components/NumberFormatText";
import { useLoader } from "../../../hooks/LoaderContext";
import orderItemApi from "../../../services/orderItemApi";
import OrderCreatedDialog from "../../../components/OrderCreatedDialog";

const validationSchema = Yup.object().shape({
  
});

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    active: true,
    supplier: {
      id: "",
      title: ""
    },
    taxOperation: {
      id: "",
      title: ""
    },
    items: [{
      unit: {
        id: ""
      },
      item: {
        id: "",
        name: ""
      },
      value: 0,
      valueDesc: 0,
      quantity: 0,
    }]
  },
  orders: [],
  supplier: [],
  taxOperations: [],

  items: [],
  itemInput: {
    title: "",
    id: ""
  },
  units: [],
  unitInput: {
    title: "",
    id: ""
  }
};

export default function PedidoCadastro() {
  const [data, setData] = useState(DEFAULT_STATE.data);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);
  const { showLoader, hideLoader } = useLoader();
  const [taxOperationList, setTaxOperationList] = useState(DEFAULT_STATE.taxOperations);
  const [enableReinitializeFormik, setEnableReinitializeFormik] = useState(false);
  const [currentOrderItem, setCurrentOrderItem] = useState();
  const [createdOrder, setCreatedOrder] = useState();

  useEffect(() => {
    async function findById() {
      if (params.ident) {
        showLoader();
        const orderResponse = await orderApi.findById(params.ident);
        if (orderResponse) {
          setEnableReinitializeFormik(true);
          const data = {
            id : orderResponse.data.response[0].id,
            code : orderResponse.data.response[0].code,
            taxOperation: {
              id: orderResponse.data.response[0].taxOperation.id,
              title: orderResponse.data.response[0].taxOperation.code,
            },
            supplier: {
              id: orderResponse.data.response[0].customer.id,
              title: orderResponse.data.response[0].customer.name
            },
            items: orderResponse.data.response[0].items.map(item => ({
              ...item,
              item: {
                id: item.item.id,
                title: item.item.name,
              },
              unit: {
                id: item.unit.id,
                title: item.unit.code,
              },
            }))
          };
          setData(data);
          console.log({data});
        }
        hideLoader();
      }
    }
    findById();
  },[]);

  useEffect(() => {
    async function getAllSuppliers() {
        const supplierReponse = await supplierApi.findAll('?category=CUSTOMER&active=true');
        if (supplierReponse) {
            setSupplierList(supplierReponse.data.response);
        }
    }
    getAllSuppliers();
  },[])

  useEffect(() => {
    async function getAllTaxOperation() {
        const taxOperationResponse = await taxOperationApi.findAll();
        if (taxOperationResponse) {
            setTaxOperationList(taxOperationResponse.data.response);
        }
    }
    getAllTaxOperation();
  },[])

  const suppliers = supplierList.map(supplier => ({
    title: supplier.name,
    id: supplier.id
  }));

  const taxOperations = taxOperationList.map(taxOperation => ({
    title: taxOperation.code,
    id: taxOperation.id
  }));

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let order;
      showLoader();
      if (values.id.length === 0) {
        order = await orderApi.post(values);
      } else {
        order = await orderApi.put(values);
      }
      hideLoader();
      if (order && values.id.length === 0) {
        setCreatedOrder(order.data.response[0]);
        handleOpenCreatedDialog();
      } else if (values.id.length !== 0) {
        redirectTo();
      } else {
        handleError();
      }
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleError = () => {
    toast.error(`Falha ao criar Pedidos, favor verifique!.`);
  }

  const redirectTo = () => {
    history.goBack();
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  
  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenCreatedDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleRedirectTimeline = () => {
    history.push(`/pedido/timeline/${createdOrder.id}`);
  };

  const handleRedirectOrderList = () => {
    history.push('/pedido');
  };

  const handleDelete = async () => {
    const deleted = await orderApi.delete(params.ident);
    if (deleted) {
      toast.success(`Pedido deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir pedido, favor verifique!.`);
    }
    redirectTo();
  };

  const handleDeleteItem = async (id) => {
    const deleted = await orderItemApi.delete(currentOrderItem.id);
    if (deleted) {
      toast.success(`Item deletado com sucesso.`);
      handleRemoveItemForm(currentOrderItem.valuesLength, currentOrderItem.arrayHelpers, currentOrderItem.index);
      handleCloseModalDelete();
    } else {
      toast.error(`Falha ao excluir item do pedido, favor verifique!.`);
    }
    setCurrentOrderItem({});
  };

  const handleRemoveItem = async (values, arrayHelpers, currentIndex) => {
    if(values.items[currentIndex].id) {
      setCurrentOrderItem({
        id: values.items[currentIndex].id,
        index: currentIndex,
        valuesLength: values.items.length,
        arrayHelpers: arrayHelpers      
      });
      handleOpenDeleteModal();
    } else {
      handleRemoveItemForm(values.items.length, arrayHelpers, currentIndex);
    }
  };

  const handleAdicionaItemForm = async (arrayHelpers) => {
    setTimeout(() => {
      arrayHelpers.push({ item: "", quantity: "", value: "", unit: "" });
    }, 0);
  };

  const handleRemoveItemForm = async (valuesLength, arrayHelpers, currentIndex) => {
    if (valuesLength === 1) {
      arrayHelpers.remove(currentIndex);
      handleAdicionaItemForm(arrayHelpers);
    } else {
      arrayHelpers.remove(currentIndex);
    }
  };

  return (
    <>
    <div className="container-fluid">
      <PageTitle title={params.ident ? 'Gerar Pedido de Venda' : 'Atualizar Pedido de Venda'} icon={<AddShoppingCartIcon style={{ color: 'gray' }}/>} />
      <BasicReturn />
      <Divider />
      <div className="artical-1 out-shadow">
        <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={enableReinitializeFormik}
        >
          {({ isSubmitting, touched, errors, values }) => (
            <Form className="mt-3">
              <Grid container spacing={2}>
                  <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <Field
                      name="supplier.id"
                      render={({ form }) => (
                        <Autocomplete
                          id="autocomplete-supplier"
                          options={suppliers}
                          value={values.supplier}
                          getOptionLabel={(option) => option.title}
                          onChange={(event, value) => {
                            form.setFieldValue("supplier", value ? value : '');
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Cliente" variant="outlined"
                              error={touched.supplier?.id && !!(errors.supplier?.id)}
                              helperText={touched.supplier?.id && errors.supplier?.id}
                            />
                          )}
                        />
                      )}
                  />
                  </Grid>
                  <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <Field
                      name="taxOperation.id"
                      render={({ form }) => (
                        <Autocomplete
                          id="autocomplete-tax-operation"
                          options={taxOperations}
                          getOptionLabel={(option) => option.title}
                          value={values.taxOperation}
                          onChange={(event, value) => {
                            form.setFieldValue("taxOperation", value ? value : '');
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Operação Fiscal" variant="outlined"
                              error={touched.taxOperation?.id && !!(errors.taxOperation?.id)}
                              helperText={touched.taxOperation?.id && errors.taxOperation?.id}
                            />
                          )}
                        />
                      )}
                  />
                  </Grid>
                </Grid>

                <FieldArray name="items">
                    {arrayHelpers => (
                      <BoxNamed title="Itens do pedido">
                        {values.items.map((item, index) => (
                          <OrderItem
                            supplierId={values.supplier.id}
                            key={index}
                            values={values}
                            index={index}
                            touched={touched}
                            errors={errors}
                            handleChangeItem={(value, index) => {
                              arrayHelpers.replace(index, { ...item, item: value });
                            }}
                            handleChangeUnit={(value, index) => {
                              arrayHelpers.replace(index, { ...item, unit: value });
                            }}
                            handleChange={(index, currentItem) => {
                              arrayHelpers.replace(index, { ...item, ...currentItem });
                            }}
                            handleInputChange={(event, index) => {
                              const { name, value } = event.target;
                              arrayHelpers.replace(index, { ...item, [name]: value });
                            }}
                            handleInputValue={(vl, index, field) => {
                              arrayHelpers.replace(index, { ...item, [field]: vl });
                            }}
                            handleRemoveItem={(currentIndex) => handleRemoveItem(values, arrayHelpers, currentIndex)}
                            handleAddItem={() => arrayHelpers.push({ item: "", quantity: "", value: "", unit: "" })}
                          />
                        ))}
                      </BoxNamed>
                    )}
                </FieldArray>
                <Grid className="mt-3 mb-2 ml-2 mr-2" item md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12} />
                      <Grid className="mt-3 mb-2" item md={1} sm={1} xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Qtd. total"
                          value={values.items.reduce((acc, curr) => acc + parseFloat(curr.quantity || 0), 0)} 
                          name="valueTotal"
                          InputProps={{
                            style: {
                              color: 'black',
                              WebkitTextFillColor: 'black',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: 'black',
                            },
                          }}
                          disabled />
                      </Grid>
                      <Grid className="mt-3 mb-2" item md={2} sm={4} xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Total bruto"
                            value={values.items.reduce((acc, curr) => acc + (parseFloat(curr.value || 0) * curr.quantity), 0)}
                            name="valueTotal"
                            InputProps={{
                              inputComponent: NumberFormatText
                            }}
                            disabled />
                      </Grid>

                      <Grid className="mt-3 mb-2" item md={2} sm={4} xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Total de desconto"
                            value={values.items.reduce((acc, curr) => acc + (parseFloat(curr.valueDesc || 0) * curr.quantity), 0)}
                            name="valueTotal"
                            InputProps={{
                              inputComponent: NumberFormatText
                            }}
                            disabled />
                      </Grid>
                      <Grid className="mt-3 mb-2" item md={3} sm={4} xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Valor final"
                            value={values.items.reduce((acc, curr) => acc + (parseFloat(curr.value - curr.valueDesc || 0) * curr.quantity), 0)}
                            name="valueTotal"
                            InputProps={{
                              inputComponent: NumberFormatText,
                              style: {
                                color: 'black', // Cor do texto
                                WebkitTextFillColor: 'black', // Para assegurar que a cor do texto no Safari também seja preta
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: 'black', // Cor do label
                              },
                            }}
                            disabled />
                      </Grid>
                    </Grid>
                </Grid>

                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Box className="mt-1 c-inline-btn">
                            <Grid container spacing={2}>
                                {params.ident &&(
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ButtonRemove
                                            title="excluir"
                                            onClick={handleOpenDeleteModal}
                                            variant="contained"
                                            color="primary"
                                            className="btn btn-primary btn-user btn-block"
                                        />
                                    </Grid>
                                )}
                                <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                  <Tooltip title="Atualizar Pedido">
                                    <ButtonSubmit
                                        label={params.ident ? "Atualizar" : "Gerar Pedido"}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn btn-primary btn-user btn-block"
                                        disable={isSubmitting}
                                    />
                                  </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
    <AlertDialog title="Realmente deseja excluir?" 
            description="O dado será excluído de forma permanente" 
            confirmTitle="Confirmar"
            cancelTitle="Cancelar"
            openDialog={openDeleteModal} 
            onConfirm={currentOrderItem ? handleDeleteItem : handleDelete}
            onCancel={handleCloseModalDelete}
        />
      <OrderCreatedDialog
            openDialog={openConfirmDialog}
            data={createdOrder}
            onConfirm={handleRedirectTimeline}
            onCancel={handleRedirectOrderList}
        />
    </>
  );
}