import { Button, CircularProgress } from '@material-ui/core';
import React from "react";


function ButtonSubmit(props) {
  const { label, type, variant, color, className, isLoading } = props;
  return (
      <Button
        type={type}
        variant={variant}
        color={color}
        disabled={isLoading}
        className={className}>
          {isLoading ? <CircularProgress size={25}/> : label}
      </Button>
    
  );
}

export default ButtonSubmit;