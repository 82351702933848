import { Box, Divider, Grid, IconButton, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { formatMoney } from "../../../../utils/moneyUtils";
import BoxNamed from "../../../../components/BoxNamed";
import TextFieldPreview from "../../../../components/TextFieldPreview";


const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  detailTextField: {
    '& .MuiFormLabel-root': {
      fontSize: '0.8rem', // aqui você coloca o tamanho que deseja
    },
    '& .MuiInputBase-input': {
      fontSize: '1rem', // aqui você coloca o tamanho que deseja
    },
    },
  root: {
    width: '100%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  }));

  export default function PedidoTimelineDetail(props) {
    const classes = useStyles();
    const { data } = props;
    console.log({data})
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
      <div className={classes.root}>
        <Accordion classes={{ root: classes.accordion }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {data && (
              <Grid container spacing={1}>
                <Grid item md={5} sm={3} xs={12}>
                     <TextFieldPreview fullWidth label="Identificador do pedido" value={data.uuid}/>
                 </Grid>
                 <Grid item md={3} sm={3} xs={12}>
                     <TextFieldPreview fullWidth label="Data do pedido" value={data.orderDate}/>
                 </Grid>
                 
                 <Grid item md={2} sm={2} xs={12}>
                     <TextFieldPreview fullWidth label="Número do pedido" value={`#${data.number}`}/>
                 </Grid>
                 <Grid item md={2} sm={3} xs={12}>
                     <TextFieldPreview fullWidth label="Valor total do pedido" value={formatMoney(data.valueTotal)}/>
                 </Grid>
                 <Grid item md={5} sm={5} xs={12}>
                     <TextFieldPreview className={classes.textField} fullWidth label="Cliente" value={data.customer.name} />
                 </Grid>
                 <Grid item md={3} sm={3} xs={12}>
                     <TextFieldPreview className={classes.textField} fullWidth label="Operação" value={data.taxOperation.description} />
                 </Grid>
                 <Grid item md={2} sm={3} xs={12}>
                     <TextFieldPreview fullWidth label="Quantidade de itens" value={data.quantityTotal}/>
                 </Grid>
                 <Grid item md={2} sm={2} xs={12}>
                     <TextFieldPreview fullWidth label="Valor total de desconto" value={formatMoney(data.valueTotalDesc)}/>
                 </Grid>
                 <Grid className="mt-2" item md={12} sm={12} xs={12}>
                     {expanded !== 'panel1' && (
                         <Box display="flex" justifyContent="center">
                         <Link component="button" variant="body1" onClick={handleChange('panel1')}>
                             Exibir detalhes
                         </Link>
                         </Box>
                     )}
                     </Grid>
             </Grid>  
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <BoxNamed title="Itens do pedido" >
                  <Grid container>
                      <Grid item md={2} sm={2} xs={12}>
                          <TextFieldPreview fullWidth className={classes.detailTextField} label="Item"/>
                      </Grid>
                      <Grid item md={2} sm={2} xs={12}>
                          <TextFieldPreview fullWidth className={classes.detailTextField} label="Unidade"/>
                      </Grid>
                      <Grid item md={2} sm={2} xs={12}>
                          <TextFieldPreview fullWidth className={classes.detailTextField} label="Valor unitário"/>
                      </Grid>
                      <Grid item md={2} sm={2} xs={12}>
                          <TextFieldPreview fullWidth className={classes.detailTextField}  label="Quantidade"/>
                      </Grid>
                      <Grid item md={2} sm={2} xs={12}>
                          <TextFieldPreview fullWidth className={classes.detailTextField} label="Valor desconto"/>
                      </Grid>
                      <Grid item md={2} sm={2} xs={12}>
                          <TextFieldPreview fullWidth className={classes.detailTextField} label="Valor total"/>
                      </Grid>
                  </Grid>
                  {data.items && data.items.map((item, index) => (
                      <Grid className="mt-1" container index={index}>
                          <Grid item md={2} sm={2} xs={12}>
                              <TextFieldPreview fullWidth value={item.item.name}/>
                          </Grid>
                          <Grid item md={2} sm={2} xs={12}>
                              <TextFieldPreview fullWidth value={item.unit.code}/>
                          </Grid>
                          <Grid item md={2} sm={2} xs={12}>
                              <TextFieldPreview fullWidth value={formatMoney(item.value)}/>
                          </Grid>
                          <Grid item md={2} sm={2} xs={12}>
                              <TextFieldPreview fullWidth value={item.quantity}/>
                          </Grid>
                          <Grid item md={2} sm={2} xs={12}>
                              <TextFieldPreview fullWidth value={formatMoney(item.valueDesc)} />
                          </Grid>
                          <Grid item md={2} sm={2} xs={12}>
                              <TextFieldPreview fullWidth value={formatMoney((item.value - item.valueDesc) * item.quantity)}/>
                          </Grid>
                      </Grid>
                  ))}
                  </BoxNamed>
              </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Grid className="mt-4" item md={12} sm={12} xs={12}>
                    {expanded === 'panel1' && (
                        <Box display="flex" justifyContent="center">
                            <Link component="button" variant="body1" onClick={handleChange('panel1')}>
                              Ocultar detalhes
                            </Link>
                        </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }