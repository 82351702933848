export const pathRoutes = {
  home: "/",
  login: "/login",
  conta: "/conta",
  insumo: "/insumo",
  insumoCadastro: "/insumo/cadastro/",
  insumoEdit: "/insumo/edicao/:ident?",
  pessoa: "/pessoa",
  pessoaCadastro: "/pessoa/cadastro",
  pessoaEdit: "/pessoa/edicao/:ident?",
  pessoaConfig: "/pessoa/configuracao/:ident?",
  servico: "/servico",
  servicoCadastro: "/servico/cadastro",
  servicoEdit: "/servico/edicao/:ident?",
  area: "/area",
  canteiro: "/canteiro",
  canteiroCadastro: "/canteiro/cadastro",
  canteiroEdit: "/canteiro/edicao/:ident?",
  line: "/line",
  lineCadastro: "/line/cadastro",
  lineEdit: "/line/edicao/:ident?",
  sector: "/sector",
  sectorCadastro: "/sector/cadastro",
  sectorEdit: "/sector/edicao/:ident?",
  site: "/site",
  siteCadastro: "/site/cadastro",
  siteEdit: "/site/edicao/:ident?",
  item: "/item",
  itemConfig: "/item/configuracao/:ident?",
  itemConfigUnidadePadraoCadastro: "/item/configuracao/unid-padrao/cadastro/:itemId?",
  itemConfigUnidadePadraoEdit: "/item/configuracao/unid-padrao/edicao/:ident?",
  itemConfigUnidadeClienteCadastro: "/item/configuracao/unid-cliente/cadastro/:itemId?",
  itemConfigUnidadeClienteEdit: "/item/configuracao/unid-cliente/edicao/:ident?",

  itemConfigPrecificacaoPadraoCadastro: "/item/configuracao/precificacao-padrao/cadastro/:itemId?",
  itemConfigPrecificacaoPadraoEdit: "/item/configuracao/precificacao-padrao/edicao/:ident?",
  itemConfigPrecificacaoClienteCadastro: "/item/configuracao/precificacao-cliente/cadastro/:itemId?",
  itemConfigPrecificacaoClienteEdit: "/item/configuracao/precificacao-cliente/edicao/:ident?",

  itemCadastro: "/item/cadastro",
  itemEdit: "/item/edicao/:ident?",
  categoria: "/categoria",
  categoriaCadastro: "/categoria/cadastro",
  categoriaEdit: "/categoria/edicao/:ident?",
  categoryConfig: "/categoria/configuracao/:ident?",
  plantio: "/plantio",
  agendamento: "/agendamento",
  agendamentoCadastro: "/agendamento/cadastro",
  agendamentoEdit: "/agendamento/edicao/:ident?",
  config: "/configuracoes",
  recuperacaoSenha: "/recuperacao",
  alterarSenha: "/alterarSenha",
  cadastro: "/cadastro",
  confirmacao: "/confirmacao",
  esperaConfirmacao: "/cadastro/espera",
  planoConta: "/plano-conta",
  planoContaCadastro: "/plano-conta/cadastro",
  planoContaEdit: "/plano-conta/edicao/:ident?",
  planoContaItem: "/plano-conta-item",
  planoContaItemCadastro: "/plano-conta-item/cadastro",
  planoContaItemEdit: "/plano-conta-item/edicao/:ident?",
  centroCustos: "/centro-custos",
  centroCustosCadastro: "/centro-custos/cadastro",
  centroCustosEdit: "/centro-custos/edicao/:ident?",
  fluxoCaixa: "/fluxo-caixa",
  fluxoCaixaLancamento: "/fluxo-caixa/lancamento",
  fluxoCaixaEdit: "/fluxo-caixa/lancamento/:ident?",
  organizacao: "/organizacao",
  empresa: "/empresa",
  empresaCadastro: "/empresa/cadastro",
  empresaEdit: "/empresa/edicao/:ident?",
  ncmGenero: "/ncm-genero",
  ncm: "/ncm",
  aliquota: "/aliquota",
  aliquotaCadastro: "/aliquota/cadastro",
  aliquotaEdit: "/aliquota/edicao/:ident?",
  pedido: "/pedido",
  pedidoCadastro: "/pedido/cadastro",
  pedidoEdit: "/pedido/edicao/:ident?",
  pedidoTimeline: "/pedido/timeline/:ident?",
  ncmCadastro: "/ncm/cadastro",
  ncmEdit: "/ncm/edicao/:ident?",
  genero: "/genero",
  generoCadastro: "/genero/cadastro",
  generoEdit: "/genero/edicao/:ident?",
  unidade: "/unidade",
  unidadeCadastro: "/unidade/cadastro",
  unidadeEdit: "/unidade/edicao/:ident?",

  operacaoFiscal: "/operacao-fiscal",
  operacaoFiscalCadastro: "/operacao-fiscal/cadastro",
  operacaoFiscalEdit: "/operacao-fiscal/edicao/:ident?",
  operacaoFiscalConfig: "/operacao-fiscal/configuracao/:ident?",

  operacaoFiscalConfigCategoryCadastro: "/operacao-fiscal/configuracao-categoria/cadastro/:itemId?",
  operacaoFiscalConfigCategoryEdit: "/operacao-fiscal/configuracao-categoria/edicao/:ident?",

  operacaoFiscalConfigPadraoCadastro: "/operacao-fiscal/configuracao-padrao/cadastro/:itemId?",
  operacaoFiscalConfigPadraoEdit: "/operacao-fiscal/configuracao-padrao/edicao/:ident?",

  operacaoFiscalConfigItemCadastro: "/operacao-fiscal/configuracao-item/cadastro/:itemId?",
  operacaoFiscalConfigItemEdit: "/operacao-fiscal/configuracao-item/edicao/:ident?",
}; 

export const REQUIRED_FIELD = "Este campo é de preenchimento obrigatório!";

export const selectSexo = [
  { label: "Masculino", value: "M" },
  { label: "Feminino", value: "F" },
];

export const selectTipoConta = [
  { label: "Corrente", value: "CORRENTE" },
  { label: "Poupança", value: "POUPANCA" },
];

export const supplierCategories = [
  {
    value: 'CUSTOMER',
    label: 'Cliente',
  },
  {
    value: 'SUPPLIER',
    label: 'Fornecedor',
  },
  {
    value: 'EMPLOYEE',
    label: 'Colaborador',
  },
  {
    value: 'SERVICE_PROVIDER',
    label: 'Prestador de Serviços',
  },
  {
    value: 'COMPANY',
    label: 'Empresa',
  }
];

export const supplierTypes = [
  {
    value: 'FISICA',
    label: 'Pessoa Física',
  },
  {
    value: 'JURIDICA',
    label: 'Pessoa Jurídica',
  },
  {
    value: 'FOREIGN',
    label: 'Pessoa Estrangeira',
  }
];

export const translateSupplierType = (type) => {
  if (type === 'JURIDICA') {
    return "Juridica";
  }
  if (type === 'FISICA') {
    return "Fisica";
  }
  if (type === 'FOREIGN') {
    return "Estrangeiro";
  }
}

export const translateSupplierCategory = (type) => {
  if (type === 'CUSTOMER') {
    return "Cliente";
  }
  if (type === 'SUPPLIER') {
    return "Fornecedor";
  }
  if (type === 'EMPLOYEE') {
    return "Colaborador";
  }
  if (type === 'SERVICE_PROVIDER') {
    return "Prestador de Serviços";
  }
  if (type === 'COMPANY') {
    return "Empresa";
  }
}

export const translateOrderStatus = (status) => {
  if (status === 'CREATED') {
    return "Criado";
  }
  if (status === 'AWAITING_MANUAL_APPROVAL') {
    return "Ag. Aprovação Manual";
  }
  if (status === 'APPROVED') {
    return "Aprovado";
  }
  if (status === 'AWAITING_VALIDATION_INVOICE') {
    return "Ag. Validação da NF";
  }
  if (status === 'AWAITING_CHARGE_INVOICE') {
    return "Ag. Geração da NF";
  }
  if (status === 'CANCELED') {
    return "Cancelado";
  }
  if (status === 'REJECTED') {
    return "Rejeitado"
  }
  if (status === 'VALIDATION_ERROR') {
    return "Erro na validação NF"
  }
  if (status === 'VALIDATED') {
    return "NF Validada"
  }
}


export const translateSupplierAddressType = (type) => {
  if (type === 'CHARGE') {
    return "Cobrança";
  }
  if (type === 'RESIDENTIAL') {
    return "Residencial";
  }
};

export const supplierAddressType = [
  {
    value: 'CHARGE',
    label: 'Cobrança',
  },
  {
    value: 'RESIDENTIAL',
    label: 'Residencial',
  }
];

export const cashFlowType = [
  {
    value: 'INPUT',
    label: 'Entrada',
  },
  {
    value: 'OUTPUT',
    label: 'Saída',
  },
];

export const cashFlowOutputStatus = [
  {
    value: 'AWAITING_PAYMENT',
    label: 'A pagar',
  },
  {
    value: 'PAYMENT_MADE',
    label: 'Pago',
  }
];

export const cashFlowInputStatus = [
  {
    value: 'AWAITING_RECEIPT',
    label: 'A Receber',
  },
  {
    value: 'RECEIVED',
    label: 'Recebido',
  }
];

export const cashFlowPaymentMethod = [
  {
    value: 'CASH',
    label: 'Dinheiro',
  },
  {
    value: 'PIX',
    label: 'PIX',
  },
  {
    value: 'CREDIT_CARD',
    label: 'Cartão de Crédito',
  },
  {
    value: 'DEBIT_CARD',
    label: 'Cartão de Débito',
  },
  {
    value: 'BANK_SLIP',
    label: 'Boleto Bancário',
  },
  {
    value: 'BANK_TRANSFER',
    label: 'Transferência',
  }
];

export const cashFlowTranslatePaymentMethod = (type) => {
  if (type === 'CASH') {
    return "Dinheiro";
  }
  if (type === 'CREDIT_CARD') {
    return "Cartão de Crédito";
  }
  if (type === 'DEBIT_CART') {
    return "Cartão de Débito";
  }
  if (type === 'BANK_SLIP') {
    return "Boleto Bancário";
  }
  if (type === 'BANK_TRANSFER') {
    return "Transferência Bancária";
  }
  return type;
}

export const cashFlowQueryParamTransationPaymentMethod = (type) => {
  if (type === 'CASH') {
    return "DINHEIRO";
  }
  if (type === 'CREDIT_CARD') {
    return "CREDITO";
  }
  if (type === 'DEBIT_CART') {
    return "DEBITO";
  }
  if (type === 'BANK_SLIP') {
    return "BOLETO";
  }
  if (type === 'BANK_TRANSFER') {
    return "TRANSFERENCIA";
  }
  if (type === 'DINHEIRO') {
    return "CASH";
  }
  if (type === 'CREDITO') {
    return "CREDIT_CARD";
  }
  if (type === 'DEBITO') {
    return "DEBIT_CART";
  }
  if (type === 'BOLETO') {
    return "BANK_SLIP";
  }
  if (type === 'TRANSFERENCIA') {
    return "BANK_TRANSFER";
  }
  return type;
}

export const cashFlowPaymentCondition = [
  {
    value: 'CASH',
    label: 'À Vista',
  },
  {
    value: 'INSTALLMENTS',
    label: 'À Prazo',
  }
];

export const cashFlowActive = [
  {
    value: 'ACTIVE',
    label: 'Ativo',
  },
  {
    value: 'INACTIVE',
    label: 'Inativo',
  }
];

export const cashFlowTranslatePaymentCondition = (type) => {
  if (type === 'CASH') {
    return "À Vista";
  }
  if (type === 'INSTALLMENTS') {
    return "À prazo";
  }
}

export const cashFlowTranslateType = (type) => {
  if (type === 'INPUT') {
    return "Entrada";
  }
  if (type === 'OUTPUT') {
    return "Saída";
  }
}

export const cashFlowTranslateStatus = (type) => {
  if (type === 'AWAITING_PAYMENT') {
    return "À pagar";
  }
  if (type === 'PAYMENT_MADE') {
    return "Pago";
  }
  if (type === 'AWAITING_RECEIPT') {
    return "À Receber";
  }
  if (type === 'RECEIVED') {
    return "Recebido";
  }
}


export const selectDocumentModel = [
  {
    value: 'NFE',
    label: 'NF-e',
  },
  {
    value: 'NFCE',
    label: 'NFC-e',
  }
];

export const selectEmissionType = [
  {
    value: 'INPUT',
    label: 'Entrada',
  },
  {
    value: 'OUTPUT',
    label: 'Saída',
  }
];

export const selectDanfeType = [
  {
    value: 'NO_DANFE',
    label: 'Sem geração de DANFE',
  },
  {
    value: 'PORTRAIT',
    label: 'Retrato',
  },
  {
    value: 'LANDSCAPE',
    label: 'Paisagem',
  },
  {
    value: 'SIMPLIFY',
    label: 'Simplificado',
  },
  {
    value: 'NFCE',
    label: 'Cupom (NFC-e)',
  },
  {
    value: 'NFCE_ELETRIC',
    label: 'Cupom em mensagem eletrônica',
  }
];

export const selectCustomerOperation = [
  {
    value: 'REGULAR',
    label: 'Normal',
  },
  {
    value: 'FINAL_CUSTOMER ',
    label: 'Consumidor Final',
  }
];

export const selectCustomerPresence = [
  {
    value: 'NOT_APPLIED',
    label: 'Não se aplica',
  },
  {
    value: 'IN_PERSON',
    label: 'Operação Presencial',
  },
  {
    value: 'ON_INTERNET',
    label: 'Operação não presencial, pela Internet',
  },
  {
    value: 'TELESERVICE',
    label: 'Operação não presencial, Teleatendimento',
  },
  {
    value: 'NFC_HOME',
    label: 'NFC-e em operação com entrega a domicílio',
  },
  {
    value: 'IN_PERSON_OUT',
    label: 'Operação presencial, fora do estabelecimento',
  },
  {
    value: 'NON_IN_PERSON',
    label: 'Operação não presencial, outros',
  }
];

export const selectInvoicePurpose = [
  {
    value: 'REGULAR',
    label: 'NF-e normal',
  },
  {
    value: 'ADDITIONAL',
    label: 'NF-e adicional',
  },
  {
    value: 'ADJUSTMENT',
    label: 'NF-e de ajuste',
  },
  {
    value: 'DEVOLUTION',
    label: 'Devolução de mercadoria',
  }
];

export const selectModBc = [
  {
    value: 'MARGEM_VL_AGREGADO',
    label: 'Margem Valor Agregado (%)',
  },
  {
    value: 'PAUTA',
    label: 'Pauta (Valor)',
  },
  {
    value: 'PRECO_TAB_MAX',
    label: 'Preço Tabelado Máx. (valor)',
  },
  {
    value: 'VL_OPER',
    label: 'Valor da operação',
  }
];

export const selectModBcSt = [
  {
    value: 'PRECO_TAB_MAX_SUGERIDO',
    label: 'Preço tabelado ou máximo sugerido',
  },
  {
    value: 'LISTA_NEGATIVA',
    label: 'Lista Negativa (valor)',
  },
  {
    value: 'LISTA_POSITIVA',
    label: 'Lista Positiva (valor)',
  },
  {
    value: 'LISTA_NEUTRA',
    label: 'Lista Neutra (valor)',
  },
  {
    value: 'MARGEM_VL_AGREGADO',
    label: 'Margem Valor Agregado (%)',
  },
  {
    value: 'PAUTA',
    label: 'Pauta (valor)',
  }
];

export const selectMotDesIcms = [
  {
    value: 'AGROPECUARIA',
    label: 'Uso na agropecuária',
  },
  {
    value: 'OUTROS',
    label: 'Outros',
  }
];

export default {
  path: {
    routes: pathRoutes,
  },
  select: {
    sexo: selectSexo,
    tipoConta: selectTipoConta,
  },
};
