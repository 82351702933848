import { pathRoutes } from "../../../../config/constants";
import ItemUnidadePadraoCadastro from "./index";

export default [
  {
    path: pathRoutes.itemConfigUnidadePadraoCadastro,
    component: ItemUnidadePadraoCadastro,
    exact: true,
  },

  {
    path: pathRoutes.itemConfigUnidadePadraoEdit,
    component: ItemUnidadePadraoCadastro,
    exact: true,
  },
];
