import AssessmentIcon from '@material-ui/icons/Assessment';
import React, { useEffect, useState } from "react";
import { Box, Divider, Grid } from "@material-ui/core";
import toast from '../../utils/toast';
import DataTable from '../../components/DataTable';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import PreviewModal from '../../components/PreviewModal';
import { useLoader } from '../../hooks/LoaderContext';
import { pathRoutes } from "../../config/constants";
import ButtonAdd from "../../components/ButtonAdd";
import SwitchStatus from '../../components/SwitchStatus';
import DataTableDefaultAction from '../../components/DataTableDefaultActions';
import aliquotaApi from '../../services/aliquotaApi';
import AliquotaFilter from '../../components/pages/Aliquota/AliquotaFilter';
import AliquotaPreview from '../../components/pages/Aliquota/AliquotaPreview';



const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    code: "",
    description: "",
    percentTax: "",
    active: true,
    taxType: {},
    createdAt: ""
  },
  aliquotas: []
};

export default function Aliquota() {

  const [aliquotaList, setAliquotaList] = useState(DEFAULT_STATE.aliquotas);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [aliquotaIdPreview, setAliquotaIdPreview] = useState(0);
  const history = useHistory();
  const [filters, setFilters] = useState('');
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
    async function getAllItems() {
      showLoader();
      const aliquotaResponse = await aliquotaApi.findAllPageable(page - 1, rowsPerPage, filters);
      if (aliquotaResponse) {
        setAliquotaList(aliquotaResponse.data.response);
        setTotalPages(aliquotaResponse.data.totalPages);
      } else {
        setAliquotaList(DEFAULT_STATE.aliquotas);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllItems();
  }, [filters, forceRefreshData]);

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };

  const handleDelete = async (id) => {
    const deleted = await aliquotaApi.delete(id);
    if (deleted) {
      if (aliquotaList.length == 1) {
        setPage(page - 1);
      }
      setForceRefreshData(!forceRefreshData);
      toast.success(`Aliquota deletada com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Aliquota, favor verifique!.`);
    }
  };

  const handlePreview = (id) => {
    setAliquotaIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.aliquota}/edicao/${id}`)
  };

  const handleCreate = async () => {
    history.push(`${pathRoutes.aliquotaCadastro}`)
  };

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const aliquot = await aliquotaApi.status(data);
    if (aliquot) {
      if (aliquot.data.response[0].active) {
        toast.success(`Aliquota ativada com sucesso.`);
      } else {
        toast.success(`Aliquota desativada com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Código', 'Descrição', 'Tipo de imposto', 'Situação', 'Ações'];

  const rows = aliquotaList.map(aliquota => [
    aliquota.code,
    aliquota.description,
    aliquota.taxType.description,
    <SwitchStatus
      id={aliquota.id}
      active={aliquota.active}
      actions={{
        onSwitch: (id, status) => handleChangeActive(id, status)
      }}
    />,
    <DataTableDefaultAction
      id={aliquota.id}
      actions={{
        onEdit: () => handleEdit(aliquota.id),
        onDelete: () => handleDelete(aliquota.id),
        onPreview: () => handlePreview(aliquota.id)
      }}
    />
  ]);


  return (
    <>
      <div className="container-fluid">
        <PageTitle title="Aliquota" icon={<AssessmentIcon style={{ color: 'gray' }} />} />
        <div className="artical-1 out-shadow">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <AliquotaFilter onFilter={handleFilter} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box>
                <ButtonAdd title="Cadastrar Aliquota" onClick={handleCreate} />
              </Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box position="relative">
                <DataTable header={header}
                  rows={rows}
                  rowsPerPage={rowsPerPage}
                  totalPages={totalPages}
                  page={page}
                  onPage={handleChangePage}
                  onRowsPerPage={handleChangeRowsPerPage} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <PreviewModal openDialog={showPreview}
        onCloseDialog={handleClosePreview} width="70%" height="55%">
        <AliquotaPreview id={aliquotaIdPreview} />
      </PreviewModal>
    </>
  );

}