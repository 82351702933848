import { pathRoutes } from "../../../config/constants";
import PlanoContaItemCadastro from "./index";

export default [
  {
    path: pathRoutes.planoContaItemCadastro,
    component: PlanoContaItemCadastro,
    exact: true,
  },
  {
    path: pathRoutes.planoContaItemEdit,
    component: PlanoContaItemCadastro,
    exact: true,
  },
];
