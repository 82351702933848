import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BuildIcon from '@material-ui/icons/Build';
import TextFieldPreview from "../../../../../TextFieldPreview";
import PageTitle from "../../../../../PageTitle";
import taxOperationItemApi from "../../../../../../services/taxOperationItemApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        taxType: "",
        cst: "",
        aliquotPercent: "",
        categoryName: "",
        taxTypeCode: ""
    }
};

export default function OperacaoFiscalCategoriaPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const taxOperationCategory = await taxOperationItemApi.findById(id);
        if (taxOperationCategory) {
            const taxOperationCategoryData = taxOperationCategory.data.response[0];
            setData({
                cst : taxOperationCategoryData.aliquotPercent ? `${taxOperationCategoryData.aliquotPercent.cst.code} - ${taxOperationCategoryData.aliquotPercent.cst.description}` : '-',
                aliquotPercent: taxOperationCategoryData.aliquotPercent ? `${taxOperationCategoryData.aliquotPercent.code} - ${taxOperationCategoryData.aliquotPercent.description}`: '-',
                categoryName: taxOperationCategoryData.category ? taxOperationCategoryData.category.name : '-',
                taxType: taxOperationCategoryData.aliquotPercent?.cst?.taxType?.code
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Categoria Padrao da Operação Fiscal" icon={<BuildIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="taxtype"
                        label="Tipo de imposto"
                        value={data.taxType}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="cst"
                        label="CST"
                        value={data.cst}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="aliquotPercent"
                        label="Alíquota"
                        value={data.aliquotPercent}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="categoryName"
                        label="Nome da Categoria"
                        value={data.categoryName}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}