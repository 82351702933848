import { pathRoutes } from "../../../config/constants";
import UnidadeCadastro from "./index";
import PlanoContaItemCadastro from "./index";

export default [
  {
    path: pathRoutes.unidadeCadastro,
    component: UnidadeCadastro,
    exact: true,
  },
  {
    path: pathRoutes.unidadeEdit,
    component: UnidadeCadastro,
    exact: true,
  },
];
