import { Box, Button, Grid, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        active: false,
        code: "",
        description: ""
    },
    service: []
};

export default function ServicoFilter(props) {

    const { onFilter } = props;

    const [filter, setFilter] = useState(DEFAULT_STATE.filter);
    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();
    const [code, setCode] = useQueryState("code", '');
    const [description, setDescription] = useQueryState("description", '');

    useEffect(() => {
        handleFilter();
    }, [])

    const handleFilter = async e => {
        let param = '';
        if (description) {
            param = param.concat('&description=').concat(description);
        }
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const cleanFilter = async e => {
        onFilter();
        setFilter(DEFAULT_STATE.filter);
        setIsFiltering(false);
        setValues({ code: "", description: "" })
    };

    const handleInputDescription = ({ target: { value } }) => {
        setDescription(value);
    };

    const handleChangeCode = ({ target: { value } }) => {
        setCode(value);
    }

    return (
        <BoxFilter>
            <form onSubmit={handleFilter}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="code"
                                label="Código"
                                value={code}
                                onChange={handleChangeCode}
                            />
                        </Grid>
                        <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="description"
                                label="Descrição"
                                value={description}
                                onChange={handleInputDescription}
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className="mt-1 c-inline-btn">
                                <ButtonSearch
                                    label="Filtrar"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                />
                            </Box>
                            <Box className="mt-1 c-inline-btn">
                                {isFiltering && (
                                    <Button
                                        color="primary"
                                        onClick={cleanFilter}>
                                        Limpar Filtros
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </BoxFilter>
    );
}