import React from "react";
import { Button, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

function ButtonSearch(props) {
  const { label, type, variant, color, className, isLoading } = props;

  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      disabled={isLoading}
      className={className}
      style={{ borderRadius: '15px', borderWidth: '2px', borderStyle: 'solid'}} 
      startIcon={!isLoading && <SearchIcon />}
    >
      {isLoading ? <CircularProgress size={24}/> : label}
    </Button>
  );
}

export default ButtonSearch;