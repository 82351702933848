export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        name: data.name,
        description: data.description,
        createdAt: data.createdAt,
        detail: {
            lifeCycle: data.detail?.lifeCycle
        },
        category: {
            id: data.category?.id
        }
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        name: data.name,
        description: data.description,
        createdAt: data.createdAt
    }
}


export default{
    serialize
}