import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import inputApi from "../../services/inputApi";
import toast from "../../utils/toast";
import BugReportRoundedIcon from '@material-ui/icons/BugReportRounded';
import PageTitle from "../../components/PageTitle";
import InsumoFilter from "../../components/pages/insumo/InsumoFilter";
import ButtonAdd from "../../components/ButtonAdd";
import { pathRoutes } from "../../config/constants";
import PreviewModal from "../../components/PreviewModal";
import InsumoPreview from "../../components/pages/insumo/InsumoPreview";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import DataTableDefaultAction from "../../components/DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    code: "",
    name: "",
    unity: "",
    active: false,
    supplier: {
      id: ""
    }
  },
  input: [],
  supplier: [],
  supplierInput: {
    title: "",
    id: ""
  }
};

export default function Insumo() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [inputList, setInputList] = useState(DEFAULT_STATE.input);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState();
  const [insumoIdPreview, setInsumoIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
    async function getAllInputs() {
      showLoader();
      const inputResponse = await inputApi.findAllPageable(0, rowsPerPage, filters);
      if (inputResponse) {
        setInputList(inputResponse.data.response);
        setTotalPages(inputResponse.data.totalPages);
      } else {
        setInputList(DEFAULT_STATE.input);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllInputs();

  }, [filters, forceRefreshData])

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const input = await inputApi.status(data);
    if (input) {
      if (input.data.response[0].active) {
        toast.success(`Insumo ativado com sucesso.`);
      } else {
        toast.success(`Insumo inativado com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleDelete = async (id) => {
    const deleted = await inputApi.delete(id);
    if (deleted) {
      cleanForm();
      if (inputList.length == 1) {
        setPage(page - 1);
      }
      setForceRefreshData(!forceRefreshData);
      toast.success(`Insumo deletado com sucesso.`);
    } else {
      toast.error(`Erro ao deletar insumo.`);
    }
  };

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.insumo}/cadastro`)
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.insumo}/edicao/${id}`)
  };

  const handlePreview = (id) => {
    setInsumoIdPreview(id);
    setShowPreview(true);
  };

  const cleanForm = () => {
    setData(DEFAULT_STATE.data);
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Código', 'Data Inclusão', 'Nome', 'Unidade', 'Fornecedor', 'Situação', 'Ações'];

  const rows = inputList.map(input => [
    input.code,
    input.createdAt,
    input.name,
    `${input.unity.code} - ${input.unity.description}`,
    input.supplier.name,
    <SwitchStatus
      id={input.id}
      active={input.active}
      actions={{
        onSwitch: (id, status) => handleChangeActive(id, status)
      }}
    />,
    <DataTableDefaultAction
      id={input.id}
      actions={{
        onEdit: () => handleEdit(input.id),
        onDelete: () => handleDelete(input.id),
        onPreview: () => handlePreview(input.id)
      }}
    />
  ]);

  return (
    <div className="container-fluid">
      <PageTitle title="Insumos" icon={<BugReportRoundedIcon style={{ color: 'gray' }} />} />
      <div className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <InsumoFilter onFilter={handleFilter} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Insumo" onClick={handleCreate} />
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable header={header}
                rows={rows}
                totalPages={totalPages}
                page={page}
                onPage={handleChangePage}
                onRowsPerPage={handleChangeRowsPerPage} />
            </Box>
            <PreviewModal openDialog={showPreview}
              onCloseDialog={handleClosePreview}
              width="70%" height="55%">
              <InsumoPreview id={insumoIdPreview} />
            </PreviewModal>
          </Grid> 
        </Grid>
      </div>
    </div>
  );
}
