import React from "react";
import Dashboard from "./Dashboard";
import Notification from "./Notification";

export default function Home({ history }) {
  return (
    <div className="container-fluid">
      <Notification />
      <Dashboard history={history}/>
    </div>
  );
}
