export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        quantityInput: data.quantityInput,
        line: {
            id : data.line?.id
        },
        serviceAssignment: {
            supplier: {
                id: data.supplier?.id
            },
            workTime: data.workTime
        }
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        description: data.description
    }

}


export default{
    serialize
}