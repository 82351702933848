import { Box, Divider, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD, pathRoutes, selectCustomerPresence, selectDanfeType, selectDocumentModel, selectEmissionType, selectInvoicePurpose } from "../../../config/constants";
import AlertDialog from "../../../components/AlertDialog";
import ButtonRemove from "../../../components/ButtonRemove";
import { useLoader } from "../../../hooks/LoaderContext";
import BuildIcon from '@material-ui/icons/Build';
import taxOperationApi from "../../../services/taxOperationApi";
import ButtonConfig from "../../../components/ButtonConfig";
import { Autocomplete } from "@material-ui/lab";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    active: true,
    code: "",
    description: "",
    cfop: "",
    documentModel: "",
    emissionType: "",
    danfeType: "",
    invoicePurpose: "",
    customerPresence: "",
  },
  taxOperationInput: {
    id: "",
    description: ""
  },
  documentModelInput: {
    id : "",
    title: ""
  }
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD),
  cfop: Yup.string().required(REQUIRED_FIELD),
  documentModel: Yup.string().required(REQUIRED_FIELD),
  emissionType: Yup.string().required(REQUIRED_FIELD),
  danfeType: Yup.string().required(REQUIRED_FIELD),
  invoicePurpose: Yup.string().required(REQUIRED_FIELD),
  customerPresence: Yup.string().required(REQUIRED_FIELD)
});

export default function OperacaoFiscalCadastro() {
  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const params = useParams();
  const { showLoader, hideLoader } = useLoader();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [documentModel, setDocumentModel] = useState(DEFAULT_STATE.documentModelInput);

  useEffect(() => {
    async function findById() {
      showLoader();
      if (!isNaN(params.ident)) {
        const taxOperation = await taxOperationApi.findById(params.ident);
        if (taxOperation) {
          const data = {
            id: taxOperation.data.response[0].id,
            code: taxOperation.data.response[0].code,
            description: taxOperation.data.response[0].description,
            documentModel: taxOperation.data.response[0].documentModel,
            cfop: taxOperation.data.response[0].cfop,
            emissionType: taxOperation.data.response[0].emissionType,
            danfeType: taxOperation.data.response[0].danfeType,
            invoicePurpose: taxOperation.data.response[0].invoicePurpose,
            customerPresence: taxOperation.data.response[0].customerPresence,
          };
          setData(data);
        }
      }
      hideLoader();
    }
    findById();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let taxOperation;
      if (values.id.length === 0) {
        taxOperation = await taxOperationApi.post(values);
      } else {
        taxOperation = await taxOperationApi.put(values);
      }
      if (taxOperation) {
        handleSuccess(values.id.length === 0);
        handleEdit(taxOperation.data.response[0].id);
      } else {
        handleError();
      }
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    const deleted = await taxOperationApi.delete(params.ident);
    if (deleted) {
      toast.success(`Operação Fiscal deletada com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Operação Fiscal, favor verifique!.`);
    }
    redirectTo();
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.operacaoFiscalEdit}`.replace(":ident?", id))
  };


  const handleSuccess = (isNew) => {
    const successMessage = isNew ? "Operação Fiscal cadastrada com sucesso." : "Operação Fiscal atualizada com sucesso.";
    toast.success(successMessage);
  };

  const handleConfig = () => {
    history.push(`${pathRoutes.operacaoFiscalConfig}`.replace(":ident?", params.ident));
  };

  const handleError = () => {
    toast.error(`Falha ao criar Operação Fiscal, favor verifique!`);
  };

  const redirectTo = () => {
    history.goBack();
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Cadastrar Operação Fiscal' : 'Editar Operação Fiscal'} icon={<BuildIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors, setFieldValue }) => (
              <Form className='mt-3'>
                <Grid container spacing={2}>
                  <Grid className="mt-3 mb-1" item md={3} sm={3} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="code"
                      label="Código"
                      helperText={touched.code && errors.code ? <ErrorMessage name="code" /> : ""}
                      error={touched.code && Boolean(errors.code)}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-1" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="description"
                      label="Descrição"
                      helperText={touched.description && errors.description ? <ErrorMessage name="description" /> : ""}
                      error={touched.description && Boolean(errors.description)}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-1" item md={3} sm={3} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="cfop"
                      label="CFOP"
                      helperText={touched.cfop && errors.cfop ? <ErrorMessage name="cfop" /> : ""}
                      error={touched.cfop && Boolean(errors.cfop)}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-1" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      className="form-control"
                      name="documentModel"
                      select
                      label="Modelo fiscal">
                        {(selectDocumentModel.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )))}
                      </Field>
                  </Grid>

                  <Grid className="mt-3 mb-1" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      className="form-control"
                      name="emissionType"
                      select
                      label="Tipo de Operação">
                        {(selectEmissionType.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )))}
                      </Field>
                  </Grid>

                  <Grid className="mt-3 mb-1" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      className="form-control"
                      name="danfeType"
                      select
                      label="Tipo da DANFE">
                        {(selectDanfeType.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )))}
                      </Field>
                  </Grid>

                  <Grid className="mt-4 mb-1" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      className="form-control"
                      name="invoicePurpose"
                      select
                      label="Finalidade da Nota Fiscal">
                        {(selectInvoicePurpose.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )))}
                      </Field>
                  </Grid>

                  <Grid className="mt-4 mb-1" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      className="form-control"
                      name="customerPresence"
                      select
                      label="Presença do comprador no estabelecimento">
                        {(selectCustomerPresence.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )))}
                      </Field>
                  </Grid>

                  <Grid className="mt-4 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <>
                              <Grid item md={4} sm={4} xs={12}>
                                <ButtonConfig disabled title="Configuraçoes" onClick={handleConfig} />
                              </Grid>
                              <Grid item md={4} sm={4} xs={12}>
                                <ButtonRemove
                                  title="excluir"
                                  onClick={handleOpenDeleteModal}
                                  variant="contained"
                                  color="primary"
                                  className="btn btn-primary btn-user btn-block"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item md={params.ident ? 4 : 12} sm={params.ident ? 4 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disabled={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}