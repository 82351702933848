import { del, get, patch, post, put } from "../utils/request"
import { serialize, serializeCancel } from "./mapper/OrderMapper";

export const create = async (data) => {
    return post("v1/order", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const update = async (data) => {
    return put("v1/order", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const status = async (data) => {
    return patch("v1/order", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const approve = async (id) => {
    return patch(`v1/order/approve/${id}`, undefined,`${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const resend = async (id) => {
    return patch(`v1/order/resend/${id}`, undefined,`${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const cancel = async (id, justify) => {
    return post(`v1/order/cancel`, serializeCancel(id, justify), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const reject = async (id, justify) => {
    return post(`v1/order/reject`, serializeCancel(id, justify), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const findAllPageable = async (page, size, filters) => {
    if (filters) {
        return get(`v1/order/all/pageable?page=${page}&size=${size}${filters}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
    }
    return get(`v1/order/all/pageable?page=${page}&size=${size}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const findAll = async (page, size) => {
    return get(`v1/order/all`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const findById = async (id) => {
    return get(`v1/order/${id}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const remove = async (id) => {
    return del(`v1/order/${id}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export default {
    post : create,
    update,
    status,
    findAllPageable,
    findAll,
    findById,
    delete : remove,
    put : update,
    approve: approve,
    cancel: cancel,
    reject: reject,
    resend: resend
}