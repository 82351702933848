import { pathRoutes } from "../../config/constants";
import Item from "./index";

export default [
  {
    path: pathRoutes.item,
    component: Item,
    exact: true,
  },
];
