import React from "react";
import arselLoader from '../../assets/animated/login/arsel-animation-login2.json';
import Lottie from 'lottie-react';

function LoginPage(props) {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: arselLoader,
    width: '220px',
    height: '210px'
  };

  const { label, text, body } = props;
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row-p row">
                <div className="col-lg-6 d-none d-lg-block bg-logo">
                  <div className="img-fluid-login">
                    <Lottie animationData={arselLoader} autoplay loop style={{ width: defaultOptions.width, height: defaultOptions.height }}  />                
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">
                      {label}
                      </h1>
                      <p className="mb-4 text-gray-900">
                        {text}
                      </p>
                    </div>
                      {body}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
