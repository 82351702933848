import React, { useEffect, useState } from "react";
import spotApi from "../../../services/spotApi";
import lineApi from "../../../services/lineApi";
import supplierApi from "../../../services/supplierApi";
import { Box, Divider, Grid, MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import toast from "../../../utils/toast";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";
import { useParams, useHistory } from 'react-router-dom';
import ButtonSubmit from "../../../components/ButtonSubmit";
import PageTitle from "../../../components/PageTitle";
import BasicReturn from "../../../components/BasicReturn";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';
import { useLoader } from "../../../hooks/LoaderContext";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";


const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: false,
        code: "",
        quantityInput: "",
        workTime: "",
        line: {
            id: ""
        },
        supplier: {
            id: ""
        }
    },
    lineInput: {
        title: "",
        id: ""
    },
    supplierInput: {
        title: "",
        id: ""
    },
    spot: [],
    lines: [],
    supplier: []
};

const validationSchema = Yup.object().shape({
    code: Yup.string().required(REQUIRED_FIELD),
    quantityInput: Yup.number().required(REQUIRED_FIELD)
});

export default function CanteiroCadastro() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [spotList, setSpotList] = useState(DEFAULT_STATE.spot);
    const [lineList, setLineList] = useState(DEFAULT_STATE.lines);
    const [line, setLine] = useState(DEFAULT_STATE.lineInput);
    const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);
    const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierInput);
    const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);
    const history = useHistory();
    const params = useParams();
    const { showLoader, hideLoader } = useLoader();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        async function getAllSpots() {
            setIsLoading(true);
            const spotReponse = await spotApi.findAll();
            if (spotReponse) {
                setSpotList(spotReponse.data.response);
            }
            setIsLoading(DEFAULT_STATE.isLoading);
        }
        getAllSpots();
    }, [])

    useEffect(() => {
        async function findById() {
            showLoader();
            if (params.ident) {
                const spotReponse = await spotApi.findById(params.ident);
                if (spotReponse) {
                    const spot = spotReponse.data.response[0];
                    console.log(spot)
                    const data = {
                        id: spot.id,
                        code: spot.code,
                        quantityInput: spot.quantityInput,
                        workTime: spot.serviceAssignment.workTime,
                        active: spot.active,
                        line: {
                            id: spot.line ? spot.line.id : ""
                        },
                        supplier: {
                            id: spot.supplier ? spot.supplier.id : ""
                        }
                    };
                    setData(data);
                    setLine(spot.line ? { title: spot.line.code, id: spot.line.id } : DEFAULT_STATE.lineInput)
                    setSupplier(spot.serviceAssignment ? { title: spot.serviceAssignment.supplier.name, id: spot.serviceAssignment.supplier.id } : DEFAULT_STATE.supplierInput)
                }
            }
            hideLoader();
        }
        findById();
    }, []);

    useEffect(() => {
        async function getAllLine() {
            const lineResponse = await lineApi.findAll();
            if (lineResponse) {
                setLineList(lineResponse.data.response);
            }
        }
        getAllLine();
    }, []);

    useEffect(() => {
        async function getAllSuppliers() {
            const supplierResponse = await supplierApi.findAll();
            if (supplierResponse) {
                setSupplierList(supplierResponse.data.response);
            }
        }
        getAllSuppliers();
    }, []);

    const handleChangeLine = (value, setFieldValue) => {
        setLine(value)
        setFieldValue("line", value ? value : '');
    };

    const handleChangeSupplier = (value, setFieldValue) => {
        setSupplier(value)
        setFieldValue("supplier", value ? value : '');
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let spot;
            if (values.id.length === 0) {
                spot = await spotApi.post(values);
            } else {
                spot = await spotApi.put(values);
            }
            if (spot) {
                handleSuccess(values.id.length === 0);
            } else {
                handleError();
            }
            redirectToCanteiro();
        } catch (error) {
            console.error('Erro ao enviar formulário: ', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleDelete = async () => {
        const deleted = await spotApi.delete(params.ident);
        if (deleted) {
            toast.success(`Canteiro deletado com sucesso.`);
        } else {
            toast.error(`Falha ao excluir canteiro, favor verifique!.`);
        }
        redirectToCanteiro();
    };

    const handleSuccess = (isNew) => {
        const successMessage = isNew ? "Canteiro cadastrado com sucesso."
            : "Canteiro atualizado com sucesso..";
        toast.success(successMessage);
    }

    const handleError = () => {
        toast.error(`Falha ao criar canteiro, favor verifique!.`);
    }

    const redirectToCanteiro = () => {
        history.goBack();
    };

    const lines = lineList.map(line => ({
        title: line.code,
        id: line.id
    }));

    const suppliers = supplierList.map(supplier => ({
        title: supplier.name,
        id: supplier.id
    }));

    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseModalDelete = () => {
        setOpenDeleteModal(false);
    };

    const canteiroCodes = [
        {
            value: 'A',
            label: 'A',
        },
        {
            value: 'B',
            label: 'B',
        },
        {
            value: 'C',
            label: 'C',
        },
    ];

    return (
        <>
            <div className="container-fluid">
                <PageTitle title={params.ident ? 'Cadastrar Canteiro' : 'Editar Canteiro'} icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
                <BasicReturn />
                <Divider />
                <div className="artical-1 out-shadow">
                    <Formik
                        initialValues={data}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ isSubmitting, touched, errors, setFieldValue }) => (
                            <Form>
                                <Box border="1px solid #EEEFF2" borderRadius="10px" p={2}>
                                    <Grid container spacing={2}>
                                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>

                                            <Field
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                select
                                                name="code"
                                                label="Código Canteiro"
                                                helperText={
                                                    (touched.code && errors.code)
                                                        ? <ErrorMessage name="code" />
                                                        : "Selecione o código do canteiro"
                                                }
                                                error={touched.code && errors.code}>
                                                {canteiroCodes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                                            <Autocomplete
                                                id="autocomplete-line"
                                                options={lines}
                                                clearText="Limpar"
                                                value={line}
                                                onChange={(event, value) => handleChangeLine(value, setFieldValue)}
                                                getOptionLabel={(option) => option.title}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderInput={(params) =>
                                                    <TextField {...params} label="Linha" variant="outlined" />
                                                }
                                            />
                                        </Grid>
                                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                                            <Field
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                name="quantityInput"
                                                label="Quantidade de Esterco"
                                                inputProps={{
                                                    type: 'number'
                                                }}
                                                helperText={(touched.quantityInput && errors.quantityInput) && <ErrorMessage name="quantityInput" />}
                                                error={touched.quantityInput && errors.quantityInput}>
                                            </Field>
                                        </Grid>
                                        <Grid className="mt-1 mb-2" item md={4} sm={4} xs={12}>
                                            <Autocomplete
                                                id="autocomplete-supplier"
                                                options={suppliers}
                                                clearText="Limpar"
                                                value={supplier}
                                                onChange={(event, value) => handleChangeSupplier(value, setFieldValue)}
                                                helperText="Selecione o prestador que atuou na criação do canteiro"
                                                getOptionLabel={(option) => option.title}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderInput={(params) =>
                                                    <TextField {...params} label="Operador da máquina" variant="outlined" />
                                                }
                                            />
                                        </Grid>
                                        <Grid className="mt-1 mb-2" item md={4} sm={4} xs={12}>
                                            <Field
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                name="workTime"
                                                label="Tempo de serviço"
                                                inputProps={{
                                                    type: 'number'
                                                }}
                                                helperText={"Em minutos"}>
                                            </Field>
                                        </Grid>
                                        <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                                <Box className="mt-1 c-inline-btn">
                                                    <Grid container spacing={2}>
                                                        {params.ident && (
                                                            <Grid item md={6} sm={6} xs={12}>
                                                                <ButtonRemove
                                                                    title="excluir"
                                                                    onClick={handleOpenDeleteModal}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="btn btn-primary btn-user btn-block"
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                                            <ButtonSubmit
                                                                label="salvar"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                className="btn btn-primary btn-user btn-block"
                                                                disable={isSubmitting}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <AlertDialog title="Realmente deseja excluir?"
                description="O dado será excluído de forma permanente"
                confirmTitle="Confirmar"
                cancelTitle="Cancelar"
                openDialog={openDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleCloseModalDelete}
            />
        </>
    );
}