import axios from "axios";
import { toast } from "react-toastify";
import { getToken, signOut } from "./auth";

const api = axios.create();

const validateError = (error, path) =>{
  const statusCode = error.response.status
  if(statusCode === 400){
    if(error.response.data.errors){
      error.response.data.errors.map(error => 
        toast.warn(error.error)
      )
    }else{
      error.response.data.map(error => 
        toast.warn(error.error)
      )
    }
  }
  if(statusCode === 401){
    if(path === 'v1/login') {
      toast.warn("Usuário ou senha inválido")
    }
  } 
  if(statusCode === 403){
    toast.warn("Sessão expirada")
    signOut();
  } 
}

const get = async (path, baseUrl, isAuth ) => {
    try {
      return await api.get(
        getUrl(baseUrl, path),
        isAuth ? { headers: { Authorization: getToken()}} : undefined
      )
    } catch (error) {
      validateError(error);
      return null;
    }
};

const download = async (path, fileName, baseUrl, isAuth, format) => {
  try {
    const response = await api.get(getUrl(baseUrl, path), {
      responseType: 'blob',
      headers: isAuth ? { Authorization: getToken() } : undefined,
    });

    const currentDate = new Date();
    const dataFormatada = currentDate.toISOString().split('T')[0];

    const nomeArquivo = `${fileName}_${dataFormatada}.${format}`;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nomeArquivo);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    link.parentNode.removeChild(link);

  } catch (error) {
    validateError(error);
    return null;
  }
};

const post = async (path, body, baseUrl, isAuth) => {
    return await api.post(
        getUrl(baseUrl, path),
        body,
        isAuth ? { headers: { Authorization: getToken()}} : undefined
    )
    .catch(error => {
      validateError(error, path);
      return undefined;
    })
};

const put = async (path, body, baseUrl) => {
    try {
    return await api.put(
        getUrl(baseUrl, path),
        body,
        { headers: { Authorization: getToken()} } 
    );
    } catch (error) {
        validateError(error);
        return undefined;
    }
};

const patch = async (path, body, baseUrl) => {
  try {
  return await api.patch(
      getUrl(baseUrl, path),
      body,
      { headers: { Authorization: getToken()} } 
  );
  } catch (error) {
      validateError(error);
      return undefined;
  }
};


 const del = async (path, baseUrl, isAuth) => {
    return await api.delete(
        getUrl(baseUrl, path),
        isAuth ? { headers: { Authorization: getToken()}} : undefined
    ).catch(error => {
      validateError(error, path) 
    });
 };

const getUrl = (baseUrl, path) =>
  baseUrl ? `${baseUrl}/${path}` : `${process.env.REACT_APP_BASE_URL}/${path}`;

export {
    get,
    post,
    put,  
    patch,
    del,
    download
}