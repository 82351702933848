import { pathRoutes } from "../../config/constants";
import Plantio from "./index";

export default [
  {
    path: pathRoutes.plantio,
    component: Plantio,
    exact: true,
  },
];
