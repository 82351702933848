import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import sectorApi from "../../../../services/sectorApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        description: "",
        site: {
            id: "",
            title: ""
        }
    }
};

export default function SectorPreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function findById() {
            const sectorResponse = await sectorApi.findById(id);
            if (sectorResponse) {
                const sectorData = sectorResponse.data.response[0];
                setData({
                    id: sectorData.id,
                    active: sectorData.active,
                    code: sectorData.code,
                    description: sectorData.description,
                    site: {
                        id: sectorData.site.id,
                        title: sectorData.site.name
                    }
                })
            }
        }
        findById();
    }, [])

    return (
        <>
            <Box>
                <PageTitle title="Setor" icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
                <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="code"
                            label="Código do Setor"
                            value={data.code}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="description"
                            label="Descrição do setor"
                            value={data.description}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="site"
                            label="Sítio"
                            value={data.site.title}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}