import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import BuildIcon from '@material-ui/icons/Build';
import PageTitle from "../../../../../PageTitle";
import TextFieldPreview from "../../../../../TextFieldPreview";
import taxOperationItemApi from "../../../../../../services/taxOperationItemApi";


const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        taxOperation: {
            title: "",
            id: ""
        },
        taxType: {
            title: "",
            id: ""
        },
        cst: {
            title: "",
            id: ""
        },
        aliquotPercent: {
            description: "",
            id: ""
        },
    }
};

export default function OperacaoFiscalPadraoPreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function findById() {
            const operacaoFiscalPadraoResponse = await taxOperationItemApi.findById(id);
            if (operacaoFiscalPadraoResponse) {
                const operacaoFiscalPadraoData = operacaoFiscalPadraoResponse.data.response[0];
                setData({
                    id: operacaoFiscalPadraoData.id,
                    taxOperation: {
                        id: operacaoFiscalPadraoData.taxOperation.id,
                        title: operacaoFiscalPadraoData.taxOperation.code
                    },
                    taxType: {
                        id: operacaoFiscalPadraoData.aliquotPercent.taxType.id,
                        title: operacaoFiscalPadraoData.aliquotPercent.taxType.description
                    },
                    cst: {
                        id: operacaoFiscalPadraoData.aliquotPercent.cst?.id,
                        title: operacaoFiscalPadraoData.aliquotPercent.cst?.description
                    },
                    aliquotPercent: {
                        id: operacaoFiscalPadraoData.aliquotPercent.id,
                        description: operacaoFiscalPadraoData.aliquotPercent.description
                    },
                })
            }
        }
        findById();
    }, [])

    return (
        <>
            <Box>
                <PageTitle title="Operação Fiscal Padrão" icon={<BuildIcon style={{ color: 'gray' }} />} />
                <Grid container spacing={2}>
                    <Grid className="mt-4" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="taxType"
                            label="Tipo de Imposto"
                            value={data.taxType.title}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    {data.cst.title && (
                        <Grid className="mt-4" item md={8} sm={8} xs={12}>
                            <TextFieldPreview
                                name="cst"
                                label="CST"
                                value={data.cst.title}
                                variant="outlined"
                            fullWidth
                            />
                        </Grid>
                    )}
                    <Grid className="mt-4 mb-2" item md={12} sm={12} xs={12}>
                        <TextFieldPreview
                            name="aliquotPercent"
                            label="Aliquota"
                            value={data.aliquotPercent.description}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}