import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { useHistory } from 'react-router-dom'

import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 25,
  },
}));

export default function BasicReturn() {
  const classes = useStyles();
  const history = useHistory();

  const handleReturn = () => {
    history.goBack();
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Button color="inherit" onClick={handleReturn}>
        <KeyboardReturnIcon className={classes.icon} />
        Voltar
      </Button>
    </Breadcrumbs>
  );
}
