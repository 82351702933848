import { pathRoutes } from "../../../config/constants";
import NcmCadastro from "./index";

export default [
  {
    path: pathRoutes.ncmCadastro,
    component: NcmCadastro,
    exact: true,
  },
  {
    path: pathRoutes.ncmEdit,
    component: NcmCadastro,
    exact: true,
  },
];
