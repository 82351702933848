import { Box, Button, Grid, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

export default function UnidadeFilter(props) {

    const { onFilter}  =  props;

    const [setValues] = useSetQueryStateValues();
    const [code, setCode] = useQueryState("code", '');
    const [description, setDescription] = useQueryState("description", '');
    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
    }, []);

    const handleFilter = async e => {
        let param = '';
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (description) {
            param = param.concat('&description=').concat(description);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        onFilter();
        setValues({code : "", description: ""})
        setIsFiltering(false);
    };

    const handleInputChangeCode = ({ target: { value } }) => {
        setCode(value);
    };

    const handleInputChangeDescription = ({ target: { value } }) => {
        setDescription(value);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                  <Grid className="mt-2 mb-1" item md={6} sm={6} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="code"
                          label="Sigla"
                          value={code}
                          onChange={handleInputChangeCode}>
                      </TextField>
                  </Grid>

                  <Grid className="mt-2 mb-1" item md={6} sm={6} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="description"
                          label="Descrição"
                          value={description}
                          onChange={handleInputChangeDescription}>
                      </TextField>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}