import { Box, Divider, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BuildIcon from '@material-ui/icons/Build';
import { useParams, useHistory } from 'react-router-dom';
import { Autocomplete } from "@material-ui/lab";
import PageTitle from "../../../../components/PageTitle";
import ButtonSubmit from "../../../../components/ButtonSubmit";
import ButtonRemove from "../../../../components/ButtonRemove";
import toast from "../../../../utils/toast";
import AlertDialog from "../../../../components/AlertDialog";
import BasicReturn from "../../../../components/BasicReturn";
import taxTypeApi from "../../../../services/taxTypeApi";
import cstApi from "../../../../services/cstApi";
import aliquotaApi from "../../../../services/aliquotaApi";
import taxOperationApi from "../../../../services/taxOperationApi";
import taxOperationItemApi from "../../../../services/taxOperationItemApi";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../../config/constants";
import { useLoader } from "../../../../hooks/LoaderContext";
import itemApi from "../../../../services/itemApi";


const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        taxOperation: {
            title: "",
            id: ""
        },
        taxType: {
            title: "",
            id: ""
        },
        cst: {
            title: "",
            id: ""
        },
        aliquotPercent: {
            description: "",
            id: ""
        },
        item: {
            title: "",
            id: ""
        }
    },
    taxTypes: [],
    taxTypeInput: {
        title: "",
        id: ""
    },
    csts: [],
    cstInput: {
        title: "",
        id: ""
    },
    aliquotPercents: [],
    aliquotPercentInput: {
        description: "",
        code: ""
    },
    items: [],
    itemInput: {
        title: "",
        id: ""
    }
};

const validationSchema = Yup.object().shape({
    // taxOperation: Yup.object().shape({
    //   id: Yup.string().required(REQUIRED_FIELD)
    // }),
    // cst: Yup.object().shape({
    //   id: Yup.string().required(REQUIRED_FIELD)
    // }),
    // aliquotPercent: Yup.object().shape({
    //   id: Yup.string().required(REQUIRED_FIELD)
    // }),
    // category: Yup.object().shape({
    //   id: Yup.string().required(REQUIRED_FIELD)
    // }),
    // item: Yup.object().shape({
    //   id: Yup.string().required(REQUIRED_FIELD)
    // }),
});

export default function OperacaoFiscalCategoriaCadastro() {

    const [data, setData] = useState(DEFAULT_STATE.data);

    const [taxType, setTaxType] = useState(DEFAULT_STATE.taxTypeInput);
    const [taxTypeList, setTaxTypeList] = useState(DEFAULT_STATE.taxTypes);

    const [cst, setCst] = useState(DEFAULT_STATE.cstInput);
    const [cstList, setCstList] = useState(DEFAULT_STATE.csts);

    const [aliquotPercent, setAliquotPercent] = useState(DEFAULT_STATE.aliquotPercentInput);
    const [aliquotPercentList, setAliquotPercentList] = useState(DEFAULT_STATE.aliquotPercents);

    const [item, setItem] = useState(DEFAULT_STATE.itemInput);
    const [itemList, setItemList] = useState(DEFAULT_STATE.items);

    const [taxOperation, setTaxOperation] = useState('');

    const history = useHistory();
    const params = useParams();
    const { showLoader, hideLoader } = useLoader();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        async function getTaxTypes() {
            showLoader();
            const taxTypeResponse = await taxTypeApi.findAll();
            if (taxTypeResponse) {
                setTaxTypeList(taxTypeResponse.data.response);
            }
            hideLoader();
        }
        getTaxTypes();
    }, []);

    useEffect(() => {
        async function getAllCsts() {
            showLoader();
            if (taxType.id) {
                const cstResponse = await cstApi.findAll(taxType.id);
                if (cstResponse) {
                    setCstList(cstResponse.data.response);
                }
            }
            hideLoader();
        }
        getAllCsts();
    }, [taxType]);

    useEffect(() => {
        async function getAllAliquots() {
            showLoader();
            if (taxType.id) {
                const aliquotResponse = await aliquotaApi.findAll(taxType.id, cst ? cst.id : undefined);
                if (aliquotResponse) {
                    setAliquotPercentList(aliquotResponse.data.response);
                } else {
                    setAliquotPercentList([]);
                }
            }
            hideLoader();
        }
        getAllAliquots();
    }, [taxType, cst]);

    useEffect(() => {
        async function getAllItem() {
            showLoader();
            const itemResponse = await itemApi.findAll();
            if (itemResponse) {
                setItemList(itemResponse.data.response);
            }
            hideLoader();
        }
        getAllItem();
    }, []);

    useEffect(() => {
        async function findById() {
            showLoader();
            if (!isNaN(params.ident)) {
                const taxOperationResponse = await taxOperationItemApi.findById(params.ident);
                if (taxOperationResponse) {
                    const taxOperationData = taxOperationResponse.data.response[0];
                    const data = {
                        id: taxOperationData.id,
                        taxOperation: {
                            id: taxOperationData.taxOperation?.id,
                            title: taxOperationData.taxOperation?.code
                        },
                        taxType: {
                            id: taxOperationData.aliquotPercent?.taxType?.id,
                            title: taxOperationData.aliquotPercent?.taxType?.description
                        },
                        cst: {
                            id: taxOperationData.aliquotPercent?.cst?.id,
                            title: taxOperationData.aliquotPercent?.cst?.description
                        },
                        aliquotPercent: {
                            id: taxOperationData.aliquotPercent?.id,
                            description: taxOperationData.aliquotPercent?.description
                        },
                        item: {
                            id: taxOperationData.item?.id,
                            title: taxOperationData.item?.name
                        }
                    };
                    setData(data);
                    setCst({
                        id: taxOperationData.aliquotPercent?.cst?.id,
                        title: taxOperationData.aliquotPercent?.cst
                            ? `${taxOperationData.aliquotPercent.cst.code} - ${taxOperationData.aliquotPercent.cst.description}`
                            : ""
                    });
                    setAliquotPercent({
                        id: taxOperationData.aliquotPercent?.id,
                        title: taxOperationData.aliquotPercent?.description
                    });
                    setTaxType({
                        id: taxOperationData.aliquotPercent?.taxType?.id,
                        title: taxOperationData.aliquotPercent?.taxType?.description
                    });
                    setTaxOperation({
                        id: taxOperationData.taxOperation?.id,
                        code: taxOperationData.taxOperation?.code
                    });
                    setItem({
                        id: taxOperationData.item?.id,
                        title: taxOperationData.item?.name
                    });
                }
            }
            hideLoader();
        }
        findById();
    }, []);

    useEffect(() => {
        async function findByOperationId() {
            showLoader();
            if (!isNaN(params.itemId)) {
                const taxOperationResponse = await taxOperationApi.findById(params.itemId);
                if (taxOperationResponse) {
                    const taxOperationData = taxOperationResponse.data.response[0];
                    const taxOperation = {
                        id: taxOperationData.id,
                        code: taxOperationData.code,
                    };
                    setTaxOperation(taxOperation);
                }
            }
            hideLoader();
        }
        findByOperationId();
    }, []);

    const taxTypes = taxTypeList.map(taxType => ({
        title: taxType.description,
        id: taxType.id
    }));

    const csts = cstList.map(cst => ({
        title: `${cst.code} - ${cst.description}`,
        id: cst.id
    }));

    const aliquotPercents = aliquotPercentList.map(aliquotPercent => ({
        title: `${aliquotPercent.code} - ${aliquotPercent.description}`,
        id: aliquotPercent.id
    }));

    const items = itemList.map(item => ({
        title: item.name,
        id: item.id
    }));


    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseModalDelete = () => {
        setOpenDeleteModal(false);
    };

    const handleChangeItem = (value, setFieldValue) => {
        setItem(value);
        setFieldValue("item", value ? value : "");
    };

    const handleChangeTaxType = (value, setFieldValue) => {
        setTaxType(value);
        setFieldValue("taxType", value ? value : "");
    };

    const handleChangeCst = (value, setFieldValue) => {
        setCst(value);
        setFieldValue("cst", value ? value : "");
        setFieldValue("aliquotPercent", "");
        setAliquotPercent(DEFAULT_STATE.aliquotPercentInput);
    };

    const handleChangeAliquotPercent = (value, setFieldValue) => {
        setAliquotPercent(value);
        setFieldValue("aliquotPercent", value ? value : "");
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        values = {
            ...values,
            taxOperation: {
                id: params.itemId
            }
        }
        try {
            let taxOperationItem;
            if (values.id.length == 0) {
                taxOperationItem = await taxOperationItemApi.post(values);
                if (taxOperationItem) {
                    toast.success(`Item operação fiscal cadastrado com sucesso para o item.`);
                }
            } else {
                taxOperationItem = await taxOperationItemApi.put(values);
                if (taxOperationItem) {
                    toast.success(`Item operação fiscal atualizado com sucesso.`);
                }
            }
            if (taxOperationItem) {
            } else {
                toast.error(`Falha ao criar Item operação fiscal, favor verifique!.`);
            }
            redirectTo();
        } catch (error) {
            console.error('Erro ao enviar formulário: ', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleDelete = async () => {
        const deleted = await taxOperationItemApi.delete(params.ident);
        if (deleted) {
            toast.success(`Item operação fiscal deletado com sucesso.`);
        } else {
            toast.error(`Falha ao excluir Item operação fiscal, favor verifique!.`);
        }
        redirectTo();
    };

    const redirectTo = () => {
        history.goBack();
    };

    return (
        <>
            <div className="container-fluid">
                <PageTitle title={isNaN(params.ident) ? `Cadastro operação por item para ${taxOperation.code}` : `Atualiza operação por item para ${taxOperation.code}`} icon={<BuildIcon style={{ color: 'gray' }} />} />
                <BasicReturn />
                <Divider />
                <div className="artical-1 out-shadow">
                    <Formik
                        initialValues={data}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ isSubmitting, touched, errors, setFieldValue }) => (
                            <Form className='mt-3'>
                                <Grid container spacing={2}>
                                    <Grid className="mt-3 mb-2" item md={8} sm={8} xs={12}>
                                        <Autocomplete
                                            id="autocomplete-tax-type"
                                            options={taxTypes}
                                            clearText="Limpar"
                                            value={taxType}
                                            onChange={(event, value) => handleChangeTaxType(value, setFieldValue)}
                                            getOptionLabel={(option) => option ? option.title : ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Tipo de Imposto" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                                        <Autocomplete
                                            id="autocomplete-cst"
                                            options={csts}
                                            clearText="Limpar"
                                            disabled={!taxType.id}
                                            value={cst}
                                            onChange={(event, value) => handleChangeCst(value, setFieldValue)}
                                            getOptionLabel={(option) => option ? option.title : ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Cst" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            id="autocomplete-aliquot"
                                            options={aliquotPercents}
                                            clearText="Limpar"
                                            disabled={!taxType.id}
                                            value={aliquotPercent}
                                            onChange={(event, value) => handleChangeAliquotPercent(value, setFieldValue)}
                                            getOptionLabel={(option) => option ? option.title : ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Aliquota" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            id="autocomplete-item"
                                            options={items}
                                            clearText="Limpar"
                                            disabled={!taxType.id}
                                            value={item}
                                            onChange={(event, value) => handleChangeItem(value, setFieldValue)}
                                            getOptionLabel={(option) => option ? option.title : ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Item" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                                            <Box className="mt-1 c-inline-btn">
                                                <Grid container spacing={2}>
                                                    {params.ident && (
                                                        <Grid item md={6} sm={6} xs={12}>
                                                            <ButtonRemove
                                                                title="excluir"
                                                                onClick={handleOpenDeleteModal}
                                                                variant="contained"
                                                                color="primary"
                                                                className="btn btn-primary btn-user btn-block"
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                                        <ButtonSubmit
                                                            label="salvar"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className="btn btn-primary btn-user btn-block"
                                                            disable={isSubmitting}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <AlertDialog title="Realmente deseja excluir?"
                description="O dado será excluído de forma permanente"
                confirmTitle="Confirmar"
                cancelTitle="Cancelar"
                openDialog={openDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleCloseModalDelete}
            />
        </>
    );

}