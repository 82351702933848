import { Box, Divider, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import serviceApi from "../../../services/serviceApi";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    active: false,
    code: "",
    description: ""
  },
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD),
});

export default function ServicoCadastro() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    handleEdit(params.ident);
  }, []);

  const handleEdit = async (id) => {
    if (id) {
      const serviceReponse = await serviceApi.findById(id);
      if (serviceReponse) {
        const data = {
          id: serviceReponse.data.response[0].id,
          code: serviceReponse.data.response[0].code,
          description: serviceReponse.data.response[0].description
        };
        setData(data);
      }
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let service;
      if (values.id.length == 0) {
        service = await serviceApi.post(values);
      } else {
        service = await serviceApi.put(values);
      }
      if (service) {
        handleSuccess(values.id.length);
      } else {
        handleError();
      }
      redirectTo();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Seviço cadastrada com sucesso."
      : "Seviço atualizado com sucesso.";
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar Serviço, favor verifique!.`);
  }

  const redirectTo = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    const deleted = await serviceApi.delete(params.ident);
    if (deleted) {
      redirectTo();
      toast.success(`Serviço deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir serviço, favor verifique!.`);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={!params.ident ? 'Cadastrar Serviço' : 'Editar Serviço'} icon={<BuildRoundedIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors }) => (
              <Form className='mt-3'>
                <Grid container spacing={2}>
                  <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="code"
                      label="Código"
                      helperText={touched.code && errors.code ? <ErrorMessage name="code" /> : ""}
                      error={touched.code && Boolean(errors.code)}
                    />
                  </Grid>
                  <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="description"
                      label="Descrição"
                      helperText={touched.description && errors.description ? <ErrorMessage name="description" /> : ""}
                      error={touched.description && Boolean(errors.description)}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <Grid item md={6} sm={6} xs={12}>
                              <ButtonRemove
                                title="excluir"
                                onClick={handleOpenDeleteModal}
                                variant="contained"
                                color="primary"
                                className="btn btn-primary btn-user btn-block"
                              />
                            </Grid>
                          )}
                          <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disable={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}