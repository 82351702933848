import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";

function CardCanteiro(props) {
    const { code, line, sector, status, id, onClick, index } = props;

    const getStatus = (status) => {
        if (status) {
            return 'Ativo'
        } else {
            return 'Inativo'
        }
    }

    const getColor = (status) => {
        if (status) {
            return 'card-spot-active'
        } else {
            return 'card-spot-inactive'
        }
    }

    return (
        <div className="col-xl-4 col-md-6 mb-4" key={index} onClick={e => { onClick(id) }}>
            <Card sx={{ minWidth: 275 }} className="card-button" key={id}>
                <CardContent className={getColor(status)}>
                    <Typography>
                        Canteiro: {code}
                    </Typography>
                    <Typography>
                        Linha: {line}
                    </Typography>
                    <Typography>
                        Setor: {sector}
                    </Typography>
                    <Typography>
                        Status: {getStatus(status)}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

export default CardCanteiro;