import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "../../DataTable";
import toast from "../../../utils/toast";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../PageTitle";
import ButtonAdd from "../../ButtonAdd";
import { pathRoutes } from "../../../config/constants";
import { useHistory } from 'react-router-dom';
import PreviewModal from "../../PreviewModal";
import { useLoader } from "../../../hooks/LoaderContext";
import GeneroFilter from "./GeneroFilter";
import GeneroPreview from "./GeneroPreview";
import generoApi from "../../../services/generoApi";
import SwitchStatus from "../../SwitchStatus";
import DataTableDefaultAction from "../../DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
      id: "",
      code: "",
      description: ""
  },
  genero: []
};

export default function Genero() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [generoList, setGeneroList] = useState(DEFAULT_STATE.genero);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState();
  const [generoIdPreview, setGeneroIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  
  useEffect(() => {
    async function getAllGeneros() {
        showLoader();
        const generoReponse = await generoApi.findAllPageable(0, rowsPerPage);
        if (generoReponse) {
          setGeneroList(generoReponse.data.response);
          setTotalPages(generoReponse.data.totalPages);
        }
        hideLoader();
    }
    getAllGeneros();
  },[])

  const handleDelete = async (id) => {
    const deleted = await generoApi.delete(id);
    if (deleted) {
      cleanForm();
      refreshDatatable(filters);
      toast.success(`Genero deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir genero, favor verifique!.`);
    }
  };

  const handleFilter = async (filters) => {
    refreshDatatable(filters);
    setFilters(filters);
  };
  
  const handleEdit = async (id) => {
    history.push(`${pathRoutes.genero}/edicao/${id}`)
  };

  const cleanForm = () => {
    setData(DEFAULT_STATE.data);
  }

  const refreshDatatable = async (filters) => {
    const generoReponse = await generoApi.findAllPageable(page-1, rowsPerPage, filters);
    if (generoReponse) {
        setGeneroList(generoReponse.data.response);
    }else{
      setGeneroList(DEFAULT_STATE.genero);
    }
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    const generoReponse = await generoApi.findAllPageable(value-1, rowsPerPage, filters);
    if (generoReponse) {
      setGeneroList(generoReponse.data.response);
      setTotalPages(generoReponse.data.totalPages);
    }
  };

  const handlePreview = (id) => {
    setGeneroIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.genero}/cadastro`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const generoReponse = await generoApi.findAllPageable(0, rows, filters);
    if (generoReponse) {
        setGeneroList(generoReponse.data.response);
        setTotalPages(generoReponse.data.totalPages);
    }
  };

  const header = ['Código', 'Descrição', 'Ações'];

  const rows = generoList.map(genero => [
    genero.code,
    genero.description,
    <DataTableDefaultAction
        id={genero.id}
        actions={{
          onEdit: () => handleEdit(genero.id),
          onDelete: () => handleDelete(genero.id),
          onPreview: () => handlePreview(genero.id)
        }} 
      />
  ]);
 
  return (
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
            <GeneroFilter onFilter={handleFilter} />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <ButtonAdd title="Cadastrar Genero" onClick={handleCreate}/>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box position="relative">
            <DataTable header={header} 
                        rows={rows}  
                        totalPages={totalPages}
                        page={page} 
                        onPage={handleChangePage} 
                        onRowsPerPage={handleChangeRowsPerPage}/>
          </Box>
          <PreviewModal openDialog={showPreview} 
                        onCloseDialog={handleClosePreview} 
                        width="70%" height="35%">
            <GeneroPreview id={generoIdPreview}/>
          </PreviewModal>
      </Grid>
    </Grid>
  );
}
