import {  get } from "../utils/request"
import { serialize, serializeInactive } from "./mapper/StateMapper";


export const findAllPageable = async (filters) => {
    if (filters && filters != '') {
        return get(`v1/state/all/pageable?&${filters}`, undefined, true);
    }
    return get(`v1/state/all/pageable?page=0&size=30`, undefined, true);
}


export default {
    findAllPageable
}