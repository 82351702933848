import React, { useEffect, useState } from "react";
import lineApi from "../../../services/lineApi";
import { Box, Divider, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import toast from "../../../utils/toast";
import sectorApi from "../../../services/sectorApi";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";
import ButtonRemove from "../../../components/ButtonRemove";
import { useParams, useHistory } from 'react-router-dom';
import { useLoader } from "../../../hooks/LoaderContext";
import AlertDialog from "../../../components/AlertDialog";
import ButtonSubmit from "../../../components/ButtonSubmit";
import PageTitle from "../../../components/PageTitle";
import BasicReturn from "../../../components/BasicReturn";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        sector: {
            id: ""
        }
    },
    lines: [],
    sectors: [],
    sectorInput: {
        title: "",
        id: ""
    }
};

const validationSchema = Yup.object().shape({
    code: Yup.string().required(REQUIRED_FIELD)
});

export default function LineCadastro() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [sector, setSector] = useState(DEFAULT_STATE.sectorInput);
    const [sectorList, setSectorList] = useState(DEFAULT_STATE.sectors);
    const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);
    const history = useHistory();
    const params = useParams();
    const { showLoader, hideLoader } = useLoader();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);


    useEffect(() => {
        async function findById() {
            showLoader();
            if (params.ident) {
                const lineResponse = await lineApi.findById(params.ident);
                if (lineResponse) {
                    const line = lineResponse.data.response[0];
                    const data = {
                        id: line.id,
                        code: line.code,
                        active: line.active,
                        sector: {
                            id: line.sector.id
                        }

                    };
                    setData(data);
                    setSector({
                        title: line.sector.code,
                        id: line.sector.id
                    });
                }
            }
            hideLoader();
        }
        findById();
    }, []);

    useEffect(() => {
        async function getAllSectors() {
            const sectorResponse = await sectorApi.findAll();
            if (sectorResponse) {
                setSectorList(sectorResponse.data.response);
            }
        }
        getAllSectors();
    }, []);

    const handleChangeSector = (value, setFieldValue) => {
        setSector(value)
        setFieldValue("sector", value ? value : '');
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let line;
            if (values.id.length === 0) {
                line = await lineApi.post(values);
            } else {
                line = await lineApi.put(values);
            }
            if (line) {
                handleSuccess(values.id.length === 0);
            } else {
                handleError();
            }
            redirectToLine();
        } catch (error) {
            console.error('Erro ao enviar formulário: ', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSuccess = (isNew) => {
        const sucessMessage = isNew ? "Linha cadastrado com sucesso."
            : "Linha atualizado com sucesso.";
        toast.success(sucessMessage);
    }

    const handleError = () => {
        toast.error(`Falha ao criar linha, favor verifique!.`);
    }

    const redirectToLine = () => {
        history.goBack();
    };

    const handleDelete = async () => {
        const deleted = await lineApi.delete(params.ident);
        if (deleted) {
            toast.success(`Linha deletada com sucesso.`);
        } else {
            toast.error(`Falha ao excluir linha, favor verifique!.`);
        }
        redirectToLine();
    };

    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseModalDelete = () => {
        setOpenDeleteModal(false);
    };

    const sectors = sectorList.map(sector => ({
        title: sector.code,
        id: sector.id
    }));

    return (
        <>
            <div className="container-fluid">
                <PageTitle title={!params.ident ? 'Cadastrar Linha' : 'Editar Linha'} icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
                <BasicReturn />
                <Divider />
                <div className="artical-1 out-shadow">
                    <Formik
                        initialValues={data}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ isSubmitting, touched, errors, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="code"
                                            label="Código da Linha"
                                            helperText={(touched.code && errors.code) && <ErrorMessage name="code" />}
                                            error={touched.code && errors.code}>
                                        </Field>
                                    </Grid>
                                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            id="autocomplete-site"
                                            options={sectors}
                                            clearText="Limpar"
                                            value={sector}
                                            onChange={(event, value) => handleChangeSector(value, setFieldValue)}
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Setor" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                                            <Box className="mt-1 c-inline-btn">
                                                <Grid container spacing={2}>
                                                    {params.ident && (
                                                        <Grid item md={6} sm={6} xs={12}>
                                                            <ButtonRemove
                                                                title="excluir"
                                                                onClick={handleOpenDeleteModal}
                                                                variant="contained"
                                                                color="primary"
                                                                className="btn btn-primary btn-user btn-block"
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                                        <ButtonSubmit
                                                            label="salvar"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className="btn btn-primary btn-user btn-block"
                                                            disable={isSubmitting}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <AlertDialog title="Realmente deseja excluir?"
                description="O dado será excluído de forma permanente"
                confirmTitle="Confirmar"
                cancelTitle="Cancelar"
                openDialog={openDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleCloseModalDelete}
            />
        </>
    );

}