import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "../../DataTable";
import toast from "../../../utils/toast";
import ButtonAdd from "../../ButtonAdd";
import { pathRoutes } from "../../../config/constants";
import { useHistory } from 'react-router-dom';
import PreviewModal from "../../PreviewModal";
import { useLoader } from "../../../hooks/LoaderContext";
import ncmApi from "../../../services/ncmApi";
import NcmPreview from "./NcmPreview";
import NcmFilter from "./NcmFilter";
import SwitchStatus from "../../SwitchStatus";
import DataTableDefaultAction from "../../DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
      id: "",
      code: "",
      description: "",
      genero: {
        id: "",
        code: ""
      }
  },
  ncm: []
};

export default function Ncm() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [ncmList, setNcmList] = useState(DEFAULT_STATE.ncm);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState();
  const [ncmIdPreview, setNcmIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  
  useEffect(() => {
    async function getAllNcm() {
        showLoader();
        const ncmReponse = await ncmApi.findAllPageable(0, rowsPerPage);
        if (ncmReponse) {
          setNcmList(ncmReponse.data.response);
          setTotalPages(ncmReponse.data.totalPages);
        }
        hideLoader();
    }
    getAllNcm();
  },[])

  const handleDelete = async (id) => {
    const deleted = await ncmApi.delete(id);
    if (deleted) {
      cleanForm();
      refreshDatatable(filters);
      toast.success(`Ncm deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir ncm, favor verifique!.`);
    }
  };

  const handleFilter = async (filters) => {
    refreshDatatable(filters);
    setFilters(filters);
  };
  
  const handleEdit = async (id) => {
    history.push(`${pathRoutes.ncm}/edicao/${id}`)
  };

  const cleanForm = () => {
    setData(DEFAULT_STATE.data);
  }

  const refreshDatatable = async (filters) => {
    const ncmReponse = await ncmApi.findAllPageable(page-1, rowsPerPage, filters);
    if (ncmReponse) {
        setNcmList(ncmReponse.data.response);
    }else{
      setNcmList(DEFAULT_STATE.ncm);
    }
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    const ncmReponse = await ncmApi.findAllPageable(value-1, rowsPerPage, filters);
    if (ncmReponse) {
      setNcmList(ncmReponse.data.response);
      setTotalPages(ncmReponse.data.totalPages);
    }
  };

  const handlePreview = (id) => {
    setNcmIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.ncm}/cadastro`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const ncmReponse = await ncmApi.findAllPageable(0, rows, filters);
    if (ncmReponse) {
        setNcmList(ncmReponse.data.response);
        setTotalPages(ncmReponse.data.totalPages);
    }
  };

  const header = ['Código', 'Descrição', 'Genero', 'Ações'];

  const rows = ncmList.map(ncm => [
    ncm.code,
    ncm.description,
    `${ncm.genero.code} - ${ncm.genero.description}`,
    <DataTableDefaultAction
        id={ncm.id}
        actions={{
          onEdit: () => handleEdit(ncm.id),
          onDelete: () => handleDelete(ncm.id),
          onPreview: () => handlePreview(ncm.id)
        }} 
    />
  ]);
 
  return (
    <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
            <NcmFilter onFilter={handleFilter} />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <ButtonAdd title="Cadastrar Ncm" onClick={handleCreate}/>
          </Box>
        </Grid>
      
        <Grid item md={12} sm={12} xs={12}>
          <Box position="relative">
            <DataTable header={header} 
                        rows={rows} 
                        totalPages={totalPages}
                        page={page} 
                        onPage={handleChangePage} 
                        onRowsPerPage={handleChangeRowsPerPage}/>
          </Box>
          <PreviewModal openDialog={showPreview} 
                        onCloseDialog={handleClosePreview} 
                        width="70%" height="55%">
            <NcmPreview id={ncmIdPreview}/>
          </PreviewModal>
          </Grid>
    </Grid>
  );
}
