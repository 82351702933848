import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { pathRoutes } from "../../../../../config/constants";
import DataTable from "../../../../DataTable";
import toast from "../../../../../utils/toast";
import ButtonAdd from "../../../../ButtonAdd";
import PreviewModal from "../../../../PreviewModal";
import { useLoader } from "../../../../../hooks/LoaderContext";
import itemUnitSupplierApi from "../../../../../services/itemUnitSupplierApi";
import ItemUnidadeClienteFilter from "./ItemUnidadeClienteFilter";
import ItemUnidadeClientePreview from "./ItemUnidadeClientePreview";
import DataTableDefaultAction from "../../../../DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  itemUnitSuppliers: []
};

export default function ItemUnidadeCliente() {

  const [itemUnitSupplierList, setItemUnitSupplierList] = useState(DEFAULT_STATE.itemUnitSuppliers);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [idPreview, setIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const params = useParams();

  const [filters, setFilters] = useState();

  useEffect(() => {
    async function getAllItemUnitDefault() {
        showLoader();
        const itemUnitSupplierResponse = await itemUnitSupplierApi.findAllPageable(page-1 , rowsPerPage, `item_id=${params.ident}`);
        if (itemUnitSupplierResponse) {
            setItemUnitSupplierList(itemUnitSupplierResponse.data.response);
            setTotalPages(itemUnitSupplierResponse.data.totalPages);
        } else {
            setItemUnitSupplierList(DEFAULT_STATE.itemUnitSuppliers);
            setTotalPages(0);
        }
        hideLoader();
    }
    getAllItemUnitDefault();
  },[forceRefreshData, filters])

  const handleDelete = async (id) => {
    const deleted = await itemUnitSupplierApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Unidade por cliente do item deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Unidade por cliente do item, favor verifique!.`);
    }
  };
  
  const handleEdit = async (id) => {
    history.push(`${pathRoutes.itemConfigUnidadeClienteEdit.replace(":ident?", "")}${id}`)
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCreate = async () => {
    history.push(`${pathRoutes.itemConfigUnidadeClienteCadastro.replace(":itemId?", "")}${params.ident}`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Unidade', 'Item', 'Cliente', 'Ações'];

  const rows = itemUnitSupplierList.map(itemUnitSupplier => [
    `${itemUnitSupplier.unit.code} - ${itemUnitSupplier.unit.description}`,
    itemUnitSupplier.item.name,
    `${itemUnitSupplier.supplier.name} - ${itemUnitSupplier.supplier.document}`,
    <DataTableDefaultAction
        id={itemUnitSupplier.id}
        actions={{
          onEdit: () => handleEdit(itemUnitSupplier.id),
          onDelete: () => handleDelete(itemUnitSupplier.id),
          onPreview: () => handlePreview(itemUnitSupplier.id)
        }} 
      />
  ]);

  const handleFilter = async (filters) => {
    setFilters(filters);
  };
 
  return (
    <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
            <ItemUnidadeClienteFilter onFilter={handleFilter} />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <ButtonAdd title="Cadastrar Unidade Por Cliente" onClick={handleCreate}/>
          </Box>
        </Grid>
      
        <Grid item md={12} sm={12} xs={12}>
          <Box position="relative">
            <DataTable header={header} 
                       rows={rows} 
                       totalPages={totalPages}
                       page={page} 
                       onPage={handleChangePage} 
                       onRowsPerPage={handleChangeRowsPerPage}/>
          </Box>
          <PreviewModal openDialog={showPreview} 
                        onCloseDialog={handleClosePreview} 
                        width="70%" height="35%">
            <ItemUnidadeClientePreview id={idPreview}/>
          </PreviewModal>
          </Grid>
    </Grid>
  );
}