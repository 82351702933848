import { pathRoutes } from "../../../config/constants";
import GeneroCadastro from "./index";

export default [
  {
    path: pathRoutes.generoCadastro,
    component: GeneroCadastro,
    exact: true,
  },
  {
    path: pathRoutes.generoEdit,
    component: GeneroCadastro,
    exact: true,
  },
];
