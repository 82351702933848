import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import toast from "../../utils/toast";
import ScheduleIcon from '@material-ui/icons/Schedule';
import scheduleApi from "../../services/scheduleApi";
import PageTitle from "../../components/PageTitle";
import { useHistory } from 'react-router-dom';
import { pathRoutes } from "../../config/constants";
import AgendamentoFilter from "../../components/pages/Agendamento/AgendamentoFilter";
import AgendamentoPreview from "../../components/pages/Agendamento/AgendamentoPreview";
import PreviewModal from "../../components/PreviewModal";
import ButtonAdd from "../../components/ButtonAdd";
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import DataTableDefaultAction from "../../components/DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    category: "",
    type: "",
    description: "",
    days: "",
    active: false
  },
  schedule: []
};

export default function Agendamento() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [scheduleList, setScheduleList] = useState(DEFAULT_STATE.schedule);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState();
  const [scheduleIdPreview, setScheduleIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    async function getAllSchedules() {
      showLoader();
      const scheduleResponse = await scheduleApi.findAllPageable(0, rowsPerPage, filters);
      if (scheduleResponse) {
        setScheduleList(scheduleResponse.data.response);
        setTotalPages(scheduleResponse.data.totalPages);
      } else {
        setScheduleList(DEFAULT_STATE.schedule);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllSchedules();
  }, [filters])

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const schedule = await scheduleApi.status(data);
    if (schedule) {
      if (schedule.data.response[0].active) {
        toast.success(`Agendamento ativado com sucesso.`);
      } else {
        toast.success(`Agendamento inativado com sucesso.`);
      }
      refreshDatatable(filters);
    }
  };

  const handlePreview = (id) => {
    setScheduleIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.agendamento}/cadastro`)
  };

  const handleDelete = async (id) => {
    const deleted = await scheduleApi.delete(id);
    if (deleted) {
      cleanForm();
      refreshDatatable(filters);
      toast.success(`Agendamento deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir insumo, favor verifique!.`);
    }
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.agendamento}/edicao/${id}`)
  };

  const handleFilter = async (filters) => {
    refreshDatatable(filters);
    setFilters(filters);
  };

  const cleanForm = () => {
    setData(DEFAULT_STATE.data);
  }

  const refreshDatatable = async (filters) => {
    const scheduleResponse = await scheduleApi.findAllPageable(page - 1, rowsPerPage, filters);
    if (scheduleResponse) {
      setScheduleList(scheduleResponse.data.response);
    }
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    const scheduleResponse = await scheduleApi.findAllPageable(value - 1, rowsPerPage, filters);
    if (scheduleResponse) {
      setScheduleList(scheduleResponse.data.response);
      setTotalPages(scheduleResponse.data.totalPages);
    }
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const scheduleResponse = await scheduleApi.findAllPageable(0, rows, filters);
    if (scheduleResponse) {
      setScheduleList(scheduleResponse.data.response);
      setTotalPages(scheduleResponse.data.totalPages);
    }
  };

  const translateCategory = (value) => {
    switch (value) {
      case 'INPUT':
        return 'Aplicação de Insumo';
      case 'PLANTING':
        return 'Replantio';
    }
  };

  const header = ['Categoria', 'Tipo', 'Descrição', 'Dias após plantio', 'Data de criação', 'Situação', 'Ações'];

  const rows = scheduleList.map(schedule => [
    translateCategory(schedule.category),
    schedule.type,
    schedule.description,
    schedule.days,
    schedule.createdAt,
    <SwitchStatus
        id={schedule.id} 
        active={schedule.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
      />,
    <DataTableDefaultAction
        id={schedule.id}
        actions={{
          onEdit: () => handleEdit(schedule.id),
          onDelete: () => handleDelete(schedule.id),
          onPreview: () => handlePreview(schedule.id)
        }} 
/>
  ]);

  return (
    <div className="container-fluid">
      <PageTitle title="Agendamentos" icon={<ScheduleIcon style={{ color: 'gray' }} />} />
      <div className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Box border={1} borderRadius="borderRadius" borderColor="#EEEEEE" p={1}>
              <AgendamentoFilter onFilter={handleFilter} />
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Agendamentos" onClick={handleCreate} />
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable header={header}
                rows={rows}
                totalPages={totalPages}
                page={page}
                onPage={handleChangePage}
                onRowsPerPage={handleChangeRowsPerPage} />
            </Box>
            <PreviewModal openDialog={showPreview}
              onCloseDialog={handleClosePreview}
              width="70%" height="55%">
              <AgendamentoPreview id={scheduleIdPreview} />
            </PreviewModal>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
