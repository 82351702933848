import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import itemPricingDefaultApi from "../../../../../services/itemPricingDefaultApi";
import { pathRoutes } from "../../../../../config/constants";
import DataTable from "../../../../DataTable";
import toast from "../../../../../utils/toast";
import ButtonAdd from "../../../../ButtonAdd";
import PreviewModal from "../../../../PreviewModal";
import { useLoader } from "../../../../../hooks/LoaderContext";
import ItemPrecificacaoPadraoPreview from "./ItemPrecificacaoPadraoPreview";
import { formatMoney } from "../../../../../utils/moneyUtils";
import DataTableDefaultAction from "../../../../DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  itemPricingDefaults: []
};

export default function ItemPrecificacaoPadrao() {

  const [itemPricingDefaultList, setitemPricingDefaultList] = useState(DEFAULT_STATE.itemPricingDefaults);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [idPreview, setIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const params = useParams();

  useEffect(() => {
    async function getAllitemPricingDefault() {
        showLoader();
        const itemPricingDefaultResponse = await itemPricingDefaultApi.findAllPageable(page-1 , rowsPerPage, `item_id=${params.ident}`);
        if (itemPricingDefaultResponse) {
            setitemPricingDefaultList(itemPricingDefaultResponse.data.response);
            setTotalPages(itemPricingDefaultResponse.data.totalPages);
        } else {
            setitemPricingDefaultList(DEFAULT_STATE.itemPricingDefaults);
            setTotalPages(0);
        }
        hideLoader();
    }
    getAllitemPricingDefault();
  },[forceRefreshData])

  const handleDelete = async (id) => {
    const deleted = await itemPricingDefaultApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Precificação padrão do item deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Precificação padrão do item, favor verifique!.`);
    }
  };
  
  const handleEdit = async (id) => {
    history.push(`${pathRoutes.itemConfigPrecificacaoPadraoEdit.replace(":ident?", "")}${id}`)
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.itemConfigPrecificacaoPadraoCadastro.replace(":itemId?", "")}${params.ident}`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Preço', 'Item', 'Ações'];

  const rows = itemPricingDefaultList.map(itemPricingDefault => [
    formatMoney(itemPricingDefault.value),
    itemPricingDefault.item.name,
    <DataTableDefaultAction
        id={itemPricingDefault.id}
        actions={{
          onEdit: () => handleEdit(itemPricingDefault.id),
          onDelete: () => handleDelete(itemPricingDefault.id),
          onPreview: () => handlePreview(itemPricingDefault.id)
        }} 
      />
  ]);
 
  return (
    <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <ButtonAdd title="Cadastrar Preço Padrão" onClick={handleCreate} disabled={itemPricingDefaultList.length > 0}/>
          </Box>
        </Grid>
      
        <Grid item md={12} sm={12} xs={12}>
          <Box position="relative">
            <DataTable  header={header} 
                        rows={rows} 
                        totalPages={totalPages}
                        page={page} 
                        onPage={handleChangePage} 
                        onRowsPerPage={handleChangeRowsPerPage}/>
          </Box>
          <PreviewModal openDialog={showPreview} 
                        onCloseDialog={handleClosePreview} 
                        width="80%" height="35%">
            <ItemPrecificacaoPadraoPreview id={idPreview}/>
          </PreviewModal>
          </Grid>
    </Grid>
  );
}