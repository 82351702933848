import React, {useEffect, useState} from "react";
import { Box, Divider, Paper, Typography } from "@material-ui/core";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import generalNotificationApi from "../../../services/generalNotificationApi";

export default function Notification() {

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function getNotifications() {
      const notificationsResponse = await generalNotificationApi.findAll();
      if (notificationsResponse) {
        setNotifications(notificationsResponse.data.response);
      }
    }
    getNotifications();
  }, []);


  return (
    <>
      {notifications.map((notification) => {
          let paperStyle = {
              backgroundColor: '#ffffcc', 
              padding: '10px', 
              margin: '10px'
          };
          let titleColor = '#405050';
          let messageColor = '#505050';

          if (notification.severity === 'ATTENTION') {
              paperStyle = {...paperStyle, backgroundColor: '#fc7c0c'};
              titleColor = '#ffffff';
              messageColor = '#ffffff';
          } else if (notification.severity === 'URGENT') {
              paperStyle = {...paperStyle, backgroundColor: 'red'};
              titleColor = '#ffffff';
              messageColor = '#ffffff';
          }

          return (
            <Paper className="artical-1 out-shadow" style={paperStyle}>
                  <Typography variant="h1" style={{fontSize: '1.2rem', textAlign: 'center', paddingTop: '5px', color: titleColor}} gutterBottom>
                      {notification.title} <NotificationsActiveIcon/>
                  </Typography>
                  <Typography style={{ fontSize: '0.9rem', textAlign: 'center', paddingTop: '10px', color: messageColor}} gutterBottom>
                      {notification.message}
                  </Typography>
              </Paper>
          );
      })}
    </>
);

}
