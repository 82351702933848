import { serialize } from "./mapper/ScheduleMapper"
import { del, get, patch, post, put } from "../utils/request"

export const create = async (data) => {
    return post("v1/config/schedule", serialize(data), `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const update = async (data) => {
    return put("v1/config/schedule", serialize(data), `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const status = async (data) => {
    return patch("v1/config/schedule", serialize(data), `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const findAllPageable = async (page, size, filters) => {
    if(filters){
        return get(`v1/config/schedule/all?page=${page}&size=${size}&${filters}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
    }
    return get(`v1/config/schedule/all?page=${page}&size=${size}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const findById = async (id) => {
    return get(`v1/config/schedule/${id}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export const remove = async (id) => {
    return del(`v1/config/schedule/${id}`, `${process.env.REACT_APP_BASE_URL_PLANTING}`, true);
}

export default {
    post : create,
    findAllPageable,
    status,
    findById,
    delete : remove,
    put : update
}