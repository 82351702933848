import LoginPage from "../../../components/LoginPage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { confirmaUsuario } from "../../../services/cadastroUsuarioApi";
import { pathRoutes } from "../../../config/constants";
import TimerRedirect from "../../../components/TimerRedirect";

export default function Confirmacao({ match: { params }, history }) {
    const { token } = params;
    const [mensagem, setMensagem] = useState("");
    const [redirectPath, setRedirectPath] = useState("");

    useEffect(() => {
        const loadConfirmacao = async() => {
            const response = await confirmaUsuario(token)
            if (response) {
                setMensagem('Usuário confirmado com sucesso!');
                setRedirectPath(pathRoutes.login);
            } else {
                setMensagem('Não foi possivel confirmar o usuario, tente realizar um novo cadastro!');
                setRedirectPath(pathRoutes.cadastro);
            }

        };
        loadConfirmacao();
    },[token])

        return(
            <LoginPage
            label={mensagem === "" ? "Processando..." : mensagem}  
            body={
                <>    
                <form className="user">
                    <div className="form-group">
                        {(redirectPath !== "" && 
                            <>
                                <TimerRedirect 
                                    time={5}
                                    history={history}
                                    path={redirectPath}/> 
                            </>
                        )}
                        
                    </div>
                    <hr/>
                    <div className="text-center">
                        <Link className="small" to={pathRoutes.login}>
                            Redirecionar agora
                        </Link>
                    </div>
                </form>
                </>
            } 
            />
        );
    
}