import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import toast from "../../utils/toast";

import AuthContext from "../../components/AuthContext";
import { pathRoutes } from "../../config/constants";
import { setUser, setToken, getLastPage } from "../../utils/auth";
import LoginPage from '../../components/LoginPage';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@material-ui/core";
import loginApi from "../../services/loginApi";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import userDetailsApi from "../../services/userDetailsApi";
import ButtonSubmit from '../../components/ButtonSubmit';
import InputText from "../../components/InputText";

const DEFAULT_STATE = {
  isLoading: false,
  data: {},
};

export default function Login({ history }) {
  const userAuth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);
  const [data, setData] = useState(DEFAULT_STATE.data);

  const { token, user } = userAuth || {};
  const { username, password } = data;

  const [values, setValues] = React.useState({
    showPassword: false,});

  useEffect(() => {
    if (userAuth) {
      history.push(pathRoutes.home);
    }
  }, [history, userAuth]);

  const handleInputChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const session = await loginApi.post(data);
    setIsLoading(false);
    if (session) {
      setToken(session.data.token);
      const userDetail = await getUserDetail(username);
      setUser(userDetail);
      const lastPage = getLastPage();
      if (lastPage) {
        history.push(lastPage);
      } else {
        redirectHome(userDetail)
      }
    }
  };

  const redirectHome = async (userDetail) => {
    if(userDetail.tempPassword) {
      history.push(pathRoutes.alterarSenha)
    } else {
      toast.success(`Olá! Seja bem vindo(a), ${userDetail.firstName}`);
      history.push(pathRoutes.home)
    }
  }

  const getUserDetail = async (username) => {
    const userDetail = await userDetailsApi.get(username);
    return userDetail.data.response[0];
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <AuthContext.Provider value={{ token, user }}>
      <LoginPage label="Seja bem-vindo(a)!" 
               text="Realize o login e gerencie seu negócio!"
        body={
            <>
              <form className="user" onSubmit={handleSubmit}>
                <div className="form-group">
                  <InputText
                    id="username"
                    name="username"
                    className="form-control"
                    aria-describedby="username"
                    label="Usuário"
                    autoComplete="on"
                    value={username}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                <FormControl className = "form-password">
                  <InputLabel>Senha</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    name="password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={password}
                    required
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  />
                </FormControl>
                </div>
                <br/>
                <ButtonSubmit
                  label="Entrar"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn btn-primary btn-user btn-block"
                  isLoading={isLoading}/>
              </form>
              <hr />
              {/* <div className="text-center">
                <Link
                  className="small"
                  to={pathRoutes.recuperacaoSenha}
                >
                  Esqueceu sua senha?
                </Link>
              </div> */}
          </>
        } />
    </AuthContext.Provider>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
