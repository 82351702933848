import { get} from "../utils/request"

export const findById = async (id) => {
    return get(`v1/city/${id}`, undefined, true);
}

export const findAllPageable = async (filters) => {
    if (filters && filters != '') {
        return get(`v1/city/all/pageable?${filters}`, undefined, true);
    }
    return get(`v1/city/all/pageable`, undefined, true);
}

export const findAllStateId = async (id) => {
    return get(`v1/city/all/${id}`, undefined, true);
}
export default {
    findAllPageable,
    findById,
    findAllStateId
}