import { pathRoutes } from "../../../config/constants";
import PessoaCadastro from "./index";

export default [
  {
    path: pathRoutes.pessoaCadastro,
    component: PessoaCadastro,
    exact: true,
  },
  {
    path: pathRoutes.pessoaEdit,
    component: PessoaCadastro,
    exact: true,
  },
];
