/* eslint-disable react/prop-types */
import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import routes from "./routes";
import Container from "../components/Container";
import { getToken } from "../utils/auth";
import { pathRoutes } from "./constants";

const PrivateRoute = ({ component: Component, path, exact }) => {
  if (getToken()) {
    return (
      <Container>
        <Route component={Component} path={path} exact={exact} />
      </Container>
    );
  }

  return <Redirect to={pathRoutes.login} />;
};

export default () => (
  <BrowserRouter>
    <ToastContainer position="bottom-right" />
    <Switch>
      {routes.map(({ component: Component, path, exact, noAuth }) => {
        if (noAuth) {
          return (
            <Route key={path} component={Component} path={path} exact={exact} />
          );
        }

        return (
          <PrivateRoute
            key={path}
            component={Component}
            path={path}
            exact={exact}
          />
        );
      })}
    </Switch>
  </BrowserRouter>
);
