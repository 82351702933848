export const serialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        name: data.name,
        descr: data.descr,
        createdAt: data.createdAt,
        active: data.active
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        name: data.name,
        descr: data.descr,
        createdAt: data.createdAt,
        active: data.active,
    }
}

export default{
    serialize
}