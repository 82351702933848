import { pathRoutes } from "../../config/constants";
import NcmGenero from "./index";

export default [
  {
    path: pathRoutes.ncmGenero,
    component: NcmGenero,
    exact: true,
  },
];
