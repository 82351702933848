import { Box, Button, Grid, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import { useQueryState } from "../../../../hooks/QueryState";

export default function CostCenterFilter(props) {

    const { onFilter}  =  props;

    const [name, setName] = useQueryState("name", '');
    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
    }, []);

    const handleFilter = async e => {
        let param = '';
        if (name) {
          param = param.concat('&name=').concat(name);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        onFilter('');
        setName("");
        setIsFiltering(false);
    };

    const handleInputChange = ({ target: { value } }) => {
        setName(value);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                  <Grid className="mt-2 mb-1" item md={12} sm={12} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="name"
                          label="Nome"
                          value={name}
                          onChange={handleInputChange}>
                      </TextField>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}