import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import financialDashboardApi from "../../services/financialDashboardApi";
import { useLoader } from "../../hooks/LoaderContext";

const BarChart = () => {
  const [data, setData] = useState([]);
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    async function getAllPayments() {
      showLoader();
      const dashBoardResponse = await financialDashboardApi.findAllPayments();
      if (dashBoardResponse) {
        setData(dashBoardResponse.data.response);
      }
      hideLoader();
    }
    getAllPayments();
  }, []);

  return (
    <div style={{ height: '300px' }}>
      <ResponsiveBar
        data={data}
        keys={['value']}
        indexBy="id"
        margin={{ top: 50, right: 120, bottom: 50, left: 120 }}
        padding={0.3}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Situação',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
          format: value =>
            `${Number(value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}`, 
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        valueFormat=" >-.2f"
        tooltip={({ id, value, color }) => (
          <strong style={{ color }}>
            {id}: {Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </strong>
        )}
      />
    </div>
  );
};

export default BarChart;
