import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { getLastPage, getUser, setToken } from "../../../utils/auth";
import toast from "../../../utils/toast";
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@material-ui/core";
import { pathRoutes } from "../../../config/constants";
import alterarSenhaApi from "../../../services/alterarSenhaApi";
import LoginPage from '../../../components/LoginPage';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import loginApi from "../../../services/loginApi";
import ButtonSubmit from '../../../components/ButtonSubmit';

const DEFAULT_STATE = {
  isLoading: false,
  data: {},
};

export default function AlterarSenha({ match: { params }, history }) {
  const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);
  const [data, setData] = useState(DEFAULT_STATE.data);

  const { password, passwordConfirmation } = data;

  const [values, setValues] = React.useState({
    showPassword: false,});

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = ({ target: { name, value } }) => {
      setData({ ...data, [name]: value });
  };

  const validatePassword = () => {
    if (password !== passwordConfirmation) {
      toast.error("As senhas não conferem!");
      setIsLoading(DEFAULT_STATE.isLoading);
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if(!validatePassword()){
      return;
    }
    const alterar = await alterarSenhaApi.put(data);
    setIsLoading(DEFAULT_STATE.isLoading);
    if (alterar) {
      const user = getUser();
      toast.success(`Senha alterada com sucesso!`);
      const session = await loginApi.post({username: user.username, password: data.password});
      if (session) {
        setToken(session.data.token);
      }
      toast.success(`Seja bem vindo(a) novamente, ${user.firstName}`);
    
      const lastPage = getLastPage();
      if (lastPage) {
        history.push(lastPage);
      } else {
        history.push(pathRoutes.home);
      }
    }
  };

  return (
      <LoginPage 
        label="Cadastre sua nova senha!" 
        text="Digite sua nova senha nos campos abaixo!"
        body={
            <>
            <form className="user" onSubmit={handleSubmit}>
                <div className="form-group">
                  <FormControl className="form-password-min">
                  <InputLabel htmlFor="standard-adornment-password">Digite sua senha </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    name="password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleInputChange}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  />
                </FormControl>
                </div>
                <div className="form-group">
                  <FormControl className="form-password-min">
                  <InputLabel htmlFor="standard-adornment-password">Confirme a senha </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    name="passwordConfirmation"
                    type={values.showPassword ? 'text' : 'password'}
                    value={passwordConfirmation}
                    required
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  />
                </FormControl>
                </div>
                <ButtonSubmit
                  label="Salvar e Entrar"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn btn-primary btn-user btn-block"
                  isLoading={isLoading}/>
              </form>
              <hr />
              <div className="text-center">
                <Link className="small" to={pathRoutes.login}>
                  Voltar para o Login!
                </Link>
              </div>
            </>
        } />
  );
}

AlterarSenha.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
