import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { pathRoutes } from "../../../../../config/constants";
import DataTable from "../../../../DataTable";
import toast from "../../../../../utils/toast";
import ButtonAdd from "../../../../ButtonAdd";
import PreviewModal from "../../../../PreviewModal";
import { useLoader } from "../../../../../hooks/LoaderContext";
import DataTableDefaultAction from "../../../../DataTableDefaultActions";
import taxOperationItemApi from "../../../../../services/taxOperationItemApi";
import OperacaoFiscalCategoriaFilter from "./OperacaoFiscalCategoriaFilter";
import OperacaoFiscalCategoriaPreview from "./OperacaoFiscalCategoriaPreview";

const DEFAULT_STATE = {
  isLoading: false,
  taxOperationCategories: []
};

export default function OperacaoFiscalCategoria() {

  const [taxOperationCategoryList, setTaxOperationCategoryList] = useState(DEFAULT_STATE.taxOperationCategories);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [idPreview, setIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const params = useParams();

  const [filters, setFilters] = useState();

  useEffect(() => {
    async function getAllTaxOperationCategoryList() {
      showLoader();
      const taxOperationItemResponse = await taxOperationItemApi.findAllPageable(page - 1, rowsPerPage,
        `&tax_operation_id=${params.ident}&type=CATEGORY${filters? filters : ''}`);
      if (taxOperationItemResponse) {
        setTaxOperationCategoryList(taxOperationItemResponse.data.response);
        setTotalPages(taxOperationItemResponse.data.totalPages);
      } else {
        setTaxOperationCategoryList(DEFAULT_STATE.taxOperationCategories);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllTaxOperationCategoryList();
  }, [forceRefreshData, filters])

  const handleDelete = async (id) => {
    const deleted = await taxOperationItemApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Operação Fiscal da categoria deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Operação Fiscal da categoria, favor verifique!.`);
    }
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.operacaoFiscalConfigCategoryEdit.replace(":ident?", "")}${id}`)
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCreate = async () => {
    history.push(`${pathRoutes.operacaoFiscalConfigCategoryCadastro.replace(":itemId?", "")}${params.ident}`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Código Alíquota', 'Categoria', 'Código CST', 'Tipo de Imposto', 'Ações'];

  const rows = taxOperationCategoryList.map(taxOperationCategory => [
    taxOperationCategory.aliquotPercent?.code,
    taxOperationCategory.category? taxOperationCategory.category.name : '-',
    taxOperationCategory.aliquotPercent?.cst ? `${taxOperationCategory.aliquotPercent.cst.code}` : '-',
    taxOperationCategory.aliquotPercent?.taxType?.code,
    <DataTableDefaultAction
      id={taxOperationCategory.id}
      actions={{
        onEdit: () => handleEdit(taxOperationCategory.id),
        onDelete: () => handleDelete(taxOperationCategory.id),
        onPreview: () => handlePreview(taxOperationCategory.id)
      }}
    />
  ]);

  const handleFilter = async (filters) => {
    setFilters(filters);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <OperacaoFiscalCategoriaFilter onFilter={handleFilter} />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Box>
          <ButtonAdd title="Cadastrar imposto por Categoria" onClick={handleCreate} />
        </Box>
      </Grid>

      <Grid item md={12} sm={12} xs={12}>
        <Box position="relative">
          <DataTable header={header}
            rows={rows}
            totalPages={totalPages}
            page={page}
            onPage={handleChangePage}
            onRowsPerPage={handleChangeRowsPerPage} />
        </Box>
        <PreviewModal openDialog={showPreview}
          onCloseDialog={handleClosePreview}
          width="70%" height="55%">
          <OperacaoFiscalCategoriaPreview id={idPreview} />
        </PreviewModal>
      </Grid>
    </Grid>
  );
}