import { pathRoutes } from "../../../config/constants";
import ServicoCadastro from "./index";

export default [
  {
    path: pathRoutes.servicoCadastro,
    component: ServicoCadastro,
    exact: true,
  },
  {
    path: pathRoutes.servicoEdit,
    component: ServicoCadastro,
    exact: true,
  },
];
