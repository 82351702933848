import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, parse, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

function FormattedDatePicker({ value, onChange, format: displayFormat = "dd/MM/yyyy", ...props }) {
  const handleChange = (date) => {
    if (date && isValid(date)) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      onChange(formattedDate);
    } else if (typeof date === 'string' && date.length === 10) {
      const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
      if (isValid(parsedDate)) {
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');
        onChange(formattedDate);
      }
    } else {
      onChange(date);
    }
  };

  const parsedDate = value ? parse(value, 'yyyy-MM-dd', new Date()) : null;
  const validParsedDate = parsedDate && isValid(parsedDate) ? parsedDate : null;

  return (
    <KeyboardDatePicker
      {...props}
      value={validParsedDate}
      onChange={handleChange}
      format={displayFormat}
    />
  );
}

function DatePickerWrapper({ id, label, value, onChange, ...props }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <FormattedDatePicker
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePickerWrapper;
