import PedidoTimeline from ".";
import { pathRoutes } from "../../../config/constants";

export default [
  {
    path: pathRoutes.pedidoTimeline,
    component: PedidoTimeline,
    exact: true,
  }
];
