import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ListAltIcon from '@material-ui/icons/ListAlt';
import PageTitle from "../../../PageTitle";
import itemApi from "../../../../services/itemApi";
import TextFieldPreview from "../../../TextFieldPreview";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        name: "",
        description: "",
        lifeCycle: "",
        categoryName: ""
    }
  };

export default function ItemPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const itemResponse = await itemApi.findById(id);
        if (itemResponse) {
            const itemData = itemResponse.data.response[0];
            setData({
                code : itemData.code,
                description : itemData.description,
                name : itemData.name,
                lifeCycle : itemData.detail.lifeCycle,
                categoryName: itemData.category.name
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Item" icon={<ListAltIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="code"
                        label="Código"
                        value={data.code}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={8} sm={8} xs={16}>
                    <TextFieldPreview
                        name="description"
                        label="Description"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="name"
                        label="Nome"
                        value={data.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="lifeCycle"
                        label="Ciclo de vida"
                        value={data.lifeCycle}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="categoryName"
                        label="Categoria"
                        value={data.categoryName}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}