import React from "react";
import { Box, Button } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.light,
    },
    '&:focus': {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
    },
  },
  icon: {
    color: 'inherit',
  },
}));

export default function ButtonRemove(props) {
  const { onClick, className, title, size } = props;
  const classes = useStyles();

  return (
      <Button
        variant="outlined"
        size={size}
        className={`${classes.button} ${className} btn-primary`}
        onClick={onClick}
        startIcon={<DeleteOutlineIcon className={classes.icon} />}
      >
        {title}
      </Button>
  );
}
