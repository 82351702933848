import { Box, FilledInput, FormControl, Grid, InputAdornment, InputLabel } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getUser } from "../../../../utils/auth";
import cashAmountApi from "../../../../services/cashAmountApi";

const DEFAULT_STATE = {
    isLoading: false,
    totalizers: {
        totalAmount: "0,00",
        totalReceived: "0,00",
        totalAwaitingReceipt: "0,00",
        totalPaid: "0,00",
        totalAwaitingPayment: "0,00"
    }
};

export default function FluxoCaixaTotalizadores(props) {

    const { searchFilters, forceRefresh }  =  props;

    const [totalizerCashAmount, setTotalizerCashAmount] = useState(DEFAULT_STATE.totalizers.totalAmount);
    const [totalizerTotalReceived, setTotalizerTotalReceived] = useState(DEFAULT_STATE.totalizers.totalReceived);
    const [totalizerTotalAwaitingReceipt, setTotalizerTotalAwaitingReceipt] = useState(DEFAULT_STATE.totalizers.totalAwaitingReceipt);
    const [totalizerTotalPaid, setTotalizerTotalPaid] = useState(DEFAULT_STATE.totalizers.totalPaid);
    const [totalizerTotalAwaitingPayment, setTotalizerTotalAwaitingPayment] = useState(DEFAULT_STATE.totalizers.totalAwaitingPayment);

    useEffect(() => {
        async function getTotalizers() {
            const cashAmountResponse = await cashAmountApi.find(searchFilters);
            if (cashAmountResponse) {
              const cashAmountDate = cashAmountResponse.data.response[0];
              setTotalizerCashAmount(cashAmountDate.value);
              setTotalizerTotalReceived(cashAmountDate.totalizers.totalReceived);
              setTotalizerTotalAwaitingReceipt(cashAmountDate.totalizers.totalAwaitingReceipt);
              setTotalizerTotalPaid(cashAmountDate.totalizers.totalPaid);
              setTotalizerTotalAwaitingPayment(cashAmountDate.totalizers.totalAwaitingPayment);
            }
        }
        getTotalizers();
      },[searchFilters, forceRefresh]);

    const getUsuarioLogado = () => {
        const user = getUser();
        return user.username;
    }

    return (
        <Box className="artical-1 out-shadow">
            <Grid container spacing={2}>
              {getUsuarioLogado() === 'lucas' &&( 
                <Grid className="mt-3 mb-2" item md={2} sm={2} xs={12}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel>Saldo em conta</InputLabel>
                    <FilledInput
                      readOnly
                      value={totalizerCashAmount}
                      startAdornment={
                        <InputAdornment position="start">R$</InputAdornment>}
                    />
                  </FormControl>
                </Grid>
               )}
              <Grid className="mt-3 mb-2 ml-6" item md={2} sm={2} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Total Recebido</InputLabel>
                  <FilledInput
                    readOnly
                    value={totalizerTotalReceived}
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>}
                  />
                </FormControl>
              </Grid> 
              <Grid className="mt-3 mb-2" item md={2} sm={2} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Total Pago</InputLabel>
                  <FilledInput
                    readOnly
                    value={totalizerTotalPaid}
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>}
                  />
                </FormControl>
              </Grid> 
              <Grid className="mt-3 mb-2" item md={2} sm={2} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Total à Pagar</InputLabel>
                  <FilledInput
                    readOnly
                    value={totalizerTotalAwaitingPayment}
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>}
                  />
                </FormControl>
              </Grid> 
              <Grid className="mt-3 mb-2" item md={2} sm={2} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Total à Receber</InputLabel>
                  <FilledInput
                    readOnly
                    value={totalizerTotalAwaitingReceipt}
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>}
                  />
                </FormControl>
              </Grid> 
            </Grid>
          </Box>
    );
}