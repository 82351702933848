import { Box, MenuItem, TextField } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";

  const numberOfRows = [
    { 
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 50,
      label: '50',
    }
  ];


export default function Paginator(props) {

    const { totalPages, onChange, onRowsPerPage, page} = props;

    const [rowsPerPage, setRowsPerPage] = useState(10); 

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        onRowsPerPage(event.target.value);
    }

    return (
        <div style={{  marginTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem'}}>
          <Box>
            <Pagination
                    variant="outlined"
                    color="primary"
                    count={totalPages}
                    onChange={onChange}
                    page={page}/>
          </Box>
          <Box>
            <TextField
                name="rowsPerPage"
                select
                label="Itens"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}>
                {numberOfRows.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
          </Box>
        </div>
    );
}