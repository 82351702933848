import { pathRoutes } from "../../../config/constants";
import PlanoContaCadastro from "./index";

export default [
  {
    path: pathRoutes.planoContaCadastro,
    component: PlanoContaCadastro,
    exact: true,
  },
  {
    path: pathRoutes.planoContaEdit,
    component: PlanoContaCadastro,
    exact: true,
  },
];
