import { pathRoutes } from '../config/constants';

export const getToken = () => localStorage.getItem("token");

export const setToken = token => {
  localStorage.setItem("token", token);
};

export const cleanToken = () => {
  localStorage.removeItem("token");
};

export const getAuthorization = () => `Bearer ${getToken()}`;

export const getUser = () => JSON.parse(localStorage.getItem("user"));

export const setUser = user => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const signOut = () => {
  cleanUser();
  cleanToken();
  cleanLastPage();
  window.location.href = pathRoutes.login;
};

export const cleanUser = () => {
  localStorage.removeItem("user");
};

export const getLastPage = () => localStorage.getItem("lastPage");

export const cleanLastPage = () => {
  localStorage.removeItem("lastPage");
};
