import { Box, Divider, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useState } from "react";


export default function Plantio() {
  return (
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Plantio</h1>
        <Divider />
        <div className="mb-4">
          <div className="artical-1 out-shadow">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus varius, sapien eget ullamcorper elementum, eros nisi gravida ipsum, ut aliquam nisi turpis tempus ex. Phasellus est urna, placerat eu dolor ut, blandit sollicitudin massa. Praesent interdum dolor et sapien interdum aliquet. Morbi viverra quis nulla et elementum. Vestibulum efficitur nunc quis nisl rhoncus, sed cursus elit pellentesque. Nulla viverra diam augue, sed elementum nisi euismod sit amet. In egestas ac metus et porttitor. Ut tristique nulla et cursus placerat. Sed eget feugiat ipsum. Praesent lectus est, sodales ac lacus nec, vestibulum elementum dui.

  Nunc neque est, porttitor non sem in, blandit pretium lacus. Suspendisse est leo, eleifend a ligula sed, scelerisque pulvinar nisi. In in dui a elit cursus tristique sed eu est. Suspendisse potenti. Etiam sollicitudin nibh interdum risus sagittis consectetur. Nam pharetra ultrices massa nec tincidunt. Phasellus aliquet, arcu vitae bibendum iaculis, justo sapien congue mauris, hendrerit ultricies tortor nisi vel ligula. Nulla a gravida dui, in lacinia justo. Suspendisse malesuada augue et faucibus auctor. Nam interdum lorem nec nunc suscipit dictum. Pellentesque bibendum eros quis libero mollis, eget semper lectus interdum. Praesent congue nibh turpis, in tincidunt quam semper at. Pellentesque faucibus tincidunt ante quis facilisis. Ut tristique id erat ut imperdiet. Phasellus rutrum imperdiet leo a scelerisque.

  Mauris fringilla neque nec nibh venenatis, ac eleifend est aliquam. Morbi eleifend augue eget turpis aliquam efficitur vitae vel felis. Nunc facilisis dolor lobortis, sollicitudin turpis in, viverra lorem. In vulputate rhoncus nibh, non tincidunt mauris sagittis at. Fusce suscipit leo et placerat placerat. Phasellus justo velit, feugiat id diam eu, laoreet facilisis sapien. Duis a ornare dui, sit amet lobortis nibh. Duis nec dignissim mauris. Etiam sodales orci ut luctus feugiat. Integer non tincidunt neque. Ut aliquet quam id facilisis pretium.

  Curabitur nec lacus scelerisque, pulvinar lacus et, dapibus nisi. Quisque rhoncus enim eu eros ornare tincidunt. Phasellus fermentum tristique pretium. Nunc ultrices sodales lacinia. Nam at mauris ut purus dignissim pellentesque quis id risus. Aliquam rutrum tempus dolor. Nunc viverra quam non dictum finibus. In posuere pretium varius. Vestibulum vitae ultrices odio. Donec iaculis porttitor ipsum eu luctus. Maecenas id lorem quis dui maximus lobortis id non libero. In in maximus mauris. Integer enim sapien, tincidunt tempus varius et, tincidunt a augue. Vestibulum sed nisi eget leo fringilla cursus sit amet ut ipsum.

  Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam accumsan nulla vestibulum metus mollis, a condimentum urna eleifend. Fusce ac sapien non velit consectetur blandit ut vitae lorem. Phasellus non erat sit amet massa pharetra pharetra ac quis dolor. Suspendisse venenatis est ac nunc tincidunt, convallis condimentum magna commodo. Etiam varius rhoncus odio, et posuere neque lacinia id. Etiam nisl sapien, consequat sit amet felis in, vehicula rutrum ex. In at consectetur metus. Sed non dui at est condimentum maximus quis a lacus. Integer suscipit, magna vitae porta ultrices, dolor urna pharetra nulla, at vehicula risus felis sed est. Fusce in porttitor felis, in sollicitudin leo.</p>
          </div>
        </div>
      </div>
  );
}
