import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import chartAccountItemApi from "../../../../services/chartAccountItemApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        name: "",
        description: "",
        chartAccount: {
            name: ""
        }
    }
  };

export default function PlanoContaItemPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const chartAccountItemResponse = await chartAccountItemApi.findById(id);
        if (chartAccountItemResponse) {
            const chartAccountItemData = chartAccountItemResponse.data.response[0];
            setData({
                name : chartAccountItemData.name,
                description: chartAccountItemData.description,
                chartAccount: {
                    name: chartAccountItemData.chartAccount.name
                }
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Plano de Conta" icon={<AssignmentIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="name"
                        label="Nome"
                        value={data.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid className="mt-4 mb-2" item md={12} sm={12} xs={12}>
                    <TextFieldPreview
                        name="chartAccoun"
                        label="Plano de Contas"
                        value={data.chartAccount.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}