export const serialize = (data) => {
    return {
        id: data.id,
        supplierId: data.supplierId,
        ie: data.ie,
        iest: data.iest,
        im: data.im,
        cnae: data.cnae,
        crt: data.crt,
        isuf: data.isuf
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        supplierId: data.supplierId,
        ie: data.ie,
        iest: data.iest,
        im: data.im,
        cnae: data.cnae,
        crt: data.crt,
        isuf: data.isuf
    }
}


export default{
    serialize
}