import React, { useEffect, useState }  from "react";
import { PropTypes } from "prop-types";

export default function TimerRedirect( props ) {
  const { time, path, history } = props;

  const [totalSeconds, setTotalSeconds] = useState(time * 1)
  const seconds = totalSeconds % 60

  useEffect(() => {
    if(totalSeconds === 0){
        history.push(path);
    }
    setTimeout(() => {
        setTotalSeconds(totalSeconds -1)     
    }, 1000)
  }, [totalSeconds, path, history])

  return (
    <div className="container">
      <div className="row justify-content-center">
        <p className="mb-4">Redirecionando em...</p>
        <h1 className="h4 text-gray-900 mb-2 cronometro">{seconds.toString()}</h1>
      </div>
    </div>
  )
}

TimerRedirect.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};