import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EcoIcon from '@material-ui/icons/Eco';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import AssessmentIcon from '@material-ui/icons/Assessment';
import cashFlowApi from "../../../../services/cashFlowApi";
import { cashFlowTranslatePaymentCondition, cashFlowTranslatePaymentMethod, cashFlowTranslateStatus, cashFlowTranslateType } from "../../../../config/constants";
import { formatMoney } from "../../../../utils/moneyUtils";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        type: "",
        status: "",
        description: "",
        value: 0,
        occurrenceDate: "",
        paymentMethod: "",
        paymentCondition: "",
        numberInstallment: 1,
        createdAt: "",
        chartAccountItem : {
          name: ""
        },
        costCenter : {
          name: ""
        },
        supplier : {
          name: ""
        }
    },
  };

export default function FluxoCaixaPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const cashFlowResponse = await cashFlowApi.findById(id);
        if (cashFlowResponse) {
            const cashFlowData = cashFlowResponse.data.response[0];
            setData({
                type : cashFlowTranslateType(cashFlowData.type),
                status : cashFlowTranslateStatus(cashFlowData.status),
                description: cashFlowData.description,
                value: formatMoney(cashFlowData.value),
                occurrenceDate: cashFlowData.occurrenceDate,
                paymentMethod:  cashFlowTranslatePaymentMethod(cashFlowData.paymentMethod),
                paymentCondition: cashFlowTranslatePaymentCondition(cashFlowData.paymentCondition),
                numberInstallment: cashFlowData.numberInstallment,
                chartAccountItem: {
                    name: cashFlowData.chartAccountItem.name,
                },
                costCenter: {
                    name: cashFlowData.costCenter.name,
                },
                supplier: {
                    name: cashFlowData.supplier.name,
                }
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Fluxo de Caixa" icon={<AssessmentIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={10} sm={10} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={2} sm={2} xs={12}>
                    <TextFieldPreview
                        name="type"
                        label="Tipo"
                        value={data.type}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={5} sm={5} xs={12}>
                    <TextFieldPreview
                        name="paymentMethod"
                        label="Forma de pagamento"
                        value={data.paymentMethod}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={3} sm={3} xs={12}>
                    <TextFieldPreview
                        name="paymentCondition"
                        label="Condição de Pagamento"
                        value={data.paymentCondition}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={2} sm={2} xs={12}>
                    <TextFieldPreview
                        name="status"
                        label="Situação"
                        value={data.status}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={2} sm={2} xs={12}>
                    <TextFieldPreview
                        name="value"
                        label="Valor"
                        value={data.value}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="occurrenceDate"
                        label="Data ocorrência"
                        value={data.occurrenceDate}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                
                
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="chartAccountItem"
                        label="Item"
                        value={data.chartAccountItem.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="costCenter"
                        label="Centro de Custo"
                        value={data.costCenter.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="supplier"
                        label="Fornecedor"
                        value={data.supplier.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}