import NumberFormat from 'react-number-format';
export default function NumberFormatText(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          isNumericString
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$"
        />
      );
}