import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";;
import BoxFilter from "../../../BoxFilter";
import chartAccountApi from "../../../../services/chartAccountApi";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    chartAccounts: [],
    chartAccountInput : {
      title: "",
      id: ""
    }
};

export default function PlanoContaItemFilter(props) {

    const { onFilter }  =  props;

    const [isFiltering, setIsFiltering] = useState(false);

    const [setValues] = useSetQueryStateValues();
    const [name, setName] = useQueryState("name", '');
    const [chartAccountId, setChartAccountId] = useQueryState("chart_account_id", '');

    const [chartAccountList, setChartAccountList] = useState(DEFAULT_STATE.chartAccounts);
    const [chartAccount, setChartAccount] = useState(DEFAULT_STATE.chartAccountInput);


    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllChartAccount() {
            const chartAccountResponse = await chartAccountApi.findAll();
            if (chartAccountResponse) {
              setChartAccountList(chartAccountResponse.data.response);
            }
        }
        getAllChartAccount();
      },[]);

      useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (setChartAccountList.length > 0 && chartAccountId) {
                const foundItem = chartAccountList.find(item => item.id == chartAccountId);
                if (foundItem) {
                    setChartAccount({title: foundItem.name, id: foundItem.id});
                }
            }
        }
        findAutocompleteQueryFilters();
    }, [chartAccountList]);
    
    const chartAccounts = chartAccountList.map(chartAccount => ({
        title: chartAccount.name,
        id: chartAccount.id
    }));

    const handleFilter = async e => {
        let param = '';
        if (name){
            param = param.concat('&name=').concat(name);
        }
        if (chartAccountId){
            param = param.concat(`&chart_account_id=${chartAccountId}`);
        }
        if (param !== '') {
            onFilter(`${param}`);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    };

    const cleanFilter = async e => {
        setValues({name : '', chart_account_id : ''});
        onFilter('');
        setChartAccount(DEFAULT_STATE.chartAccountInput);
        setIsFiltering(false);
    };

    const handleChangeChartAccount = (value) => {
        setChartAccount(value);
        setChartAccountId(value.id);
    };

    const handleInputChange = ({ target: { value } }) => {
        setName(value);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
              <Grid className="mt-3 mb-3" item md={6} sm={6} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="name"
                          label="Nome"
                          value={name}
                          onChange={handleInputChange}
                      />
                    </Grid>
                <Grid className="mt-3 mb-3" item md={6} sm={6} xs={12}>
                    <Autocomplete
                        id="autocomplete-chartaccount"
                        options={chartAccounts}
                        clearText="Limpar"
                        value={chartAccount}
                        onChange={(event, value) => handleChangeChartAccount(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Plano de Contas" variant="outlined" />
                        }
                    />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                        {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                        )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}