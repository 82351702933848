import { pathRoutes } from "../../config/constants";
import RecuperarSenha from "./index";
import AlterarSenha from "./AlterarSenha";

export default [
  {
    path: pathRoutes.recuperacaoSenha,
    component: RecuperarSenha,
    exact: true,
    noAuth: true,
  },
  {
    path: pathRoutes.alterarSenha,
    component: AlterarSenha,
    exact: true,
    noAuth: true,
  },
];
