import { pathRoutes } from "../../config/constants";
import Organizacao from "./index";

export default [
  {
    path: pathRoutes.organizacao,
    component: Organizacao,
    exact: true,
  },
];
