import { Box, Divider,Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EcoIcon from '@material-ui/icons/Eco';
import { useParams, useHistory } from 'react-router-dom';
import { Autocomplete } from "@material-ui/lab";
import { getUser } from "../../../../utils/auth";
import PageTitle from "../../../../components/PageTitle";
import ButtonSubmit from "../../../../components/ButtonSubmit";
import ButtonRemove from "../../../../components/ButtonRemove";
import toast from "../../../../utils/toast";
import AlertDialog from "../../../../components/AlertDialog";
import BasicReturn from "../../../../components/BasicReturn";
import itemApi from "../../../../services/itemApi";
import supplierApi from "../../../../services/supplierApi";
import itemPricingSupplierApi from "../../../../services/itemPricingSupplierApi";
import NumberFormatText from "../../../../components/NumberFormatText";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
      id: "",
      active: true,
      sigla: "",
      detail: {
        lifeCycle: ""
      }
  },
  items: [],
  units: [],
  unitInput: {
      title: "",
      id: ""
  },
  suppliers: [],
  supplierInput: {
      title: "",
      id: ""
  }
};

export default function ItemPrecificacaoClienteCadastro() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierInput);
  const [supplierList, setSupplierList] = useState(DEFAULT_STATE.units);

  const [item, setItem] = useState({});

  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const company =  getUser().company;

  useEffect(() => {
    async function getItem() {
        if (!isNaN(params.itemId)) {
            const itemResponse = await itemApi.findById(params.itemId);
            if (itemResponse) {
                setItem(itemResponse.data.response[0]);
            }
        }
    }
    getItem();
  },[]);

  useEffect(() => {
    async function getAllSuppliers() {
        const supplierResponse = await supplierApi.findAll("?category=CUSTOMER");
        if (supplierResponse) {
            setSupplierList(supplierResponse.data.response);
        }
    }
    getAllSuppliers();
  },[]);

useEffect(() => {
  async function findById() {
      if (!isNaN(params.ident)) {
        const itemPricingSupplierResponse = await itemPricingSupplierApi.findById(params.ident);
        if (itemPricingSupplierResponse) {
          const data = {
              id : itemPricingSupplierResponse.data.response[0].id,
              value : itemPricingSupplierResponse.data.response[0].value,
              item : {
                id: itemPricingSupplierResponse.data.response[0].item.id,
              },
              supplier: {
                id : itemPricingSupplierResponse.data.response[0].supplier.id,
              }
          };
          setData(data);
          setItem(itemPricingSupplierResponse.data.response[0].item);
          setSupplier({title: `${itemPricingSupplierResponse.data.response[0].supplier.name} - 
                               ${itemPricingSupplierResponse.data.response[0].supplier.document}`, 
                        id: itemPricingSupplierResponse.data.response[0].supplier.id});
        }
      }
  }
  findById();
},[]);

const suppliers = supplierList.map(supplier => ({
  title: `${supplier.name} - ${supplier.document}`,
  id: supplier.id
}));

const handleOpenDeleteModal = () => {
  setOpenDeleteModal(true);
};

const handleCloseModalDelete = () => {
  setOpenDeleteModal(false);
};

const handleChangeSupplier = (value) => {
  setSupplier(value);
  setData({ ...data, supplier: { id : value.id }, item: { id : item.id } });
};

const handleSubmit = async e => {
  e.preventDefault();
  
  let itemPricingSupplier;
  if (data.id.length == 0) {
    itemPricingSupplier = await itemPricingSupplierApi.post(data);
    if (itemPricingSupplier) {
      toast.success(`Unidade padrão cadastrada com sucesso para o item.`);
    }
  } else {
    itemPricingSupplier = await itemPricingSupplierApi.put(data);
    if (item) {
      toast.success(`Unidade padrão atualizada com sucesso.`);
    }
  }
  if (itemPricingSupplier) {
    redirectTo();
  } else {
    toast.error(`Falha ao criar Unidade padrão, favor verifique!.`);
  }
};

const handleDelete = async () => {
  const deleted = await itemPricingSupplierApi.delete(params.ident);
  if (deleted) {
    toast.success(`Tabela de preço padrão deletado com sucesso.`);
  } else {
    toast.error(`Falha ao excluir item, favor verifique!.`);
  }
  redirectTo();
};

const redirectTo = () => {
  history.goBack();
};

const handleInputChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value, item: { id : item.id } });
};

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? `Cadastrar preço padrão para ${item.name}` : `Atualiza preço padrão item ${item.name}`} icon={<EcoIcon style={{ color: 'gray' }}/>} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
        <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="company"
                        label="Empresa"
                        value={company.name}
                        disabled
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                        id="autocomplete-supplier"
                        options={suppliers}
                        clearText="Limpar"
                        value={supplier}
                        onChange={(event, value) => handleChangeSupplier(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Cliente" variant="outlined" />
                        }
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                          variant="outlined"
                          label="Preço"
                          value={data.value}
                          onChange={handleInputChange}
                          name="value"
                          id="formatted-numberformat-input"
                          InputProps={{
                            inputComponent: NumberFormatText,
                          }}
                          style={{width:"100%"}}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Box className="mt-1 c-inline-btn">
                              <Grid container spacing={2}>
                                {params.ident && (
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ButtonRemove
                                            title="excluir"
                                            onClick={handleOpenDeleteModal}
                                            variant="contained"
                                            color="primary"
                                            className="btn btn-primary btn-user btn-block"
                                        />
                                    </Grid>
                                )}
                                <Grid item md={params.ident ? 6: 12} sm={params.ident ? 6: 12} xs={12}>
                                    <ButtonSubmit
                                        label="salvar"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn btn-primary btn-user btn-block"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
              </Grid>
          </form>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?" 
          description="O dado será excluído de forma permanente" 
          confirmTitle="Confirmar"
          cancelTitle="Cancelar"
          openDialog={openDeleteModal} 
          onConfirm={handleDelete}
          onCancel={handleCloseModalDelete}
        />
     </>
  );

}