import React, { useEffect, useState } from "react";
import { Box, Divider, TextField, Grid, MenuItem, Tooltip, IconButton, makeStyles, InputAdornment } from "@material-ui/core";
import toast from '../../../../utils/toast';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import { useParams, useHistory } from 'react-router-dom';
import Image from '../../../../components/Image';
import ClearIcon from '@material-ui/icons/Clear';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DatePickerWrapper from '../../../../components/FormattedDatePicker';
import { useLoader } from "../../../../hooks/LoaderContext";
import companyTaxInformationApi from "../../../../services/companyTaxInformationApi";
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../../config/constants";
import { parseISO, format } from 'date-fns';

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    companyId: "",
    certificate: "",
    passCertificate: "",
    expCertificate: "",
    taxRegime: "",
    active: true,
  }
};

const validationSchema = Yup.object().shape({
  passCertificate: Yup.string().required(REQUIRED_FIELD),
  expCertificate: Yup.string().required(REQUIRED_FIELD),
  taxRegime: Yup.string().required(REQUIRED_FIELD),
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function EmpresaEdit() {
  const [urlPfxUpload, setUrlPfxUpload] = useState("")
  const [data, setData] = useState(DEFAULT_STATE.data);
  const [password, setValues] = React.useState({ showPassword: false, });
  const { showLoader, hideLoader } = useLoader();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    handleEdit(params.ident);
  }, []);

  const handleEdit = async (companyId) => {
    if (!isNaN(companyId)) {
      showLoader();
      const companyResponse = await companyTaxInformationApi.findAllPageable(page - 1, rowsPerPage, `&idCompany=${companyId}`);
      if (companyResponse) {
        const companyData = companyResponse.data.response[0];
        const expCertificateDate = parseISO(companyData.expCertificate);
        const expCertificateFormatted = format(expCertificateDate, 'yyyy-MM-dd');
        const data = {
          id: companyData.id,
          companyId: companyData.companyId,
          certificate: companyData.certificate,
          passCertificate: companyData.passCertificate,
          expCertificate: expCertificateFormatted,
          taxRegime: companyData.taxRegime,
        };
        setData(data);
        setUrlPfxUpload(data.certificate ? data.certificate : "");
      }
      hideLoader();
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      values.certificate = urlPfxUpload;
      values.companyId = params.ident;
      let empresa;
      if (values.id.length === 0) {
        empresa = await companyTaxInformationApi.post(values);
      } else {
        empresa = await companyTaxInformationApi.put(values);
      }
      if (empresa) {
        handleSuccess();
      } else {
        handleError();
      }
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuccess = () => {
    toast.success("Configuração fiscal atualizada com sucesso.");
  }

  const handleError = () => {
    toast.error("Falha ao atualizar configuração fiscal, favor verifique!.");
  }

  const handleChangeUploadCert = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ['pfx'];
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        reader.onloadend = () => {
          setUrlPfxUpload(reader.result)
        };
        reader.readAsDataURL(file);
      } else {
        toast.error(`Arquivo escolhido não é um certificado, escolha um arquivo com extenção .pfx!`);
      }
    } else {
      toast.error(`Selecione um certificado!`);
    }
  };

  const handleRemoveImgCert = (e) => {
    e.preventDefault();
    if (urlPfxUpload) {
      setUrlPfxUpload("")
    }
  };

  const typeTaxRegime = [
    {
      value: 'SIMPLES_NACIONAL',
      label: 'Simples Nacional',
    },
    {
      value: 'LUCRO_REAL',
      label: 'Lucro Real',
    },
    {
      value: 'LUCRO_PRESUMIDO',
      label: 'Lucro Presumido',
    }
  ];

  const classes = useStyles();

  return (
    <div className="container-fluid">
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting, touched, errors, setFieldValue, values }) => (
          <Form className='mt-3'>
            <Grid container spacing={2}>
              <Grid className="mt-1 " item md={12} sm={12} xs={12}>
                <Box display="flex" justifyContent="center" alignItems="flex-start">
                  <Grid item md={2} sm={4} xs={12} className="c-inline-img">
                    <Box className="ml-2">
                      Certificado Digital PFX
                    </Box>
                    <label htmlFor="icon-button-file2" >
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span">
                        <Image
                          source={urlPfxUpload === "" ? "pfx_descarregado.png" : "pfx_carregado.png"}
                          className="img-fluid"
                          alt="Perfil"
                        />
                      </IconButton>
                    </label>
                    <input
                      id="icon-button-file2"
                      className={classes.input}
                      type="file"
                      onChange={e => handleChangeUploadCert(e)}
                    />
                    {urlPfxUpload && (
                      <Box className="ml-5">
                        <Tooltip title="Remover Certificado">
                          <IconButton>
                            <ClearIcon onClick={handleRemoveImgCert} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Grid>
                  <Grid className="mt-3  mr-3" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      type={password.showPassword ? 'text' : 'password'}
                      name="passCertificate"
                      label="Senha do certificado"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}>
                              {password.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      inputProps={{ style: { width: '100%' } }} // Define a largura do campo
                    >
                    </Field>
                  </Grid>
                  <Grid className=" mb-1" item md={4} sm={4} xs={12}>
                    <DatePickerWrapper
                      disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-data-vencimento"
                      value={values.expCertificate}
                      onChange={(date) => setFieldValue('expCertificate', date)}
                      label="Data vencimento certificado"
                      style={{ width: "100%" }}
                      helperText={(touched.expCertificate && errors.expCertificate) && <ErrorMessage name="expCertificate" />}
                      error={touched.expCertificate && errors.expCertificate} />
                  </Grid>
                  <Grid className="mt-3 mr-3 ml-3" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      select
                      name="taxRegime"
                      label="Regime tributário"
                      helperText={(touched.taxRegime && errors.taxRegime) && <ErrorMessage name="taxRegime" />}
                      error={touched.taxRegime && errors.taxRegime}>
                      {typeTaxRegime.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                      )}
                    </Field>
                  </Grid>
                </Box>
              </Grid>
              <Grid className=" " item md={12} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Box className="mt-1 c-inline-btn">
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12}>
                        <ButtonSubmit
                          label="salvar"
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="btn btn-primary btn-user btn-block"
                          disable={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Divider />
    </div>
  );
}