import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {

  const { openDialog, onConfirm, onCancel, confirmTitle, cancelTitle, title, description} = props;

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {cancelTitle}
          </Button>
          <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
            {confirmTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
