import React from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';

const Navigation = ({ currentPage }) => {
    return (
        <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Página Principal
        </Link>
        <Typography color="textPrimary">{currentPage}</Typography>
      </Breadcrumbs>
    );
  };
  
  export default Navigation;