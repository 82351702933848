import ApartmentIcon from '@material-ui/icons/Apartment';
import React, { useEffect, useState } from "react";
import { Box, Divider, Button, Grid, TextField } from "@material-ui/core";
import organizationApi from "../../services/organizationApi";
import toast from '../../utils/toast';
import ButtonSubmit from '../../components/ButtonSubmit';
import DataTable from '../../components/DataTable';
import PageTitle from '../../components/PageTitle';
import { useLoader } from '../../hooks/LoaderContext';
import SwitchStatus from '../../components/SwitchStatus';
import DataTableDefaultAction from '../../components/DataTableDefaultActions';

const DEFAULT_STATE = {
  isLoading: false,
  data: {
      id: "",
      code: "",
      name: "",
      descr: "",
      active: true,
  },
  organizations: [],
};

export default function Organizacao() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [disable, setDisable] = useState(true);
  const [organizationList, setOrganizationList] = useState(DEFAULT_STATE.organizations);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
      async function getAllItems() {
          showLoader();
          const organizationResponse = await organizationApi.findAllPageable(0, rowsPerPage);
          if (organizationResponse) {
            setOrganizationList(organizationResponse.data.response);
          }
          hideLoader();
      }
      getAllItems();
  },[]);

  const handleInputChange = ({ target: { name, value } }) => {
      setData({ ...data, [name]: value });
  };

  const handleSubmit = async e => {
      e.preventDefault();
      
      let item;
      if (data.id.length == 0) {
        item = await organizationApi.post(data);
        if (item) {
          toast.success(`Organização cadastrado com sucesso.`);
        }
      } else {
        item = await organizationApi.put(data);
        if (item) {
          toast.success(`Organização atualizado com sucesso.`);
        }
      }
      if (item) {
        refreshDatatable();
        cleanForm();
      } else {
        toast.error(`Falha ao criar organização, favor verifique!.`);
      }
  };

  const handleDelete = async (id) => {
      const deleted = await organizationApi.delete(id);
      if (deleted) {
        cleanForm();
        refreshDatatable();
        toast.success(`Organização deletado com sucesso.`);
      } else {
        toast.error(`Falha ao excluir organização, favor verifique!.`);
      }
  };

  const handleEdit = async (id) => {
      const organizationResponse = await organizationApi.findById(id);
      if (organizationResponse) {
        setDisable(false);
        const data = {
            id : organizationResponse.data.response[0].id,
            code : organizationResponse.data.response[0].code,
            name : organizationResponse.data.response[0].name,
            descr : organizationResponse.data.response[0].descr
        };
        setData(data);
      }
  };

  const handleChangeActive = async (id, status) => {
      const data = {
          id: id,
          active: status
      };
      const org = await organizationApi.status(data);
      if (org) {
        if (org.data.response[0].active) {
          toast.success(`Organização ativado com sucesso.`);
        } else {
          toast.success(`Organização desativado com sucesso.`);
        }
        refreshDatatable();
      }
  };
  
  const refreshDatatable = async () => {
      const itemResponse = await organizationApi.findAllPageable(page-1, rowsPerPage);
      if (itemResponse) {
          setOrganizationList(itemResponse.data.response);
      }else{
        setOrganizationList(DEFAULT_STATE.organizations);
      }
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    const itemResponse = await organizationApi.findAllPageable(value-1, rowsPerPage);
    if (itemResponse) {
      setOrganizationList(itemResponse.data.response);
      setTotalPages(itemResponse.data.totalPages);
    }
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const itemResponse = await organizationApi.findAllPageable(0, rows);
    if (itemResponse) {
        setOrganizationList(itemResponse.data.response);
        setTotalPages(itemResponse.data.totalPages);
    }
  };

  const cleanForm = () => {
      setData(DEFAULT_STATE.data);
      setDisable(true);
  }

  const header = ['Código', 'Nome', 'Descrição', 'Data Inclusão', 'Situação', 'Ações'];

  const rows = organizationList.map(organization => [
      organization.code,
      organization.name,
      organization.descr,
      organization.createdAt,
      <SwitchStatus
        id={organization.id} 
        active={organization.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
      />,
      <DataTableDefaultAction
          id={organization.id} 
          actions={{
            onEdit: () => handleEdit(organization.id),
            onDelete: () => handleDelete(organization.id)
          }} 
      />
  ]);

  return (
      <div className="container-fluid">
        <PageTitle title="Organização" icon={<ApartmentIcon style={{ color: 'gray' }}/>} />
        <Divider />
        <div className="artical-1 out-shadow">
        <>
        {(data.id && (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="code"
                        disabled={disable}
                        label="Código"
                        value={data.code}
                        onChange={handleInputChange}>
                    </TextField>                    
                </Grid>
                <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        disabled={disable}
                        name="name"
                        label="Nome"
                        value={data.name}
                        onChange={handleInputChange}>
                    </TextField>
                </Grid>
                <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        disabled={disable}
                        name="descr"
                        label="Descrição"
                        value={data.descr}
                        onChange={handleInputChange}>
                    </TextField>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSubmit
                            label="Salvar"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="btn btn-primary btn-user btn-block"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                        {data.id &&(
                            <Button
                                color="primary"
                                onClick={cleanForm}>
                                Cancelar Edição
                            </Button>)}
                    </Box>
                </Grid>
            </Grid>
        </form>
        ))}
          <Box >
            <DataTable header={header} 
                       rows={rows} 
                       totalPages={totalPages}
                       page={page}
                       onPage={handleChangePage}
                       onRowsPerPage={handleChangeRowsPerPage}/>
          </Box>
        </>
        </div>
      </div>
  );

}