import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { pathRoutes } from "../../config/constants";
import { getUser, signOut } from "../../utils/auth";
import Image from "../Image";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Avatar, Box, Button, Collapse, InputAdornment, Menu, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import ReceiptIcon from '@material-ui/icons/Receipt';
import BugReportRoundedIcon from "@material-ui/icons/BugReportRounded";
import TerrainRoundedIcon from "@material-ui/icons/TerrainRounded";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AlertDialog from "../AlertDialog";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PieChartIcon from '@material-ui/icons/PieChart';
import { useNavBar } from "../../hooks/NavbarContext";
import BusinessIcon from '@material-ui/icons/Business';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BuildIcon from '@material-ui/icons/Build';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CategoryIcon from '@material-ui/icons/Category';

const DEFAULT_STATE = {
  usuario: {},
  linkImagem: "../../assets/img/perfil.png",
  openLogoutModal: false,
};

const drawerWidth = 225;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 30,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
  },
  drawerItem: {
    marginLeft: theme.spacing(-2),
  },
  drawerItemSubMenu: {
    marginLeft: theme.spacing(-4),
  },
  subMenuItem: {
    paddingLeft: theme.spacing(3), // Recuo para a direita
    fontSize: '0.9rem', 
  },
  subMenuItemText: {
    paddingLeft: theme.spacing(2), // Adiciona recuo adicional para o texto
  },
}));


export default function Nav(props) {
  const { wrapperComponent } = props;
  const [usuario, setUsuario] = useState(DEFAULT_STATE.usuario);
  const [openLogoutModal, setOpenLogoutModal] = useState(
    DEFAULT_STATE.openLogoutModal
  );

  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    setOpen,
    openSubMenus, 
    setOpenSubMenus,
    selectedItem, setSelectedItem,
  } = useNavBar();

  const menuItems = [
    {
      text: 'Plantio',
      icon: <LocalFloristIcon />,
      permission: 'PLANTING',
      subMenu: [
        { text: 'Insumos', icon: <BugReportRoundedIcon />, path: pathRoutes.insumo, key: 'inputs' },
        { text: 'Área', icon: <TerrainRoundedIcon />, path: pathRoutes.area, key: 'spot' },
        { text: 'Serviços', icon: <BuildRoundedIcon />, path: pathRoutes.servico, key: 'service' },
        { text: 'Agendamentos', icon: <ScheduleIcon />, path: pathRoutes.agendamento, key: 'schedule' }
      ],
    },
    {
      text: 'Cadastro',
      icon: <BusinessCenterIcon />,
      permission: 'REGISTER',
      subMenu: [
        { text: 'Pessoa', icon: <PeopleAltRoundedIcon />, path: pathRoutes.pessoa, key: 'supplier' },
        { text: 'Categoria', icon: <CategoryIcon />, path: pathRoutes.categoria, key: 'category' },
        { text: 'Item', icon: <ListAltIcon />, path: pathRoutes.item, key: 'item' },
        { text: 'Unidade', icon: <PieChartIcon />, path: pathRoutes.unidade, key: 'unit' }
      ],
    },
    {
      text: 'Financeiro',
      icon: <MonetizationOnIcon />,
      permission: 'FINANCIAL',
      subMenu: [
        { text: 'Fluxo de caixa', icon: <AccountBalanceIcon />, path: pathRoutes.fluxoCaixa, key: 'cashFlow' },
        { text: 'Centro de custo', icon: <AssignmentIcon />, path: pathRoutes.centroCustos, key: 'costCenter' },
        { text: 'Plano de conta', icon: <AssignmentIcon />, path: pathRoutes.planoConta, key: 'chartAccount' },
        { text: 'Item plano de conta', icon: <AssignmentIcon />, path: pathRoutes.planoContaItem, key: 'chartAccounItem' }
      ],
    },
    {
      text: 'Fiscal',
      icon: <ReceiptIcon />,
      permission: 'INVOICE',
      subMenu: [
        { text: 'NCM', icon: <AssignmentIcon />, path: pathRoutes.ncmGenero, key: 'ncmGenero' },
        { text: 'Aliquota', icon: <AssessmentIcon />, path: pathRoutes.aliquota, key: 'aliquota'},
        { text: 'Operação fiscal', icon: <BuildIcon />, path: pathRoutes.operacaoFiscal, key: 'taxOperation'}
      ],
    },
    {
      text: 'Vendas',
      icon: <ShoppingCartIcon />,
      permission: 'SALES',
      subMenu: [
        { text: 'Pedido', icon: <AddShoppingCartIcon />, path: pathRoutes.pedido, key: 'order' }
      ],
    },
    {
      text: 'Corporativo',
      icon: <HomeWorkIcon />,
      permission: 'CORPORATIVE',
      subMenu: [
        { text: 'Organização', icon: <ApartmentIcon />, path: pathRoutes.organizacao, key: 'organization' },
        { text: 'Empresa', icon: <BusinessIcon />, path: pathRoutes.empresa, key: 'company' }
      ],
    }
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setUsuario(getUser());
  }, []);

  const openLogoutDialog = () => {
    setOpenLogoutModal(true);
  };

  const handleClickLogout = () => {
    setOpenLogoutModal(false);
    signOut();
  };

  const handleClickCancelLogout = () => {
    setOpenLogoutModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openComboBox = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Link to={pathRoutes.home}>
            <Image
              source="logo-menu-quality.png"
              className="img-fluid"
              alt="Login"
              width="180"
            />
          </Link>
         
          
          <ul className="navbar-nav ml-auto">
            <div>
              <Box display="flex" alignItems="center">
                <BusinessCenterIcon />
                <Box ml={1}>
                  <Typography variant="subtitle1">{usuario.company?.name}</Typography>
              </Box>
              <Button
                id="nav-combo-menu"
                aria-controls={
                  openComboBox ? "demo-positioned-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openComboBox ? "true" : undefined}
                onClick={handleClick}
              >
                <Avatar alt={usuario.firstName} src="assets/img/perfil.png" />
              </Button>
              </Box>
              <Menu
                id="nav-bar-menu"
                anchorEl={anchorEl}
                open={openComboBox}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem>
                  <Link className="dropdown-item" to={pathRoutes.conta}>
                    <SettingsIcon />
                    <i className="mr-2 text-gray-400" />
                    Conta
                  </Link>
                </MenuItem>
                <MenuItem onClick={openLogoutDialog}>
                  <a className="dropdown-item">
                    <ExitToAppIcon />
                    <i className="mr-2 text-gray-400" />
                    Sair
                  </a>
                </MenuItem>
              </Menu>
            </div>
          </ul>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            usuario.modulesView?.includes(item.permission) && (
              <>
                <ListItem button onClick={() => setOpenSubMenus({...openSubMenus, [index]: !openSubMenus[index]})}>
                    <Tooltip title={item.text}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                    </Tooltip>
                    <ListItemText className={classes.drawerItem}>{item.text}</ListItemText>
                  {openSubMenus[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSubMenus[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenu.map((subItem) => (
                      <ListItem
                        button
                        component={Link}
                        to={subItem.path}
                        key={subItem.key}
                        selected={selectedItem === subItem.key}
                        onClick={() => setSelectedItem(subItem.key)}
                        className={classes.subMenuItem}
                      >
                        <Tooltip title={subItem.text}>
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                        </Tooltip>
                        <ListItemText className={`${classes.subMenuItemText} ${classes.drawerItemSubMenu}`}>{subItem.text}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            )
          ))}
        </List>
      </Drawer>

      <AlertDialog
        title="Deseja realmente sair?"
        confirmTitle="Sair"
        cancelTitle="Cancelar"
        openDialog={openLogoutModal}
        onConfirm={handleClickLogout}
        onCancel={handleClickCancelLogout}
      />
      <main className={classes.content}>{wrapperComponent}</main>
    </div>
  );
}