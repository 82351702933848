import { pathRoutes } from "../../../config/constants";
import InsumoCadastro from "./index";

export default [
  {
    path: pathRoutes.insumoCadastro,
    component: InsumoCadastro,
    exact: true,
  },
  {
    path: pathRoutes.insumoEdit,
    component: InsumoCadastro,
    exact: true,
  },
];
