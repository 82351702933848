import { pathRoutes } from "../../config/constants";
import Login from "./index";

export default [
  {
    path: pathRoutes.login,
    component: Login,
    exact: true,
    noAuth: true,
  },
];
