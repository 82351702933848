import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import {omit} from 'lodash';
import {omitBy} from 'lodash';

export const useQueryState = (
    key,
    defaultValue
  ) => {
    const router = useHistory();
    const params = queryStringToObject(router.location?.search)
    const queryState = getQueryValue(params, key);
    const [value, _setValue] = useState(queryState || defaultValue);
    const debouncedPush = useDebouncedCallback(router.push, 500);
  
    const setValue = useCallback(
      (nextValue) => {
        _setValue(nextValue ?? defaultValue);
        const currentSearch = router.location.search;
        const currentParams = queryStringToObject(currentSearch);
    
        const nextQuery = nextValue
          ? { ...currentParams, [key]: nextValue } 
          : omit(currentParams, key);
          
    
        const nextQueryString = objectToQueryString(nextQuery);
        debouncedPush(
          { pathname: router.location.pathname, search: nextQueryString }
        );
      },
      [key, defaultValue, router, debouncedPush]
    );
  
    useEffect(() => {
      _setValue(queryState ?? defaultValue);
    }, [queryState, defaultValue]);
  
    return [value, setValue];
  };

  function objectToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
      }
    }
    return keyValuePairs.join('&');
  }

  const getQueryValue = (query, key) => {
    const rawValue = query[key];
    if (Array.isArray(rawValue)) {
      return rawValue[0];
    }
    return rawValue;
  };

  function queryStringToObject(queryString) {
    const params = new URLSearchParams(queryString);
    const obj = {};
    params.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }
  

  export default function useDebounce(value, delay = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  }
  

  export const useDebouncedCallback = (
    cb,
    delay = 500,
    dependencies = []
  ) => {
    const timerRef = useRef(null);
  
    return useCallback(
      (...args) => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
  
        timerRef.current = setTimeout(() => {
          cb(...args);
          timerRef.current = null;
        }, delay);
      },
      [cb, delay, ...dependencies]
    );
  };

  export const useSetQueryStateValues = () => {
    const router = useHistory();
    const [, _setValues] = useState(router.query ?? {});
    const debouncedPush = useDebouncedCallback(router.push, 500);
  
    const setValues = useCallback(
      (nextValues) => {
        _setValues(nextValues);
        
        const currentSearch = router.location.search;
        const currentParams = queryStringToObject(currentSearch);

        const nextQuery = omitBy(
          { ...currentParams, ...(nextValues ?? {}) },
          (value) => value == null || value === ''
        );
  
        const nextQueryString = objectToQueryString(nextQuery);
        debouncedPush(
          { pathname: router.location.pathname, search: nextQueryString }
        );

      },
      [router, debouncedPush]
    );
  
    return [setValues];
  };