export const serialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        active: data.active,
        sector: {
            id: data.sector?.id
        }
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        description: data.description
    }

}


export default{
    serialize
}