import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import AssessmentIcon from '@material-ui/icons/Assessment';
import taxOperationApi from "../../../../services/taxOperationApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        description: "",
        cfop: "",
        documentModel: "",
        emissionType: "",
        danfeType: "",
        invoicePurpose: "",
        customerPresence: "",
    }
};

export default function OperacaoFiscalPreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function getAliquota() {
            const taxOperation = await taxOperationApi.findById(id);
            if (taxOperation) {
                setData({
                    id: taxOperation.data.response[0].id,
                    code: taxOperation.data.response[0].code,
                    description: taxOperation.data.response[0].description,
                    documentModel: taxOperation.data.response[0].documentModel,
                    cfop: taxOperation.data.response[0].cfop,
                    emissionType: taxOperation.data.response[0].emissionType,
                    danfeType: taxOperation.data.response[0].danfeType,
                    invoicePurpose: taxOperation.data.response[0].invoicePurpose,
                    customerPresence: taxOperation.data.response[0].customerPresence,
                })
            }
        }
        getAliquota();
    }, [])

    return (
        <Box>
            <PageTitle title="Aliquota" icon={<AssessmentIcon style={{ color: 'gray' }} />} />
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={3} sm={3} xs={12}>
                    <TextFieldPreview
                        name="code"
                        label="Código"
                        value={data.code}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={3} sm={3} xs={12}>
                    <TextFieldPreview
                        name="cfop"
                        label="CFOP"
                        value={data.cfop}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="documentModel"
                        label="Modelo Fiscal"
                        value={data.documentModel}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="emissionType"
                        label="Tipo de Operação"
                        value={data.emissionType}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="danfeType"
                        label="Tipo de Danfe"
                        value={data.danfeType}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="invoicePurpose"
                        label="Finalidade da Nota"
                        value={data.invoicePurpose}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="customerPresence"
                        label="Presença do Cliente"
                        value={data.customerPresence}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    );
}