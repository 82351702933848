import { serialize } from "./mapper/CadastrarEmpresaMapper"
import { post, put } from "../utils/request";

export const cadastrarEmpresa = async (data, email) => {
    return post("v1/company" , serialize(data, email),undefined , true);
}

export const updateEmpresa = async (data, id) => {
    return put("v1/company", serialize(data, undefined, id),undefined, true);
}

export default {
    post: cadastrarEmpresa,
    put: updateEmpresa
}