import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
  } from "react";
  
  const LoaderContext = createContext(null);
  
  export const LoaderProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false)
  
    const showLoader = useCallback(() => {
      setIsLoading(true)
    }, [])
  
    const hideLoader = useCallback(() => {
      setIsLoading(false)
    }, [])
  
    const value = useMemo(
      () => ({
        isLoading,
        showLoader,
        hideLoader,
      }),
      [isLoading, showLoader, hideLoader]
    )
  
    return (
      <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
    )
  }
  
  export const useLoader = () => {
    const context = useContext(LoaderContext)
  
    if (!context) {
      throw new Error("useLoader must be used within a LoaderProvider")
    }
  
    return context
  }
  