export const serialize = (data) => {
    return {
        id: data.id,
        aliquotPercent: {
            id: data.aliquotPercent?.id,
        },
        taxOperation: {
            id: data.taxOperation?.id
        },
        cst: {
            id: data.cst?.id
        },
        category: {
            id: data.category?.id
        },
        item: {
            id: data.item?.id
        },
        code: data.code,
        description: data.description,
        cfop: data.cfop,
        documentModel: data.documentModel,
        emissionType: data.emissionType,
        danfeType: data.danfeType,
        invoicePurpose: data.invoicePurpose,
        customerPresence: data.customerPresence,
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        name: data.code,
        createdAt: data.createdAt,
        type: data.name
    }
}


export default{
    serialize
}