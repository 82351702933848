import { Box, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, makeStyles, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Image from '../Image';
import { getUser, setUser } from "../../utils/auth";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ButtonSubmit from "../ButtonSubmit";
import cadastroUsuarioApi from '../../services/cadastroUsuarioApi';
import toast from '../../utils/toast';
import userDetailsApi from '../../services/userDetailsApi';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import ClearIcon from '@material-ui/icons/Clear';

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        nome: "",
        sobrenome: "",
        email: "",
        senha: "",
        confirmaSenha: "",
        telefone: ""
    },
  };

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

export default function Perfil() {
    
    const [urlImgUpload, setUrlImgUpload] = useState("")
    const [data, setData] = useState(DEFAULT_STATE.data);
    const [values, setValues] = React.useState({
        showPassword: false,});
    const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);

    useEffect(() => {
        const user = getUser();
        const data = {
            nome : user.firstName,
            sobrenome: user.lastName,
            email: user.email,
            telefone: user.telephone
        };
        setData(data);
    },[]);

    const handleInputChange = ({ target: { name, value } }) => {
        setData({ ...data, [name]: value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeUpload = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.onloadend = () => {
            setUrlImgUpload(reader.result)
        };
        reader.readAsDataURL(file);
    };

    const handleRemoveImg = (e) => {
        e.preventDefault();
        if(urlImgUpload){
            setUrlImgUpload("")
        }
    }

    const validatePassword = () => {
        if (data.senha !== data.confirmaSenha) {
          toast.error("As senhas não conferem!");
          setIsLoading(DEFAULT_STATE.isLoading);
          return false;
        }
        return true;
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        if(!validatePassword()){
          return;
        }
        const usuario = await cadastroUsuarioApi.put(data);
        setIsLoading(DEFAULT_STATE.isLoading);
        if (usuario) {
          const userDetails = await getUserDetails(data.email);
          setUser(userDetails);
          toast.success(
            `Usuário atualizado com sucesso.`
          );
        }
    };

    const getUserDetails = async(email) => {
        const userDetails = await userDetailsApi.get(email);
        return userDetails.data.response[0];
    }

    const classes = useStyles();

    return(
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item md={2} sm={4} xs={12} className="c-inline-img">
                    <input 
                        accept="image/*" 
                        id="icon-button-file" 
                        className={classes.input}
                        type="file" 
                        onChange={e => handleChangeUpload(e)}
                    />
                    <label htmlFor="icon-button-file">
                        <IconButton 
                            color="primary" 
                            aria-label="upload picture" 
                            component="span">
                            <Image
                                source={urlImgUpload === "" ? "perfil.png" : urlImgUpload}
                                className="img-fluid"
                                alt="Perfil"
                            /> 
                        </IconButton>
                    </label>
                    {urlImgUpload && (
                        <Box className="ml-5">
                            <Tooltip title="Remover foto">
                                <IconButton>
                                    <ClearIcon onClick={handleRemoveImg}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                    }
                </Grid>
                <Grid item md={5} sm={4} xs={12} className="c-inline">
                    <Box className="form-group-perfil">
                        <TextField
                            className="form-control"
                            label="Email"
                            name="email"
                            disabled
                            onChange={handleInputChange}
                            value={data.email}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box className="form-group-perfil">
                        <TextField
                            className="form-control"
                            name="nome"
                            label="Nome"
                            onChange={handleInputChange}
                            value={data.nome}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>    
                    <Box className="form-group-perfil">
                        <TextField
                            className="form-control"
                            label="Sobrenome"
                            name="sobrenome"
                            onChange={handleInputChange}
                            value={data.sobrenome}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item md={5} sm={4} xs={12} className="c-inline"> 
                    <Box className="form-group-perfil">
                            <TextField
                            className="form-control"
                            label="Telefone"
                            name="telefone"
                            onChange={handleInputChange}
                            value={data.telefone}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CallIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            />
                    </Box>
                    <Box className="form-group-perfil">
                        <FormControl className="form-control form-control-perfil">
                            <InputLabel htmlFor="senha-perfil">Senha</InputLabel>
                            <Input
                                id="senha-perfil"
                                name="senha"
                                onChange={handleInputChange}
                                type={values.showPassword ? 'text' : 'password'}
                                value={data.senha}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}>
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box className="form-group-perfil">
                        <FormControl className="form-control form-control-perfil">
                            <InputLabel htmlFor="confirma-senha-perfil" >Confirme a senha</InputLabel>
                            <Input
                                id="confirma-senha-perfil"
                                name="confirmaSenha"
                                type={values.showPassword ? 'text' : 'password'}
                                value={data.confirmaSenha}
                                onChange={handleInputChange}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            
            <Box className="c-inline-btn">
                <ButtonSubmit
                    label="Salvar"
                    type="submit"
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    className="btn btn-primary btn-user btn-block-small"
                />
            </Box>
        </form>
    );
}