import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import itemPricingDefaultApi from "../../../../../../services/itemPricingDefaultApi";
import PageTitle from "../../../../../PageTitle";
import TextFieldPreview from "../../../../../TextFieldPreview";
import { formatMoney } from "../../../../../../utils/moneyUtils";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        itemName: "",
        value:"",
        companyName: ""
    }
  };

export default function ItemPrecificacaoPadraoPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const itemPricingDefault = await itemPricingDefaultApi.findById(id);
        if (itemPricingDefault) {
            const itemPricingDefaultData = itemPricingDefault.data.response[0];
            setData({
                itemName : itemPricingDefaultData.item.name,
                value : formatMoney(itemPricingDefaultData.value)
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Preço Padrão do Item" icon={<AttachMoneyIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="itemName"
                        label="Item"
                        value={data.itemName}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="value"
                        label="Preço"
                        value={data.value}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}