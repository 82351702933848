import { del, get, patch, post, put } from "../utils/request"


export const findAll = async () => {
    return get("v1/config/general-notification/all", `${process.env.REACT_APP_BASE_URL}`, true);
}


export default {
    findAll
}