import { Box, Divider, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BugReportRoundedIcon from '@material-ui/icons/BugReportRounded';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import inputApi from "../../../services/inputApi";
import supplierApi from "../../../services/supplierApi";
import { Autocomplete } from "@material-ui/lab";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";
import unitApi from "../../../services/unitApi";
import { useLoader } from "../../../hooks/LoaderContext";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    code: "",
    name: "",
    unity: {
      id: ""
    },
    active: true,
    supplier: {
      id: ""
    }
  },
  supplier: [],
  supplierInput: {
    title: "",
    id: ""
  },
  unityInput: {
    title: "",
    id: ""
  },
  units: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  code: Yup.string().required(REQUIRED_FIELD),
  supplier: Yup.object().shape({
    id: Yup.string().required(REQUIRED_FIELD)
  })
});
export default function InsumoCadastro() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [unity, setUnity] = useState(DEFAULT_STATE.data.unity);
  const [unityList, setUnityList] = useState(DEFAULT_STATE.units);
  const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);
  const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierInput);
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    async function getAllSuppliers() {
      const supplierReponse = await supplierApi.findAll();
      if (supplierReponse) {
        setSupplierList(supplierReponse.data.response);
      }
    }
    getAllSuppliers();
  }, [])

  useEffect(() => {
    async function getAllUnits() {
      const unitResponse = await unitApi.findAll();
      if (unitResponse) {
        setUnityList(unitResponse.data.response);
      }
    }
    getAllUnits();
  }, []);

  useEffect(() => {
    async function findById() {
      if (params.ident) {
        showLoader();
        const inputReponse = await inputApi.findById(params.ident);
        if (inputReponse) {
          const data = {
            id: inputReponse.data.response[0].id,
            code: inputReponse.data.response[0].code,
            name: inputReponse.data.response[0].name,
            unity: {
              id: inputReponse.data.response[0].unity.id
            },
            supplier: {
              id: inputReponse.data.response[0].supplier.id
            }
          };
          setData(data);
          setSupplier({
            title: inputReponse.data.response[0].supplier.name,
            id: inputReponse.data.response[0].supplier.id
          });
          setUnity({
            title: `${inputReponse.data.response[0].unity.code} - ${inputReponse.data.response[0].unity.description}`,
            id: inputReponse.data.response[0].unity.id
          });
        }
        hideLoader();
      }
    }
    findById();
  }, []);

  const suppliers = supplierList.map(supplier => ({
    title: supplier.name,
    id: supplier.id
  }));

  const units = unityList.map(unity => ({
    title: `${unity.code} - ${unity.description}`,
    id: unity.id
  }));

  const handleChangeUnit = (value) => {
    setUnity(value);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let input;
      console.log(values)
      if (values.id.length == 0) {
        input = await inputApi.post(values);
      } else {
        input = await inputApi.put(values);
      }
      if (input) {
        handleSuccess(values.id.length == 0);
      } else {
        handleError();
      }
      redirectToInsumo();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };


  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Insumo cadastrado com sucesso."
      : "Insumo atualizado com sucesso.";
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar insumo, favor verifique!.`);
  }

  const redirectToInsumo = () => {
    history.goBack();
  };

  const handleChangeSupplier = (value) => {
    setSupplier(value);
  };

  const handleDelete = async () => {
    const deleted = await inputApi.delete(params.ident);
    if (deleted) {
      redirectToInsumo();
      toast.success(`Insumo deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir insumo, favor verifique!.`);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={!params.ident ? 'Cadastrar Insumo' : 'Editar Insumo'} icon={<BugReportRoundedIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors, setFieldValue }) => (
              <Form className='mt-3'>
                <Grid container spacing={2}>
                  <Grid className="mt-4" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="code"
                      label="Código Insumo"
                      helperText={(touched.code && errors.code) && <ErrorMessage name="code" />}
                      error={touched.code && errors.code}>
                    </Field>
                  </Grid>
                  <Grid className="mt-4" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="name"
                      label="Nome"
                      helperText={touched.name && errors.name ? <ErrorMessage name="name" /> : ""}
                      error={touched.name && Boolean(errors.name)}
                    />
                  </Grid>
                  <Grid className="mt-3" item md={6} sm={6} xs={12}>
                    <Autocomplete
                      id="autocomplete-unit"
                      options={units}
                      getOptionLabel={(option) => option.title}
                      clearText="Limpar"
                      value={unity}
                      onChange={(event, value) => {
                        setFieldValue("unity", value ? value : '');
                        handleChangeUnit(value ? value : '')
                      }}
                      renderInput={(params) =>
                        <TextField {...params} label="Unidade" variant="outlined" />
                      }
                    />
                  </Grid>
                  <Grid className="mt-3" item md={6} sm={6} xs={12}>
                    <Autocomplete
                      id="autocomplete-supplier"
                      options={suppliers}
                      getOptionLabel={(option) => option.title}
                      value={supplier}
                      onChange={(event, value) => {
                        setFieldValue("supplier.id", value ? value.id : '');
                        handleChangeSupplier(value ? value : '');
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Fornecedor" variant="outlined"
                          error={touched.supplier?.id && !!(errors.supplier?.id)}
                          helperText={touched.supplier?.id && errors.supplier?.id}
                        />
                      )}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <Grid item md={6} sm={6} xs={12}>
                              <ButtonRemove
                                title="excluir"
                                onClick={handleOpenDeleteModal}
                                variant="contained"
                                color="primary"
                                className="btn btn-primary btn-user btn-block"
                              />
                            </Grid>
                          )}
                          <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disable={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}