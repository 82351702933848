import { pathRoutes } from "../../../config/constants";
import OperacaoFiscalConfig from "./index";

export default [
  {
    path: pathRoutes.operacaoFiscalConfig,
    component: OperacaoFiscalConfig,
    exact: true,
  },
];
