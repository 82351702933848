import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import financialDashboardApi from "../../services/financialDashboardApi";
import { useLoader } from "../../hooks/LoaderContext";

const PieChartFuturePayment = () => {
  const [data, setData] = useState([]);
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    async function getAllFuturePayments() {
      showLoader();
      const dashBoardResponse = await financialDashboardApi.findFuturePayments();
      if (dashBoardResponse) {
        setData(dashBoardResponse.data.response);
      }
      hideLoader();
    }
    getAllFuturePayments();
  }, []);

  return (
    <div style={{ height: "300px" }}>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: "category10" }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        valueFormat={(value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
      />
    </div>
  );
};

export default PieChartFuturePayment;
