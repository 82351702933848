import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CategoryIcon from '@material-ui/icons/Category';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import categoryApi from "../../../../services/categoryApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        name: "",
        genero: "",
        ncm: "",
        cest: ""
    }
};

export default function CategoriaPreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function findById() {
            const categoryResponse = await categoryApi.findById(id);
            if (categoryResponse) {
                const categoryData = categoryResponse.data.response[0];
                setData({
                    name: categoryData.name
                })
            }
        }
        findById();
    }, [])

    return (
        <>
            <Box>
                <PageTitle title="Categoria" icon={<CategoryIcon style={{ color: 'gray' }} />} />
                <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={12} sm={12} xs={12}>
                        <TextFieldPreview
                            name="name"
                            label="Nome"
                            value={data.name}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box className="mt-5 mb-2" border={1} borderRadius="borderRadius" borderColor="#EEEEEE" p={2} component="fieldset">
                    <Typography variant="h5" style={{ fontSize: '1rem', textAlign: 'center', paddingTop: '5px', color: 'gray' }} gutterBottom>
                        Informações Fiscais
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                            <TextFieldPreview
                                name="genero"
                                label="Genero"
                                value={data.genero}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                            <TextFieldPreview
                                name="ncm"
                                label="NCM"
                                value={data.ncm}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                            <TextFieldPreview
                                name="cest"
                                label="Cest"
                                value={data.cest}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}