import { del, get, patch, post, put } from "../utils/request"

export const findAll = async (taxTypeId, cstId) => {
    if (taxTypeId && cstId) {
        return get(`v1/screen-view/tax-type/all/${taxTypeId}/${cstId}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
    }
    return get(`v1/screen-view/tax-type/all/${taxTypeId}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export default {
    findAll
}