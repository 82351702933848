import { Box, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import { useQueryState } from "../../../../../hooks/QueryState";
import OperacaoFiscalCategoria from "../OperacaoFiscalCategoria";
import OperacaoFiscalPadrao from "../OperacaoFiscalPadrao";
import OperacaoFiscalItem from "../OperacaoFiscalItem";

export default function OperacaoFiscalSubTabOpcoes(props) {

  const { menu } = props

  const [currentTab, setCurrentTab] = useQueryState("subtab", "padrao");
  
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  }; 

  return (
      <div className="container-fluid" style={{display: "flex"}}>
        <Box border={1} borderRadius="borderRadius" borderColor="#DDDDDD" p={2} mt={2} width={"100%"}>
          <TabContext value={currentTab}>
                  <Tabs
                    value={currentTab} 
                    onChange={handleChange}
                    indicatorColor="primary"
                    centered
                    textColor="primary">
                    <Tab label="Padrão" value={"padrao"} />
                    <Tab label="Por Categoria" value={"categoria"} />
                    <Tab label="Por Item" value={"item"} />
                  </Tabs>
                  <TabPanel value={"padrao"}>
                      <OperacaoFiscalPadrao/>
                  </TabPanel>
                  <TabPanel value={"categoria"}>
                      <OperacaoFiscalCategoria/>
                  </TabPanel>
                  <TabPanel value={"item"}>
                   <OperacaoFiscalItem/>
                  </TabPanel>
          </TabContext>
          </Box>
    </div>
  );

}
