export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        description: data.description,
        createdAt: data.createdAt,
        site: {
            id : data.site?.id
        },
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        description: data.description,
        createdAt: data.createdAt
    }
}


export default{
    serialize
}