import React, { useEffect, useState } from "react";
import spotApi from "../../../services/spotApi";
import { Box, CircularProgress, Grid, MenuItem, TextField } from "@material-ui/core";
import CardCanteiro from "../../CardCanteiro";
import ButtonAdd from "../../ButtonAdd";
import { pathRoutes } from "../../../config/constants";
import { useHistory } from 'react-router-dom';
import CanteiroFilter from "./CanteiroFilter/Index";
import { useLoader } from "../../../hooks/LoaderContext";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: false,
        code: "",
        quantityInput: "",
        workTime: "",
        line: {
            id: ""
        },
        supplier: {
            id: ""
        }
    },
    lineInput: {
        title: "",
        id: ""
    },
    supplierInput: {
        title: "",
        id: ""
    },
    spot: [],
    lines: [],
    supplier: []
};

export default function Canteiro() {

    const [spotList, setSpotList] = useState(DEFAULT_STATE.spot);
    const [lineList, setLineList] = useState(DEFAULT_STATE.lines);
    const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();
    const [filters, setFilters] = useState();
    const [forceRefreshData, setForceRefreshData] = useState(false);
    const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);


    useEffect(() => {
        async function getAllSpot() {
            showLoader()
            const spotResponse = await spotApi.findAllPageable(0, rowsPerPage, filters);
            if (spotResponse) {
                setSpotList(spotResponse.data.response);
                setTotalPages(spotResponse.data.totalPages);
            } else {
                setSpotList(DEFAULT_STATE.spot);
                setTotalPages(0);
            }
            hideLoader();
        }
        getAllSpot();
    }, [filters, forceRefreshData]);

    const handleFilter = async (filters) => {
        setFilters(filters);
        setForceRefreshData(!forceRefreshData);
    };

    const handleEdit = async (id) => {
        history.push(`${pathRoutes.canteiro}/edicao/${id}`)
    };

    const handleCreate = async () => {
        history.push(`${pathRoutes.canteiro}/cadastro`)
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <CanteiroFilter onFilter={handleFilter} />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Box>
                        <ButtonAdd title="Cadastrar Canteiro" onClick={handleCreate} />
                    </Box>
                </Grid>
                <div className="row">
                    <Cards />
                </div>
            </Grid>
        </>
    );

    function Cards() {
        if (isLoading) {
            return (<CircularProgress size={35} />)
        }
        if (spotList.length === 0) {
            return (
                <p>Nenhum canteiro foi encontrado.</p>
            )
        } else {
            return (
                <>
                    {spotList.map((spot, index) => (
                        <CardCanteiro
                            key={index}
                            code={spot.code}
                            status={spot.active}
                            line={spot.line.code}
                            sector={spot.line.sector.code}
                            id={spot.id}
                            onClick={handleEdit}
                        />
                    ))}
                </>
            )
        }
    }

}