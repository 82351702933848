import { Box, Button, Grid, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import generoApi from "../../../../services/generoApi";
import { Autocomplete } from "@material-ui/lab";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        code: "",
        description: "",
        genero: {
            id: ""
        },
    },
    generos: [],
    generoInput: {
        title: "",
        id: ""
    }
};

export default function NcmFilter(props) {

    const { onFilter } = props;

    const [filter, setFilter] = useState(DEFAULT_STATE.filter);
    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();
    const [code, setCode] = useQueryState("code", '');
    const [description, setDescription] = useQueryState("description", '');
    const [generoId, setGeneroId] = useQueryState("genero", '');

    const [generoList, setGeneroList] = useState(DEFAULT_STATE.generos);
    const [genero, setGenero] = useState(DEFAULT_STATE.generoInput);

    useEffect(() => {
        handleFilter();
    }, [])

    useEffect(() => {
        async function getAllGenero() {
            const generosResponse = await generoApi.findAll();
            if (generosResponse) {
                setGeneroList(generosResponse.data.response);
            }
        }
        getAllGenero();
    }, []);

    const handleFilter = async e => {
        if (e) {
            e.preventDefault();
        }
        let param = '';
        if (description) {
            param = param.concat('&description=').concat(description);
        }
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (generoId) {
            param = param.concat(`&generoId=${generoId}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const generos = generoList.map(genero => ({
        title: `${genero.code} - ${genero.description}`,
        id: genero.id
    }));

    const cleanFilter = async e => {
        onFilter();
        setIsFiltering(false);;
        setValues({ genero: "", code: "", description: "" })
        setGenero(DEFAULT_STATE.generoInput)
    };

    const handleInputDescription = ({ target: { value } }) => {
        setDescription(value);
    };

    const handleChangeCode = ({ target: { value } }) => {
        setCode(value);
    };

    const handleChangeGenero = (value) => {
        setGenero(value)
        setGeneroId(value.id);
    };

    return (
        <BoxFilter>
            <form onSubmit={handleFilter}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid className="mt-4 mb-2" item md={3} sm={3} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="code"
                                label="Código"
                                value={code}
                                onChange={handleChangeCode}
                            />
                        </Grid>
                        <Grid className="mt-4 mb-2" item md={9} sm={9} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="description"
                                label="Descrição"
                                value={description}
                                onChange={handleInputDescription}
                            />
                        </Grid>
                        <Grid className="mt-3 mb-1" item md={12} sm={12} xs={12}>
                            <Autocomplete
                                id="autocomplete-genero"
                                options={generos}
                                clearText="Limpar"
                                value={genero}
                                onChange={(event, value) => handleChangeGenero(value ? value : '')}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) =>
                                    <TextField {...params} label="Genero" variant="outlined" />
                                }
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className="mt-1 c-inline-btn">
                                <ButtonSearch
                                    label="Filtrar"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                />
                            </Box>
                            <Box className="mt-1 c-inline-btn">
                                {isFiltering && (
                                    <Button
                                        color="primary"
                                        onClick={cleanFilter}>
                                        Limpar Filtros
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </BoxFilter>
    );
}