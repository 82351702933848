import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BugReportRoundedIcon from '@material-ui/icons/BugReportRounded';
import PageTitle from "../../../PageTitle";
import inputApi from "../../../../services/inputApi";
import TextFieldPreview from "../../../TextFieldPreview";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        code: "",
        name: "",
        unity: {
            title: "",
        },
        active: false,
        supplierName: ""
    },
};

export default function InsumoPreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function findById() {
            const inputReponse = await inputApi.findById(id);
            if (inputReponse) {
                const data = {
                    id: inputReponse.data.response[0].id,
                    code: inputReponse.data.response[0].code,
                    name: inputReponse.data.response[0].name,
                    unity: `${inputReponse.data.response[0].unity.code} - ${inputReponse.data.response[0].unity.description}`,
                    supplierName: inputReponse.data.response[0].supplier.name
                };
                setData(data);
            }
        }
        findById();
    }, [])

    return (
        <>
            <Box>
                <PageTitle title="Insumo" icon={<BugReportRoundedIcon style={{ color: 'gray' }} />} />
                <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="code"
                            label="Código Insumo"
                            value={data.code}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="name"
                            label="Nome"
                            value={data.name}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="unity"
                            label="Unidade de Medida"
                            value={data.unity}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="supplierName"
                            label="Fonecedor"
                            value={data.supplierName}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}