import { Box, Divider, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import PageTitle from "../../../components/PageTitle";
import { useParams } from 'react-router-dom'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BasicReturn from "../../../components/BasicReturn";
import { useQueryState } from "../../../hooks/QueryState";
import taxOperationApi from "../../../services/taxOperationApi";
import BuildIcon from '@material-ui/icons/Build';
import OperacaoFiscalSubTabOpcoes from "../../../components/pages/OperacaoFiscal/config/OperacaoFiscalSubTabOpcoes";

export default function OperacaoFiscalConfig() {

  const [currentTab, setCurrentTab] = useQueryState("tab", "impostos");
  const [taxOperation, setTaxOperation] = useState({});
  const params = useParams();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  }; 

  useEffect(() => {
    async function getTaxOperationById() {
        const taxOperationResponse = await taxOperationApi.findById(params.ident);
        if (taxOperationResponse) {
          setTaxOperation(taxOperationResponse.data.response[0]);
        }
    }
    getTaxOperationById();
  },[]);

  return (
      <div className="container-fluid">
        <PageTitle title={`Configuração Fiscal - ${taxOperation.description}`} icon={<BuildIcon style={{ color: 'gray' }}/>} />
        <BasicReturn />
        <Divider />
        <Box className="artical-1 out-shadow">
          <TabContext value={currentTab} >
            <Box style={{display: "flex"}}>
              <Tabs 
                value={currentTab} 
                orientation="vertical"
                onChange={handleChange}
                style={{paddingTop : "3em", width: "15%"}}
                indicatorColor="primary"
                textColor="primary"
                centered>
                <Tab label="Impostos" icon={<AttachMoneyIcon />} value={"impostos"} />
              </Tabs>
              <TabPanel value={"impostos"} style={{width : "100%"}}>
                <OperacaoFiscalSubTabOpcoes menu="impostos"/>
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </div>
  );

}
