import { serialize } from "./mapper/ItemConfigInvoiceMapper"
import { del, get, post, put } from "../utils/request"

export const create = async (data) => {
    return post("v1/config/item/config-invoice", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}
 
export const update = async (data) => {
    return put("v1/config/item/config-invoice", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}
export const findAllPageable = async (page, size, filters) => {
    if (filters) {
        return get(`v1/config/item/config-invoice/all?page=${page}&size=${size}&${filters}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
    }
    return get(`v1/config/item/config-invoice/all?page=${page}&size=${size}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const findById = async (id) => {
    return get(`v1/config/item/config-invoice/${id}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const remove = async (id) => {
    return del(`v1/config/item/config-invoice/${id}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export default {
    post : create,
    findAllPageable,
    findById,
    delete : remove,
    put : update
}