export const serialize = (data) => {
    return {
        id: data.id,
        companyId: data.companyId,
        certificate: data.certificate,
        passCertificate: data.passCertificate,
        expCertificate: data.expCertificate,
        taxRegime: data.taxRegime,

    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        companyId: data.companyId,
        certificate: data.certificate,
        passCertificate: data.passCertificate,
        expCertificate: data.expCertificate,
        taxRegime: data.taxRegime,
    }

}


export default{
    serialize
}