export const serialize = (data) => {
    return {
        id: data.id,
        value: data.value,
        item: {
            id : data.item?.id
        },
        supplier: {
            id : data.supplier?.id
        },
        active: data.active,
    }
}


export default {
    serialize
}