import { pathRoutes } from "../../../config/constants";
import LineCadastro from "./index";

export default [
  {
    path: pathRoutes.lineCadastro,
    component: LineCadastro,
    exact: true,
  },
  {
    path: pathRoutes.lineEdit,
    component: LineCadastro,
    exact: true,
  },
];