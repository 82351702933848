import { Box, Divider, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import categoryApi from "../../../services/categoryApi";
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { pathRoutes, REQUIRED_FIELD } from "../../../config/constants";
import AlertDialog from "../../../components/AlertDialog";
import ButtonRemove from "../../../components/ButtonRemove";
import { useLoader } from "../../../hooks/LoaderContext";
import ButtonConfig from "../../../components/ButtonConfig";
import CategoryIcon from '@material-ui/icons/Category';

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    active: true,
    name: ""
  },
  categories: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
});

export default function CategoriaCadastro() {
  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const params = useParams();
  const { showLoader, hideLoader } = useLoader();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    async function findById() {
      showLoader();
      if (!isNaN(params.ident)) {
        const categoryResponse = await categoryApi.findById(params.ident);
        if (categoryResponse) {
          const data = {
            id: categoryResponse.data.response[0].id,
            name: categoryResponse.data.response[0].name
          };
          setData(data);
        }
      }
      hideLoader();
    }
    findById();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let category;
      if (values.id.length === 0) {
        category = await categoryApi.post(values);
      } else {
        category = await categoryApi.put(values);
      }
      if (category) {
        handleSuccess(values.id.length === 0);
      } else {
        handleError();
      }
      redirectToCategoria();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    const deleted = await categoryApi.delete(params.ident);
    if (deleted) {
      toast.success(`Categoria deletada com sucesso.`);
    } else {
      toast.error(`Falha ao excluir categoria, favor verifique!.`);
    }
    redirectToCategoria();
  };

  const handleSuccess = (isNew) => {
    const successMessage = isNew ? "Categoria cadastrada com sucesso." : "Categoria atualizada com sucesso.";
    toast.success(successMessage);
  };

  const handleError = () => {
    toast.error(`Falha ao criar Categoria, favor verifique!`);
  };

  const redirectToCategoria = () => {
    history.goBack();
  };

  const handleConfig = () => {
    history.push(`${pathRoutes.categoria}/configuracao/${params.ident}`);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Cadastrar Categoria' : 'Editar Categoria'} icon={<CategoryIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors}) => (
              <Form className='mt-3'>
                <Grid container spacing={2}>
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="name"
                      label="Nome da Categoria"
                      helperText={touched.name && errors.name ? <ErrorMessage name="name" /> : ""}
                      error={touched.name && Boolean(errors.name)}
                    />
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <> 
                              <Grid item md={4} sm={4} xs={12}>
                                <ButtonConfig disabled title="Configuraçoes" onClick={handleConfig} />
                              </Grid>
                              <Grid item md={4} sm={4} xs={12}>
                                <ButtonRemove
                                  title="excluir"
                                  onClick={handleOpenDeleteModal}
                                  variant="contained"
                                  color="primary"
                                  className="btn btn-primary btn-user btn-block"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item md={params.ident ? 4 : 12} sm={params.ident ? 4 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disabled={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}
