import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { pathRoutes } from "../../../../../config/constants";
import DataTable from "../../../../DataTable";
import toast from "../../../../../utils/toast";
import ButtonAdd from "../../../../ButtonAdd";
import PreviewModal from "../../../../PreviewModal";
import { useLoader } from "../../../../../hooks/LoaderContext";
import itemPricingSupplierApi from "../../../../../services/itemPricingSupplierApi";
import ItemPrecificacaoClienteFilter from "./ItemPrecificacaoClienteFilter";
import { formatMoney } from "../../../../../utils/moneyUtils";
import ItemPrecificacaoClientePreview from "./ItemPrecificacaoClientePreview";
import DataTableDefaultAction from "../../../../DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  itemPricingSuppliers: []
};

export default function ItemPrecificacaoCliente() {

  const [itemPricingSupplierList, setitemPricingSupplierList] = useState(DEFAULT_STATE.itemPricingSuppliers);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [idPreview, setIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const params = useParams();

  const [filters, setFilters] = useState();

  useEffect(() => {
    async function getAllitemPricingDefault() {
        showLoader();
        const itemPricingSupplierResponse = await itemPricingSupplierApi.findAllPageable(page-1 , rowsPerPage, `item_id=${params.ident}`);
        if (itemPricingSupplierResponse) {
            setitemPricingSupplierList(itemPricingSupplierResponse.data.response);
            setTotalPages(itemPricingSupplierResponse.data.totalPages);
        } else {
            setitemPricingSupplierList(DEFAULT_STATE.itemPricingSuppliers);
            setTotalPages(0);
        }
        hideLoader();
    }
    getAllitemPricingDefault();
  },[forceRefreshData, filters])

  const handleDelete = async (id) => {
    const deleted = await itemPricingSupplierApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Preço por cliente do item deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Preço por cliente do item, favor verifique!.`);
    }
  };
  
  const handleEdit = async (id) => {
    history.push(`${pathRoutes.itemConfigPrecificacaoClienteEdit.replace(":ident?", "")}${id}`)
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCreate = async () => {
    history.push(`${pathRoutes.itemConfigPrecificacaoClienteCadastro.replace(":itemId?", "")}${params.ident}`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Preço', 'Item', 'Cliente', 'Ações'];

  const rows = itemPricingSupplierList.map(itemPricingSupplier => [
    formatMoney(itemPricingSupplier.value),
    itemPricingSupplier.item.name,
    `${itemPricingSupplier.supplier.name} - ${itemPricingSupplier.supplier.document}`,
    <DataTableDefaultAction
      id={itemPricingSupplier.id}
      actions={{
        onEdit: () => handleEdit(itemPricingSupplier.id),
        onDelete: () => handleDelete(itemPricingSupplier.id),
        onPreview: () => handlePreview(itemPricingSupplier.id)
      }} 
    />
  ]);

  const handleFilter = async (filters) => {
    setFilters(filters);
  };
 
  return (
    <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
            <ItemPrecificacaoClienteFilter onFilter={handleFilter} />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <ButtonAdd title="Cadastrar Preço Por Cliente" onClick={handleCreate}/>
          </Box>
        </Grid>
      
        <Grid item md={12} sm={12} xs={12}>
          <Box position="relative">
            <DataTable header={header} 
                       rows={rows} 
                       totalPages={totalPages}
                       page={page} 
                       onPage={handleChangePage} 
                       onRowsPerPage={handleChangeRowsPerPage}/>
          </Box>
          <PreviewModal openDialog={showPreview} 
                        onCloseDialog={handleClosePreview} 
                        width="70%" height="50%">
            <ItemPrecificacaoClientePreview id={idPreview}/>
          </PreviewModal>
          </Grid>
    </Grid>
  );
}