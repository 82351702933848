import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import PageTitle from "../../../PageTitle";
import supplierApi from "../../../../services/supplierApi";
import TextFieldPreview from "../../../TextFieldPreview";
import { translateSupplierAddressType, translateSupplierCategory, translateSupplierType } from "../../../../config/constants";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        name: "",
        document: "",
        type: "",
        category: "",
        address: {
            id: "",
            type: "",
            zipCode: "",
            number: "",
            city: "",
            street: "",
            district: "",
            complement: "",
            uf: "",
            createdAt: "",
        }
    }
  };

export default function PessoaPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const supplierResponse = await supplierApi.findById(id);
        if (supplierResponse) {
            const supplierData = supplierResponse.data.response[0];
            setData({
                name : supplierData.name,
                document : supplierData.document,
                type: translateSupplierType(supplierData.type),
                category: translateSupplierCategory(supplierData.category),
                address: {
                    type: translateSupplierAddressType(supplierData?.addresses[0]?.type),
                    zipCode: supplierData?.addresses[0]?.zipCode,
                    number: supplierData?.addresses[0]?.number,
                    city: supplierData?.addresses[0]?.city,
                    street: supplierData?.addresses[0]?.street,
                    district: supplierData?.addresses[0]?.district,
                    complement: supplierData?.addresses[0]?.complement,
                    uf: supplierData?.addresses[0]?.uf
                }
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Pessoa" icon={<PeopleAltRoundedIcon style={{ color: 'gray' }} />}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={7} sm={7} xs={12}>
                    <TextFieldPreview
                        name="name"
                        label="Nome"
                        value={data.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={5} sm={5} xs={12}>
                    <TextFieldPreview
                        name="document"
                        label="Documento"
                        value={data.document}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="type"
                        label="Tipo da pessoa"
                        value={data.type}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="categoryName"
                        label="Categoria da pessoa"
                        value={data.category}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Box className="mt-5 mb-2" border={1} borderRadius="borderRadius" borderColor="#EEEEEE" p={2} component="fieldset">
                <Typography variant="h5" style={{ fontSize: '1rem', textAlign: 'center', paddingTop: '5px', color: 'gray' }} gutterBottom>
                    Endereço
                </Typography>
                <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressType"
                            label="Tipo do Endereço"
                            value={data.address.type}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressZipCode"
                            label="Cep"
                            value={data.address.zipCode}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressCity"
                            label="Cidade"
                            value={data.address.city}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressStreet"
                            label="Rua"
                            value={data.address.street}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressNumber"
                            label="Número"
                            value={data.address.number}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressDistrict"
                            label="Bairro"
                            value={data.address.district}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressComplement"
                            label="Complemento"
                            value={data.address.complement}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                        <TextFieldPreview
                            name="addressUf"
                            label="UF"
                            value={data.address.uf}
                            variant="outlined"
                        fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </>
  );
}