import { Box, Grid, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import itemInvoiceApi from "../../services/itemInvoiceApi";
import unitInvoiceApi from "../../services/unitInvoiceApi";
import ButtonRemoveIcon from "../ButtonRemoveIcon";
import ButtonAddIcon from "../ButtonAddIcon";
import orderPricingApi from "../../services/orderPricingApi";
import NumberFormatText from "../NumberFormatText";
import orderUnitApi from "../../services/orderUnitApi";
import { Field } from "formik";


const DEFAULT_STATE = {
    ordemItems: [],
    items: [],
    itemInput: {
      title: "",
      id: ""
    },
    units: [],
    unitInput: {
      title: "",
      id: ""
    }
  };


export default function OrderItem({ values, handleAddItem, index, supplierId, handleChangeItem, handleChange, handleChangeUnit, handleInputChange, handleRemoveItem }) {

    const [unitList, setUnitList] = useState(DEFAULT_STATE.units);
    const [itemList, setItemList] = useState(DEFAULT_STATE.items);
    const [itemId, setItemId] = useState('');

    useEffect(() => {
        async function getAllItems() {
            const itemInvoiceResponse = await itemInvoiceApi.findAll();
            if (itemInvoiceResponse) {
                setItemList(itemInvoiceResponse.data.response);
            }
        }
        getAllItems();
    },[])

    useEffect(() => {
        async function getAllUnits() {
            const unitInvoiceResponse = await unitInvoiceApi.findAll();
            if (unitInvoiceResponse) {
                setUnitList(unitInvoiceResponse.data.response);
            }
        }
        getAllUnits();
    },[])

    useEffect(() => {
      async function getDefaultValues() {
          if (supplierId && itemId) {
            const currentValue = values[index] ?? {};

            const [unitItem, currentPrice] = await Promise.all([orderUnitApi.findUnit(supplierId, itemId), orderPricingApi.findPricing(supplierId, itemId)]) ;
            
            currentValue.unit = unitItem ?  {id: unitItem.data.response[0].id, title: unitItem.data.response[0].code} : {}
            currentValue.value = currentPrice ? currentPrice.data.response[0].value : ""
            currentValue.valueDesc = 0.00;
            currentValue.quantity = 1;

            handleChange(index, currentValue);
          }
      }
      getDefaultValues();
    },[supplierId, itemId])

    const handleChangeItemId = async (itemId) => {
      setItemId(itemId?.id);
    };

    const items = itemList.map(item => ({
        title: item.name,
        id: item.id
    }));
    
    const units = unitList.map(unit => ({
        title: unit.code,
        id: unit.id
    }));

    const isItemFilled = values.items[index].item && values.items[index].item.name !== "";

    return (
        <React.Fragment key={index}>
          <Grid container spacing={1}>
            <Grid className="mt-4 mb-2" item md={3} sm={3} xs={12}>
              <Field
                as={Autocomplete}
                id={`autocomplete-item-${index}`}
                options={items}
                clearText="Limpar"
                value={values.items[index].item}
                onChange={(event, value) => {
                  handleChangeItem(value ? value : '', index);
                  handleChangeItemId(value);
                }}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                disabled={!supplierId}
                renderInput={(params) => <TextField {...params} label="Item" variant="outlined" />}
              />
            </Grid>
            <Grid className="mt-4 mb-2" item md={1} sm={1} xs={12}>
            <Tooltip title="Unidade de medida">
              <Field
                as={Autocomplete}
                id={`autocomplete-unit-${index}`}
                options={units}
                clearText="Limpar"
                value={values.items[index].unit}
                onChange={(event, value) => handleChangeUnit(value ? value : '', index)}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                disabled={!isItemFilled}
                renderInput={(params) => <TextField {...params} label="Unid" variant="outlined" />
              }
              />
              </Tooltip>
            </Grid>
            <Grid className="mt-4 mb-2" item md={1} sm={1} xs={12}>
              <Tooltip title="Quantidade">
                <Field
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  label="Qtd."
                  name="quantity"
                  value={values.items[index].quantity}
                  onChange={(event) => handleInputChange(event, index)}
                  disabled={!isItemFilled}
                />
              </Tooltip>
            </Grid>
            <Grid className="mt-4 mb-2" item md={2} sm={2} xs={12}>
              <Field
                as={TextField}
                variant="outlined"
                fullWidth
                label="Valor"
                value={values.items[index].value} 
                name="value"
                onChange={(event) => handleInputChange(event, index)}
                InputProps={{
                  inputComponent: NumberFormatText,
                }}
                disabled={!isItemFilled}
              />
            </Grid>
            <Grid className="mt-4 mb-2" item md={2} sm={2} xs={12}>
              <Field
                as={TextField}
                variant="outlined"
                fullWidth
                label="Vl. Desconto"
                simple="small"
                value={values.items[index].valueDesc} 
                name="valueDesc"
                onChange={(event) => handleInputChange(event, index)}
                InputProps={{
                  inputComponent: NumberFormatText,
                }}
                disabled={!isItemFilled}
              />
            </Grid>
            <Grid className="mb-1" item md={1} sm={1} xs={12}>
                <Grid container className="mt-4" spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <Box display="flex">
                    <ButtonRemoveIcon
                            variant="contained"
                            size="small"
                            title="Remover Item"
                            color="primary"
                            onClick={() => handleRemoveItem(index)}
                          />
                          {index === values.items.length - 1 && (
                      <ButtonAddIcon className="ml-3" title="Adicionar Item" simple onClick={() => handleAddItem()} />
                    )}
                    </Box>
                  </Grid>
                </Grid>
            </Grid>
            
            <Grid className="mt-3 mb-2" item md={2} sm={2} xs={12}>
              <Grid container spacing={2}>
                    <Grid item md={3} sm={3} xs={12} />
                    <Grid item md={9} sm={9} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Valor Total"
                        value={(values.items[index].value - values.items[index].valueDesc) * values.items[index].quantity} 
                        name="valueTotal"
                        InputProps={{
                          inputComponent: NumberFormatText,
                        }}
                        disabled/>
                    </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    };