export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        code: data.code,
        customer: {
            id: data.supplier?.id
        },
        taxOperation: {
            id: data.taxOperation?.id
        },
        items: data.items,
        createdAt: data.createdAt
    }
}

export const serializeCancel = (id, justify) => {
    return {
        id: id,
        justify: justify
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        name: data.code,
        createdAt: data.createdAt,
        type: data.name
    }
}


export default{
    serialize
}