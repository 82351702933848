export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        name: data.name,
        genero: data.genero,
        ncm: data.ncm,
        cest: data.cest,
        createdAt: data.createdAt,
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        name: data.name,
        genero: data.genero,
        ncm: data.ncm,
        cest: data.cest,
        createdAt: data.createdAt
    }
}


export default{
    serialize
}