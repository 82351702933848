export const serialize = (data, email, id) => {
    return{
        id: id,
        name: data.nome,
        email: email,
        document: data.documento
    }
}

export default {
    serialize
}