export const serialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        name: data.name,
        description: data.description,
        createdAt: data.createdAt,
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        active: data.active,
        name: data.name,
        description: data.description,
        createdAt: data.createdAt
    }
}


export default{
    serialize
}