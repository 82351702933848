import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import toast from "../../utils/toast";
import DataTable from "../../components/DataTable";
import PreviewModal from "../../components/PreviewModal";
import ButtonAdd from "../../components/ButtonAdd";
import PageTitle from "../../components/PageTitle";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { pathRoutes } from "../../config/constants";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../hooks/LoaderContext";
import chartAccountItemApi from "../../services/chartAccountItemApi";
import PlanoContaItemFilter from "../../components/pages/planoContaItem/PlanoContaItemFilter";
import PlanoContaItemPreview from "../../components/pages/planoContaItem/PlanoContaItemPreview";
import SwitchStatus from "../../components/SwitchStatus";
import DataTableDefaultAction from "../../components/DataTableDefaultActions";

const DEFAULT_STATE = {
    isLoading: false,
    chartAccountItems: [],
};

export default function PlanoContaItem() {

  const [chartAccountItemList, setChartAccountItemList] = useState(DEFAULT_STATE.chartAccountItems);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [idPreview, setIdPreview] = useState(0);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [filters, setFilters] = useState('');
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
      async function getAllChartAccountItems() {
          showLoader();
          const chartAccountItemResponse = await chartAccountItemApi.findAllPageable(page-1, rowsPerPage, filters);
          if (chartAccountItemResponse) {
            setChartAccountItemList(chartAccountItemResponse.data.response);
            setTotalPages(chartAccountItemResponse.data.totalPages)
          } else {
            setChartAccountItemList(DEFAULT_STATE.chartAccountItems);
            setTotalPages(0);
          }
          hideLoader();
      }
      getAllChartAccountItems();
  },[filters, forceRefreshData]);

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleDelete = async (id) => {
      const deleted = await chartAccountItemApi.delete(id);
      if (deleted) {
        if (chartAccountItemList.length == 1) {
          setPage(page - 1);
        }
        setForceRefreshData(!forceRefreshData);
        toast.success(`Item do plano de contas deletado com sucesso.`);
      } else {
        toast.error(`Falha ao excluir Item do Plano de Contas, favor verifique!.`);
      }
  };

  const handleChangeActive = async (id, status) => {
      const data = {
          id: id,
          active: status
      };
      const chartAccountItem = await chartAccountItemApi.status(data);
      if (chartAccountItem) {
        if (chartAccountItem.data.response[0].active) {
          toast.success(`Plano de Conta ativado com sucesso.`);
        } else {
          toast.success(`Plano de Conta inativado com sucesso.`);
        }
        setForceRefreshData(!forceRefreshData);
      }
  };

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };

  const handleChangePage = async (event, value) => {
      setPage(value);
      setForceRefreshData(!forceRefreshData);
    };
  
  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.planoContaItem}/edicao/${id}`)
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.planoContaItemCadastro}`)
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const header = ['Nome', 'Descrição', 'Plano de Contas', 'Data Inclusão', 'Situação', 'Ações'];

  const rows = chartAccountItemList.map(chartAccountItem => [
      chartAccountItem.name,
      chartAccountItem.description,
      chartAccountItem.chartAccount.name,
      chartAccountItem.createdAt,
      <SwitchStatus
        id={chartAccountItem.id} 
        active={chartAccountItem.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
    />,
    <DataTableDefaultAction
        id={chartAccountItem.id} 
        actions={{
          onEdit: () => handleEdit(chartAccountItem.id),
          onDelete: () => handleDelete(chartAccountItem.id),
          onPreview: () => handlePreview(chartAccountItem.id)
        }} 
    />
  ]);

  return (
      <div className="container-fluid">
        <PageTitle title="Item do Plano de Contas" icon={<AssignmentIcon style={{ color: 'gray' }}/>} />
        <div className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <PlanoContaItemFilter onFilter={handleFilter} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Item" onClick={handleCreate}/>
            </Box>
          </Grid>
        
          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable header={header} 
                          rows={rows} 
                          totalPages={totalPages}
                          page={page} 
                          onPage={handleChangePage} 
                          onRowsPerPage={handleChangeRowsPerPage}/>
            </Box>
            <PreviewModal openDialog={showPreview} 
                          onCloseDialog={handleClosePreview} 
                          width="70%" height="55%">
              <PlanoContaItemPreview id={idPreview}/>
            </PreviewModal>
            </Grid>
        </Grid>
        </div>
      </div>
  );

}