import { Box, Divider, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';
import { TabContext, TabPanel } from "@material-ui/lab";
import Canteiro from "../../components/pages/Canteiro";
import Linha from "../../components/pages/Linha";
import Setor from "../../components/pages/Setor";
import Sitio from "../../components/pages/Sitio";
import PageTitle from "../../components/PageTitle";
import { useQueryState } from "../../hooks/QueryState";

export default function Area() {

  const [currentTab, setCurrentTab] = useQueryState("tab", "canteiro");

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className="container-fluid">
      <PageTitle title="Área" icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
      <Divider />
      <div className="artical-1 out-shadow">
        <TabContext value={currentTab}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label="Canteiros" value="canteiro" />
            <Tab label="Linhas" value="linhas" />
            <Tab label="Setores" value="setores" />
            <Tab label="Sítios" value="sitios" />
          </Tabs>
          <TabPanel value="canteiro">
            <Canteiro />
          </TabPanel>
          <TabPanel value="linhas">
            <Linha />
          </TabPanel>
          <TabPanel value="setores">
            <Setor />
          </TabPanel>
          <TabPanel value="sitios">
            <Sitio />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );

}
