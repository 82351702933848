import React from "react";
import { Box, Button, Tooltip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

export default function ButtonAddIcon(props) {
    const { onClick, className, disabled, size, simple, title } = props;

    return (
        <Box className={simple ? "" : "c-inline-btn-float-right"}>
            <Tooltip title={title}>
                <Button
                    variant="contained"
                    color="primary"
                    size={size}
                    disabled={disabled}
                    className={className}
                    onClick={onClick}
                >
                    <AddIcon />
                </Button>
            </Tooltip>
        </Box>
    )
}
