export const serialize = (data) => {
    return {
        id: data.id,
        country: data.country,
        description: data.description,
        acronym: data.acronym,
        ibge: data.ibge
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        country: data.country,
        description: data.description,
        acronym: data.acronym,
        ibge: data.ibge
    }
}


export default{
    serialize
}