import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import lineApi from "../../../../services/lineApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        sector: {
            id: "",
            title: ""
        }
    }
};

export default function LinePreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function findById() {
            const lineResponse = await lineApi.findById(id);
            if (lineResponse) {
                const lineData = lineResponse.data.response[0];
                setData({
                    id: lineData.id,
                    active: lineData.active,
                    code: lineData.code,
                    sector: {
                        id: lineData.sector.id,
                        title: lineData.sector.code
                    }
                })
            }
        }
        findById();
    }, [])

    return (
        <>
            <Box>
                <PageTitle title="Linha" icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
                <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="code"
                            label="Código da Linha"
                            value={data.code}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="sector"
                            label="Setor"
                            value={data.sector.title}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}