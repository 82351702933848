import { pathRoutes } from "../../config/constants";
import PlanoContaItem from "./index";
import PlanoConta from "./index";

export default [
  {
    path: pathRoutes.planoContaItem,
    component: PlanoContaItem,
    exact: true,
  },
];
