export const serialize = (data) => {
  
  return { 
    firstName: data.nome,
    lastName: data.sobrenome,
    telephone: data.telefone,
    email: data.email,
    password: data.senha,
    passwordAffirm: data.confirmaSenha
  }
};
  
export default {
  serialize,
};