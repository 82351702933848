import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import toast from "../../../utils/toast";
import sectorApi from "../../../services/sectorApi";
import siteApi from "../../../services/siteApi";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";
import ButtonRemove from "../../../components/ButtonRemove";
import { useParams, useHistory } from 'react-router-dom';
import { useLoader } from "../../../hooks/LoaderContext";
import AlertDialog from "../../../components/AlertDialog";
import ButtonSubmit from "../../../components/ButtonSubmit";
import PageTitle from "../../../components/PageTitle";
import BasicReturn from "../../../components/BasicReturn";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        description: "",
        site: {
            id: ""
        }
    },
    sectors: [],
    sites: [],
    siteInput: {
        title: "",
        id: ""
    }
};

const validationSchema = Yup.object().shape({
    code: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().required(REQUIRED_FIELD)
});

export default function SectorCadastro() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [site, setSite] = useState(DEFAULT_STATE.siteInput);
    const [siteList, setSiteList] = useState(DEFAULT_STATE.sites);
    const [sectorList, setSectorList] = useState(DEFAULT_STATE.sectors);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const history = useHistory();
    const params = useParams();
    const { showLoader, hideLoader } = useLoader();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);


    useEffect(() => {
        async function findById() {
            showLoader();
            if (params.ident) {
                const sectorResponse = await sectorApi.findById(params.ident);
                if (sectorResponse) {
                    const sector = sectorResponse.data.response[0];
                    const data = {
                        id: sector.id,
                        code: sector.code,
                        description: sector.description,
                        site: {
                            id: sector.site.id
                        }

                    };
                    setData(data);
                    setSite({
                        title: sector.site.name,
                        id: sector.site.id
                    });
                }
            }
            hideLoader();
        }
        findById();
    }, []);

    useEffect(() => {
        async function getAllSectors() {
            const sectorResponse = await sectorApi.findAllPageable(0, rowsPerPage);
            if (sectorResponse) {
                setSectorList(sectorResponse.data.response);
            }
        }
        getAllSectors();
    }, []);

    useEffect(() => {
        async function getAllSites() {
            const siteResponse = await siteApi.findAll();
            if (siteResponse) {
                setSiteList(siteResponse.data.response);
            }
        }
        getAllSites();
    }, []);

    const handleChangeSite = (value, setFieldValue) => {
        setSite(value);
        setFieldValue("site", value ? value : '');
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let sector;
            if (values.id.length == 0) {
                sector = await sectorApi.post(values);
            } else {
                sector = await sectorApi.put(values);
            }
            if (sector) {
                handleSuccess(values.id.length == 0);
            } else {
                handleError();
            }
            redirectToSector();
        } catch (error) {
            console.error('Erro ao enviar formulário: ', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSuccess = (isNew) => {
        const sucessMessage = isNew ? "Setor cadastrado com sucesso."
            : "Setor atualizado com sucesso.";
        toast.success(sucessMessage);
    }

    const handleError = () => {
        toast.error(`Falha ao criar Setor, favor verifique!.`);
    }

    const redirectToSector = () => {
        history.goBack();
    };

    const handleDelete = async () => {
        const deleted = await sectorApi.delete(params.ident);
        if (deleted) {
            toast.success(`Setor deletado com sucesso.`);
        } else {
            toast.error(`Falha ao excluir setor, favor verifique!.`);
        }
        redirectToSector();
    };

    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseModalDelete = () => {
        setOpenDeleteModal(false);
    };

    const sites = siteList.map(site => ({
        title: site.name,
        id: site.id
    }));

    return (
        <>
        <div className="container-fluid">
            <PageTitle title={!params.ident ? 'Cadastrar Setor' : 'Editar Setor'} icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
            <BasicReturn />
            <Divider />
            <div className="artical-1 out-shadow">
                <Formik
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ isSubmitting, touched, errors, setFieldValue }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="code"
                                        label="Código do Setor"
                                        helperText={(touched.code && errors.code) && <ErrorMessage name="code" />}
                                        error={touched.code && errors.code}>
                                    </Field>
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="description"
                                        label="Descrição do setor"
                                        helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                                        error={touched.description && errors.description}>
                                    </Field>
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                                    <Autocomplete
                                        id="autocomplete-site"
                                        options={sites}
                                        clearText="Limpar"
                                        value={site}
                                        onChange={(event, value) => handleChangeSite(value, setFieldValue)}
                                        getOptionLabel={(option) => option.title}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderInput={(params) =>
                                            <TextField {...params} label="Sítio" variant="outlined" />
                                        }
                                    />
                                </Grid>
                                <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                                        <Box className="mt-1 c-inline-btn">
                                            <Grid container spacing={2}>
                                                {params.ident && (
                                                    <Grid item md={6} sm={6} xs={12}>
                                                        <ButtonRemove
                                                            title="excluir"
                                                            onClick={handleOpenDeleteModal}
                                                            variant="contained"
                                                            color="primary"
                                                            className="btn btn-primary btn-user btn-block"
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                                    <ButtonSubmit
                                                        label="salvar"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className="btn btn-primary btn-user btn-block"
                                                        disable={isSubmitting}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
            <AlertDialog title="Realmente deseja excluir?"
                description="O dado será excluído de forma permanente"
                confirmTitle="Confirmar"
                cancelTitle="Cancelar"
                openDialog={openDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleCloseModalDelete}
            />
        </>
    );

}