import React from 'react';
import NumberFormat from 'react-number-format';

export default function PercentFormatText(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        let { value } = values;
        if (parseFloat(value) > 100) {
          value = '100';
        }
        onChange({
          target: {
            name: props.name,
            value,
          },
        });
      }}
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator="."
    />
  );
}
