import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import toast from "../../../utils/toast";
import DataTable from "../../DataTable";
import siteApi from "../../../services/siteApi";
import ButtonAdd from "../../ButtonAdd";
import { pathRoutes } from "../../../config/constants";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../../hooks/LoaderContext";
import SwitchStatus from "../../SwitchStatus";
import DataTableDefaultAction from "../../DataTableDefaultActions";
import PreviewModal from "../../PreviewModal";
import SitePreview from "./SitePreview";
import SiteFilter from "./SiteFilter";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        name: "",
        description: "",
    },
    sites: []
};

export default function Sitio() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [siteList, setSiteList] = useState(DEFAULT_STATE.sites);
    const [siteIdPreview, setSiteIdPreview] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();
    const [filters, setFilters] = useState();
    const [forceRefreshData, setForceRefreshData] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        showLoader();
        async function getAllSites() {
            const siteResponse = await siteApi.findAllPageable(0, rowsPerPage, filters);
            if (siteResponse) {
                setSiteList(siteResponse.data.response);
                setTotalPages(siteResponse.data.totalPages);
            } else {
                setSiteList(DEFAULT_STATE.sites);
                setTotalPages(0);
            }
            hideLoader();
        }
        getAllSites();
    }, [filters, forceRefreshData, rowsPerPage]);

    const handleFilter = async (filters) => {
        setFilters(filters);
        setForceRefreshData(!forceRefreshData);
    };

    const handleDelete = async (id) => {
        const deleted = await siteApi.delete(id);
        if (deleted) {
            cleanForm();
            setForceRefreshData(!forceRefreshData);
            toast.success(`Site deletado com sucesso.`);
        } else {
            toast.error(`Falha ao excluir site, favor verifique!.`);
        }
    };

    const handlePreview = (id) => {
        setSiteIdPreview(id);
        setShowPreview(true);
    };

    const handleClosePreview = (id) => {
        setShowPreview(false);
    };

    const handleEdit = async (id) => {
        history.push(`${pathRoutes.site}/edicao/${id}`)
    };

    const handleCreate = async () => {
        history.push(`${pathRoutes.site}/cadastro`)
    };

    const handleChangeActive = async (id, status) => {
        const data = {
            id: id,
            active: status
        };
        const site = await siteApi.status(data);
        if (site) {
            if (site.data.response[0].active) {
                toast.success(`Sítio ativado com sucesso.`);
            } else {
                toast.success(`Sítio desativado com sucesso.`);
            }
            setForceRefreshData(!forceRefreshData);
        }
    };

    const handleChangePage = async (event, value) => {
        setPage(value);
        const siteResponse = await siteApi.findAllPageable(value - 1, rowsPerPage);
        if (siteResponse) {
            setSiteList(siteResponse.data.response);
            setTotalPages(siteResponse.data.totalPages);
        }
    };

    const handleChangeRowsPerPage = async (rows) => {
        setRowsPerPage(rows);
        const siteResponse = await siteApi.findAllPageable(0, rows);
        if (siteResponse) {
            setSiteList(siteResponse.data.response);
            setTotalPages(siteResponse.data.totalPages);
        }
    };

    const cleanForm = () => {
        setData(DEFAULT_STATE.data);
    }

    const header = ['Nome', 'Descrição', 'Data Inclusão', 'Situação', 'Ações'];

    const rows = siteList.map(site => [
        site.name,
        site.description,
        site.createdAt,
        <SwitchStatus
            id={site.id}
            active={site.active}
            actions={{
                onSwitch: (id, status) => handleChangeActive(id, status)
            }}
        />,
        <DataTableDefaultAction
            id={site.id}
            actions={{
                onEdit: () => handleEdit(site.id),
                onDelete: () => handleDelete(site.id),
                onPreview: () => handlePreview(site.id)
            }}
        />
    ]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <SiteFilter onFilter={handleFilter} />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Box>
                        <ButtonAdd title="Cadastrar Sítio" onClick={handleCreate} />
                    </Box>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Box position="relative">
                        <DataTable header={header}
                            rows={rows}
                            totalPages={totalPages}
                            page={page}
                            onPage={handleChangePage}
                            onRowsPerPage={handleChangeRowsPerPage} />
                    </Box>
                    <PreviewModal openDialog={showPreview}
                        onCloseDialog={handleClosePreview}
                        width="70%" height="35%">
                        <SitePreview id={siteIdPreview} />
                    </PreviewModal>
                </Grid>
            </Grid>
        </>
    );

}