import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { pathRoutes } from "../../../../../config/constants";
import DataTable from "../../../../DataTable";
import toast from "../../../../../utils/toast";
import ButtonAdd from "../../../../ButtonAdd";
import PreviewModal from "../../../../PreviewModal";
import { useLoader } from "../../../../../hooks/LoaderContext";
import DataTableDefaultAction from "../../../../DataTableDefaultActions";
import taxOperationItemApi from "../../../../../services/taxOperationItemApi";
import OperacaoFiscalPadraoFilter from "./OperacaoFiscalPadraoFilter";
import OperacaoFiscalPadraoPreview from "./OperacaoFiscalPadraoPreview";

const DEFAULT_STATE = {
    isLoading: false,
    taxOperationStandards: []
};

export default function OperacaoFiscalPadrao() {
    const [taxOperationStandardList, setTaxOperationStandardList] = useState(DEFAULT_STATE.taxOperationStandards);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [idPreview, setIdPreview] = useState(0);
    const [showPreview, setShowPreview] = useState(false);
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();
    const [forceRefreshData, setForceRefreshData] = useState(false);
    const [filters, setFilters] = useState();
    const params = useParams();

    useEffect(() => {
        async function getAllTaxOperationStandardList() {
            showLoader();
            const taxOperationItemResponse = await taxOperationItemApi.findAllPageable(page - 1, rowsPerPage,
                `&tax_operation_id=${params.ident}&type=DEFAULT${filters? filters : ''}`);
            if (taxOperationItemResponse) {
                setTaxOperationStandardList(taxOperationItemResponse.data.response);
                setTotalPages(taxOperationItemResponse.data.totalPages);
            } else {
                setTaxOperationStandardList(DEFAULT_STATE.taxOperationStandards);
                setTotalPages(0);
            }
            hideLoader();
        }
        getAllTaxOperationStandardList();
    }, [forceRefreshData, filters])
    const handleDelete = async (id) => {
        const deleted = await taxOperationItemApi.delete(id);
        if (deleted) {
            setForceRefreshData(!forceRefreshData);
            toast.success(`Operação Fiscal padrão deletado com sucesso.`);
        } else {
            toast.error(`Falha ao excluir Operação Fiscal padrão, favor verifique!.`);
        }
    };

    const handleEdit = async (id) => {
        history.push(`${pathRoutes.operacaoFiscalConfigPadraoEdit.replace(":ident?", "")}${id}`)
    };

    const handleChangePage = async (event, value) => {
        setPage(value);
        setForceRefreshData(!forceRefreshData);
    };

    const handlePreview = (id) => {
        setIdPreview(id);
        setShowPreview(true);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    const handleCreate = async () => {
        history.push(`${pathRoutes.operacaoFiscalConfigPadraoCadastro.replace(":itemId?", "")}${params.ident}`)
    };

    const handleChangeRowsPerPage = async (rows) => {
        setRowsPerPage(rows);
        setForceRefreshData(!forceRefreshData);
    };

    const header = ['Tipo de Imposto', 'CST', 'Ações'];

    const rows = taxOperationStandardList.map(taxOperationStandardList => [
        taxOperationStandardList.aliquotPercent?.taxType?.description,
        taxOperationStandardList.aliquotPercent.cst ? `${taxOperationStandardList.aliquotPercent.cst.code} - ${taxOperationStandardList.aliquotPercent.cst.description}`: '-',
        <DataTableDefaultAction
            id={taxOperationStandardList.id}
            actions={{
                onEdit: () => handleEdit(taxOperationStandardList.id),
                onDelete: () => handleDelete(taxOperationStandardList.id),
                onPreview: () => handlePreview(taxOperationStandardList.id)
            }}
        />
    ]);

    const handleFilter = async (filters) => {
        setFilters(filters);
    };

    return (
        <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <OperacaoFiscalPadraoFilter onFilter={handleFilter} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Box>
                    <ButtonAdd title="Cadastrar imposto por Padrao" onClick={handleCreate} />
                </Box>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
                <Box position="relative">
                    <DataTable header={header}
                        rows={rows}
                        totalPages={totalPages}
                        page={page}
                        onPage={handleChangePage}
                        onRowsPerPage={handleChangeRowsPerPage} />
                </Box>
                <PreviewModal openDialog={showPreview}
                    onCloseDialog={handleClosePreview}
                    width="70%" height="55%">
                        <OperacaoFiscalPadraoPreview id={idPreview} />
                </PreviewModal>
            </Grid>
        </Grid>
    );
}