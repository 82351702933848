import React, { useEffect, useState } from "react";
import { Box, Grid, } from "@material-ui/core";
import toast from "../../../utils/toast";
import sectorApi from "../../../services/sectorApi";
import DataTable from "../../DataTable";
import ButtonAdd from "../../ButtonAdd";
import { pathRoutes } from "../../../config/constants";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../../hooks/LoaderContext";
import SwitchStatus from "../../SwitchStatus";
import DataTableDefaultAction from "../../DataTableDefaultActions";
import PreviewModal from "../../PreviewModal";
import SectorFilter from "./SectorFilter";
import SectorPreview from "./SectorPreview";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        description: "",
        site: {
            id: ""
        }
    },
    sectors: [],
    sites: [],
    siteInput: {
        title: "",
        id: ""
    }
};

export default function Setor() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [site, setSite] = useState(DEFAULT_STATE.siteInput);
    const [siteList, setSiteList] = useState(DEFAULT_STATE.sites);
    const [sectorList, setSectorList] = useState(DEFAULT_STATE.sectors);
    const [sectorIdPreview, setSectorIdPreview] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();
    const [filters, setFilters] = useState();
    const [forceRefreshData, setForceRefreshData] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        showLoader();
        async function getAllSectors() {
            const sectorResponse = await sectorApi.findAllPageable(0, rowsPerPage, filters);
            if (sectorResponse) {
                setSectorList(sectorResponse.data.response);
                setTotalPages(sectorResponse.data.totalPages);
            } else {
                setSectorList(DEFAULT_STATE.sectors);
                setTotalPages(0);

            }
            hideLoader();
        }
        getAllSectors();
    }, [filters, forceRefreshData, rowsPerPage]);

    const handleFilter = async (filters) => {
        setFilters(filters);
        setForceRefreshData(!forceRefreshData);
    };

    const handleDelete = async (id) => {
        const deleted = await sectorApi.delete(id);
        if (deleted) {
            cleanForm();
            setForceRefreshData(!forceRefreshData);
            toast.success(`Setor deletado com sucesso.`);
        } else {
            toast.error(`Falha ao excluir setor, favor verifique!.`);
        }
    };

    const handlePreview = (id) => {
        setSectorIdPreview(id);
        setShowPreview(true);
    };

    const handleClosePreview = (id) => {
        setShowPreview(false);
    };

    const handleEdit = async (id) => {
        history.push(`${pathRoutes.sector}/edicao/${id}`)
    };

    const handleCreate = async () => {
        history.push(`${pathRoutes.sector}/cadastro`)
    };

    const handleChangeActive = async (id, status) => {
        const data = {
            id: id,
            active: status
        };
        const sector = await sectorApi.status(data);
        if (sector) {
            if (sector.data.response[0].active) {
                toast.success(`Setor ativado com sucesso.`);
            } else {
                toast.success(`Setor desativado com sucesso.`);
            }
            setForceRefreshData(!forceRefreshData);
        }
    };

    const handleChangePage = async (event, value) => {
        setPage(value);
        const sectorResponse = await sectorApi.findAllPageable(value - 1, rowsPerPage);
        if (sectorResponse) {
            setSectorList(sectorResponse.data.response);
            setTotalPages(sectorResponse.data.totalPages);
        }
    };

    const handleChangeRowsPerPage = async (rows) => {
        setRowsPerPage(rows);
        const sectorResponse = await sectorApi.findAllPageable(0, rows);
        if (sectorResponse) {
            setSectorList(sectorResponse.data.response);
            setTotalPages(sectorResponse.data.totalPages);
        }
    };

    const cleanForm = () => {
        setData(DEFAULT_STATE.data);
        setSite(DEFAULT_STATE.siteInput);
    }

    const header = ['Código', 'Descrição', 'Data Inclusão', 'Sítio', 'Situação', 'Ações'];

    const rows = sectorList.map(sector => [
        sector.code,
        sector.description,
        sector.createdAt,
        sector.site.name,
        <SwitchStatus
            id={sector.id}
            active={sector.active}
            actions={{
                onSwitch: (id, status) => handleChangeActive(id, status)
            }}
        />,
        <DataTableDefaultAction
            id={sector.id}
            actions={{
                onEdit: () => handleEdit(sector.id),
                onDelete: () => handleDelete(sector.id),
                onPreview: () => handlePreview(sector.id)
            }}
        />
    ]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <SectorFilter onFilter={handleFilter} />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Box>
                        <ButtonAdd title="Cadastrar Setor" onClick={handleCreate} />
                    </Box>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Box position="relative">
                        <DataTable header={header}
                            rows={rows}
                            totalPages={totalPages}
                            page={page}
                            onPage={handleChangePage}
                            onRowsPerPage={handleChangeRowsPerPage} />
                    </Box>
                    <PreviewModal openDialog={showPreview}
                        onCloseDialog={handleClosePreview}
                        width="70%" height="35%">
                        <SectorPreview id={sectorIdPreview} />
                    </PreviewModal>
                </Grid>
            </Grid>
        </>
    );

}