import { pathRoutes } from "../../config/constants";
import Insumo from "./index";

export default [
  {
    path: pathRoutes.insumo,
    component: Insumo,
    exact: true,
  },
];
