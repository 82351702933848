import { del, get, patch, post, put } from "../utils/request"
import { serialize } from './mapper/CompanyTaxInformationMapper'

export const create = async (data) => {
    return post("v1/config/company-tax-information", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const update = async (data) => {
    return put("v1/config/company-tax-information", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const status = async (data) => {
    return patch("v1/config/company-tax-information", serialize(data), `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const findAllPageable = async (page, size, filters) => {
    if(filters){
        return get(`v1/config/company-tax-information/all/pageable?page=${page}&size=${size}${filters}`,`${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
    }
    return get(`v1/config/company-tax-information/all/pageable?page=${page}&size=${size}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const findById = async (id) => {
    return get(`v1/config/company-tax-information/${id}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export const remove = async (id) => {
    return del(`v1/config/company-tax-information/${id}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}

export default {
    post : create,
    update,
    status,
    findAllPageable,
    findById,
    delete : remove,
    put : update
}