import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import generoApi from "../../../../services/generoApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: false,
        code: "",
        description: ""
    },
  };
 
export default function GeneroPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const generoReponse = await generoApi.findById(id);
        if (generoReponse) {
          const data = {
              id : generoReponse.data.response[0].id,
              code : generoReponse.data.response[0].code,
              description : generoReponse.data.response[0].description
          };
          setData(data);
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Genero" icon={<AssignmentIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={2} sm={2} xs={8}>
                    <TextFieldPreview
                        name="code"
                        label="Código"
                        value={data.code}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={10} sm={10} xs={14}>
                    <TextFieldPreview
                        name="description"
                        label="Nome"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}