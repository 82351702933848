import { pathRoutes } from "../../config/constants";
import FluxoCaixa from "./index";

export default [
  {
    path: pathRoutes.fluxoCaixa,
    component: FluxoCaixa,
    exact: true,
  },
];
