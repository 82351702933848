import { Box } from "@material-ui/core";

export default function PageTitle({ title, icon }) {
    return (
      <div style={{ display: 'flex'}}>
        <Box style={{ marginTop: '5px', marginRight: '10px' }}>
          {icon}
        </Box>
        <h1 className="h3 mb-2 text-gray-800">{title}</h1>
      </div>
    );
  }