import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import ButtonSearch from "../../../../../ButtonSearch";
import BoxFilter from "../../../../../BoxFilter";
import { useQueryState, useSetQueryStateValues } from "../../../../../../hooks/QueryState";
import supplierApi from "../../../../../../services/supplierApi";

const DEFAULT_STATE = {
    isLoading: false,
    suppliers: [],
    supplierInput: {
        title: "",
        id: ""
    }
};

export default function ItemPrecificacaoClienteFilter(props) {

    const { onFilter}  =  props;

    const [setValues] = useSetQueryStateValues();

    const [supplierId, setSupplierId] = useQueryState("customer", "");

    const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierInput);
    const [supplierList, setSupplierList] = useState(DEFAULT_STATE.suppliers);

    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
      }, [])

    useEffect(() => {
        async function getAllSuppliers() {
            const supplierResponse = await supplierApi.findAll("?category=CUSTOMER");
            if (supplierResponse) {
                setSupplierList(supplierResponse.data.response);
            }
        }
        getAllSuppliers();
    },[]);

    const suppliers = supplierList.map(supplier => ({
        title: `${supplier.name} - ${supplier.document}`,
        id: supplier.id
    }));

    const handleFilter = async e => {
        let param = '';
        if (supplierId){
            param = param.concat(`&supplier_id=${supplierId}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (supplierList.length > 0 && supplierId) {
                const foundItem = supplierList.find(supplier => supplier.id == supplierId);
                if (foundItem) {
                    setSupplier({title: `${foundItem.name} - ${foundItem.document}`, id: foundItem.id});
                }
            }
        }
        findAutocompleteQueryFilters();
    }, [supplierList]);

    const cleanFilter = async e => {
        onFilter();
        setValues({customer: ""});
        setSupplier(DEFAULT_STATE.supplierInput);
        setIsFiltering(false);
    };

    const handleChangeSupplier = (value) => {
        setSupplier(value);
        setSupplierId(value.id);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mt-2 mb-1" item md={12} sm={12} xs={12}>
                    <Autocomplete
                        id="autocomplete-supplier-filter"
                        options={suppliers}
                        clearText="Limpar"
                        value={supplier}
                        onChange={(event, value) => handleChangeSupplier(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Cliente" variant="outlined" />
                        }
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}