import { pathRoutes } from "../../../config/constants";
import SectorCadastro from "./index";

export default [
  {
    path: pathRoutes.sectorCadastro,
    component: SectorCadastro,
    exact: true,
  },
  {
    path: pathRoutes.sectorEdit,
    component: SectorCadastro,
    exact: true,
  },
];