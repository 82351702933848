import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import ButtonSearch from "../../../../../ButtonSearch";
import BoxFilter from "../../../../../BoxFilter";
import aliquotaApi from "../../../../../../services/aliquotaApi";
import { useQueryState, useSetQueryStateValues } from "../../../../../../hooks/QueryState";
import cstApi from "../../../../../../services/cstApi";
import taxTypeApi from "../../../../../../services/taxTypeApi";

const DEFAULT_STATE = {
    isLoading: false,
    taxtTypes: [],
    taxTypeInput: {
        title: "",
        id: ""
    },
    csts: [],
    cstInput: {
        title: "",
        id: ""
    }
};

export default function OperacaoFiscalPadraoFilter(props) {

    const { onFilter } = props;

    const [setValues] = useSetQueryStateValues();

    const [taxTypeId, setTaxTypeId] = useQueryState("taxTypeId", "");
    const [cstId, setCstId] = useQueryState("cstId", "");

    const [taxType, setTaxType] = useState(DEFAULT_STATE.taxTypeInput);
    const [taxTypeList, setTaxTypeList] = useState(DEFAULT_STATE.taxtTypes);

    const [cst, setCst] = useState(DEFAULT_STATE.cstInput);
    const [cstList, setCstList] = useState(DEFAULT_STATE.csts);

    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
    }, [])

    useEffect(() => {
        async function getTaxTypes() {
          const taxTypeResponse = await taxTypeApi.findAll();
          if (taxTypeResponse) {
            setTaxTypeList(taxTypeResponse.data.response);
          }
        }
        getTaxTypes();
      }, []);

    useEffect(() => {
        async function getAllCsts() {
            if (taxType.id) {
                const cstResponse = await cstApi.findAll(taxType.id);
                if (cstResponse) {
                    setCstList(cstResponse.data.response);
                } else {
                    setCstList(DEFAULT_STATE.csts);
                }
            }
        }
        getAllCsts();
    }, [taxType]);

    const taxTypes = taxTypeList.map(taxType => ({
        title: taxType.description,
        id: taxType.id
    }));

    const csts = cstList.map(cst => ({
        title: `${cst.code} - ${cst.description}`,
        id: cst.id
    }));

    const handleFilter = async e => {
        let param = '';
        if (taxTypeId) {
            param = param.concat(`&taxTypeId=${taxTypeId}`);
        }
        if (cstId) {
            param = param.concat(`&cstId=${cstId}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (taxTypeList.length > 0 && taxTypeId) {
                const foundItem = taxTypes.find(taxType => taxType.id == taxTypeId);
                if (foundItem) {
                    setTaxType({ title: `${foundItem.code} - ${foundItem.description}`, id: foundItem.id });
                }
            }
        }
        findAutocompleteQueryFilters();
    }, [taxTypeList]);

    const cleanFilter = async e => {
        onFilter();
        setValues({ taxTypeId: "", cstId: "" });
        setTaxType(DEFAULT_STATE.taxTypeInput);
        setCst(DEFAULT_STATE.cstInput);
        setIsFiltering(false);
    };

    const handleChangeTaxType = (value) => {
        setTaxType(value);
        setTaxTypeId(value.id);
    };

    const handleChangeCst = (value) => {
        setCst(value);
        setCstId(value.id);
    }


    return (
        <BoxFilter>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                        <Autocomplete
                            id="autocomplete-tax-type"
                            options={taxTypes}
                            clearText="Limpar"
                            value={taxType}
                            onChange={(event, value) => handleChangeTaxType(value ? value : '')}
                            getOptionLabel={(option) => option.title}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField {...params} label="Tipo de Imposto" variant="outlined" />
                            }
                        />

                    </Grid>
                    <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                        <Autocomplete
                            id="autocomplete-cst"
                            options={csts}
                            clearText="Limpar"
                            disabled={!taxType.id}
                            value={cst}
                            onChange={(event, value) => handleChangeCst(value ? value : '')}
                            getOptionLabel={(option) => option.title}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField {...params} label="Cst" variant="outlined" />
                            }
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box className="mt-1 c-inline-btn">
                            <ButtonSearch
                                label="Filtrar"
                                type="submit"
                                variant="outlined"
                                color="primary"
                            />
                        </Box>
                        <Box className="mt-1 c-inline-btn">
                            {isFiltering && (
                                <Button
                                    color="primary"
                                    onClick={cleanFilter}>
                                    Limpar Filtros
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </BoxFilter>
    );
}