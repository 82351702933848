import { Box, Grid, TextField, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useParams } from 'react-router-dom'
import itemConfigInvoiceApi from "../../../../../services/ItemConfigInvoiceApi";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { REQUIRED_FIELD } from "../../../../../config/constants";
import ButtonSubmit from "../../../../ButtonSubmit";
import toast from "../../../../../utils/toast";
import { useLoader } from "../../../../../hooks/LoaderContext";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    itemId:"",
    cbenef: "",
    scaleInd: "",
    cnpjFab: "",
    barcode: "",
    exTipi: ""
  },
};

const validationSchema = Yup.object().shape({
  barcode: Yup.string().required(REQUIRED_FIELD),
});

export default function ItemConfigInvoice() {

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(DEFAULT_STATE.data);
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const params = useParams();

  useEffect(() => {
    handleEdit(params.ident);
  }, []);

  const handleEdit = async (itemId) => {
    if (!isNaN(itemId)) {
      showLoader();
      const itemConfigInvoice = await itemConfigInvoiceApi.findAllPageable(page-1 , rowsPerPage, `item_id=${itemId}`);
      if (itemConfigInvoice) {
        const data = {
          id: itemConfigInvoice.data.response[0].id,
          itemId: itemConfigInvoice.data.response[0].itemId,
          cbenef: itemConfigInvoice.data.response[0].cbenef,
          scaleInd: itemConfigInvoice.data.response[0].scaleInd,
          cnpjFab: itemConfigInvoice.data.response[0].cnpjFab,
          barcode: itemConfigInvoice.data.response[0].barcode,
          exTipi: itemConfigInvoice.data.response[0].exTipi
        };
        setData(data);
      }
      hideLoader();
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
      try {
        let itemConfigInvoice;
        values = {
          ...values,
          itemId : params.ident
        }
        if (values.id.length == 0) {
          itemConfigInvoice = await itemConfigInvoiceApi.post(values);
          if (itemConfigInvoice) {
            toast.success(`Configuração fiscal do item cadastrada com sucesso para o item.`);
          }
        } else {
          itemConfigInvoice = await itemConfigInvoiceApi.put(values);
          if (itemConfigInvoice) {
            toast.success(`Configuração fiscal do item atualizada com sucesso.`);
          }
        }
        setForceRefreshData(!forceRefreshData);
      } catch (error) {
        toast.error(`Falha ao criar Configuração fiscal do item, favor verifique!.`);
      } finally {
        setSubmitting(false);
      }
  };
 
  const typeYesNo = [
    {
      value: '',
      label: '',
    },
    {
      value: 'S',
      label: 'Sim',
    },
    {
      value: 'N',
      label: 'Não',
    }
  ];

  return (
      <div className="container-fluid">
        <div className="artical-1 out-shadow">
        <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors }) => (
              <Form className='mt-3'>
                    <Grid container spacing={2}>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="cbenef"
                            label="Código de Benefício Fiscal"
                            helperText={touched.cbenef && errors.cbenef ? <ErrorMessage name="cbenef" /> : ""}
                            error={touched.cbenef && Boolean(errors.cbenef)}
                          />
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field as={TextField}
                            variant="outlined"
                            fullWidth
                            select
                            name="scaleInd"
                            label="Indicador de Escala Relevante"
                            helperText={touched.scaleInd && errors.scaleInd ? <ErrorMessage name="scaleInd" /> : ""}
                            error={touched.scaleInd && Boolean(errors.scaleInd)}>
                            {typeYesNo.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            )
                            )}
                          </Field>
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="cnpjFab"
                            label="CNPJ do Fabricante da Mercadoria"
                            helperText={touched.cnpjFab && errors.cnpjFab ? <ErrorMessage name="cnpjFab" /> : ""}
                            error={touched.cnpjFab && Boolean(errors.cnpjFab)}
                          />
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="barcode"
                            label="Código de barras"
                            helperText={(touched.barcode && errors.barcode) && <ErrorMessage name="barcode" />}
                            error={touched.barcode && errors.barcode}
                          />
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="exTipi"
                            label="Código EX da TIPI"
                            helperText={touched.exTipi && errors.exTipi ? <ErrorMessage name="exTipi" /> : ""}
                            error={touched.exTipi && Boolean(errors.exTipi)}
                          />
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                          <Box display="flex" justifyContent="flex-end" alignItems="center">
                              <Box className="mt-1 c-inline-btn">
                                    <Grid container spacing={2}>
                                      <Grid item md={params.ident ? 6: 12} sm={params.ident ? 6: 12} xs={12}>
                                          <ButtonSubmit
                                              label="salvar"
                                              type="submit"
                                              variant="contained"
                                              color="primary"
                                              className="btn btn-primary btn-user btn-block"
                                              disable={isSubmitting}
                                          />
                                      </Grid>
                                  </Grid>
                              </Box>
                          </Box>
                      </Grid>
                    </Grid>
                </Form>
            )}
            </Formik>
        </div>
      </div>
  );
}