import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import toast from "../../utils/toast";
import DataTable from "../../components/DataTable"
import ButtonAdd from "../../components/ButtonAdd";
import PageTitle from "../../components/PageTitle";
import { pathRoutes, translateOrderStatus } from "../../config/constants";
import { useHistory } from 'react-router-dom';
import CostCenterFilter from "../../components/pages/costCenter/CostCenterFilter";
import { useLoader } from "../../hooks/LoaderContext";
import orderApi from "../../services/orderApi";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { formatMoney } from "../../utils/moneyUtils";
import OrderActions from "../../components/OrderActions";

const DEFAULT_STATE = {
    isLoading: false,
    orders: [],
    suppliers: [],
    supplierInput: {
        title: "",
        id: ""
    },
    taxOperations: [],
    taxOperationInput: {
        title: "",
        id: ""
    }
};

export default function Pedido() {

  const [orderList, setOrderList] = useState(DEFAULT_STATE.orders);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [filters, setFilters] = useState('');
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
      async function getAllOrders() {
          showLoader();
          const orderResponse = await orderApi.findAllPageable(page-1, rowsPerPage, filters);
          if (orderResponse) {
            console.log({orderResponse});
            setOrderList(orderResponse.data.response);
            setTotalPages(orderResponse.data.totalPages);
          } else {
            setOrderList(DEFAULT_STATE.orders);
            setTotalPages(0);
          }
          hideLoader();
      }
      getAllOrders();
  },[filters, forceRefreshData]);

  const handleDelete = async (id) => {
      const deleted = await orderApi.delete(id);
      if (deleted) {
        if (orderList.length == 1) {
          setPage(page - 1);
        }
        setForceRefreshData(!forceRefreshData);
        toast.success(`Pedido deletado com sucesso.`);
      } else {
        toast.error(`Falha ao excluir pedido, favor verifique!.`);
      }
  };

  const handleApprove = async (id) => {
    const order = await orderApi.approve(id);
      if (order) {
        if (order) {
          toast.success(`Pedido aprovado sucesso.`);
        } else {
          toast.success(`Erro ao aprovar pedido.`);
        }
        setForceRefreshData(!forceRefreshData);
      }
  };

  const handleCancelOrder = async (justify, id) => {
    const order = await orderApi.cancel(id, justify);
    if (order) {
      if (order) {
        toast.success(`Pedido cancelado sucesso.`);
      } else {
        toast.success(`Erro ao cancelar pedido.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleRejectOrder = async (justify, id) => {
    const order = await orderApi.reject(id, justify);
    if (order) {
      if (order) {
        toast.success(`Pedido rejeitado com sucesso.`);
      } else {
        toast.success(`Erro ao rejeitar pedido.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleResendOrder = async (id) => {
    const order = await orderApi.resend(id);
    if (order) {
      if (order) {
        toast.success(`Pedido reenviado sucesso.`);
      } else {
        toast.success(`Erro ao reenviar pedido.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };
  
  const handleChangePage = async (event, value) => {
      setPage(value);
      setForceRefreshData(!forceRefreshData);
  };
  
  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.pedidoEdit.replace(":ident?", "")}${id}`)
  };

  
  const handleCreate = async (id) => {
    history.push(`${pathRoutes.pedidoCadastro}`)
  };

  const handleTimeline = async (id) => {
      history.push(`${pathRoutes.pedidoTimeline.replace(":ident?", "")}${id}`)
  };

  const header = ['Identificador', 'Número', 'Cliente', 'Data do Pedido', 'Valor total','Operação', 'Status', 'Ações'];

  const rows = orderList.map(order => [
    order.uuid,
    `#${order.number}`,
    order.customer?.name,
    order.orderDate,
    formatMoney(order.valueTotal),
    order.taxOperation.description,
    translateOrderStatus(order.status),
    <OrderActions
        id={order.id}
        status={order.status}
        number={order.number}
        actions={{
          onEdit: () => handleEdit(order.id),
          onDelete: () => handleDelete(order.id),
          onTimeline: () => handleTimeline(order.id),
          onApproval: () => handleApprove(order.id),
          onReject: (e) => handleRejectOrder(e, order.id),
          onCancelOrder: (e) => handleCancelOrder(e, order.id),
          onResend: () => handleResendOrder(order.id)
        }} 
    />
  ]);

  return (
      <div className="container-fluid">
        <PageTitle title="Consulta de Pedidos" icon={<AddShoppingCartIcon style={{ color: 'gray' }}/>} />
        <div className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <CostCenterFilter onFilter={handleFilter} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Gerar Pedido" onClick={handleCreate}/>
            </Box>
          </Grid>
        
          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable 
                    header={header} 
                    rows={rows}
                    totalPages={totalPages}
                    page={page} 
                    onPage={handleChangePage} 
                    onRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>
        </Grid>
        </div>
      </div>
  );

}