import { Box, Divider, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EcoIcon from '@material-ui/icons/Eco';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import { cashFlowInputStatus, cashFlowOutputStatus, cashFlowPaymentCondition, cashFlowPaymentMethod, cashFlowType } from "../../../config/constants";
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import AlertDialog from "../../../components/AlertDialog";
import ButtonRemove from "../../../components/ButtonRemove";
import cashFlowApi from "../../../services/cashFlowApi";
import costCenterApi from "../../../services/costCenterApi";
import chartAccountItemApi from "../../../services/chartAccountItemApi";
import financialSupplierApi from "../../../services/financialSupplierApi";
import { Autocomplete } from "@material-ui/lab";
import NumberFormatText from "../../../components/NumberFormatText";
import { useLoader } from "../../../hooks/LoaderContext";
import DatePickerWrapper from "../../../components/FormattedDatePicker";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    type: "",
    status: "",
    description: "",
    value: 0,
    occurrenceDate: "",
    paymentMethod: "",
    paymentCondition: "",
    numberInstallment: 1,
    createdAt: "",
    chartAccountItem: {
      id: ""
    },
    costCenter: {
      id: ""
    },
    supplier: {
      id: ""
    }
  },
  chartAccountItems: [],
  chartAccountItemInput: {
    title: "",
    id: ""
  },
  costsCenters: [],
  costsCenterInput: {
    title: "",
    id: ""
  },
  financialSuppliers: [],
  financialSuppliersInput: {
    title: "",
    id: ""
  }
};

const validationSchema = Yup.object().shape({
  type: Yup.string().required(REQUIRED_FIELD),
  status: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD),
  paymentMethod: Yup.string().required(REQUIRED_FIELD),
  paymentCondition: Yup.string().required(REQUIRED_FIELD),
  occurrenceDate: Yup.date().required(REQUIRED_FIELD),
});

export default function FluxoCaixaLancamento() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [costCenterList, setCostCenterList] = useState(DEFAULT_STATE.costsCenters);
  const [costCenter, setCostCenter] = useState(DEFAULT_STATE.costsCenterInput);

  const [financialSupplierList, setFinancialSupplierList] = useState(DEFAULT_STATE.financialSuppliers);
  const [financialSupplier, setFinancialSupplier] = useState(DEFAULT_STATE.financialSuppliersInput);

  const [chartAccountItemList, setChartAccountItemList] = useState(DEFAULT_STATE.chartAccountItems);
  const [chartAccountItem, setChartAccountItem] = useState(DEFAULT_STATE.chartAccountItemInput);
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    async function findById() {
      if (!isNaN(params.ident)) {
        showLoader();
        const cashFlowResponse = await cashFlowApi.findById(params.ident);
        if (cashFlowResponse) {
          const data = {
            id: cashFlowResponse.data.response[0].id,
            description: cashFlowResponse.data.response[0].description,
            type: cashFlowResponse.data.response[0].type,
            status: cashFlowResponse.data.response[0].status,
            value: cashFlowResponse.data.response[0].value,
            occurrenceDate: cashFlowResponse.data.response[0].occurrenceDate,
            paymentMethod: cashFlowResponse.data.response[0].paymentMethod,
            paymentCondition: cashFlowResponse.data.response[0].paymentCondition,
            numberInstallment: cashFlowResponse.data.response[0].numberInstallment ?? cashFlowResponse.data.response[0].numberInstallment,
            chartAccountItem: {
              id: cashFlowResponse.data.response[0].chartAccountItem.id
            },
            costCenter: {
              id: cashFlowResponse.data.response[0].costCenter.id
            },
            supplier: {
              id: cashFlowResponse.data.response[0].supplier.id
            }
          };
          setData(data);
          setChartAccountItem({
            title: cashFlowResponse.data.response[0].chartAccountItem.name,
            id: cashFlowResponse.data.response[0].chartAccountItem.id
          });
          setCostCenter({
            title: cashFlowResponse.data.response[0].costCenter.name,
            id: cashFlowResponse.data.response[0].costCenter.id
          });
          setFinancialSupplier({
            title: cashFlowResponse.data.response[0].supplier.name,
            id: cashFlowResponse.data.response[0].supplier.id
          });
        }
        hideLoader();
      }
    }
    findById();
  }, []);

  useEffect(() => {
    async function getAllCostsCenter() {
      const costCenterResponse = await costCenterApi.findAll();
      if (costCenterResponse) {
        setCostCenterList(costCenterResponse.data.response);
      }
    }
    getAllCostsCenter();
  }, []);

  useEffect(() => {
    async function getAllChartAccountItems() {
      const chartAccountItemResponse = await chartAccountItemApi.findAll();
      if (chartAccountItemResponse) {
        setChartAccountItemList(chartAccountItemResponse.data.response);
      }
    }
    getAllChartAccountItems();
  }, []);

  useEffect(() => {
    async function getAllFinancialSupplier() {
      const financialSupplierResponse = await financialSupplierApi.findAll();
      if (financialSupplierResponse) {
        setFinancialSupplierList(financialSupplierResponse.data.response);
      }
    }
    getAllFinancialSupplier();
  }, []);

  const costsCenter = costCenterList.map(costCenter => ({
    title: costCenter.name,
    id: costCenter.id
  }));

  const chartAccountItems = chartAccountItemList.map(chartAccountItem => ({
    title: chartAccountItem.name,
    id: chartAccountItem.id
  }));

  const financialSuppliers = financialSupplierList.map(supplier => ({
    title: supplier.name,
    id: supplier.id
  }));

  const handleDelete = async () => {
    const deleted = await cashFlowApi.delete(params.ident);
    if (deleted) {
      toast.success(`Lançamento deletado com sucesso.`);
      redirectTo();
    } else {
      toast.error(`Falha ao excluir lançamento, favor verifique!.`);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const handleChangeCostCenter = (value, setFieldValue) => {
    setCostCenter(value)
    setFieldValue("costCenter", value ? value : "");
  };

  const handleChangeChartAccountItem = (value, setFieldValue) => {
    setChartAccountItem(value)
    setFieldValue("chartAccountItem", value ? value : "");
  };

  const handleChangeFinancialSupplier = (value, setFieldValue) => {
    setFinancialSupplier(value)
    setFieldValue("supplier", value ? value : "");
  };

  const redirectTo = () => {
    history.goBack();
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let cashFlow;
      if (values.id.length == 0) {
        cashFlow = await cashFlowApi.post(values);
      } else {
        cashFlow = await cashFlowApi.put(values);
      }
      if (cashFlow) {
        handleSuccess(values.id.length == 0);
        redirectTo();
      } else {
        handleError();
      }
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Entrada no fluxo de caixa realizado com sucesso."
      : "Entrada no fluxo de caixa realizado com sucesso.";
    toast.success(sucessMessage);
  }

  const handleError = () => {
    toast.error(`Falha ao criar entrada no fluxo de caixa, favor verifique!.`);
  }

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Criar lançamento' : 'Editar lançamento'} icon={<EcoIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors, setFieldValue, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid className="mt-3 mb-2" item md={3} sm={3} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      select
                      name="type"
                      label="Tipo do Lançamento"
                      helperText={
                        (touched.type && errors.type)
                          ? <ErrorMessage name="type" />
                          : "Selecione o tipo do lançamento"
                      }
                      error={touched.type && errors.type}>
                      {cashFlowType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={3} sm={3} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      select
                      name="status"
                      label="Situação do lançamento"
                      helperText={
                        (touched.status && errors.status)
                          ? <ErrorMessage name="status" />
                          : "Selecione a situação do lançamento"
                      }
                      error={touched.status && errors.status}>
                      {values.type === 'INPUT' ? (
                        cashFlowInputStatus.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      ) : (
                        cashFlowOutputStatus.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      )}
                    </Field>
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                      id="autocomplete-chartaccount-item"
                      options={chartAccountItems}
                      clearText="Limpar"
                      value={chartAccountItem}
                      onChange={(event, value) => handleChangeChartAccountItem(value, setFieldValue)}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) =>
                        <TextField {...params} label="Item" variant="outlined" />
                      }
                    />
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="description"
                      label={
                        values.type === 'INPUT' ? 'Observação do recebimento' : 'Observação da despesa'
                      }
                      helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                      error={touched.description && errors.description}>
                    </Field>
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={3} sm={3} xs={12}>
                    <Autocomplete
                      id="autocomplete-financial-supplier"
                      options={financialSuppliers}
                      clearText="Limpar"
                      value={financialSupplier}
                      onChange={(event, value) => handleChangeFinancialSupplier(value, setFieldValue)}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) =>
                        <TextField {...params} label="Fornecedor" variant="outlined" />
                      }
                    />
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={2} sm={2} xs={12}>
                    <Field
                      as={TextField}
                      id="formatted-numberformat-input"
                      variant="outlined"
                      fullWidth
                      name="value"
                      label="Valor"
                      disabled={values.id}
                      InputProps={{
                        inputComponent: NumberFormatText,
                      }}>
                    </Field>
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={3} sm={3} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      select
                      name="paymentMethod"
                      label={
                        values.type === 'INPUT' ? 'Forma de recebimento' : 'Forma de pagamento'
                      }
                      helperText={(touched.paymentMethod && errors.paymentMethod) && <ErrorMessage name="paymentMethod" />}
                      error={touched.paymentMethod && errors.paymentMethod}>
                      {cashFlowPaymentMethod.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                      )}
                    </Field>
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      select
                      name="paymentCondition"
                      label="Condição de pagamento"
                      helperText={
                        (touched.paymentCondition && errors.paymentCondition)
                          ? <ErrorMessage name="paymentCondition" />
                          : "Selecione a forma de pagamento"
                      }
                      error={touched.paymentCondition && errors.paymentCondition}>
                      {cashFlowPaymentCondition.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                      )}
                    </Field>
                  </Grid>
                  {values.paymentCondition == "INSTALLMENTS" && (
                    <Grid className="mt-3 mb-2" item md={1} sm={1} xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        name="numberInstallment"
                        label="Nro Parcelas"
                        helperText="Digite a quantidade 1-60">
                      </Field>
                    </Grid>
                  )}
                  <Grid className=" mb-2" item md={3} sm={3} xs={12}>
                      <DatePickerWrapper
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-data-final"
                        value={values.occurrenceDate}
                        onChange={(date) => setFieldValue('occurrenceDate', date)}
                        label="Data de vencimento"
                        style={{ width: "100%" }}
                        helperText={(touched.occurrenceDate && errors.occurrenceDate) && <ErrorMessage name="occurrenceDate" />}
                        error={touched.occurrenceDate && errors.occurrenceDate} />
                  </Grid>
                   <Grid className="mt-3 mb-1" item md={values.paymentCondition == "INSTALLMENTS" ? 4 : 5} sm={values.paymentCondition == "INSTALLMENTS" ? 4 : 5} xs={12}>
                    <Autocomplete
                      id="autocomplete-costcenter"
                      options={costsCenter}
                      clearText="Limpar"
                      value={costCenter}
                      onChange={(event, value) => handleChangeCostCenter(value, setFieldValue)}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) =>
                        <TextField {...params} label="Centro de Custos" variant="outlined" />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <Box className="mt-1 c-inline-btn">
                      <Grid container spacing={2}>
                        {params.ident && (
                          <Grid item md={6} sm={6} xs={12}>
                            <ButtonRemove
                              title="excluir"
                              onClick={handleOpenDeleteModal}
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                            />
                          </Grid>
                        )}
                        <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                          <ButtonSubmit
                            label="salvar"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="btn btn-primary btn-user btn-block"
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}