import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import lineApi from "../../../../services/lineApi";
import { Autocomplete } from "@material-ui/lab";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        code: "",
        line: {
            id: ""
        },
    },
    lines: [],
    lineInput: {
        title: "",
        id: ""
    },
};

export default function CanteiroFilter(props) {

    const { onFilter } = props;

    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();
    const [code, setCode] = useQueryState("codeSpot", '');
    const [lineId, setLineId] = useQueryState("lineId", '');
    const [status, setStatus] = useQueryState("status", '');

    const [lineList, setLineList] = useState(DEFAULT_STATE.lines);
    const [line, setLine] = useState(DEFAULT_STATE.lineInput);

    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllLine() {
            const lineResponse = await lineApi.findAll();
            if (lineResponse) {
                setLineList(lineResponse.data.response);
            }
        }
        getAllLine();
    }, []);

    const handleFilter = async e => {
        if (e) {
            e.preventDefault();
        }
        let param = '';
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (lineId) {
            param = param.concat('&lineId=').concat(lineId);
        }
        if (status) {
            param = param.concat('&status=').concat(status);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const lines = lineList.map(line => ({
        title: line.code,
        id: line.id
    }));

    const cleanFilter = e => {
        onFilter();
        setIsFiltering(false);
        setLine(DEFAULT_STATE.lineInput);
        setValues({ lineId: "", codeSpot: "", status: "" });
    };

    const handleInputCode = ({ target: { value } }) => {
        setCode(value);
    };

    const handleChangeLine = (value) => {
        setLine(value);
        setLineId(value.id);
    };

    const handleInputChangeStatus = ({ target: { value } }) => {
        setStatus(value);
    };

    const canteiroCodes = [
        {
            value: 'A',
            label: 'A',
        },
        {
            value: 'B',
            label: 'B',
        },
        {
            value: 'C',
            label: 'C',
        },
    ];

    const statusCodes = [
        {
            value: 'true',
            label: 'Ativo',
        },
        {
            value: 'false',
            label: 'Inativo',
        }
    ];

    return (
        <BoxFilter>
            <form onSubmit={handleFilter}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="code"
                                select
                                label="Canteiro"
                                value={code}
                                onChange={handleInputCode}
                                helperText="Selecione o canteiro">
                                {canteiroCodes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                            <Autocomplete
                                id="autocomplete-line"
                                options={lines}
                                clearText="Limpar"
                                value={line}
                                onChange={(event, value) => handleChangeLine(value ? value : DEFAULT_STATE.lineInput)}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) =>
                                    <TextField {...params} label="Linha" variant="outlined" />
                                }
                            />
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="ACTIVE"
                                label="Status"
                                value={status}
                                select
                                onChange={handleInputChangeStatus}
                                helperText="Selecione o status">
                                {statusCodes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className="mt-1 c-inline-btn">
                                <ButtonSearch
                                    label="Filtrar"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                />
                            </Box>
                            <Box className="mt-1 c-inline-btn">
                                {isFiltering && (
                                    <Button
                                        color="primary"
                                        onClick={cleanFilter}>
                                        Limpar Filtros
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </BoxFilter>
    );
}
