import { pathRoutes } from "../../../../config/constants";
import OperacaoFiscalItemCadastro from "./index";

export default [
  {
    path: pathRoutes.operacaoFiscalConfigItemCadastro,
    component: OperacaoFiscalItemCadastro,
    exact: true,
  },

  {
    path: pathRoutes.operacaoFiscalConfigItemEdit,
    component: OperacaoFiscalItemCadastro,
    exact: true,
  },
];
