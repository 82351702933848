import { serialize } from "./mapper/AlterarSenhaMapper";
import { put } from "../utils/request";

export const alterarSenha = async (data) => {
  return put("v1/user/password/reset", serialize(data));
};

export default {
  put: alterarSenha
};
