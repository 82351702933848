import { pathRoutes } from "../../../config/constants";
import CategoryConfig from "./index";

export default [
  {
    path: pathRoutes.categoryConfig,
    component: CategoryConfig,
    exact: true,
  },
];
