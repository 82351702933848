import { Box, Button, Typography } from "@material-ui/core"
import React from "react";

export default function BoxNamed(props) {
    const { title, children } = props;

    return (
        <Box position="relative" display="inline-block" width="100%">
            <Typography
                variant="caption"
                component="div"
                style={{
                  position: 'absolute',
                  top: 5, 
                  left: 10,
                  backgroundColor: '#fff',
                  padding: '0 4px', 
                  color: 'gray',
                }}
              >
              {title}
              </Typography>      
            <Box border={1} borderRadius="borderRadius" borderColor="#DDDDDD" p={2} mt={2}>
              {children}
              </Box>
        </Box>
    )
}