import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import generoApi from "../../../../services/generoApi";
import ncmApi from "../../../../services/ncmApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: false,
        code: "",
        description: "",
        genero: ""
    },
  };
 
export default function NcmPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const ncmReponse = await ncmApi.findById(id);
        if (ncmReponse) {
          const data = {
              id : ncmReponse.data.response[0].id,
              code : ncmReponse.data.response[0].code,
              description : ncmReponse.data.response[0].description,
              genero: `${ncmReponse.data.response[0].genero.code} - ${ncmReponse.data.response[0].genero.description}`
          };
          setData(data);
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="NCM" icon={<AssignmentIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={10}>
                    <TextFieldPreview
                        name="code"
                        label="Código"
                        value={data.code}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={8} sm={8} xs={14}>
                    <TextFieldPreview
                        name="description"
                        label="Nome"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={12} sm={12} xs={14}>
                    <TextFieldPreview
                        name="genero"
                        label="Genero"
                        value={data.genero}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}