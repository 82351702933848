import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import TerrainRoundedIcon from '@material-ui/icons/TerrainRounded';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import siteApi from "../../../../services/siteApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        name: "",
        description: "",
    }
};

export default function SitePreview(props) {

    const { id } = props;
    const [data, setData] = useState(DEFAULT_STATE.data);

    useEffect(() => {
        async function findById() {
            const siteResponse = await siteApi.findById(id);
            if (siteResponse) {
                const siteData = siteResponse.data.response[0];
                setData({
                    id: siteData.id,
                    active: siteData.active,
                    name: siteData.name,
                    description: siteData.description
                })
            }
        }
        findById();
    }, [])

    return (
        <>
            <Box>
                <PageTitle title="Sítio" icon={<TerrainRoundedIcon style={{ color: 'gray' }} />} />
                <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="name"
                            label="Nome do Sítio"
                            value={data.name}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                        <TextFieldPreview
                            name="description"
                            label="Descrição do Sítio"
                            value={data.description}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}