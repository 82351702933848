import BusinessIcon from '@material-ui/icons/Business';
import React, { useEffect, useState } from "react";
import { Box, Divider, Button, Grid, TextField, MenuItem } from "@material-ui/core";
import empresaApi from "../../services/companyApi";
import toast from '../../utils/toast';
import DataTable from '../../components/DataTable';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import Navigation from '../../components/Navigation';
import PreviewModal from '../../components/PreviewModal';
import EmpresaPreview from '../../components/pages/empresa/EmpresaPreview';
import { useLoader } from '../../hooks/LoaderContext';
import CompanyFilter from '../../components/pages/empresa/CompanyFilter';
import { pathRoutes } from "../../config/constants";
import ButtonAdd from "../../components/ButtonAdd";
import SwitchStatus from '../../components/SwitchStatus';
import DataTableDefaultAction from '../../components/DataTableDefaultActions';

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    name: "",
    document: "",
    certificate: "",
    passCertificate: "",
    expCertificate: "",
    logo: "",
    environment: "",
    active: true,
    organization: {},
    supplier: {},
    createdAt: ""
  },
  companies: []
};

export default function Empresa() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [companyList, setCompanyList] = useState(DEFAULT_STATE.companies);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [companyIdPreview, setCompanyIdPreview] = useState(0);
  const history = useHistory();
  const [filters, setFilters] = useState('');
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
    async function getAllItems() {
      showLoader();
      const companyResponse = await empresaApi.findAllPageable(page-1, rowsPerPage, filters);
      if (companyResponse) {
        setCompanyList(companyResponse.data.response);
        setTotalPages(companyResponse.data.totalPages);
      } else {
        setCompanyList(DEFAULT_STATE.companies);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllItems();
  }, [filters, forceRefreshData]);

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };

  const handleDelete = async (id) => {
    const deleted = await empresaApi.delete(id);
    if (deleted) {
      if(companyList.length == 1){
        setPage(page - 1);
      }
      setForceRefreshData(!forceRefreshData);
      toast.success(`Empresa deletada com sucesso.`);
    } else {
      toast.error(`Falha ao excluir empresa, favor verifique!.`);
    }
  };

  const handlePreview = (id) => {
    setCompanyIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.empresa}/edicao/${id}`)
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.empresaCadastro}`)
  };

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const company = await empresaApi.status(data);
    if (company) {
      if (company.data.response[0].active) {
        toast.success(`Empresa ativada com sucesso.`);
      } else {
        toast.success(`Empresa desativada com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };
 
  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const translateEnvironment = (type) => {
    if (type === 'PRODUCTION') {
      return "Produção";
    }
    if (type === 'HOMOLOGATION') {
      return "Homologação";
    }
  }

  const header = ['Nome', 'CNPJ', 'Ambiente', 'Data Inclusão', 'Situação', 'Ações'];

  const rows = companyList.map(company => [
    company.name,
    company.document,
    translateEnvironment(company.environment),
    company.createdAt,
    <SwitchStatus
        id={company.id} 
        active={company.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
      />,
    <DataTableDefaultAction
        id={company.id} 
        actions={{
          onEdit: () => handleEdit(company.id),
          onDelete: () => handleDelete(company.id),
          onPreview: () => handlePreview(company.id)
        }} 
    />
  ]);


  return (
    <>
      <div className="container-fluid">
        <PageTitle title="Empresa" icon={<BusinessIcon style={{ color: 'gray' }} />} />
        <Navigation currentPage="Empresa" />
        <Divider />
        <div className="artical-1 out-shadow">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <CompanyFilter onFilter={handleFilter} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Empresa" onClick={handleCreate}/>
            </Box>
          </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box position="relative">
                <DataTable header={header}
                  rows={rows}
                  rowsPerPage={rowsPerPage}
                  totalPages={totalPages}
                  page={page}
                  onPage={handleChangePage}
                  onRowsPerPage={handleChangeRowsPerPage} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <PreviewModal openDialog={showPreview}
                    onCloseDialog={handleClosePreview} width="70%" height="35%">
        <EmpresaPreview id={companyIdPreview} />
      </PreviewModal>
    </>
  );

}