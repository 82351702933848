import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import toast from "../../utils/toast";
import DataTable from "../../components/DataTable";
import PreviewModal from "../../components/PreviewModal";
import ButtonAdd from "../../components/ButtonAdd";
import PageTitle from "../../components/PageTitle";
import { pathRoutes } from "../../config/constants";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../hooks/LoaderContext";
import PieChartIcon from '@material-ui/icons/PieChart';
import unitApi from "../../services/unitApi";
import UnidadeFilter from "../../components/pages/unidade/UnidadeFilter";
import UnidadePreview from "../../components/pages/unidade/UnidadePreview";
import SwitchStatus from "../../components/SwitchStatus";
import DataTableDefaultAction from "../../components/DataTableDefaultActions";

const DEFAULT_STATE = {
    isLoading: false,
    units: [],
};

export default function Unidade() {

  const [unitList, setunitList] = useState(DEFAULT_STATE.units);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [idPreview, setIdPreview] = useState(0);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [filters, setFilters] = useState('');
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
      async function getAllUnits() {
          showLoader();
          const unitResponse = await unitApi.findAllPageable(page-1, rowsPerPage, filters);
          if (unitResponse) {
            setunitList(unitResponse.data.response);
            setTotalPages(unitResponse.data.totalPages)
          } else {
            setunitList(DEFAULT_STATE.units);
            setTotalPages(0);
          }
          hideLoader();
      }
      getAllUnits();
  },[filters, forceRefreshData]);

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleDelete = async (id) => {
      const deleted = await unitApi.delete(id);
      if (deleted) {
        if (unitList.length == 1) {
          setPage(page - 1);
        }
        setForceRefreshData(!forceRefreshData);
        toast.success(`Unidade deletada com sucesso.`);
      } else {
        toast.error(`Falha ao excluir Unidade, favor verifique!.`);
      }
  };

  const handleChangeActive = async (id, status) => {
      const data = {
          id: id,
          active: status
      };
      const unit = await unitApi.status(data);
      if (unit) {
        if (unit.data.response[0].active) {
          toast.success(`Unidade ativada com sucesso.`);
        } else {
          toast.success(`Unidade inativado com sucesso.`);
        }
        setForceRefreshData(!forceRefreshData);
      }
  };

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };

  const handleChangePage = async (event, value) => {
      setPage(value);
      setForceRefreshData(!forceRefreshData);
    };
  
  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.unidade}/edicao/${id}`)
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.unidadeCadastro}`)
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const header = ['Sigla', 'Descrição', 'Data Inclusão', 'Situação', 'Ações'];

  const rows = unitList.map(unit => [
      unit.code,
      unit.description,
      unit.createdAt,
      <SwitchStatus
        id={unit.id} 
        active={unit.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
    />,
    <DataTableDefaultAction
        id={unit.id} 
        actions={{
          onEdit: () => handleEdit(unit.id),
          onDelete: () => handleDelete(unit.id),
          onPreview: () => handlePreview(unit.id)
        }} 
    />
  ]);

  return (
      <Box className="container-fluid">
        <PageTitle title="Unidade" icon={<PieChartIcon style={{ color: 'gray' }}/>} />
        <Box className="artical-1 out-shadow">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <UnidadeFilter onFilter={handleFilter} />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Box>
                <ButtonAdd title="Cadastrar unidade" onClick={handleCreate}/>
              </Box>
            </Grid>
          
            <Grid item md={12} sm={12} xs={12}>
              <Box position="relative">
                <DataTable header={header} 
                            rows={rows}
                            totalPages={totalPages}
                            page={page} 
                            onPage={handleChangePage} 
                            onRowsPerPage={handleChangeRowsPerPage}/>
              </Box>
              <PreviewModal openDialog={showPreview} 
                            onCloseDialog={handleClosePreview} 
                            width="70%" height="35%">
                <UnidadePreview id={idPreview}/>
              </PreviewModal>
              </Grid>
          </Grid>
        </Box>
      </Box>
  );

}