import { pathRoutes } from "../../config/constants";
import CentroCustos from "./index";

export default [
  {
    path: pathRoutes.centroCustos,
    component: CentroCustos,
    exact: true,
  },
];
