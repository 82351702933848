import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import itemUnitDefaultApi from "../../../../../../services/itemUnitDefaultApi";
import TextFieldPreview from "../../../../../TextFieldPreview";
import PageTitle from "../../../../../PageTitle";
import PieChartIcon from '@material-ui/icons/PieChart';

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        unitCode: "",
        itemName: "",
        unitDescription: "",
        companyName: ""
    }
  };

export default function ItemUnidadePadraoPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const itemUnitDefault = await itemUnitDefaultApi.findById(id);
        if (itemUnitDefault) {
            const itemUnitDefaultData = itemUnitDefault.data.response[0];
            setData({
                itemName : itemUnitDefaultData.item.name,
                unitCode : itemUnitDefaultData.unit.code,
                unitDescription: itemUnitDefaultData.unit.description
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Unidade Padrão do Item" icon={<PieChartIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="itemName"
                        label="Item"
                        value={data.itemName}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="unitCode"
                        label="Sigla da Unidade"
                        value={data.unitCode}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="unitDescription"
                        label="Descrição da unidade"
                        value={data.unitDescription}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}