import { get } from "../utils/request"


export const findFuturePayments = async () => {
    return get(`v1/dashboard/future-payments`, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export const findCurrentPayments = async () => {
    return get(`v1/dashboard/current-payments`, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export const findAllPayments = async () => {
    return get(`v1/dashboard/all-payments`, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export default {
    findFuturePayments,
    findCurrentPayments,
    findAllPayments
}