import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 5000,
  draggable: false,
  hideProgressBar: false,
});

export const toastWarning = message => {
  toast.warning(message);
};

export const toastInfo = message => {
  toast.info(message);
};

export const toastSuccess = message => {
  toast.success(message);
};

export const toastError = message => {
  toast.error(message);
};

export default {
  warning: toastWarning,
  info: toastInfo,
  success: toastSuccess,
  error: toastError,
};