export const serialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        active: data.active,
        name: data.name,
        unity: data.unity,
        supplier: {
            id: data.supplier ? data.supplier.id : ""
        }
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        name: data.name,
        unity: data.unit,
        supplier: {
            id: data.supplierId
        }
    }

}


export default{
    serialize
}