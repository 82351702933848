import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import { cashFlowActive, cashFlowInputStatus, cashFlowOutputStatus, cashFlowPaymentCondition, cashFlowPaymentMethod, cashFlowQueryParamTransation, cashFlowQueryParamTransationPaymentMethod, cashFlowType } from "../../../../config/constants";
import costCenterApi from "../../../../services/costCenterApi";
import chartAccountItemApi from "../../../../services/chartAccountItemApi";
import financialSupplierApi from "../../../../services/financialSupplierApi";
import BoxFilter from "../../../BoxFilter";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    chartAccountItems: [],
    chartAccountItemInput : {
      title: "",
      id: ""
    },
    costsCenters: [],
    costsCenterInput : {
      title: "",
      id: ""
    },
    financialSuppliers: [],
    financialSuppliersInput : {
      title: "",
      id: ""
    }
};

export default function FluxoCaixaFilter(props) {

    const { onFilter }  =  props;

    const [isFiltering, setIsFiltering] = useState(false);

    const [setValues] = useSetQueryStateValues();

    const [type, setType] = useQueryState("type", "");
    const [status, setStatus] = useQueryState("status", "");
    const [active, setActive] = useQueryState("active", "");
    const [paymentMethod, setPaymentMethod] = useQueryState("payment_method", "");
    const [paymentCondition, setPaymentCondition] = useQueryState("payment_condition", "");
    const [chartAccountItemId, setChartAccountItemId] = useQueryState("chart_account_item", "");
    const [financialSupplierId, setFinancialSupplierId] = useQueryState("supplier", "");
    const [costCenterId, setCostCenterId] = useQueryState("cost_center","");

    const [chartAccountItemList, setChartAccountItemList] = useState(DEFAULT_STATE.chartAccountItems);
    const [chartAccountItem, setChartAccountItem] = useState(DEFAULT_STATE.financialSuppliersInput);

    const [financialSupplier, setFinancialSupplier] = useState(DEFAULT_STATE.financialSuppliersInput);
    const [financialSupplierList, setFinancialSupplierList] = useState(DEFAULT_STATE.financialSuppliers);
    
    const [costCenterList, setCostCenterList] = useState(DEFAULT_STATE.costsCenters);
    const [costCenter, setCostCenter] = useState(DEFAULT_STATE.costsCenterInput);

    useEffect(() => {
        async function getAllCostsCenter() {
            const costCenterResponse = await costCenterApi.findAll();
            if (costCenterResponse) {
              setCostCenterList(costCenterResponse.data.response);
            }
        }
        getAllCostsCenter();
      },[]);
    
    useEffect(() => {
        handleFilter();
      }, [])
    
    useEffect(() => {
        async function getAllChartAccountItems() {
            const chartAccountItemResponse = await chartAccountItemApi.findAll();
            if (chartAccountItemResponse) {
                setChartAccountItemList(chartAccountItemResponse.data.response);
            }
        }
        getAllChartAccountItems();
    },[]);

    useEffect(() => {
        async function getAllFinancialSupplier() {
            const financialSupplierResponse = await financialSupplierApi.findAll();
            if (financialSupplierResponse) {
              setFinancialSupplierList(financialSupplierResponse.data.response);
            }
        }
        getAllFinancialSupplier();
    },[]);

    useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (chartAccountItemList.length > 0 && chartAccountItemId) {
                const foundItem = chartAccountItemList.find(item => item.id == chartAccountItemId);
                if (foundItem) {
                    setChartAccountItem({title: foundItem.name, id: foundItem.id});
                }
            }
            if (financialSupplierList.length > 0 && financialSupplierId) {
                const foundItem = financialSupplierList.find(item => item.id == financialSupplierId);
                if (foundItem) {
                    setFinancialSupplier({title: foundItem.name, id: foundItem.id});
                }
            }
            if (costCenterList.length > 0 && costCenterId) {
                const foundItem = costCenterList.find(item => item.id == costCenterId);
                if (foundItem) {
                    setCostCenter({title: foundItem.name, id: foundItem.id});
                }
            }
        }
        findAutocompleteQueryFilters();
    }, [chartAccountItemList, financialSupplierList, costCenterList]);

    const financialSuppliers = financialSupplierList.map(supplier => ({
        title: supplier.name,
        id: supplier.id
    }));

    const chartAccountItems = chartAccountItemList.map(chartAccountItem => ({
        title: chartAccountItem.name,
        id: chartAccountItem.id
    }));

    const costsCenter = costCenterList.map(costCenter => ({
        title: costCenter.name,
        id: costCenter.id
    }));

    const handleFilter = async e => {
        let param = '';
        if (status){
            param = param.concat('&status=').concat(status);
        }
        if (type){
            param = param.concat('&type=').concat(type);
        }
        if (paymentMethod){
            param = param.concat('&paymentMethod=').concat(paymentMethod);
        }
        if (paymentCondition){
            param = param.concat('&paymentCondition=').concat(paymentCondition);
        }
        if (chartAccountItemId){
            param = param.concat(`&chart_account_item_id=${chartAccountItemId}`);
        }
        if (costCenterId){
            param = param.concat(`&cost_center_id=${costCenterId}`);
        }
        if (financialSupplierId){
            param = param.concat(`&supplier_id=${financialSupplierId}`);
        }
        if (active){
            param = param.concat(`&active=${active}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        setValues({type : "", status: "", payment_method: "", payment_condition: "", chart_account_item : "", supplier : "", cost_center : "", active : ""});
        setChartAccountItem("");
        setCostCenter("");
        setFinancialSupplier("");
        onFilter();
        setIsFiltering(false);
    };

    const handleChangeChartAccountItem = (value) => {
        setChartAccountItem(value);
        setChartAccountItemId(value.id);
    };

    const handleChangeFinancialSupplier = (value) => {
        setFinancialSupplier(value);
        setFinancialSupplierId(value.id);
    };

    const handleChangeCostCenter = (value) => {
        setCostCenter(value);
        setCostCenterId(value.id);
    };

    const handleInputChangeType = ({ target: { value } }) => {
       setType(value);
    };

    const handleInputChangeStatus = ({ target: { value } }) => {
        setStatus(value);
    };

    const handleInputChangePaymentMethod = ({ target: { value } }) => {
        setPaymentMethod(value);
    };

    const handleInputChangePaymentCondition = ({ target: { value } }) => {
        setPaymentCondition(value);
    };

    const handleInputChangeActive = ({ target: { value } }) => {
        setActive(value);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mt-2 mb-2" item md={4} sm={4} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="type"
                        select
                        label="Tipo do Lançamento"
                        value={type}
                        onChange={handleInputChangeType}>
                        {cashFlowType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid className="mt-2 mb-2" item md={3} sm={3} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="status"
                        select
                        label="Situação"
                        value={status}
                        onChange={handleInputChangeStatus}>
                        {type === 'INPUT' ? (
                            cashFlowInputStatus.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))
                            )
                            : (
                            cashFlowOutputStatus.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </Grid>
                <Grid className="mt-2 mb-2" item md={5} sm={5} xs={12}>
                    <Autocomplete
                        id="autocomplete-chartaccount-item"
                        options={chartAccountItems}
                        clearText="Limpar"
                        value={chartAccountItem}
                        onChange={(event, value) => handleChangeChartAccountItem(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => 
                        <TextField {...params} label="Item" variant="outlined" />}
                    />
                </Grid>
                <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                        id="autocomplete-financial-supplier"
                        options={financialSuppliers}
                        clearText="Limpar"
                        value={financialSupplier}
                        onChange={(event, value) => handleChangeFinancialSupplier(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Fornecedor" variant="outlined" />
                        } />
                </Grid>
                <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                        id="autocomplete-costcenter"
                        options={costsCenter}
                        clearText="Limpar"
                        value={costCenter}
                        onChange={(event, value) => handleChangeCostCenter(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Centro de Custos" variant="outlined" />
                        }
                    />
                </Grid>
                <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="paymentMethod"
                        select
                        label={
                            type === 'INPUT' ? 'Forma de recebimento' : 'Forma de pagamento'
                        }
                        value={paymentMethod}
                        onChange={handleInputChangePaymentMethod}>
                        {cashFlowPaymentMethod.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid className="mt-2 mb-2" item md={4} sm={4} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="paymentCondition"
                        select
                        label="Condição de pagamento"
                        value={paymentCondition}
                        onChange={handleInputChangePaymentCondition}>
                        {cashFlowPaymentCondition.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid className="mt-2 mb-2" item md={2} sm={2} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="ACTIVE"
                        select
                        label="Ativo"
                        value={active}
                        onChange={handleInputChangeActive}>
                        {cashFlowActive.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Box className="c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="c-inline-btn">
                        {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                        )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}