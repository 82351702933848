import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import cashFlowApi from "../../services/cashFlowApi";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import FluxoCaixaFilter from "../../components/pages/fluxoCaixa/FluxoCaixaFilter";
import FluxoCaixaTotalizadores from "../../components/pages/fluxoCaixa/FluxoCaixaTotalizadores";
import FluxoCaixaHeader from "../../components/pages/fluxoCaixa/FluxoCaixaHeader";
import { cashFlowTranslateStatus, cashFlowTranslateType, pathRoutes } from "../../config/constants";
import DataTable from "../../components/DataTable";
import PreviewModal from "../../components/PreviewModal";
import FluxoCaixaPreview from "../../components/pages/fluxoCaixa/FluxoCaixaPreview";
import ButtonAdd from "../../components/ButtonAdd";
import { useHistory } from 'react-router-dom';
import toast from "../../utils/toast";
import { formatMoney } from "../../utils/moneyUtils";
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import DataTableDefaultAction from "../../components/DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  cashFlows: []
};

export default function FluxoCaixa() {

  const [cashFlowList, setCashFlowList] = useState(DEFAULT_STATE.cashFlows);
  const [gridFilters, setGridFilters] = useState('');
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [cashFlowIdPreview, setCashFlowIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [forceRefreshData, setForceRefreshData] = useState(false);

  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    async function getAllCashFlows() {
        showLoader();
        if (search !== '') {
          const cashFlowResponse = await cashFlowApi.findAllPageable(page-1, rowsPerPage, `${search}${gridFilters ?? ''} `);
          if (cashFlowResponse) {
            setCashFlowList(cashFlowResponse?.data?.response);
            setTotalPages(cashFlowResponse?.data.totalPages);
          } else {
            setCashFlowList(DEFAULT_STATE.cashFlows);
            setTotalPages(0);
          }
        }
        hideLoader();
    }
    getAllCashFlows();
  }, [search, gridFilters, forceRefreshData]);

  const handleDelete = async (id) => {
    const deleted = await cashFlowApi.delete(id);
    if (deleted) {
      if (cashFlowList.length == 1) {
        setPage(page - 1);
      }
      setForceRefreshData(!forceRefreshData);
      toast.success(`Lançamento deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir lançamento, favor verifique!.`);
    }
  };

const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
};

const handleChangeRowsPerPage = async (rows) => {
  setRowsPerPage(rows);
  setForceRefreshData(!forceRefreshData);
};

const header = ['Descrição', 'Fornecedor', 'Valor', 'Competência', 'Tipo', 'Situação', 'Item', 'Data Inclusão', 'Situação', 'Ações'];

const rows = cashFlowList.map(cashFlow => [
  cashFlow.description,
  cashFlow.supplier.name,
  formatMoney(cashFlow.value),
  cashFlow.occurrenceDate,
  cashFlowTranslateType(cashFlow.type),
  cashFlowTranslateStatus(cashFlow.status),
  cashFlow.chartAccountItem.name,
  cashFlow.createdAt,
  <SwitchStatus
        id={cashFlow.id} 
        active={cashFlow.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
      />,
  <DataTableDefaultAction
      id={cashFlow.id} 
      actions={{
        onEdit: () => handleEdit(cashFlow.id),
        onDelete: () => handleDelete(cashFlow.id),
        onPreview: () => handlePreview(cashFlow.id)
      }} 
  />
]);

  const handleSearch = async (search) => {
    setSearch(search);
  };

  const handlePreview = (id) => {
    setCashFlowIdPreview(id);
    setShowPreview(true);
  };

  const handleGridFilter = async (filters) => {
    setPage(1);
    setGridFilters(filters);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleChangeActive = async (id, status) => {
    const data = {
        id: id,
        active: status
    };
    const cashFlow = await cashFlowApi.status(data);
    if (cashFlow) {
      if (cashFlow.data.response[0].active) {
        toast.success(`Lançamento ativado com sucesso.`);
      } else {
        toast.success(`Lançamento inativado com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
};

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.fluxoCaixaLancamento}/${id}`)
  };

  const handleCreate = async () => {
    history.push(`${pathRoutes.fluxoCaixaLancamento}`)
  };

  return (
      <div className="container-fluid">
        <div style={{ display: 'flex'}}>
          <Box style={{ marginTop: '5px', marginRight: '10px' }}>
            <AccountBalanceIcon style={{ color: 'gray' }}/>
          </Box>
          <h1 className="h3 mb-2 text-gray-800">Fluxo de Caixa</h1>
        </div>
        <Divider />
        <Paper>
            <FluxoCaixaHeader onSearch={handleSearch} />
        </Paper>
        <Paper>
          <Typography variant="h5" style={{ fontSize: '1.1rem', textAlign: 'center', paddingTop: '5px', color: 'gray' }} gutterBottom>
            Totalizadores
          </Typography>
          <FluxoCaixaTotalizadores searchFilters={search} forceRefresh={forceRefreshData}/>
        </Paper>
          <Paper>
            <Box className="artical-1 out-shadow">  
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <FluxoCaixaFilter onFilter={handleGridFilter}/>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box>
                    <ButtonAdd title="Criar Lançamento" onClick={handleCreate}/>
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <DataTable header={header} 
                            rows={rows} 
                            totalPages={totalPages}
                            page={page}
                            onPage={handleChangePage} 
                            onRowsPerPage={handleChangeRowsPerPage}/>
                  <PreviewModal openDialog={showPreview} 
                            onCloseDialog={handleClosePreview} 
                            width="70%" height="75%">
                    <FluxoCaixaPreview id={cashFlowIdPreview}/>
                  </PreviewModal>
                </Grid>
              </Grid>
            </Box>
        </Paper>
      </div>
  );
}