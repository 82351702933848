import { pathRoutes } from "../../../config/constants";
import CanteiroCadastro from "./index";

export default [
  {
    path: pathRoutes.canteiroCadastro,
    component: CanteiroCadastro,
    exact: true,
  },
  {
    path: pathRoutes.canteiroEdit,
    component: CanteiroCadastro,
    exact: true,
  },
];