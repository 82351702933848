import { pathRoutes } from "../../../config/constants";
import ItemConfig from "./index";

export default [
  {
    path: pathRoutes.itemConfig,
    component: ItemConfig,
    exact: true,
  },
];
