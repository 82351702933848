import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import Nav from "../Nav";
import Footer from "../Footer";
import AuthContext from "../AuthContext";
import { getToken, getUser } from "../../utils/auth";

const DEFAULT_STATE = {
  token: null,
  user: null,
};

function Container({ children }) {
  const [token, setToken] = useState(DEFAULT_STATE.token);
  const [user, setUser] = useState(DEFAULT_STATE.user);

  useEffect(() => {
    setToken(getToken());
    setUser(getUser());
  }, []);

  return (
    <AuthContext.Provider value={{ token, user }}>
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div className="content">
            <Nav wrapperComponent={children}/>
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
};

export default Container;