import { Box, Grid, TextField, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { REQUIRED_FIELD } from "../../../../../config/constants";
import ButtonSubmit from "../../../../ButtonSubmit";
import toast from "../../../../../utils/toast";
import { useLoader } from "../../../../../hooks/LoaderContext";
import supplierTaxInformationApi from "../../../../../services/supplierTaxInformationApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        supplierId: "",
        ie: "",
        iest: "",
        im: "",
        cnae: "",
        crt: "",
        isuf: ""
    }
};

const validationSchema = Yup.object().shape({
    ie: Yup.string().required(REQUIRED_FIELD),
    iest: Yup.string().required(REQUIRED_FIELD),
    im: Yup.string().required(REQUIRED_FIELD),
    cnae: Yup.string().required(REQUIRED_FIELD),
    crt: Yup.string().required(REQUIRED_FIELD),
    isuf: Yup.string().required(REQUIRED_FIELD),
});

export default function SupplierTaxInformation() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { showLoader, hideLoader } = useLoader();
    const params = useParams();

    useEffect(() => {
        handleEdit(params.ident);
    }, []);

    const handleEdit = async (supplierId) => {
        if (!isNaN(supplierId)) {
            showLoader();
            const supplierResponse = await supplierTaxInformationApi.findAllPageable(page - 1, rowsPerPage, `&supplierId=${supplierId}`);
            if (supplierResponse) {
                const data = {
                    supplierId: supplierResponse.data.response[0].supplierId,
                    id: supplierResponse.data.response[0].id,
                    ie: supplierResponse.data.response[0].ie,
                    iest: supplierResponse.data.response[0].iest,
                    im: supplierResponse.data.response[0].im,
                    cnae: supplierResponse.data.response[0].cnae,
                    crt: supplierResponse.data.response[0].crt,
                    isuf: supplierResponse.data.response[0].isuf
                };
                setData(data);
            }
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let supplier;
            values = {
                ...values,
                supplierId: params.ident
            }
            if (values.id.length === 0) {
                supplier = await supplierTaxInformationApi.post(values);
            } else {
                supplier = await supplierTaxInformationApi.put(values);
            }
            if (supplier) {
                handleSuccess(values.id.length === 0);
            } else {
                handleError();
            }
        } catch (error) {
            console.error('Erro ao enviar formulário: ', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSuccess = (isNew) => {
        const successMessage = isNew ? "Informação fiscal da pessoa cadastrada com sucesso." : "Informação fiscal da pessoa atualizada com sucesso.";
        toast.success(successMessage);
    };

    const handleError = () => {
        toast.error(`Falha ao criar Informação fiscal da pessoa, favor verifique!`);
    };

    return (
        <div className="container-fluid">
            <div className="artical-1 out-shadow">
                <Formik
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ isSubmitting, touched, errors }) => (
                        <Form className='mt-3'>
                            <Grid container spacing={2}>
                                <Grid className="mt-3 mb-2" item md={4} sm={12} xs={8}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="ie"
                                        label="Ie"
                                        helperText={touched.ie && errors.ie ? <ErrorMessage name="ie" /> : ""}
                                        error={touched.ie && Boolean(errors.ie)}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={4} sm={12} xs={8}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="iest"
                                        label="Iest"
                                        helperText={touched.iest && errors.iest ? <ErrorMessage name="iest" /> : ""}
                                        error={touched.iest && Boolean(errors.iest)}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={4} sm={12} xs={8}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="im"
                                        label="Im"
                                        helperText={touched.im && errors.im ? <ErrorMessage name="im" /> : ""}
                                        error={touched.im && Boolean(errors.im)}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={4} sm={12} xs={8}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="cnae"
                                        label="Cnae"
                                        helperText={touched.cnae && errors.cnae ? <ErrorMessage name="cnae" /> : ""}
                                        error={touched.cnae && Boolean(errors.cnae)}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={4} sm={12} xs={8}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="crt"
                                        label="Crt"
                                        helperText={touched.crt && errors.crt ? <ErrorMessage name="crt" /> : ""}
                                        error={touched.crt && Boolean(errors.crt)}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={4} sm={12} xs={8}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        name="isuf"
                                        label="Isuf"
                                        helperText={touched.isuf && errors.isuf ? <ErrorMessage name="isuf" /> : ""}
                                        error={touched.isuf && Boolean(errors.isuf)}
                                    />
                                </Grid>
                                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                                        <Box className="mt-1 c-inline-btn">
                                            <Grid container spacing={2}>
                                                <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                                                    <ButtonSubmit
                                                        label="salvar"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className="btn btn-primary btn-user btn-block"
                                                        disable={isSubmitting}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}