import { Box, Divider, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ListAltIcon from '@material-ui/icons/ListAlt';
import PageTitle from "../../../components/PageTitle";
import itemApi from "../../../services/itemApi";
import { useParams, useHistory } from 'react-router-dom';
import { pathRoutes } from "../../../config/constants";
import categoryApi from "../../../services/categoryApi";
import { Autocomplete } from "@material-ui/lab";
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import ButtonConfig from "../../../components/ButtonConfig";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    active: true,
    code: "",
    name: "",
    description: "",
    detail: {
      lifeCycle: ""
    }
  },
  items: [],
  categories: [],
  categoryInput: {
    title: "",
    id: ""
  }
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD),
  name: Yup.string().required(REQUIRED_FIELD),
});

export default function ItemCadastro() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [category, setCategory] = useState(DEFAULT_STATE.categoryInput);
  const [categoryList, setCategoryList] = useState(DEFAULT_STATE.categories);
  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    async function getAllCategories() {
      const categoryResponse = await categoryApi.findAll();
      if (categoryResponse) {
        setCategoryList(categoryResponse.data.response);
      }
    }
    getAllCategories();
  }, []);

  useEffect(() => {
    async function findById() {
      if (!isNaN(params.ident)) {
        const itemResponse = await itemApi.findById(params.ident);
        if (itemResponse) {
          const data = {
            id: itemResponse.data.response[0].id,
            code: itemResponse.data.response[0].code,
            name: itemResponse.data.response[0].name,
            description: itemResponse.data.response[0].description,
            detail: {
              lifeCycle: itemResponse.data.response[0].detail.lifeCycle
            },
            category: {
              id: itemResponse.data.response[0].category.id
            }
          };
          setData(data);
          setCategory({
            title: itemResponse.data.response[0].category.name,
            id: itemResponse.data.response[0].category.id
          });
        }
      }
    }
    findById();
  }, []);

  const categories = categoryList.map(category => ({
    title: category.name,
    id: category.id
  }));

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const handleChangeCategory = (value, setFieldValue) => {
    setCategory(value);
    setFieldValue("category", value ? value : "");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let item;
      if (values.id.length == 0) {
        item = await itemApi.post(values);
        if (item) {
          toast.success(`Item cadastrado com sucesso.`);
        }
      } else {
        item = await itemApi.put(values);
        if (item) {
          toast.success(`Item atualizado com sucesso.`);
        }
      }
      if (item) {
        redirectTo();
      } else {
        toast.error(`Falha ao criar Item, favor verifique!.`);
      }
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    const deleted = await itemApi.delete(params.ident);
    if (deleted) {
      toast.success(`Item deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir item, favor verifique!.`);
    }
    redirectTo();
  };

  const redirectTo = () => {
    history.goBack();
  };

  const handleConfig = () => {
    history.push(`${pathRoutes.item}/configuracao/${params.ident}`);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Cadastrar Item' : 'Atualizar Item'} icon={<ListAltIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors, setFieldValue, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid className="mt-3 mb-2" item md={3} sm={3} xs={6}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="code"
                      label="Código"
                      helperText={(touched.code && errors.code) && <ErrorMessage name="code" />}
                      error={touched.code && errors.code}>
                    </Field>
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={9} sm={9} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="name"
                      label="Nome"
                      helperText={touched.name && errors.name ? <ErrorMessage name="name" /> : ""}
                      error={touched.name && Boolean(errors.name)}
                    />
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="description"
                      label="Descrição"
                      helperText={(touched.description && errors.description) && <ErrorMessage name="description" />}
                      error={touched.description && errors.description}>
                    </Field>
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                      id="autocomplete-categoria"
                      options={categories}
                      clearText="Limpar"
                      value={category}
                      onChange={(event, value) => handleChangeCategory(value, setFieldValue)}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) =>
                        <TextField {...params} label="Categoria" variant="outlined"/>
                      }
                    />
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="detail.lifeCycle"
                      label="Ciclo de vida"
                    />
                  </Grid>

                  <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <> 
                              <Grid item md={4} sm={4} xs={12}>
                                <ButtonConfig disabled title="Configuraçoes" onClick={handleConfig} />
                              </Grid>
                              <Grid item md={4} sm={4} xs={12}>
                                <ButtonRemove
                                  title="excluir"
                                  onClick={handleOpenDeleteModal}
                                  variant="contained"
                                  color="primary"
                                  className="btn btn-primary btn-user btn-block"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item md={params.ident ? 4 : 12} sm={params.ident ? 4 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disabled={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );

}