import { Box, Divider,Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EcoIcon from '@material-ui/icons/Eco';
import { useParams, useHistory } from 'react-router-dom';
import { Autocomplete } from "@material-ui/lab";
import unitApi from "../../../../services/unitApi";
import itemUnitSupplierApi from "../../../../services/itemUnitSupplierApi";
import { getUser } from "../../../../utils/auth";
import PageTitle from "../../../../components/PageTitle";
import ButtonSubmit from "../../../../components/ButtonSubmit";
import ButtonRemove from "../../../../components/ButtonRemove";
import toast from "../../../../utils/toast";
import AlertDialog from "../../../../components/AlertDialog";
import BasicReturn from "../../../../components/BasicReturn";
import itemApi from "../../../../services/itemApi";
import supplierApi from "../../../../services/supplierApi";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
      id: "",
      active: true,
      sigla: "",
      detail: {
        lifeCycle: ""
      }
  },
  items: [],
  units: [],
  unitInput: {
      title: "",
      id: ""
  },
  suppliers: [],
  supplierInput: {
      title: "",
      id: ""
  }
};

export default function ItemUnidadeClienteCadastro() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [unit, setUnit] = useState(DEFAULT_STATE.unitInput);
  const [unitList, setUnitList] = useState(DEFAULT_STATE.units);
  const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierInput);
  const [supplierList, setSupplierList] = useState(DEFAULT_STATE.units);

  const [item, setItem] = useState({});

  const history = useHistory();
  const params = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const company =  getUser().company;

  useEffect(() => {
    async function getItem() {
        if (!isNaN(params.itemId)) {
            const itemResponse = await itemApi.findById(params.itemId);
            if (itemResponse) {
                setItem(itemResponse.data.response[0]);
            }
        }
    }
    getItem();
  },[]);

  useEffect(() => {
    async function getAllUnits() {
        const unitResponse = await unitApi.findAll();
        if (unitResponse) {
            setUnitList(unitResponse.data.response);
        }
    }
    getAllUnits();
  },[]);

  useEffect(() => {
    async function getAllSuppliers() {
        const supplierResponse = await supplierApi.findAll("?category=CUSTOMER");
        if (supplierResponse) {
            setSupplierList(supplierResponse.data.response);
        }
    }
    getAllSuppliers();
  },[]);

useEffect(() => {
  async function findById() {
      if (!isNaN(params.ident)) {
        const itemUnitSupplierResponse = await itemUnitSupplierApi.findById(params.ident);
        if (itemUnitSupplierResponse) {
          const data = {
              id : itemUnitSupplierResponse.data.response[0].id,
              unit : {
                id: itemUnitSupplierResponse.data.response[0].unit.id,
              },
              item : {
                id: itemUnitSupplierResponse.data.response[0].item.id,
              },
              supplier: {
                id : itemUnitSupplierResponse.data.response[0].supplier.id,
              }
          };
          setData(data);
          setItem(itemUnitSupplierResponse.data.response[0].item);
          setUnit({title: itemUnitSupplierResponse.data.response[0].unit.code, 
                       id: itemUnitSupplierResponse.data.response[0].unit.id});
          setSupplier({title: `${itemUnitSupplierResponse.data.response[0].supplier.name} - 
                               ${itemUnitSupplierResponse.data.response[0].supplier.document}`, 
                        id: itemUnitSupplierResponse.data.response[0].supplier.id});
        }
      }
  }
  findById();
},[]);

const units = unitList.map(unit => ({
  title: `${unit.code} - ${unit.description}`,
  id: unit.id
}));

const suppliers = supplierList.map(supplier => ({
  title: `${supplier.name} - ${supplier.document}`,
  id: supplier.id
}));

const handleOpenDeleteModal = () => {
  setOpenDeleteModal(true);
};

const handleCloseModalDelete = () => {
  setOpenDeleteModal(false);
};

const handleChangeUnit = (value) => {
  setUnit(value);
  setData({ ...data, unit: { id : value.id }, item: { id : item.id } });
};

const handleChangeSupplier = (value) => {
  setSupplier(value);
  setData({ ...data, supplier: { id : value.id }, item: { id : item.id } });
};

const handleSubmit = async e => {
  e.preventDefault();
  
  let itemUnitSupplier;
  if (data.id.length == 0) {
    itemUnitSupplier = await itemUnitSupplierApi.post(data);
    if (itemUnitSupplier) {
      toast.success(`Unidade padrão cadastrada com sucesso para o item.`);
    }
  } else {
    itemUnitSupplier = await itemUnitSupplierApi.put(data);
    if (item) {
      toast.success(`Unidade padrão atualizada com sucesso.`);
    }
  }
  if (itemUnitSupplier) {
    redirectTo();
  } else {
    toast.error(`Falha ao criar Unidade padrão, favor verifique!.`);
  }
};

const handleDelete = async () => {
  const deleted = await itemUnitSupplierApi.delete(params.ident);
  if (deleted) {
    toast.success(`Unidade padrão deletado com sucesso.`);
  } else {
    toast.error(`Falha ao excluir item, favor verifique!.`);
  }
  redirectTo();
};

const redirectTo = () => {
  history.goBack();
};

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? `Cadastrar unidade padrão para ${item.name}` : `Atualiza unidade padrão item ${item.name}`} icon={<EcoIcon style={{ color: 'gray' }}/>} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
        <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="company"
                        label="Empresa"
                        value={company.name}
                        disabled
                    />
                </Grid>
                <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                  <Autocomplete
                      id="autocomplete-supplier"
                      options={suppliers}
                      clearText="Limpar"
                      value={supplier}
                      onChange={(event, value) => handleChangeSupplier(value ? value : '')}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => 
                          <TextField {...params} label="Cliente" variant="outlined" />
                      }
                  />
                </Grid>
                <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                        id="autocomplete-unit"
                        options={units}
                        clearText="Limpar"
                        value={unit}
                        onChange={(event, value) => handleChangeUnit(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Unidade" variant="outlined" />
                        }
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Box className="mt-1 c-inline-btn">
                              <Grid container spacing={2}>
                                {params.ident && (
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ButtonRemove
                                            title="excluir"
                                            onClick={handleOpenDeleteModal}
                                            variant="contained"
                                            color="primary"
                                            className="btn btn-primary btn-user btn-block"
                                        />
                                    </Grid>
                                )}
                                <Grid item md={params.ident ? 6: 12} sm={params.ident ? 6: 12} xs={12}>
                                    <ButtonSubmit
                                        label="salvar"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn btn-primary btn-user btn-block"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
              </Grid>
          </form>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?" 
          description="O dado será excluído de forma permanente" 
          confirmTitle="Confirmar"
          cancelTitle="Cancelar"
          openDialog={openDeleteModal} 
          onConfirm={handleDelete}
          onCancel={handleCloseModalDelete}
        />
     </>
  );

}