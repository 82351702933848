import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PieChartIcon from '@material-ui/icons/PieChart';
import itemUnitDefaultApi from "../../../../../../services/itemUnitDefaultApi";
import PageTitle from "../../../../../PageTitle";
import TextFieldPreview from "../../../../../TextFieldPreview";
import itemUnitSupplierApi from "../../../../../../services/itemUnitSupplierApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        unitCode: "",
        itemName: "",
        unitDescription: "",
        supplierName: "",
        supplierDocument: ""
    }
  };

export default function ItemUnidadeClientePreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const itemUnitSupplier = await itemUnitSupplierApi.findById(id);
        if (itemUnitSupplier) {
            const itemUnitSupplierData = itemUnitSupplier.data.response[0];
            setData({
                itemName : itemUnitSupplierData.item.name,
                unitCode : itemUnitSupplierData.unit.code,
                unitDescription: itemUnitSupplierData.unit.description,
                supplierName: itemUnitSupplierData.supplier.name,
                supplierDocument: itemUnitSupplierData.supplier.document,
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Unidade Padrão do Item" icon={<PieChartIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="supplierName"
                        label="Nome cliente"
                        value={data.supplierName}
                        variant="outlined"
                        fullWidth                    
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="supplierDocument"
                        label="Documento cliente"
                        value={data.supplierDocument}
                        variant="outlined"
                        fullWidth                    
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="itemName"
                        label="Item"
                        value={data.itemName}
                        variant="outlined"
                        fullWidth                    
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="unitCode"
                        label="Sigla da Unidade"
                        value={data.unitCode}
                        variant="outlined"
                        fullWidth                    
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="unitDescription"
                        label="Descrição da unidade"
                        value={data.unitDescription}
                        variant="outlined"
                        fullWidth                    
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}