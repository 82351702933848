import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import PageTitle from "../../components/PageTitle";
import { pathRoutes, translateSupplierCategory, translateSupplierType } from "../../config/constants";
import supplierApi from "../../services/supplierApi";
import DataTable from "../../components/DataTable";
import PessoaFilter from "../../components/pages/pessoa/PessoaFilter";
import PreviewModal from "../../components/PreviewModal";
import PessoaPreview from "../../components/pages/pessoa/PessoaPreview";
import ButtonAdd from "../../components/ButtonAdd";
import { useHistory } from 'react-router-dom';
import toast from "../../utils/toast";
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import ItemActions from "../../components/ItemActions";

const DEFAULT_STATE = {
  supplier: []
};

export default function Pessoa() {

  const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [supplierIdPreview, setSupplierIdPreview] = useState(0);
  const [filters, setFilters] = useState('');
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
    async function getAllSuppliers() {
      showLoader();
      const supplierReponse = await supplierApi.findAllPageable(0, rowsPerPage, filters);
      if (supplierReponse) {
        setSupplierList(supplierReponse.data.response);
        setTotalPages(supplierReponse.data.totalPages);
      } else{
        setSupplierList(DEFAULT_STATE.supplier);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllSuppliers();
  }, [filters, forceRefreshData]);

  const handleDelete = async (id) => {
    const deleted = await supplierApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Pessoa deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir pessoa, favor verifique!.`);
    }
  };

  const handlePreview = (id) => {
    setSupplierIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };


  const handleEdit = async (id) => {
    history.push(`${pathRoutes.pessoa}/edicao/${id}`)
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.pessoa}/cadastro`)
  };

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const supplier = await supplierApi.status(data);
    if (supplier) {
      if (supplier.data.response[0].active) {
        toast.success(`Pessoa ativada com sucesso.`);
      } else {
        toast.success(`Pessoa inativada com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleConfig = async (id) => {
    history.push(`${pathRoutes.pessoaConfig}`.replace(":ident?", id))
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    const supplierReponse = await supplierApi.findAllPageable(value - 1, rowsPerPage, filters);
    if (supplierReponse) {
      setSupplierList(supplierReponse.data.response);
      setTotalPages(supplierReponse.data.totalPages);
    }
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const supplierReponse = await supplierApi.findAllPageable(0, rows, filters);
    if (supplierReponse) {
      setSupplierList(supplierReponse.data.response);
      setTotalPages(supplierReponse.data.totalPages);
    }
  };

  const handleFilter = async (filters) => {
    setFilters(filters);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Data Inclusão', 'Nome', 'Documento', 'Tipo', 'Categoria', 'Situação', 'Ações'];

  const rows = supplierList.map(supplier => [
    supplier.createdAt,
    supplier.name,
    supplier.document,
    translateSupplierType(supplier.type),
    translateSupplierCategory(supplier.category),
    <SwitchStatus
      id={supplier.id}
      active={supplier.active}
      actions={{
        onSwitch: (id, status) => handleChangeActive(id, status)
      }} 
    />,
    <ItemActions
      id={supplier.id}
      actions={{
        onEdit: () => handleEdit(supplier.id),
        onDelete: () => handleDelete(supplier.id),
        onPreview: () => handlePreview(supplier.id),
        onConfig: () => handleConfig(supplier.id)
      }}
    />
  ]);

  return (
    <div className="container-fluid">
      <PageTitle title="Pessoa" icon={<PeopleAltRoundedIcon style={{ color: 'gray' }} />} />
      <div className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <PessoaFilter onFilter={handleFilter} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Pessoa" onClick={handleCreate} />
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable header={header}
                rows={rows}
                totalPages={totalPages}
                page={page}
                onPage={handleChangePage}
                onRowsPerPage={handleChangeRowsPerPage} />
            </Box>
            <PreviewModal openDialog={showPreview}
              onCloseDialog={handleClosePreview}
              width="70%" height="75%">
              <PessoaPreview id={supplierIdPreview} />
            </PreviewModal>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}