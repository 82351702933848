import { pathRoutes } from "../../../config/constants";
import OperacaoFiscalCadastro from "./index";

export default [
  {
    path: pathRoutes.operacaoFiscalCadastro,
    component: OperacaoFiscalCadastro,
    exact: true,
  },
  {
    path: pathRoutes.operacaoFiscalEdit,
    component: OperacaoFiscalCadastro,
    exact: true,
  },
];
