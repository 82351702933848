import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { supplierCategories, supplierTypes } from "../../../../config/constants";
import BoxFilter from "../../../BoxFilter";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import { useState, useEffect } from "react";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        name: "",
        type: "",
        category: "",
        document: ""
    }
};

export default function PessoaFilter(props) {

    const { onFilter}  =  props;
    const [name, setName] = useQueryState("name", '');
    const [type, setType] = useQueryState("type", '');
    const [category, setCategory] = useQueryState("category", '');
    const [document, setDocument] = useQueryState("document", '');
    const [setValues] = useSetQueryStateValues();
    const [isFiltering, setIsFiltering] = useState(false);
    
    useEffect(() => {
        handleFilter();
    }, []);

    const handleFilter = async e => {
        let param = '';
        if (name){
            param = param.concat('&name=').concat(name);
        }
        if (type){
            param = param.concat('&type=').concat(type);
        }
        if (category){
            param = param.concat('&category=').concat(category);
        }
        if (document){
            param = param.concat('&document=').concat(document);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        onFilter();
        setIsFiltering(false);
        setValues({name: "", type: "", category: "", document: ""})
    };

    const handleInputChange = ({ target: { value } }) => {
        setName(value);
    };

    const handleChangeDocument = ({ target: { value } }) => {
        setDocument(value);
    };

    const handleChangeType = ({ target: { value } }) => {
        setType(value);
      };
    
    const handleChangeCategory = ({ target: { value } }) => {
        setCategory(value);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="name"
                        label="Nome"
                        value={name}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                    variant="outlined"
                    className="form-control"
                    name="type"
                    select
                    label="Categoria da Pessoa"
                    value={category}
                    onChange={handleChangeCategory}
                    helperText="Selecione a categoria da pessoa">
                    {supplierCategories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                </Grid>

                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="type"
                        select
                        label="Tipo da pessoa"
                        value={type}
                        onChange={handleChangeType}
                        helperText="Selecione o tipo da pessoa">
                        {supplierTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="document"
                        label={type === 'FISICA' ? 'Digite o CPF' : "Digite o CNPJ"}
                        helperText="Somente números"
                        value={document}
                        onChange={handleChangeDocument}
                    />
                </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}