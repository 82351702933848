import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import toast from "../../utils/toast";
import DataTable from "../../components/DataTable";
import itemApi from "../../services/itemApi";
import ItemPreview from "../../components/pages/item/ItemPreview";
import PreviewModal from "../../components/PreviewModal";
import ItemFilter from "../../components/pages/item/ItemFilter";
import { pathRoutes } from "../../config/constants";
import ButtonAdd from "../../components/ButtonAdd";
import PageTitle from "../../components/PageTitle";
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import ListAltIcon from '@material-ui/icons/ListAlt';
import ItemActions from "../../components/ItemActions";

const DEFAULT_STATE = {
    isLoading: false,
    items: [],
    categories: [],
    categoryInput: {
        title: "",
        id: ""
    }
};

export default function Item() {

    const [itemList, setItemList] = useState(DEFAULT_STATE.items);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [showPreview, setShowPreview] = useState(false);
    const [itemIdPreview, setItemIdPreview] = useState(0);
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();

    useEffect(() => {
        async function getAllItems() {
            showLoader();
            const itemResponse = await itemApi.findAllPageable(0, rowsPerPage);
            if (itemResponse) {
              setItemList(itemResponse.data.response);
              setTotalPages(itemResponse.data.totalPages);
            }
            hideLoader();
        }
        getAllItems();
    },[]);
  
    const handlePreview = (id) => {
      setItemIdPreview(id);
      setShowPreview(true);
    };
  
    const handleClosePreview = (id) => {
      setShowPreview(false);
    };

    const handleDelete = async (id) => {
        const deleted = await itemApi.delete(id);
        if (deleted) {
          refreshDatatable();
          toast.success(`Item deletado com sucesso.`);
        } else {
          toast.error(`Falha ao excluir item, favor verifique!.`);
        }
    };

    const handleEdit = async (id) => {
      history.push(`${pathRoutes.item}/edicao/${id}`)
    };

    const handleCreate = async (id) => {
      history.push(`${pathRoutes.item}/cadastro`)
    };

    const handleChangeActive = async (id, status) => {
        const data = {
            id: id,
            active: status
        };
        const item = await itemApi.status(data);
        if (item) {
          if (item.data.response[0].active) {
            toast.success(`Item ativado com sucesso.`);
          } else {
            toast.success(`Item desativado com sucesso.`);
          }
          refreshDatatable();
        }
    };

    const handleFilter = async (filters) => {
      refreshDatatable(filters);
    };
    
    const refreshDatatable = async (filters) => {
        const itemResponse = await itemApi.findAllPageable(page-1, rowsPerPage, filters);
        if (itemResponse) {
            setItemList(itemResponse.data.response);
            setTotalPages(itemResponse.data.totalPages);
        } else {
          setItemList(DEFAULT_STATE.items);
          setTotalPages(0);
        }
    }

    const handleChangePage = async (event, value) => {
      setPage(value);
      const itemResponse = await itemApi.findAllPageable(value-1, rowsPerPage);
      if (itemResponse) {
        setItemList(itemResponse.data.response);
        setTotalPages(itemResponse.data.totalPages);
      }
    };
  
    const handleChangeRowsPerPage = async (rows) => {
      setRowsPerPage(rows);
      const itemResponse = await itemApi.findAllPageable(0, rows);
      if (itemResponse) {
          setItemList(itemResponse.data.response);
          setTotalPages(itemResponse.data.totalPages);
      }
    };

    const handleConfig = async (id) => {
      history.push(`${pathRoutes.itemConfig}`.replace(":ident?", id))
    };

    const header = ['Código', 'Nome', 'Categoria', 'Ciclo de vida', 'Data Inclusão', 'Situação', 'Ações'];

    const rows = itemList.map(item => [
        item.code,
        item.name,
        item.category.name,
        item.detail?.lifeCycle,
        item.createdAt,
        <SwitchStatus
            id={item.id} 
            active={item.active}
            actions= {{
              onSwitch: (id, status) => handleChangeActive(id, status)
            }}
          />,
        <ItemActions
            id={item.id} 
            actions={{
              onEdit: () => handleEdit(item.id),
              onDelete: () => handleDelete(item.id),
              onPreview: () => handlePreview(item.id),
              onConfig: () => handleConfig(item.id)
            }} 
        />
    ]);

  return (
      <div className="container-fluid">
        <PageTitle title="Item" icon={<ListAltIcon style={{ color: 'gray' }}/>} />
        <div className="artical-1 out-shadow">
          <Grid container spacing={2}>
            
            <Grid item md={12} sm={12} xs={12}>
                <ItemFilter onFilter={handleFilter} />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Box>
                <ButtonAdd title="Cadastrar item" onClick={handleCreate}/>
              </Box>
            </Grid>
          
            <Grid item md={12} sm={12} xs={12}>
              <Box position="relative">
                <DataTable header={header} 
                            rows={rows}
                            totalPages={totalPages}
                            page={page} 
                            onPage={handleChangePage} 
                            onRowsPerPage={handleChangeRowsPerPage}/>
              </Box>
              <PreviewModal openDialog={showPreview} 
                            onCloseDialog={handleClosePreview} 
                            width="70%" height="55%">
                <ItemPreview id={itemIdPreview}/>
              </PreviewModal>
              </Grid>
          </Grid>
        </div>
      </div>
  );

}