import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import { Autocomplete } from "@material-ui/lab";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import siteApi from "../../../../services/siteApi";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        active: true,
        code: "",
        description: "",
        site: {
            id: ""
        }
    },
    sectors: [],
    sites: [],
    siteInput: {
        title: "",
        id: ""
    }
};

export default function SectorFilter(props) {

    const { onFilter } = props;

    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();
    const [code, setCode] = useQueryState("codeSector", '');
    const [siteId, setSiteId] = useQueryState("siteId", '');

    const [siteList, setSiteList] = useState(DEFAULT_STATE.sites);
    const [site, setSite] = useState(DEFAULT_STATE.siteInput);

    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllSites() {
            const siteResponse = await siteApi.findAll();
            if (siteResponse) {
                setSiteList(siteResponse.data.response);
            }
        }
        getAllSites();
    }, []);

    const handleFilter = async e => {
        if (e) {
            e.preventDefault();
        }
        let param = '';
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (siteId) {
            param = param.concat('&siteId=').concat(siteId);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const sites = siteList.map(site => ({
        title: site.name,
        id: site.id
    }));

    const cleanFilter = e => {
        onFilter();
        setIsFiltering(false);
        setSite(DEFAULT_STATE.siteInput);
        setValues({ siteId: "", codeSector: "" });
    };

    const handleInputCode = ({ target: { value } }) => {
        setCode(value);
    };

    const handleChangeSite = (value) => {
        setSite(value);
        setSiteId(value.id);
    };

    return (
        <BoxFilter>
            <form onSubmit={handleFilter}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="code"
                                label="Código do Setor"
                                value={code}
                                onChange={handleInputCode}>
                            </TextField>
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                            <Autocomplete
                                id="autocomplete-site"
                                options={sites}
                                clearText="Limpar"
                                value={site}
                                onChange={(event, value) => handleChangeSite(value ? value : '')}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) =>
                                    <TextField {...params} label="Sítio" variant="outlined" 
                                    helperText="Selecione o Sítio"/>
                                }
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className="mt-1 c-inline-btn">
                                <ButtonSearch
                                    label="Filtrar"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                />
                            </Box>
                            <Box className="mt-1 c-inline-btn">
                                {isFiltering && (
                                    <Button
                                        color="primary"
                                        onClick={cleanFilter}>
                                        Limpar Filtros
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </BoxFilter>
    );
}