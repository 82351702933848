export const serialize = (data) => {
    return {
        id: data.id,
        itemId: data.itemId,
        cbenef: data.cbenef, 
        scaleInd: data.scaleInd,
        cnpjFab: data.cnpjFab,
        barcode: data.barcode,
        exTipi: data.exTipi
    }
}

export const deserialize = (data) => {
    return {
        id: data.id,
        item: data.itemId,
        cbenef: data.cbenef,
        scaleInd: data.scaleInd,
        cnpjFab: data.cnpjFab,
        barcode: data.barcode,
        exTipi: data.exTipi
    }
}


export default {
    serialize
}