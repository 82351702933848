import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { pathRoutes } from "../../config/constants";
import LoginPage from '../../components/LoginPage';
import { TextField } from "@material-ui/core";
import recuperarSenhaApi from "../../services/recuperarSenhaApi";
import ButtonSubmit from '../../components/ButtonSubmit';
import toast from "../../utils/toast";
import { getLastPage } from "../../utils/auth";

const DEFAULT_STATE = {
  isLoading: false,
  data: {},
};

export default function RecuperarSenha({ history }) {
  const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);
  const [data, setData] = useState(DEFAULT_STATE.data);

  const { email } = data;

  const handleInputChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const recuperacao = await recuperarSenhaApi.put(data);
    setIsLoading(DEFAULT_STATE.isLoading);
    if (recuperacao) {
      toast.success(
        `Foi enviado uma senha temporária de acesso no seu email cadastrado.`
      );

      const lastPage = getLastPage();
      if (lastPage) {
        history.push(lastPage);
      } else {
        history.push(pathRoutes.login);
      }
    }
  };

  return (
    <LoginPage 
      label="Esqueceu sua senha?" 
      text="Digite seu e-mail cadastrado no campo abaixo que
               enviaremos um e-mail com as instruções para você
               cadastrar uma nova senha!"
      body={
            <>
              <form className="user" onSubmit={handleSubmit}>
                <div className="form-group">
                  <TextField
                    id="email"
                    name="email"
                    className="form-control"
                    aria-describedby="email"
                    label="Digite seu e-mail"
                    autoComplete="on"
                    value={email}
                    onChange={handleInputChange}
                    require
                  />
                </div>
                <br/>
                <ButtonSubmit
                  label="Enviar"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn btn-primary btn-user btn-block"
                  isLoading={isLoading}
                />
              </form>
              <hr />
              <div className="text-center">
                <Link className="small" to={pathRoutes.login}>
                  Voltar para o Login!
                </Link>
              </div>
            </>
      } />
  );
}

RecuperarSenha.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
