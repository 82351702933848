import { Box, Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import cadastrarEmpresaApi from "../../services/cadastrarEmpresaApi";
import userDetailsApi from "../../services/userDetailsApi";
import { getUser, setUser } from '../../utils/auth';
import toast from "../../utils/toast";
import ButtonSubmit from "../ButtonSubmit";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

const DEFAULT_STATE = {
    data: {
        nome: "",
        documento: ""
    },
  };

export default function Empresa() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const user = getUser();
        const data = {
            nome : user.company?.name,
            documento: user.company?.document,
        };
        setData(data);
    },[]);

    const handleInputChange = ({ target: { name, value } }) => {
        setData({ ...data, [name]: value });
    };

    const getUserDetails = async(email) => {
        const userDetails = await userDetailsApi.get(email);
        return userDetails.data.response[0];
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        const user = getUser();
        let empresa;
        if (user.company.id) {
            empresa = await cadastrarEmpresaApi.put(data, user.company.id)  
        } else {
            empresa = await cadastrarEmpresaApi.post(data, user.email)
        }
        setIsLoading(false);
        if (empresa) {
            const userDetails = await getUserDetails(user.email);
            setUser(userDetails);
            toast.success("Empresa cadastrada com sucesso");
        }
    }
    
    return(
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid container spacing={3}>
                    <Grid item md={4} sm={5} xs={12}>
                        <div className="form-group-perfil">
                            <TextField
                                className="form-control"
                                name="nome"
                                label="Nome da empresa"
                                value={data.nome}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BusinessCenterIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={5} xs={12}>
                        <div className="form-group-perfil c-inline-empresa">
                            <TextField
                                className="form-control"
                                name="documento"
                                label="CNPJ"
                                value={data.documento}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Box className="c-inline-btn">
                    <ButtonSubmit
                        label="Salvar"
                        type="submit"
                        variant="contained"
                        isLoading={isLoading}
                        color="primary"
                        className="btn btn-primary btn-user btn-block"
                    />
                </Box>
            </Box>
        </form>
    )
}