export const serialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        description: data.description,
        percentTax: data.percentTax,
        active: data.active,
        taxType: {
            id: data.taxType ? data.taxType.id : ""
        },
        cst: {
            id: data.cst ? data.cst.id : ""
        },
        createdAt: data.createdAt
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        description: data.description,
        percentTax: data.percentTax,
        active: data.active,
        taxType: {
            id: data.taxType ? data.taxType.id : ""
        },
        createdAt: data.createdAt
    }

}

export default{
    serialize
}