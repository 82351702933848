import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import toast from '../../utils/toast';
import DataTable from '../../components/DataTable';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import PreviewModal from '../../components/PreviewModal';
import { useLoader } from '../../hooks/LoaderContext';
import { pathRoutes } from "../../config/constants";
import ButtonAdd from "../../components/ButtonAdd";
import SwitchStatus from '../../components/SwitchStatus';
import taxOperationApi from '../../services/taxOperationApi';
import BuildIcon from '@material-ui/icons/Build';
import OperacaoFiscalFilter from '../../components/pages/OperacaoFiscal/OperacaoFiscalFilter';
import TaxOperationActions from "../../components/TaxOperationActions";
import OperacaoFiscalPreview from "../../components/pages/OperacaoFiscal/OperacaoFiscalPreview";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    code: "",
    description: "",
    percentTax: "",
    active: true,
    taxType: {},
    createdAt: ""
  },
  taxOperations: []
};

export default function OperacaoFiscal() {

  const [taxOperationList, setTaxOperationList] = useState(DEFAULT_STATE.taxOperations);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [taxOperationIdPreview, setTaxOperationIdPreview] = useState(0);
  const history = useHistory();
  const [filters, setFilters] = useState('');
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
    async function getAllTaxOperations() {
      showLoader();
      const taxOperationResponse = await taxOperationApi.findAllPageable(page-1, rowsPerPage, filters);
      if (taxOperationResponse) {
        setTaxOperationList(taxOperationResponse.data.response);
        setTotalPages(taxOperationResponse.data.totalPages);
      } else {
        setTaxOperationList(DEFAULT_STATE.taxOperations);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllTaxOperations();
  }, [filters, forceRefreshData]);

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };

  const handleDelete = async (id) => {
    const deleted = await taxOperationApi.delete(id);
    if (deleted) {
      if (taxOperationList.length == 1) {
        setPage(page - 1);
      }
      setForceRefreshData(!forceRefreshData);
      toast.success(`Operação Fiscal deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Operação Fiscal, favor verifique!.`);
    }
  };

  const handlePreview = (id) => {
    setTaxOperationIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.operacaoFiscalEdit}`.replace(":ident?", id))
  };

  const handleConfig = async (id) => {
    history.push(`${pathRoutes.operacaoFiscalConfig}`.replace(":ident?", id))
  };

  const handleCreate = async () => {
    history.push(`${pathRoutes.operacaoFiscalCadastro}`)
  };

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const taxOperation = await taxOperationApi.status(data);
    if (taxOperation) {
      if (taxOperation.data.response[0].active) {
        toast.success(`Operação Fiscal ativada com sucesso.`);
      } else {
        toast.success(`Operação FIscal desativada com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Código', 'Descrição', 'Cfop', 'Modelo Documento', 'Situação', 'Ações'];

  const rows = taxOperationList.map(taxOperation => [
    taxOperation.code,
    taxOperation.description,
    taxOperation.cfop,
    taxOperation.documentModel,
    <SwitchStatus
      id={taxOperation.id}
      active={taxOperation.active}
      actions={{
        onSwitch: (id, status) => handleChangeActive(id, status)
      }}
    />,
    <TaxOperationActions
      id={taxOperation.id}
      actions={{
        onEdit: () => handleEdit(taxOperation.id),
        onConfig: () => handleConfig(taxOperation.id),
        onDelete: () => handleDelete(taxOperation.id),
        onPreview: () => handlePreview(taxOperation.id)
      }}
    />
  ]);

  return (
    <>
      <div className="container-fluid">
        <PageTitle title="Configuração Operação Fiscal" icon={<BuildIcon style={{ color: 'gray' }} />} />
        <div className="artical-1 out-shadow">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <OperacaoFiscalFilter onFilter={handleFilter} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box>
                <ButtonAdd title="Cadastrar Operação" onClick={handleCreate} />
              </Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box position="relative">
                <DataTable header={header}
                  rows={rows}
                  rowsPerPage={rowsPerPage}
                  totalPages={totalPages}
                  page={page}
                  onPage={handleChangePage}
                  onRowsPerPage={handleChangeRowsPerPage} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <PreviewModal openDialog={showPreview}
        onCloseDialog={handleClosePreview} width="70%" height="55%">
        <OperacaoFiscalPreview id={taxOperationIdPreview} />
      </PreviewModal>
    </>
  );

}