import { pathRoutes } from "../../../config/constants";
import EmpresaCadastro from "./index";

export default [
  {
    path: pathRoutes.empresaCadastro,
    component: EmpresaCadastro,
    exact: true,
  },
  {
    path: pathRoutes.empresaEdit,
    component: EmpresaCadastro,
    exact: true,
  },
];
