import { TextField, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    textField: {
      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#2d3748',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#2d3748',
      },
    }
    }));

export default function TextFieldPreview(props) {
    const { name, label, value, className, fullWidth, InputLabelProps, InputProps, variant } = props;

    const classes = useStyles();

    return (
        <TextField 
            className={`${classes.textField} ${className}`} 
            fullWidth={fullWidth} 
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            label={label} 
            value={value} 
            name={name} 
            variant={variant}
            disabled/>
      );
}