export const serialize = (data) => {
    return {
        id: data.id,
        number: data.number,
        series: data.series,
        model: data.model,
        active: data.active,
        company: {
            id: data.company ? data.company.id : ""
        },
        createdAt: data.createdAt
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        number: data.number,
        series: data.series,
        model: data.model,
        active: data.active,
        company: {
            id: data.company ? data.company.id : ""
        },
        createdAt: data.createdAt
    }

}


export default{
    serialize
}