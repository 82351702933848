import { pathRoutes } from "../../../config/constants";
import AliquotaCadastro from "./index";

export default [
  {
    path: pathRoutes.aliquotaCadastro,
    component: AliquotaCadastro,
    exact: true,
  },
  {
    path: pathRoutes.aliquotaEdit,
    component: AliquotaCadastro,
    exact: true,
  },
];
