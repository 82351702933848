import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import itemUnitDefaultApi from "../../../../../services/itemUnitDefaultApi";
import { pathRoutes } from "../../../../../config/constants";
import DataTable from "../../../../DataTable";
import toast from "../../../../../utils/toast";
import ButtonAdd from "../../../../ButtonAdd";
import PreviewModal from "../../../../PreviewModal";
import { useLoader } from "../../../../../hooks/LoaderContext";
import ItemUnidadePadraoPreview from "./ItemUnidadePadraoPreview";
import DataTableDefaultAction from "../../../../DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  itemUnitDefaults: []
};

export default function ItemUnidadePadrao() {

  const [itemUnitDefaultList, setItemUnitDefaultList] = useState(DEFAULT_STATE.itemUnitDefaults);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [idPreview, setIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const params = useParams();

  useEffect(() => {
    async function getAllItemUnitDefault() {
        showLoader();
        const itemUnitDefaultResponse = await itemUnitDefaultApi.findAllPageable(page-1 , rowsPerPage, `item_id=${params.ident}`);
        if (itemUnitDefaultResponse) {
            setItemUnitDefaultList(itemUnitDefaultResponse.data.response);
            setTotalPages(itemUnitDefaultResponse.data.totalPages);
        } else {
            setItemUnitDefaultList(DEFAULT_STATE.itemUnitDefaults);
            setTotalPages(0);
        }
        hideLoader();
    }
    getAllItemUnitDefault();
  },[forceRefreshData])

  const handleDelete = async (id) => {
    const deleted = await itemUnitDefaultApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Unidade padrão do item deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Unidade padrão do item, favor verifique!.`);
    }
  };
  
  const handleEdit = async (id) => {
    history.push(`${pathRoutes.itemConfigUnidadePadraoEdit.replace(":ident?", "")}${id}`)
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.itemConfigUnidadePadraoCadastro.replace(":itemId?", "")}${params.ident}`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Unidade', 'Item', 'Ações'];

  const rows = itemUnitDefaultList.map(itemUnitDefault => [
    `${itemUnitDefault.unit.code} - ${itemUnitDefault.unit.description}`,
    itemUnitDefault.item.name,
    <DataTableDefaultAction
        id={itemUnitDefault.id}
        actions={{
          onEdit: () => handleEdit(itemUnitDefault.id),
          onDelete: () => handleDelete(itemUnitDefault.id),
          onPreview: () => handlePreview(itemUnitDefault.id)
        }} 
      />
  ]);
 
  return (
    <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <ButtonAdd title="Cadastrar Unidade Padrão" onClick={handleCreate} disabled={itemUnitDefaultList.length > 0}/>
          </Box>
        </Grid>
      
        <Grid item md={12} sm={12} xs={12}>
          <Box position="relative">
            <DataTable  header={header} 
                        rows={rows} 
                        totalPages={totalPages}
                        page={page} 
                        onPage={handleChangePage} 
                        onRowsPerPage={handleChangeRowsPerPage}/>
          </Box>
          <PreviewModal openDialog={showPreview} 
                        onCloseDialog={handleClosePreview} 
                        width="70%" height="35%">
            <ItemUnidadePadraoPreview id={idPreview}/>
          </PreviewModal>
          </Grid>
    </Grid>
  );
}