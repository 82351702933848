import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function OrderCreatedDialog(props) {

  const { openDialog, onConfirm, onCancel, data  } = props;

  const [copied, setCopied] = useState(false);

  return (
    <div>
      <Dialog
        open={openDialog}
        maxWidth="800"
        aria-labelledby="order-dialog-title"
        aria-describedby="order-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#f7f7f7',
            borderRadius: 10,
            width: '35%',
            height: '40%',
            padding: '20px',
          },
        }}>
        <DialogTitle id="alert-dialog-title" style={{ color: '#2d3748', fontSize: '1.8rem', marginBottom: '10px', textAlign: 'center' }}>
          <DoneAllIcon style={{ color: '#4CAF50' }} size='large' /> Pedido <b>#{data?.number}</b> criado com sucesso
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ fontSize: '1.2rem', textAlign: 'center', color: '#2d3748', marginBottom: '50px' }}>
            Acompanhe o andamento do seu pedido!
            </DialogContentText>
          <DialogContentText id="alert-dialog-description" style={{ color: '#757575', fontSize: '1rem', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <TextField
                id="protocol"
                label="Protocolo"
                variant="outlined"
                fullWidth
                value={data?.uuid}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Tooltip title={copied ? "Copiado" : "Clique para copiar"}>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(data?.uuid);
                          setCopied(true);
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </div>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button onClick={onCancel} color="primary">
            Página inicial
          </Button>
          <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
            Acompanhar pedido
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
