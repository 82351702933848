import { pathRoutes } from "../../config/constants";
import Confirmacao from "./Confirmacao/index";
import EsperaConfirmacao from "./EsperaConfimacao";
import CadastroUsuario from "./index";

export default [
  {
    path: pathRoutes.cadastro,
    component: CadastroUsuario,
    exact: true,
    noAuth: true,
  },
  {
    path: `${pathRoutes.cadastro}/confirmacao/:token`,
    component: Confirmacao,
    exact: true,
    noAuth: true
  },
  {
    path: pathRoutes.esperaConfirmacao,
    component: EsperaConfirmacao,
    exact: true,
    noAuth: true
  }
];
