import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, DialogContent } from '@material-ui/core';

export default function PreviewModal(props) {

  const { openDialog, onCloseDialog, width, height, children } = props;
  
  return (
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: { 
            width: `${width}`,
            height: `${height}`,
            maxWidth: 'none',
          },
        }}>
        <DialogContent>
          <Box>
            {children}
          </Box>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => onCloseDialog()} color="primary">
                Fechar
              </Button>
          </DialogActions>
      </Dialog>
  );
}
