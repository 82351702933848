import { pathRoutes } from "../../../config/constants";
import SupplierConfig from "./index";

export default [
  {
    path: pathRoutes.pessoaConfig,
    component: SupplierConfig,
    exact: true,
  },
];
