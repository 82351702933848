import { serialize } from "./mapper/CadastroMapper";
import { get, post, put }  from "../utils/request";

export const cadastraUsuario = async (data) => {
  return post('out/user', serialize(data));
};

export const atualizaUsuario = async (data) => {
  return put('v1/user', serialize(data));
};

export const confirmaUsuario = async (token) => {
  return get(`out/user/active?token=${token}`)
}

export default {
  post: cadastraUsuario,
  put: atualizaUsuario,
  confirmaUsuario
};
