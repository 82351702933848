import React from 'react';
import { useLoader } from '../../hooks/LoaderContext';
import arselLoader from '../../assets/loader/arsel-loader.json';
import Lottie from 'lottie-react';

const Loader = ({ children }) => {
  const { isLoading } = useLoader();

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: arselLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    width: '120px',
    height: '120px'
  };

  return (
    <>
      {isLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)', 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1050, 
        }}>
          <Lottie animationData={arselLoader} autoplay loop style={{ width: defaultOptions.width, height: defaultOptions.height }} />
        </div>
      )}
      <div style={{
        filter: isLoading ? 'blur(4px)' : 'none',
        transition: 'filter 0.5s ease',
      }}>
        {children}
      </div>
    </>
  );
};
  
export default Loader;
