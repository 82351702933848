import { pathRoutes } from "../../../config/constants";
import FluxoCaixaLancamento from "./index";

export default [
  {
    path: pathRoutes.fluxoCaixaLancamento,
    component: FluxoCaixaLancamento,
    exact: true,
  },
  {
    path: pathRoutes.fluxoCaixaEdit,
    component: FluxoCaixaLancamento,
    exact: true,
  },
];


