import { TextField } from "@material-ui/core";
import React from "react";

export default function InputText(props) {
    const { id, name, className, ariaDescribedby, label, autoComplete, required, value, onChange, onBlur } = props;

    return (
        <TextField
            id={id}
            name={name}
            className={className}
            aria-describedby={ariaDescribedby}
            label={label}
            color="primary"
            autoComplete={autoComplete}
            required={required}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
}