import { Box, Button } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add';
import React from "react";

export default function ButtonAdd(props) {
    const { onClick, className, disabled, title, size, simple } = props;

    return (
        <Box className={simple ? "" : "c-inline-btn-float-right"}>
             <Button
                variant="contained"
                color="primary"
                size={size}
                disabled={disabled}
                className={className}
                onClick={onClick}
                startIcon={<AddIcon />}>
                {title}
            </Button>
        </Box>
    )
}