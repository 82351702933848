import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import toast from "../../utils/toast";
import serviceApi from "../../services/serviceApi";
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import PageTitle from "../../components/PageTitle";
import ServicoFilter from "../../components/pages/Servico/ServicoFilter";
import ServicoPreview from "../../components/pages/Servico/ServicoPreview";
import ButtonAdd from "../../components/ButtonAdd";
import { pathRoutes } from "../../config/constants";
import { useHistory } from 'react-router-dom';
import PreviewModal from "../../components/PreviewModal";
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import DataTableDefaultAction from "../../components/DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  data: {
      id: "",
      active: false,
      code: "",
      description: ""
  },
  service: []
};

export default function Servico() {

  const [data, setData] = useState(DEFAULT_STATE.data);
  const [serviceList, setServiceList] = useState(DEFAULT_STATE.service);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState();
  const [servicoIdPreview, setServicoIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  
  useEffect(() => {
    async function getAllServices() {
        showLoader();
        const serviceReponse = await serviceApi.findAllPageable(0, rowsPerPage);
        if (serviceReponse) {
          setServiceList(serviceReponse.data.response);
          setTotalPages(serviceReponse.data.totalPages);
        }
        hideLoader();
    }
    getAllServices();
  },[])

  const handleDelete = async (id) => {
    const deleted = await serviceApi.delete(id);
    if (deleted) {
      cleanForm();
      refreshDatatable(filters);
      toast.success(`Serviço deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir serviço, favor verifique!.`);
    }
  };

  const handleFilter = async (filters) => {
    refreshDatatable(filters);
    setFilters(filters);
  };
  
  const handleEdit = async (id) => {
    history.push(`${pathRoutes.servico}/edicao/${id}`)
  };

  const handleChangeActive = async (id, status) => {
    const data = {
        id: id,
        active: status
    };
    const service = await serviceApi.status(data);
    if (service) {
      if (service.data.response[0].active) {
        toast.success(`Serviço ativado com sucesso.`);
      } else {
        toast.success(`Serviço inativado com sucesso.`);
      }
      refreshDatatable(filters);
    }
  };

  const cleanForm = () => {
    setData(DEFAULT_STATE.data);
  }

  const refreshDatatable = async (filters) => {
    const serviceReponse = await serviceApi.findAllPageable(page-1, rowsPerPage, filters);
    if (serviceReponse) {
        setServiceList(serviceReponse.data.response);
    }else{
      setServiceList(DEFAULT_STATE.service);
    }
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    const serviceReponse = await serviceApi.findAllPageable(value-1, rowsPerPage, filters);
    if (serviceReponse) {
      setServiceList(serviceReponse.data.response);
      setTotalPages(serviceReponse.data.totalPages);
    }
  };

  const handlePreview = (id) => {
    setServicoIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.servico}/cadastro`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    const serviceReponse = await serviceApi.findAllPageable(0, rows, filters);
    if (serviceReponse) {
        setServiceList(serviceReponse.data.response);
        setTotalPages(serviceReponse.data.totalPages);
    }
  };

  const header = ['Código', 'Data Criação', 'Descrição', 'Situação', 'Ações'];

  const rows = serviceList.map(service => [
    service.code,
    service.createdAt,
    service.description,
    <SwitchStatus
        id={service.id} 
        active={service.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
    />,
    <DataTableDefaultAction
        id={service.id} 
        actions={{
          onEdit: () => handleEdit(service.id),
          onDelete: () => handleDelete(service.id),
          onPreview: () => handlePreview(service.id)
        }} 
    />
  ]);
 
  return (
    <div className="container-fluid">
      <PageTitle title="Serviços" icon={<BuildRoundedIcon style={{ color: 'gray' }}/>} />
      <div className="artical-1 out-shadow">
      <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
              <ServicoFilter onFilter={handleFilter} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Serviço" onClick={handleCreate}/>
            </Box>
          </Grid>
        
          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable header={header} 
                         rows={rows}
                         totalPages={totalPages}
                         page={page} 
                         onPage={handleChangePage} 
                         onRowsPerPage={handleChangeRowsPerPage}/>
            </Box>
            <PreviewModal openDialog={showPreview} 
                          onCloseDialog={handleClosePreview} 
                          width="70%" height="35%">
              <ServicoPreview id={servicoIdPreview}/>
            </PreviewModal>
            </Grid>
        </Grid>
      </div>
    </div>
  );
}
