import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PieChartIcon from '@material-ui/icons/PieChart';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import unitApi from "../../../../services/unitApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        code: "",
        description: ""
    }
  };

export default function UnidadePreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const unitResponse = await unitApi.findById(id);
        if (unitResponse) {
            const unitData = unitResponse.data.response[0];
            setData({
                code : unitData.code,
                description: unitData.description
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Unidade" icon={<PieChartIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="code"
                        label="Sigla"
                        value={data.code}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}