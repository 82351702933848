import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import toast from '../../utils/toast'

import { getLastPage } from "../../utils/auth";
import { pathRoutes } from "../../config/constants";
import cadastroUsuarioApi from "../../services/cadastroUsuarioApi";
import LoginPage from '../../components/LoginPage';

import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ButtonSubmit from '../../components/ButtonSubmit';

const DEFAULT_STATE = {
  isLoading: false,
  data: {},
};

export default function CadastroUsuario({ history }) {
  const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);
  const [data, setData] = useState(DEFAULT_STATE.data);

  const { nome } = data;
  const { sobrenome } = data;
  const { senha } = data;
  const { confirmaSenha } = data;
  const { email } = data;

  const [values, setValues] = React.useState({
    showPassword: false,});

  const handleInputChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const validatePassword = () => {
    if (senha !== confirmaSenha) {
      toast.error("As senhas não conferem!");
      setIsLoading(DEFAULT_STATE.isLoading);
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if(!validatePassword()){
      return;
    }
    const usuario = await cadastroUsuarioApi.post(data);
    setIsLoading(DEFAULT_STATE.isLoading);
    if (usuario) {
      toast.success(
        `Foi enviado um e-mail para confirmação de identidade. Favor verificar sua caixa de e-mail.`
      );
      const lastPage = getLastPage();
      if (lastPage) {
        history.push(lastPage);
      } else {
        history.push(pathRoutes.esperaConfirmacao)
      }
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
        <LoginPage label="Cadastre-se agora!" 
               text="Realize seu cadastro gratuitamente."
        body={
        <>
          <form className="user" onSubmit={handleSubmit}>
          <div className="form-group">
            <TextField
                id="emailCadastro"
                name="email"
                className="form-control"
                label="E-mail"
                autoComplete="on"
                value={email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group form-group-inline">
              <TextField
                  id="nomeCadastro"
                  name="nome"
                  className="form-control"
                  aria-describedby="nomeCadastro"
                  label="Nome"
                  autoComplete="on"
                  value={nome}
                  onChange={handleInputChange}
                  required
              />
            </div>
            <div className="form-group form-group-inline">
              <TextField
                  id="sobrenomeCadastro"
                  name="sobrenome"
                  className="form-control"
                  aria-describedby="sobrenome"
                  label="Sobrenome"
                  autoComplete="on"
                  value={sobrenome}
                  onChange={handleInputChange}
                  required
              />
            </div>
            <div className="form-group form-group-inline">
            <FormControl className="form-password-min">
                  <InputLabel htmlFor="standard-adornment-password">Senha *</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    name="senha"
                    type={values.showPassword ? 'text' : 'password'}
                    value={senha}
                    required
                    onChange={handleInputChange}
                    
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  />
                </FormControl>
            </div>
            <div className="form-group form-group-inline">
            <FormControl className="form-password-min">
                  <InputLabel htmlFor="standard-adornment-password">Confirme a senha *</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    name="confirmaSenha"
                    type={values.showPassword ? 'text' : 'password'}
                    value={confirmaSenha}
                    required
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  />
            </FormControl>
            </div>
            <br/>
            <br/>
            <ButtonSubmit
                label="Cadastrar"
                type="submit"
                variant="contained"
                color="primary"
                className="btn btn-primary btn-user btn-block"
                isLoading={isLoading}/>
          <hr />
          <div className="text-center">
            <Link className="small" to={pathRoutes.login}>
              Voltar para o Login!
            </Link>
          </div>
        </form>
        </>
      } />
  );
}

CadastroUsuario.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
