import { Box, Divider, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../../components/PageTitle";
import { useParams } from 'react-router-dom'
import ReceiptIcon from '@material-ui/icons/Receipt';
import BasicReturn from "../../../components/BasicReturn";
import { useQueryState } from "../../../hooks/QueryState";
import CategoryConfigInvoice from "../../../components/pages/categoria/config/CategoryConfigInvoice";
import categoryApi from "../../../services/categoryApi";

export default function CategoriaConfig() {

  const [currentTab, setCurrentTab] = useQueryState("tab", "config-invoice");
  const [categoria, setCategoria] = useState({});
  const params = useParams();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  }; 

  useEffect(() => {
    async function getCategoryById() {
        const categoryResponse = await categoryApi.findById(params.ident);
        if (categoryResponse) {
          setCategoria(categoryResponse.data.response[0]);
        }
    }
    getCategoryById();
  },[]);

  return (
      <div className="container-fluid">
        <PageTitle title={`Configuração da categoria - ${categoria.name}`} icon={<AssignmentIcon style={{ color: 'gray' }}/>} />
        <BasicReturn />
        <Divider />
        <Box className="artical-1 out-shadow">
          <TabContext value={currentTab} >
            <Box style={{display: "flex"}}>
              <Tabs 
                value={currentTab} 
                orientation="vertical"
                onChange={handleChange}
                style={{paddingTop : "3em", width: "15%"}}
                indicatorColor="primary"
                textColor="primary"
                centered>
                <Tab label="Informação Fiscal" icon={<ReceiptIcon />} value={"config-invoice"} />
              </Tabs>
              <TabPanel value={"config-invoice"} style={{width : "100%"}}>
                <CategoryConfigInvoice />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </div>
  );

}
