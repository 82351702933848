import { Box, Divider, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Perfil from "../../components/Perfil";
import Empresa from "../../components/Empresa";
import { useNavBar } from "../../hooks/NavbarContext";

function TabPanel(props) {
  
  const { children, value, index, ...other } = props;

  const {
    setSelectedItem,
  } = useNavBar();

  useEffect(() => {
    setSelectedItem(null);
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export default function Conta() {
  const [value, setValue] = useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Conta</h1>
      <Divider />
      <div className="card shadow mt-4">
        <div className="card-header tabs">
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}>
            <Tab label="Meu perfil" {...a11yProps(0)}/>
            <Tab label="Minha Empresa" {...a11yProps(1)} />
          </Tabs>
          </div>
          <div className="card-body">
          <TabPanel value={value} index={0}>
            <Perfil />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Empresa />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
