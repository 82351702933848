import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import companyApi from "../../../../services/companyApi";
import BusinessIcon from '@material-ui/icons/Business';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        name: "",
        document: "",
        certificate: "",
        passCertificate: "",
        expCertificate: "",
        logo: "",
        environment: "",
        active: true,
        supplierName: "",
        createdAt: ""
    }
  };

export default function EmpresaPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function getCompany() {
        const companyResponse = await companyApi.findById(id);
        if (companyResponse) {
            const companyData = companyResponse.data.response[0];
            setData({
                name : companyData.name,
                document : companyData.document,
                environment: companyData.environment,
                supplierName: companyData.supplier.name
            })
        }
    }
    getCompany();
  },[])

  return (
        <Box>
            <PageTitle title="Empresa" icon={<BusinessIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="name"
                        label="Nome"
                        value={data.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="document"
                        label="Documento"
                        value={data.document}
                        variant="outlined"
                        fullWidth
                    />    
                </Grid>
                <Grid className="mt-4 mb-2" item md={4} sm={4} xs={12}>
                    <TextFieldPreview
                        name="supplierName"
                        label="Pessoa"
                        value={data.supplierName}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
  );
}