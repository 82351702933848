import { Box, Divider, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../../components/PageTitle";
import itemApi from "../../../services/itemApi";
import { useParams } from 'react-router-dom'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PieChartIcon from '@material-ui/icons/PieChart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ItemSubTabOpcoes from "../../../components/pages/item/config/ItemSubTabOpcoes";
import BasicReturn from "../../../components/BasicReturn";
import { useQueryState } from "../../../hooks/QueryState";
import ItemConfigInvoice from "../../../components/pages/item/config/ItemConfigInvoice";

export default function ItemConfig() {

  const [currentTab, setCurrentTab] = useQueryState("tab", "unidade");
  const [item, setItem] = useState({});
  const params = useParams();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  }; 

  useEffect(() => {
    async function getItemById() {
        const itemResponse = await itemApi.findById(params.ident);
        if (itemResponse) {
          setItem(itemResponse.data.response[0]);
        }
    }
    getItemById();
  },[]);

  return (
      <div className="container-fluid">
        <PageTitle title={`Configuração do item - ${item.name}`} icon={<AssignmentIcon style={{ color: 'gray' }}/>} />
        <BasicReturn />
        <Divider />
        <Box className="artical-1 out-shadow">
          <TabContext value={currentTab} >
            <Box style={{display: "flex"}}>
              <Tabs 
                value={currentTab} 
                orientation="vertical"
                onChange={handleChange}
                style={{paddingTop : "3em", width: "15%"}}
                indicatorColor="primary"
                textColor="primary"
                centered>
                <Tab label="Unidade" icon={<PieChartIcon/>} value={"unidade"} />
                <Tab label="Precificação" icon={<AttachMoneyIcon />} value={"precificacao"} />
                <Tab label="Informação Fiscal" icon={<ReceiptIcon />} value={"config-invoice"} />
              </Tabs>
              <TabPanel value={"unidade"} style={{width : "100%"}}>
                <ItemSubTabOpcoes menu="unidade"/>
              </TabPanel>
              <TabPanel value={"precificacao"} style={{width : "100%"}}>
                <ItemSubTabOpcoes menu="precificacao" />
              </TabPanel>
              <TabPanel value={"config-invoice"} style={{width : "100%"}}>
                <ItemConfigInvoice />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </div>
  );

}
