import { Box, Divider,Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import { useParams } from 'react-router-dom'
import { useQueryState } from "../../../../../hooks/QueryState";
import ItemUnidadePadrao from "../ItemUnidadePadrao";
import ItemUnidadeCliente from "../ItemUnidadeCliente";
import ItemPrecificacaoPadrao from "../ItemPrecificacaoPadrao";
import ItemPrecificacaoCliente from "../ItemPrecificacaoCliente";

export default function ItemSubTabOpcoes(props) {

  const { menu } = props

  const [currentTab, setCurrentTab] = useQueryState("subtab", "padrao");
  
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  }; 

  return (
      <div className="container-fluid" style={{display: "flex"}}>
        <Box border={1} borderRadius="borderRadius" borderColor="#DDDDDD" p={2} mt={2} width={"100%"}>
          <TabContext value={currentTab}>
                  <Tabs
                    value={currentTab} 
                    onChange={handleChange}
                    indicatorColor="primary"
                    centered
                    textColor="primary">
                    <Tab label="Padrão" value={"padrao"} />
                    <Tab label="Por Cliente" value={"cliente"} />
                  </Tabs>
                  <TabPanel value={"padrao"}>
                    {(menu == "unidade" &&(
                      <ItemUnidadePadrao/>
                    ))}
                    {(menu == "precificacao" &&(
                      <ItemPrecificacaoPadrao/>
                    ))}
                  </TabPanel>
                  <TabPanel value={"cliente"}>
                    {(menu == "unidade" &&(
                      <ItemUnidadeCliente/>
                    ))}
                    {(menu == "precificacao" &&(
                      <ItemPrecificacaoCliente/>
                    ))}
                  </TabPanel>
          </TabContext>
          </Box>
    </div>
  );

}
