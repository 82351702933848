import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { pathRoutes } from "../../../../../config/constants";
import DataTable from "../../../../DataTable";
import toast from "../../../../../utils/toast";
import ButtonAdd from "../../../../ButtonAdd";
import PreviewModal from "../../../../PreviewModal";
import { useLoader } from "../../../../../hooks/LoaderContext";
import DataTableDefaultAction from "../../../../DataTableDefaultActions";
import taxOperationItemApi from "../../../../../services/taxOperationItemApi";
import OperacaoFiscalItemFilter from "./OperacaoFiscalFilterItem";
import OperacaoFiscalItemPreview from "./OperacaoFiscalPreviewItem";

const DEFAULT_STATE = {
  isLoading: false,
  taxOperationItems: []
};

export default function OperacaoFiscalItem() {

  const [taxOperationItemList, setTaxOperationItemList] = useState(DEFAULT_STATE.taxOperationItems);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [idPreview, setIdPreview] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const params = useParams();

  const [filters, setFilters] = useState();

  useEffect(() => {
    async function getAllTaxOperationItemList() {
      showLoader();
      const taxOperationItemResponse = await taxOperationItemApi.findAllPageable(page - 1, rowsPerPage,
        `&tax_operation_id=${params.ident}&type=ITEM${filters? filters : ''}`);
      if (taxOperationItemResponse) {
        setTaxOperationItemList(taxOperationItemResponse.data.response);
        setTotalPages(taxOperationItemResponse.data.totalPages);
      } else {
        setTaxOperationItemList(DEFAULT_STATE.taxOperationItems);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllTaxOperationItemList();
  }, [forceRefreshData, filters])

  const handleDelete = async (id) => {
    const deleted = await taxOperationItemApi.delete(id);
    if (deleted) {
      setForceRefreshData(!forceRefreshData);
      toast.success(`Operação Fiscal do item deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir Operação Fiscal da categoria, favor verifique!.`);
    }
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.operacaoFiscalConfigItemEdit.replace(":ident?", "")}${id}`)
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCreate = async () => {
    history.push(`${pathRoutes.operacaoFiscalConfigItemCadastro.replace(":itemId?", "")}${params.ident}`)
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const header = ['Tipo de imposto', 'CST', 'Item', 'Ações'];

  const rows = taxOperationItemList.map(taxOperationItem => [
    taxOperationItem.aliquotPercent?.taxType?.description,
    taxOperationItem.aliquotPercent.cst ? `${taxOperationItem.aliquotPercent.cst.code} - ${taxOperationItem.aliquotPercent.cst.description}`: '-',
    taxOperationItem.item? taxOperationItem.item.name : '-',
    
    <DataTableDefaultAction
      id={taxOperationItem.id}
      actions={{
        onEdit: () => handleEdit(taxOperationItem.id),
        onDelete: () => handleDelete(taxOperationItem.id),
        onPreview: () => handlePreview(taxOperationItem.id)
      }}
    />
  ]);

  const handleFilter = async (filters) => {
    setFilters(filters);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <OperacaoFiscalItemFilter onFilter={handleFilter} />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Box>
          <ButtonAdd title="Cadastrar imposto por Item" onClick={handleCreate} />
        </Box>
      </Grid>

      <Grid item md={12} sm={12} xs={12}>
        <Box position="relative">
          <DataTable header={header}
            rows={rows}
            totalPages={totalPages}
            page={page}
            onPage={handleChangePage}
            onRowsPerPage={handleChangeRowsPerPage} />
        </Box>
        <PreviewModal openDialog={showPreview}
          onCloseDialog={handleClosePreview}
          width="70%" height="55%">
          <OperacaoFiscalItemPreview id={idPreview} />
        </PreviewModal>
      </Grid>
    </Grid>
  );
}