import { Box, Button, Grid, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import generoApi from "../../../../services/generoApi";
import { Autocomplete } from "@material-ui/lab";

const DEFAULT_STATE = {
    isLoading: false,
    generoList: [],
    genero: {
        description: "",
        code: ""
    }
};

export default function CategoriaFilter(props) {

    const { onFilter } = props;
    const [name, setName] = useQueryState("name", '');
    const [generoId, setGeneroId] = useQueryState("generoId", '');
    const [genero, setGenero] = useState(DEFAULT_STATE.genero);
    const [generoList, setGeneroList] = useState(DEFAULT_STATE.generoList);
    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();

    useEffect(() => {
        handleFilter();
    }, [])

    useEffect(() => {
        async function getAllGenero() {
            const generoResponse = await generoApi.findAll();
            if (generoResponse) {
                setGeneroList(generoResponse.data.response);
            }
        }
        getAllGenero();
    }, []);

    const handleFilter = () => {
        let param = '';
        if (name) {
            param = param.concat('&name=').concat(name);
        }
        if (generoId) {
            param = param.concat(`&genero=${generoId}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const onSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        onFilter();
        setIsFiltering(false);
        setValues({ generoId: "", name: "" })
        setGenero(DEFAULT_STATE.genero)
    };

    const handleInputChangeName = ({ target: { value } }) => {
        setName(value);
    };

    const handleChangeGenero = (value) => {
        setGenero(value);
        setGeneroId(value.code);
    };

    const generos = generoList.map(genero => ({
        title: `${genero.code} - ${genero.description}`,
        code: genero.code
    }));

    return (
        <BoxFilter>
            <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                    <Grid className="mt-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="name"
                            label="Nome"
                            value={name}
                            onChange={handleInputChangeName}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-2" item md={6} sm={6} xs={12}>
                        <Autocomplete
                            id="autocomplete-invoice-genero"
                            options={generos}
                            clearText="Limpar"
                            value={genero}
                            onChange={(event, value) => handleChangeGenero(value ? value : '')}
                            getOptionLabel={(option) => option.title}
                            getOptionSelected={(option, value) => option.code === value.code}
                            renderInput={(params) =>
                                <TextField {...params} label="Genero" variant="outlined" />
                            } />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box className="mt-1 c-inline-btn">
                            <ButtonSearch
                                label="Filtrar"
                                type="submit"
                                variant="outlined"
                                color="primary"
                            />
                        </Box>
                        <Box className="mt-1 c-inline-btn">
                            {isFiltering && (
                                <Button
                                    color="primary"
                                    onClick={cleanFilter}>
                                    Limpar Filtros
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </BoxFilter>
    );
}