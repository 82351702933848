import { Box, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from "../../../PageTitle";
import TextFieldPreview from "../../../TextFieldPreview";
import chartAccountApi from "../../../../services/chartAccountApi";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        active: true,
        name: "",
        description: "",
        chartAccount: {
            name: ""
        }
    }
  };

export default function PlanoContaPreview(props) {

  const { id } = props;
  const [data, setData] = useState(DEFAULT_STATE.data);
  
  useEffect(() => {
    async function findById() {
        const chartAccountResponse = await chartAccountApi.findById(id);
        if (chartAccountResponse) {
            const chartAccountData = chartAccountResponse.data.response[0];
            setData({
                name : chartAccountData.name,
                description: chartAccountData.description
            })
        }
    }
    findById();
  },[])

  return (
    <>
        <Box>
            <PageTitle title="Item Plano de Contas" icon={<AssignmentIcon style={{ color: 'gray' }}/>}/>
            <Grid container spacing={2}>
                    <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="name"
                        label="Nome"
                        value={data.name}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
                    <TextFieldPreview
                        name="description"
                        label="Descrição"
                        value={data.description}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    </>
  );
}