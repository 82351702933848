export const serialize = (data) => {
    return {
        id: data.id,
        item: {
            id : data.item?.id
        },
        unit: {
            id : data.unit?.id
        },
        supplier: {
            id : data.supplier?.id
        },
        active: data.active,
    }
}


export default {
    serialize
}