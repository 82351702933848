import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { Suspense } from "react";

import Router from "./config/router";
import { NavBarProvider } from "./hooks/NavbarContext";
import Loader from "./components/Loader";
import { LoaderProvider } from "./hooks/LoaderContext";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#0095FF",
      dark: "#007dd6",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#e6e6e6",
      dark: "#4F771C",
      contrastText: "#000",
    },
    third: {
      light: "#5fba7d",
      main: "#0095FF",
      dark: "#ba000d",
      contrastText: "#000",
    },
    removeButton: {
      light: "#000",
      main: "#000",
      dark: "#000",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <NavBarProvider>
        <LoaderProvider>
            <Loader>
              <Router />
            </Loader>
        </LoaderProvider>
        </NavBarProvider>
    </ThemeProvider>
  );
}

export default App;
