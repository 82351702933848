import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import React from "react";

const useStyles = makeStyles((theme) => ({
    error: {
        backgroundColor: theme.palette.error.dark,
      '&:hover': {
        backgroundColor: theme.palette.error.light
      }
    },
    warning: {
        backgroundColor: "#FF6400",
      '&:hover': {
        backgroundColor: "#FFA500"
      }
    },
    success: {
        backgroundColor: "#568203",
      '&:hover': {
        backgroundColor: "#2E8857",
      }
    },
    root: {
        width: '100%',
      },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
      },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },

      paper: {
        padding: '6px 16px',
      },
    
  }));

export default function CustomTimelineItem({ key, date, time, title, subtitle, status, children }) {

    const classes = useStyles();

    const translateColorByStatus = (status) => {
        switch (status) {
            case 'ERROR':
                return classes.error;
            case 'SUCCESS':
                return classes.success;
            case 'APPROVED':
                return classes.success;
            case 'REJECTED':
                return classes.error;
            case 'PROCESSING':
                return;
            case 'SUCCESS_WARNING':
                return classes.warning;
            default:
        }
    }

    const translateTitleByStatus = (status) => {
        switch (status) {
            case 'ERROR':
                return 'Processado com erro';
            case 'SUCCESS':
                return 'Processado com sucesso';
            case 'APPROVED':
                return 'Pedido aprovado';
            case 'REJECTED':
                return 'Pedido Rejeitado';
            case 'PROCESSING':
                return 'Processando';
            case 'SUCCESS_WARNING':
                return 'Processado com sucesso, mas com alerta';
            default:
        }
    }
    
    const translateIconByStatus = (status) => {
        switch (status) {
            case 'ERROR':
                return <ErrorIcon />;
            case 'SUCCESS':
                return <CheckCircleIcon/>;
            case 'APPROVED':
                return <ThumbUpIcon/>;
            case 'REJECTED':
                return <ThumbDownIcon/>;
            case 'PROCESSING':
                return <HourglassEmptyIcon />;
            case 'SUCCESS_WARNING':
                return <WarningIcon />;
            default:
        }
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <TimelineItem align="left" key={key}>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {time} {date}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot className={translateColorByStatus(status)}>
                    <Tooltip title={translateTitleByStatus(status)}>
                        {translateIconByStatus(status)}
                    </Tooltip>
                </TimelineDot>
            <TimelineConnector className={translateColorByStatus(status)} />
            </TimelineSeparator>
            <TimelineContent>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography component="h1">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography color="textSecondary">{subtitle}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                <Typography color="textSecondary">
                    {children}
                </Typography>
                </AccordionDetails>
            </Accordion>
            </TimelineContent>
        </TimelineItem>
    )
}