import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import toast from "../../utils/toast";
import DataTable from "../../components/DataTable";
import PreviewModal from "../../components/PreviewModal";
import ButtonAdd from "../../components/ButtonAdd";
import PageTitle from "../../components/PageTitle";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { pathRoutes } from "../../config/constants";
import { useHistory } from 'react-router-dom';
import costCenterApi from "../../services/costCenterApi";
import CostCenterFilter from "../../components/pages/costCenter/CostCenterFilter";
import CostCenterPreview from "../../components/pages/costCenter/CostCenterPreview";
import { useLoader } from "../../hooks/LoaderContext";
import SwitchStatus from "../../components/SwitchStatus";
import DataTableDefaultAction from "../../components/DataTableDefaultActions";

const DEFAULT_STATE = {
  isLoading: false,
  costCenters: [],
  costCenterInput: {
    title: "",
    id: ""
  }
};

export default function CentroCustos() {

  const [costCenterList, setCostCenterList] = useState(DEFAULT_STATE.costCenters);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [idPreview, setIdPreview] = useState(0);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [filters, setFilters] = useState('');
  const [forceRefreshData, setForceRefreshData] = useState(false);

  useEffect(() => {
    async function getAllCostsCenter() {
      showLoader();
      const costCenterResponse = await costCenterApi.findAllPageable(page - 1, rowsPerPage, filters);
      if (costCenterResponse) {
        setCostCenterList(costCenterResponse.data.response);
        setTotalPages(costCenterResponse.data.totalPages);
      } else {
        setCostCenterList(DEFAULT_STATE.costCenters);
        setTotalPages(0);
      }
      hideLoader();
    }
    getAllCostsCenter();
  }, [filters, forceRefreshData]);

  const handlePreview = (id) => {
    setIdPreview(id);
    setShowPreview(true);
  };


  const handleDelete = async (id) => {
    const deleted = await costCenterApi.delete(id);
    if (deleted) {
      if (costCenterList.length == 1) {
        setPage(page - 1);
      }
      setForceRefreshData(!forceRefreshData);
      toast.success(`Centro de custos deletado com sucesso.`);
    } else {
      toast.error(`Falha ao excluir centro de custos, favor verifique!.`);
    }
  };

  const handleChangeActive = async (id, status) => {
    const data = {
      id: id,
      active: status
    };
    const costCenter = await costCenterApi.status(data);
    if (costCenter) {
      if (costCenter.data.response[0].active) {
        toast.success(`Centro de custos ativado com sucesso.`);
      } else {
        toast.success(`Centro de custos inativado com sucesso.`);
      }
      setForceRefreshData(!forceRefreshData);
    }
  };

  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };

  const handleChangePage = async (event, value) => {
    setPage(value);
    setForceRefreshData(!forceRefreshData);
  };

  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  const handleEdit = async (id) => {
    history.push(`${pathRoutes.centroCustos}/edicao/${id}`)
  };

  const handleCreate = async (id) => {
    history.push(`${pathRoutes.centroCustosCadastro}`)
  };

  const handleClosePreview = (id) => {
    setShowPreview(false);
  };

  const header = ['Nome', 'Descrição', 'Data Inclusão', 'Situação', 'Ações'];

  const rows = costCenterList.map(costCenter => [
    costCenter.name,
    costCenter.description,
    costCenter.createdAt,
    <SwitchStatus
        id={costCenter.id} 
        active={costCenter.active}
        actions= {{
          onSwitch: (id, status) => handleChangeActive(id, status)
        }}
      />,
    <DataTableDefaultAction
        id={costCenter.id} 
        actions={{
          onEdit: () => handleEdit(costCenter.id),
          onDelete: () => handleDelete(costCenter.id),
          onPreview: () => handlePreview(costCenter.id)
        }} 
    />
  ]);

  return (
    <div className="container-fluid">
      <PageTitle title="Centro de Custos" icon={<AssignmentIcon style={{ color: 'gray' }} />} />
      <div className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <CostCenterFilter onFilter={handleFilter} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ButtonAdd title="Cadastrar Centro de Custos" onClick={handleCreate} />
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable header={header}
                rows={rows}
                totalPages={totalPages}
                page={page}
                onPage={handleChangePage}
                onRowsPerPage={handleChangeRowsPerPage} />
            </Box>
            <PreviewModal openDialog={showPreview}
                          onCloseDialog={handleClosePreview}
              width="70%" height="55%">
              <CostCenterPreview id={idPreview} />
            </PreviewModal>
          </Grid>
        </Grid>
      </div>
    </div>
  );

}