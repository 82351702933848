import React, {useEffect} from "react";
import BarChart from "../../../components/BarChart";
import { Box, Grid, Typography } from "@material-ui/core";
import PieChartFuturePayment from "../../../components/PieChartFuturePayment";
import LineChart from "../../../components/LineChart";
import { useNavBar } from "../../../hooks/NavbarContext";
import PieChartCurrentPayment from "../../../components/PieChartCurrentPayment";

export default function Dashboard({ history }) {

  const {
    setSelectedItem,
  } = useNavBar();

  useEffect(() => {
    setSelectedItem(null);
  }, []);

  return (
    <Box className="mb-4">
      <Box className="artical-1 out-shadow">
        <Typography variant="h5" style={{ fontSize: '1.1rem', textAlign: 'center', paddingTop: '5px', color: 'gray' }} gutterBottom>
            Totalizadores Financeiros
        </Typography>
      </Box>
      <Box className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <PieChartFuturePayment />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <PieChartCurrentPayment />
          </Grid>
        </Grid>
      </Box>
      <Box className="artical-1 out-shadow">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <BarChart />
          </Grid>
        </Grid>
      </Box>
        {/* <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
          <LineChart />
        </Grid> */}
     
      
    </Box>
  );

}
