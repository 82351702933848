import { pathRoutes } from "../../../../config/constants";
import OperacaoFiscalPadraoCadastro from "./index";

export default [
  {
    path: pathRoutes.operacaoFiscalConfigPadraoCadastro,
    component: OperacaoFiscalPadraoCadastro,
    exact: true,
  },

  {
    path: pathRoutes.operacaoFiscalConfigPadraoEdit,
    component: OperacaoFiscalPadraoCadastro,
    exact: true,
  },
];
