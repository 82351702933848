import { createContext, useContext, useState } from "react";

const NavbarContext = createContext(null);

export const NavBarProvider = ({children}) => {
  const [open, setOpen] = useState(true);
  const [openSubMenus, setOpenSubMenus] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);

    return (
        <NavbarContext.Provider value={{
            open, setOpen,
            openSubMenus, setOpenSubMenus,
            selectedItem, setSelectedItem
        }}>
            {children}
        </NavbarContext.Provider>
    ) 
}

export const useNavBar = () => {
    const context = useContext(NavbarContext);
    return context;
}

export default NavbarContext;