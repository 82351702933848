export const serialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        description: data.description,
        active: data.active,
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        code: data.code,
        description: data.description,
    }

}


export default {
    serialize
}