import { pathRoutes } from "../../../config/constants";
import ItemCadastro from "./index";

export default [
  {
    path: pathRoutes.itemCadastro,
    component: ItemCadastro,
    exact: true,
  },
  {
    path: pathRoutes.itemEdit,
    component: ItemCadastro,
    exact: true,
  },
];
