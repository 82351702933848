export const serialize = (data) => {
    return {
        id: data.id,
        name: data.name,
        document: data.document,
        certificate: data.certificate,
        passCertificate: data.passCertificate,
        expCertificate: data.expCertificate,
        logo: data.logo,
        environment: data.environment,
        active: data.active,
        organization: {
            id: data.organization ? data.organization.id : ""
        },
        supplier: {
            id: data.supplier ? data.supplier.id : ""
        },
        createdAt: data.createdAt
    }

}

export const deserialize = (data) => {
    return {
        id: data.id,
        name: data.name,
        document: data.document,
        certificate: data.certificate,
        passCertificate: data.passCertificate,
        expCertificate: data.expCertificate,
        logo: data.logo,
        environment: data.environment,
        active: data.active,
        organization: {
            id: data.organization ? data.organization.id : ""
        },
        supplier: {
            id: data.supplier ? data.supplier.id : ""
        },
        createdAt: data.createdAt
    }

}


export default{
    serialize
}