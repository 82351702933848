import { Box, Divider, Grid, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssessmentIcon from '@material-ui/icons/Assessment';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import aliquotaApi from "../../../services/aliquotaApi";
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD, selectModBc, selectModBcSt, selectMotDesIcms } from "../../../config/constants";
import AlertDialog from "../../../components/AlertDialog";
import ButtonRemove from "../../../components/ButtonRemove";
import { useLoader } from "../../../hooks/LoaderContext";
import taxTypeApi from "../../../services/taxTypeApi";
import { Autocomplete } from "@material-ui/lab";
import screenViewTaxTypeApi from "../../../services/screenViewTaxTypeApi";
import cstApi from "../../../services/cstApi";
import PercentFormatText from "../../../components/PercentFormatText";


const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    active: true,
    code: "",
    description: "",
    percentTax: "",
    taxType: {
      id: ""
    },
    cst: {
      id: ""
    },
  },
  taxTypes: [],
  taxTypeInput: {
    id: "",
    title: ""
  },
  csts: [],
  cstInput: {
    title: "",
    id: ""
  },
};

export default function AliquotaCadastro() {
  const [data, setData] = useState(DEFAULT_STATE.data);
  const history = useHistory();
  const [taxType, setTaxType] = useState(DEFAULT_STATE.taxTypeInput);
  const [taxTypeList, setTaxTypeList] = useState(DEFAULT_STATE.taxTypes);

  const [cst, setCst] = useState(DEFAULT_STATE.cstInput);
  const [cstList, setCstList] = useState(DEFAULT_STATE.csts);

  const params = useParams();
  const { showLoader, hideLoader } = useLoader();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [screenViewList, setScreenViewList] = useState([]);

  useEffect(() => {
    async function getAllTaxType() {
      const taxTypeResponse = await taxTypeApi.findAll();
      if (taxTypeResponse) {
        setTaxTypeList(taxTypeResponse.data.response);
      }
    }
    getAllTaxType();
  }, []);


  useEffect(() => {
    async function getAllCsts() {
      if (taxType.id) {
        const cstResponse = await cstApi.findAll(taxType.id);
        if (cstResponse) {
          setCstList(cstResponse.data.response);
        } else {
          setCstList(DEFAULT_STATE.csts);
        }
      }
    }
    getAllCsts();
  }, [taxType]);

  useEffect(() => {
    async function getAllScreenViewFields() {
      if (taxType.id && cst.id) {
        const screenViewResponse = await screenViewTaxTypeApi.findAll(taxType.id, cst.id ?? undefined);
        if (screenViewResponse) {
          setScreenViewList(screenViewResponse.data.response);
        } else {
          setScreenViewList([]);
        }
      }
    }
    getAllScreenViewFields();
  }, [taxType, cst]);

  useEffect(() => {
    async function findById() {
      showLoader();
      if (!isNaN(params.ident)) {
        const aliquota = await aliquotaApi.findById(params.ident);
        if (aliquota) {
          const data = {
            id: aliquota.data.response[0].id,
            code: aliquota.data.response[0].code,
            description: aliquota.data.response[0].description,
            percentTax: aliquota.data.response[0].percentTax,
            taxType: aliquota.data.response[0].taxType,
            cst: aliquota.data.response[0].cst,
            modBc: aliquota.data.response[0].modBc,
            modBcSt: aliquota.data.response[0].modBcSt,
            percentFcp: aliquota.data.response[0].percentFcp,
            percentFcpSt: aliquota.data.response[0].percentFcpSt,
            percentMvast: aliquota.data.response[0].percentMvast,
            percentRedBc: aliquota.data.response[0].percentRedBc,
            percentRedBcSt: aliquota.data.response[0].percentRedBcSt,
            percentIcmsSt: aliquota.data.response[0].percentIcmsSt,
            motDesIcms: aliquota.data.response[0].motDesIcms,
            percentDif: aliquota.data.response[0].percentDif,
            percentSt: aliquota.data.response[0].percentSt,
            percentFcpCtRet: aliquota.data.response[0].percentFcpCtRet,
            percentRedBcEffec: aliquota.data.response[0].percentRedBcEffec,
            percentIcmsEffec: aliquota.data.response[0].percentIcmsEffec,
            percentBcOp: aliquota.data.response[0].percentBcOp,
            percentIof: aliquota.data.response[0].percentIof,
            percentCredSn: aliquota.data.response[0].percentCredSn,
            cenq: aliquota.data.response[0].cenq
          };
          setData(data);
          setTaxType(
            {
              id: aliquota.data.response[0].taxType.id,
              title: aliquota.data.response[0].taxType.description
            }
          );
          setCst(
            {
              id: aliquota.data.response[0].cst?.id,
              title: aliquota.data.response[0].cst?.code
            }
          );
        }
      }
      hideLoader();
    }
    findById();
  }, []);

  const buildValidationSchema = () => {
    let validationObject = {
      code: Yup.string().required(REQUIRED_FIELD),
      description: Yup.string().required(REQUIRED_FIELD),
      taxType: Yup.object().shape({
        id: Yup.string().required(REQUIRED_FIELD)
      }),
    };

    screenViewList.forEach((item) => {
      if (item.required) {
        validationObject[item.fieldName] = Yup.string().required(REQUIRED_FIELD);
      }
    });

    return Yup.object().shape(validationObject);
  };

  const taxTypes = taxTypeList.map((taxType) => {
    return {
      id: taxType.id,
      title: taxType.description
    };
  });

  const csts = cstList.map(cst => ({
    id: cst.id,
    title: `${cst.code} - ${cst.description}`
  }));

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let aliquota;
      if (values.id.length === 0) {
        aliquota = await aliquotaApi.post(values);
      } else {
        aliquota = await aliquotaApi.put(values);
      }
      if (aliquota) {
        handleSuccess(values.id.length === 0);
      } else {
        handleError();
      }
      redirectToAliquota();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    const deleted = await aliquotaApi.delete(params.ident);
    if (deleted) {
      toast.success(`Aliquota deletada com sucesso.`);
    } else {
      toast.error(`Falha ao excluir aliquota, favor verifique!.`);
    }
    redirectToAliquota();
  };

  const handleSuccess = (isNew) => {
    const successMessage = isNew ? "Aliquota cadastrada com sucesso." : "Aliquota atualizada com sucesso.";
    toast.success(successMessage);
  };

  const handleError = () => {
    toast.error(`Falha ao criar Aliquota, favor verifique!`);
  };

  const redirectToAliquota = () => {
    history.goBack();
  };

  const handleChangeTaxType = (taxType) => {
    setTaxType(taxType);
  };

  const handleChangeCst = (value) => {
    setCst(value);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={isNaN(params.ident) ? 'Cadastrar Aliquota' : 'Editar Aliquota'} icon={<AssessmentIcon style={{ color: 'gray' }} />} />
        <BasicReturn />
        <Divider />
        <div className="artical-1 out-shadow">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={buildValidationSchema()}
            enableReinitialize
          >
            {({ isSubmitting, touched, errors, setFieldValue }) => (
              <Form className='mt-3'>
                <Grid container spacing={2}>
                  <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                      id="autocomplete-taxType"
                      options={taxTypes}
                      getOptionLabel={(option) => option.title}
                      value={taxType}
                      onChange={(event, value) => {
                        setFieldValue("taxType.id", value ? value.id : '');
                        handleChangeTaxType(value ? value : '');
                      }}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField {...params} label="Tipo de Imposto" variant="outlined"
                          error={touched.taxType?.id && !!(errors.taxType?.id)}
                          helperText={touched.taxType?.id && errors.taxType?.id}
                        />
                      )}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                    <Autocomplete
                      id="autocomplete-cst"
                      options={csts}
                      getOptionLabel={(option) => option.title}
                      clearText="Limpar"
                      disabled={!taxType.id}
                      value={cst}
                      onChange={(event, value) => {
                        setFieldValue("cst.id", value ? value.id : '');
                        handleChangeCst(value ? value : '')
                      }}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) =>
                        <TextField {...params} label="Cst" variant="outlined" />
                      }
                    />
                  </Grid>
                  <Grid className="mt-3 mb-1" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="code"
                      label="Código"
                      helperText={touched.code && errors.code ? <ErrorMessage name="code" /> : ""}
                      error={touched.code && Boolean(errors.code)}
                    />
                  </Grid>
                  <Grid className="mt-3 mb-1" item md={6} sm={6} xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="description"
                      label="Descrição"
                      helperText={touched.description && errors.description ? <ErrorMessage name="description" /> : ""}
                      error={touched.description && Boolean(errors.description)}
                    />
                  </Grid>
                      
                  {screenViewList.map((item) => {
                    if (item.fieldName === "percentTax") {
                      return (
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentTax"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual de Imposto"
                            helperText={touched.percentTax && errors.percentTax ? <ErrorMessage name="percentTax" /> : ""}
                            error={touched.percentTax && Boolean(errors.percentTax)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "modBc") {
                      return (
                        <Grid className="mt-3 mb-1" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            className="form-control"
                            name="modBc"
                            select
                            label="Modalidade de determinação da BC do ICMS">
                            {(selectModBc.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            )))}
                          </Field>
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcFcp") {
                      return (
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentFcp"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual do Fundo de Combate à Pobreza (FCP)"
                            helperText={touched.percentFcp && errors.percentFcp ? <ErrorMessage name="percentFcp" /> : ""}
                            error={touched.percentFcp && Boolean(errors.percentFcp)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcFcpSt") {
                      return (
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentFcpSt"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual do FCP retido por Substituição Tributári"
                            helperText={touched.percentFcpSt && errors.percentFcpSt ? <ErrorMessage name="percentFcpSt" /> : ""}
                            error={touched.percentFcpSt && Boolean(errors.percentFcpSt)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "modBcSt") {
                      return (
                        <Grid className="mt-3 mb-1" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            className="form-control"
                            name="modBcSt"
                            select
                            label="Modalidade de determinação da BC do ICMS ST">
                            {(selectModBcSt.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            )))}
                          </Field>
                        </Grid>
                      )
                    }
                    if (item.fieldName === "pcMvast") {
                      return (
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentMvast"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual da margem de valor Adicionado do ICMS ST"
                            helperText={touched.percentMvast && errors.percentMvast ? <ErrorMessage name="percentMvast" /> : ""}
                            error={touched.percentMvast && Boolean(errors.percentMvast)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcRedBc") {
                      return (
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentRedBc"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual da Redução de BC do ICMS"
                            helperText={touched.percentRedBc && errors.percentRedBc ? <ErrorMessage name="percentRedBc" /> : ""}
                            error={touched.percentRedBc && Boolean(errors.percentRedBc)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcRedBcSt") {
                      return (
                        <Grid className="mt-3 mb-2" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentRedBcSt"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual da Redução de BC do ICMS ST"
                            helperText={touched.percentRedBcSt && errors.percentRedBcSt ? <ErrorMessage name="percentRedBcSt" /> : ""}
                            error={touched.percentRedBcSt && Boolean(errors.percentRedBcSt)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcIcmsSt") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentIcmsSt"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Alíquota do imposto do ICMS ST"
                            helperText={touched.percentIcmsSt && errors.percentIcmsSt ? <ErrorMessage name="percentIcmsSt" /> : ""}
                            error={touched.percentIcmsSt && Boolean(errors.percentIcmsSt)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "motDesIcms") {
                      return (
                        <Grid className="mt-3 mb-1" item md={4} sm={4} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            className="form-control"
                            name="motDesIcms"
                            select
                            label="Motivo da desoneração do ICMS">
                            {(selectMotDesIcms.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            )))}
                          </Field>
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcDif") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentDif"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Alíquota do imposto do ICMS ST"
                            helperText={touched.percentDif && errors.percentDif ? <ErrorMessage name="percentDif" /> : ""}
                            error={touched.percentDif && Boolean(errors.percentDif)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcSt") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="pcSt"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Alíquota suportada pelo Consumidor Final"
                            helperText={touched.percentSt && errors.percentSt ? <ErrorMessage name="percentSt" /> : ""}
                            error={touched.percentSt && Boolean(errors.percentSt)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcFcpCtRet") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentFcpCtRet"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual do FCP retido anteriormente por Substituição Tributária"
                            helperText={touched.percentFcpCtRet && errors.percentFcpCtRet ? <ErrorMessage name="percentFcpCtRet" /> : ""}
                            error={touched.percentFcpCtRet && Boolean(errors.percentFcpCtRet)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcRedBcEffec") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentRedBcEffec"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual de redução da base de cálculo efetiva"
                            helperText={touched.percentRedBcEffec && errors.percentRedBcEffec ? <ErrorMessage name="percentRedBcEffec" /> : ""}
                            error={touched.percentRedBcEffec && Boolean(errors.percentRedBcEffec)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcIcmsEffec") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentIcmsEffec"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Alíquota do ICMS efetiva"
                            helperText={touched.percentIcmsEffec && errors.percentIcmsEffec ? <ErrorMessage name="percentIcmsEffec" /> : ""}
                            error={touched.percentIcmsEffec && Boolean(errors.percentIcmsEffec)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcBcOp") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentBcOp"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Percentual da BC operação própria"
                            helperText={touched.percentBcOp && errors.percentBcOp ? <ErrorMessage name="percentBcOp" /> : ""}
                            error={touched.percentBcOp && Boolean(errors.percentBcOp)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcCredSn") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentCredSn"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Alíquota aplicável de cálculo do crédito (SIMPLES NACIONAL)"
                            helperText={touched.percentCredSn && errors.percentCredSn ? <ErrorMessage name="percentCredSn" /> : ""}
                            error={touched.percentCredSn && Boolean(errors.percentCredSn)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "pcIof") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="percentIof"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              inputComponent: PercentFormatText,
                            }}
                            label="Imposto sobre Operações Financeiras"
                            helperText={touched.percentIof && errors.percentIof ? <ErrorMessage name="percentIof" /> : ""}
                            error={touched.percentIof && Boolean(errors.percentIof)}
                          />
                        </Grid>
                      );
                    }
                    if (item.fieldName === "cEnq") {
                      return (
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="cEnq"
                            label="Código de Enquadramento Legal do IPI"
                            helperText={touched.cEnq && errors.cEnq ? <ErrorMessage name="cEnq" /> : ""}
                            error={touched.cEnq && Boolean(errors.cEnq)}
                          />
                        </Grid>
                      );
                    }
                  })}
                  <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Box className="mt-1 c-inline-btn">
                        <Grid container spacing={2}>
                          {params.ident && (
                            <Grid item md={6} sm={6} xs={12}>
                              <ButtonRemove
                                title="excluir"
                                onClick={handleOpenDeleteModal}
                                variant="contained"
                                color="primary"
                                className="btn btn-primary btn-user btn-block"
                              />
                            </Grid>
                          )}
                          <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                            <ButtonSubmit
                              label="salvar"
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                              disable={isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AlertDialog title="Realmente deseja excluir?"
        description="O dado será excluído de forma permanente"
        confirmTitle="Confirmar"
        cancelTitle="Cancelar"
        openDialog={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
}