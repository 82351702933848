import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import { REQUIRED_FIELD } from '../../config/constants';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

export default function OrderCancelDialog(props) {

  const { openDialog, onConfirm, onCancel, number, description, title } = props;

  const validationSchema = Yup.object().shape({
    justify: Yup.string().required(REQUIRED_FIELD)
  });

  return (
    <div>

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deseja {title} o pedido #{number}?</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {description}
            </DialogContentText>
          </DialogContent>
        <Formik
          initialValues={{ justify: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => onConfirm(values.justify)}
          enableReinitialize
        >
          {({ touched, errors }) => (
            <Form>
              <DialogContent>
                <Field
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  name="justify"
                  label="Justificativa"
                  helperText={(touched.justify && errors.justify) && <ErrorMessage name="justify" />}
                  error={touched.justify && errors.justify}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Sair
                </Button>
                <Button type='submit' color="primary" variant="contained" autoFocus>
                  Confirmar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
