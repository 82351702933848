import { pathRoutes } from "../../config/constants";
import Empresa from "./index";

export default [
  {
    path: pathRoutes.empresa,
    component: Empresa,
    exact: true,
  },
];
