import { del, get, post, put, download, patch } from "../utils/request"
import { serialize } from "./mapper/CashFlowMapper";

export const create = async (data) => {
    return post("v1/cash-flow", serialize(data), `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export const update = async (data) => {
    return put("v1/cash-flow", serialize(data), `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export const status = async (data) => {
    return patch("v1/cash-flow", serialize(data), `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export const findAllPageable = async (page, size, filters) => {
    if (filters && filters != '') {
        return get(`v1/cash-flow/all?page=${page}&size=${size}${filters}`, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
    }
    return get(`v1/cash-flow/all?page=${page}&size=${size}`, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export const findById = async (id) => {
    return get(`v1/cash-flow/${id}`, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export const getReport = async (data, title) => {
    return download(`v1/report/?type=${data.type}&reportType=${data.reportType}&startDate=${data.startDate}&endDate=${data.endDate}&format=${data.format}`, title, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true, data.format);
}

export const remove = async (id) => {
    return del(`v1/cash-flow/${id}`, `${process.env.REACT_APP_BASE_URL_FINANCIAL}`, true);
}

export default {
    post : create,
    update,
    status,
    findAllPageable,
    findById,
    delete : remove,
    put : update,
    getReport
}