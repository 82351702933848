import { Box, Button, Grid, IconButton, Tooltip, } from "@material-ui/core";
import { useEffect, useState } from "react";
import ReportDialog from "../../../ReportDialog";
import cashFlowApi from "../../../../services/cashFlowApi";
import SearchIcon from '@material-ui/icons/Search';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useQueryState } from "../../../../hooks/QueryState";
import { format } from 'date-fns';
import DatePickerWrapper from "../../../FormattedDatePicker";

const DEFAULT_STATE = {
    openReportModal: false,
    isLoading: false
  };

export default function FluxoCaixaHeader(props) {

    const { onSearch } = props;
    const [openReportModal, setOpenReportModal] = useState(DEFAULT_STATE.openReportModal);
    const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);

    const [startDate, setStartDate] = useQueryState("dataInicial", format(new Date(), 'yyyy-MM-dd'));
    const [endDate, setEndDate] = useQueryState("dataFinal", format(new Date(), 'yyyy-MM-dd'));

    const handleChangeDataInicialFiltro = (date) => {
        setStartDate(date);
    };

    const handleChangeDataFinalFiltro = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {
        onSearch(`&startDate=${startDate}&endDate=${endDate}`)
    }

    useEffect(() => {
        handleSearch();
    }, [])

    const openReportDialog = () => {
        setOpenReportModal(true);
    }

    const handleCloseReportDialog = () => {
        setOpenReportModal(DEFAULT_STATE.openReportModal);
    }

    const deParaReport = (reportName) => {
        if (reportName == 'ANALYTIC') {
          return "Relatorio_Analitico";
        }
        if (reportName == 'CHART_ACCOUNT') {
          return "Relatorio_Plano_Contas"
        }
        if (reportName == 'SUPPLIER') {
          return "Relatorio_Fornecedor"
        }
        if (reportName == 'COST_CENTER') {
          return "Relatorio_Centro_Ceusto"
        }
        if (reportName == 'ANALYTIC_SUPPLIER') {
          return "Relatorio_Analitico_Fornecedor"
        }
        if (reportName == 'ITEM') {
          return "Relatorio_Item"
        }
    }

    const handleConfirmReport = async (data) => {
        setIsLoading(true);
        const report = await cashFlowApi.getReport(data, deParaReport(data.reportType));
        setIsLoading(DEFAULT_STATE.isLoading);
        return report;
    }

    return (
        <Box className="artical-1 out-shadow" >
            <Grid container spacing={2} justifyContent="center">
                <Grid className="mt-3 mb-2" item md={3} sm={2} xs={12}>
                    <DatePickerWrapper
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-data-inicial"
                        value={startDate}
                        onChange={handleChangeDataInicialFiltro}
                        label="Competência final"
                        style={{width:"100%"}}/>
                    </Grid>
                <Grid className="mt-3 mb-2" item md={3} sm={3} xs={12}>
                    <DatePickerWrapper
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-data-final"
                        value={endDate}
                        onChange={handleChangeDataFinalFiltro}
                        label="Competência final"
                        style={{width:"100%"}}/>
                </Grid>
                <Grid className="mt-4 mb-1" item md={1} sm={1} xs={12}>
                <Tooltip title="Filtrar">
                    <IconButton color="primary" onClick={() => handleSearch()}>
                        <SearchIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
                </Grid>
                <Grid className="mt-5 mb-2"  item md={2} sm={2} xs={12}>
                    <Button label="Relatórios"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={openReportDialog}
                            startIcon={<AssessmentIcon/>}
                            className="btn btn-primary btn-user btn-block">
                            Relatórios
                    </Button>
                </Grid>
            </Grid>
            <ReportDialog title="Geração de Relatórios" 
                   confirmTitle="Gerar"
                   cancelTitle="Sair"
                   isLoading={isLoading}
                   openDialog={openReportModal}
                   filterStartDate={startDate}
                   filterEndDate={endDate}
                   onCancel={handleCloseReportDialog}
                   onConfirm={handleConfirmReport}/>
        </Box>
    );
}